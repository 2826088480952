import React, { useState, useEffect } from "react";
import packageJson from "../../package.json";

export default function ClearCache(props) {

  const [isLatestBuild, setIsLatestBuild] = useState(false);

  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.version;
        const currentVersionDate = packageJson.version;

        const shouldForceRefresh = semverGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          setIsLatestBuild(false);
          refreshCacheAndReload();
        } else {
          setIsLatestBuild(true);
        }
      });
  }, []);

  const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
    
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  };

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  return (
    <>
      {isLatestBuild ? props.children : null} 
    </>
  );
}