import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import i18n from "../../../locales/i18n";
import RiskCalculation from "../../../helpers/riskCalculation.json";
import AlertDialog from "../../../components/AlertDialog";
import MaterialTable, { MTableGroupRow } from "material-table";
import ScrollDialog from "../../ScrollDialog";
import { Button } from "@material-ui/core";
import { ControlPoint, GetApp, Publish, Refresh } from "@material-ui/icons";
import {
  EquiposAPI,
  PlantasAPI,
  SiteAssessmentVersionsAPI,
} from "../../../services/API";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { Common_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import AppContext from "../../../contexts/AppContext";
import { SnackbarSuccess } from "../../../helpers/Common";

const materialTableTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
  },
});

export default function InventarioTable(props) {
  const { plantaId, keyuser, isGestorThisPartner, nombre } = props;

  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const createSiteAssessmentDialog = useRef(null);
  const [equipos, setEquipos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [isUpload, setIsUpload] = useState(true);

  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  const fileInputRef = useRef(null);

  const getEquiposByplanta = useCallback(async () => {
    setLoading(true);
    await EquiposAPI.getEquiposByplanta(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        data = data.map((equipo) => getRisk(equipo));
        setEquipos(data);
      })
      .catch((error) => {
        setLoading(false);
      });

    setLoading(false);
  }, [plantaId]);

  const getRisk = (equipo) => {
    var risk = RiskCalculation.find(
      (c) =>
        c.lifeCycle === equipo.cicloVida &&
        c.criticality === equipo.criticidad &&
        c.condition === equipo.estado
    );
    if (!risk) {
      equipo.riesgoText = "N/A";
      return equipo;
    }
    switch (risk.result) {
      case 0:
        equipo.riesgoText = i18n.t("common.alto");
        break;
      case 1:
        equipo.riesgoText = i18n.t("common.medio");
        break;
      case 2:
        equipo.riesgoText = i18n.t("common.bajo");
        break;
      default:
        equipo.riesgoText = "N/A";
        break;
    }
    equipo.riesgo = risk.result;
    return equipo;
  };

  const uploadExcel = async () => {
    setLoading(true);
    setIsUpload(true);
    const file = fileInputRef.current.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("locale", i18n.locale);

    await EquiposAPI.uploadInventario(plantaId, formData)
      .then(async (response) => {
        if (response.ok) {
          getEquiposByplanta();
          return response.json();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        setDialogText(data);
        setDialogOpen(true);
      })
      .catch((error) => {
        console.error(error.message);
      });

    setLoading(false);
  };

  const updateLifeCycle = () => {
    setLoading(true);
    setIsUpload(false);
    PlantasAPI.updateLifeCycleOfDrives(plantaId)
      .then(async (response) => {
        if (response.ok) {
          getEquiposByplanta();
          return response.json();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        setDialogText(data);
        setDialogOpen(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const createSiteAssessment = () => {
    createSiteAssessmentDialog.current.close();
    setLoading(true);
    setIsUpload(false);
    SiteAssessmentVersionsAPI.postSiteAssessmentVersions(plantaId)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        SnackbarSuccess(setGlobal, i18n.t("common.success"));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getEquiposByplanta();
  }, [getEquiposByplanta]);

  return (
    <>
      <ScrollDialog
        open={dialogOpen}
        title={
          isUpload
            ? i18n.t("dataTableProps.dialog.import")
            : i18n.t("dataTableProps.dialog.refresh")
        }
        text={dialogText}
        handleClose={() => setDialogOpen(false)}
      />
      {(keyuser || isGestorThisPartner) && (
        <div className={commonStyles.headerContainer}>
          <input
            type="file"
            ref={fileInputRef}
            accept=".xlsx"
            onChange={uploadExcel}
            style={{ display: "none" }}
          />
          <div className={commonStyles.header}>
            {/* <Button
              onClick={() => {
                updateLifeCycle();
              }}
              variant="outlined"
              endIcon={<Refresh />}
              className={commonStyles.secondaryButtonRaw}
            >
              {i18n.t("dataTableProps.toolbar.refreshLifeCycle")}
            </Button> */}
            <Button
              onClick={() => {
                createSiteAssessmentDialog.current.open();
              }}
              variant="outlined"
              endIcon={<ControlPoint />}
              className={commonStyles.secondaryButtonRaw}
            >
              {i18n.t("dataTableProps.toolbar.createSiteAssessment.button")}
            </Button>
          </div>
        </div>
      )}
      <div>
        {/* @ts-ignore */}
        <ThemeProvider theme={materialTableTheme}>
          {/* @ts-ignore */}
          <MaterialTable
            style={{ width: "100%" }}
            title={null}
            // @ts-ignore
            localization={i18n.t("dataTableProps")}
            isLoading={loading}
            data={equipos}
            options={{
              sorting: true,
              showTitle: false,
              searchFieldAlignment: "left",
              pageSize: 10,
              grouping: true,
            }}
            components={{
              GroupRow: (props) => {
                const { groupData } = props;
                var value = (
                  <span>
                    {groupData.value === 0
                      ? "0"
                      : !groupData.value
                      ? "N/A"
                      : groupData.value}{" "}
                    -{" "}
                    <b>
                      {groupData.data.length === 0
                        ? groupData.groups.length
                        : groupData.data.length}{" "}
                      {i18n.t("common.unidades")}
                    </b>
                  </span>
                );
                var newGroupData = { ...groupData, value: value };
                // @ts-ignore
                return <MTableGroupRow {...props} groupData={newGroupData} />;
              },
            }}
            columns={[
              {
                field: "idCliente",
                title: i18n.t("equipo.idCliente"),
                grouping: false,
                render: (equipo) => (
                  <Link
                    className={styles.linkColor}
                    target="_blank"
                    to={`${plantaId}/equipos/${equipo.id}`}
                  >
                    {equipo.idCliente || i18n.t("common.noId")}
                  </Link>
                ),
              },
              {
                field: "nombre",
                title: i18n.t("equipo.nombre"),
                grouping: false,
                render: (equipo) => (
                  <Link
                    className={styles.linkColor}
                    target="_blank"
                    to={`${plantaId}/equipos/${equipo.id}`}
                  >
                    {equipo.nombre || i18n.t("common.noName")}
                  </Link>
                ),
              },
              {
                field: "descripcion",
                title: i18n.t("equipo.descripcion"),
                render: (equipo) =>
                  equipo.descripcion || i18n.t("common.noDescription"),
              },
              { field: "tc", title: "TC / TYPE" },
              { field: "pn", title: "PN", defaultGroupOrder: 1 },
              { field: "zona.descripcion", title: i18n.t("equipo.zona") },
              {
                field: "añoFabricacion",
                title: i18n.t("equipo.antiguedad"),
                searchable: false,
              },
              {
                field: "powerSize",
                title: i18n.t("equipo.powerSize"),
                searchable: false,
                width: 135,
              },
              {
                field: "voltage",
                title: i18n.t("equipo.voltage"),
                searchable: false,
                width: 135,
              },
              {
                field: "riesgoText",
                title: i18n.t("equipo.riesgo"),
                searchable: false,
                width: 135,
              },
            ]}
            actions={[
              keyuser && {
                icon: () => <GetApp />,
                tooltip: i18n.t("dataTableProps.toolbar.exportInventory"),
                isFreeAction: true,
                onClick: () => {
                  var exportEquipos = equipos
                    .map((row) => {
                      if (row.data && row.data.length > 0) {
                        return row.data.flat();
                      } else if (row.groups && row.groups.length > 0) {
                        var groups = row.groups;
                        var data = row.data;

                        do {
                          if (groups.length > 0) {
                            data = groups.map((g) => g.data).flat();
                            groups = groups.map((g) => g.groups).flat();
                          }
                        } while (groups.length > 0);

                        return data.flat();
                      } else {
                        return row;
                      }
                    })
                    .flat();

                  exportEquipos = exportEquipos.map((e) => {
                    if (e.retrofit === "") e.retrofit = "N/A";
                    return e;
                  });

                  EquiposAPI.getExportInventario(exportEquipos, i18n.locale)
                    .then((response) => response.blob())
                    .then((blob) => {
                      var url = window.URL.createObjectURL(blob);
                      var a = document.createElement("a");
                      a.href = url;
                      a.download =
                        i18n.locale === "en"
                          ? "Inventory " + nombre + ".xlsx"
                          : "Inventario " + nombre + ".xlsx";
                      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                      a.click();
                      a.remove(); //afterwards we remove the element again
                    });
                },
              },
              keyuser
                ? {
                    icon: () => <Publish />,
                    tooltip: i18n.t("dataTableProps.toolbar.importCSV"),
                    isFreeAction: true,
                    onClick: () => {
                      fileInputRef.current.value = "";
                      fileInputRef.current.click();
                    },
                  }
                : null,
              {
                icon: "refresh",
                tooltip: i18n.t("alarma.actualizar"),
                isFreeAction: true,
                onClick: (event) => {
                  getEquiposByplanta();
                },
              },
            ]}
          />
        </ThemeProvider>
      </div>
      {/* @ts-ignore */}
      <AlertDialog
        ref={createSiteAssessmentDialog}
        title={i18n.t("dataTableProps.toolbar.createSiteAssessment.title")}
        text={i18n.t("dataTableProps.toolbar.createSiteAssessment.text")}
        confirmText={i18n.t("common.aceptar")}
        cancelText={i18n.t("common.cancelar")}
        confirmAction={() => createSiteAssessment()}
      />
    </>
  );
}
