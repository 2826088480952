import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import moment from "moment";
import i18n from "../locales/i18n";
import { EquipoScheduledPreventiveMaintenancesAPI } from "../services/API";
import PreventiveMaintenanceType from "./PreventiveMaintenance_Type";
import PreventiveMaintenanceState from "./PreventiveMaintenance_State";
import RelativeBackdrop from "./RelativeBackdrop";
import { Common_Style, DetailTabs_Style } from "../assets/css/Styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function ScheduledPreventiveMaintenance(props) {
  const { filteredData } = props;
  const [loading, setLoading] = useState(false);
  const [schePrevMaint, setSchePrevMaint] = useState([]);

  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  const getScheduledPreventiveMaintinance = useCallback(() => {
    setLoading(true);

    const currentYear = new Date().getFullYear().toString();
    EquipoScheduledPreventiveMaintenancesAPI.getScheduledInYear(currentYear)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setSchePrevMaint(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (filteredData) {
      setSchePrevMaint(filteredData);
    } else {
      getScheduledPreventiveMaintinance();
    }
  }, [filteredData, getScheduledPreventiveMaintinance]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <Card>
        <CardHeader
          title={i18n.t("dashboard.pmYear", {
            year: new Date().getFullYear(),
          })}
          avatar={
            <Avatar aria-label="avatar" className={styles.tabHeaderAvatar}>
              <CalendarToday />
            </Avatar>
          }
        />
        <PerfectScrollbar className={commonStyles.scrollCard}>
          <Table
            size="small"
            className={commonStyles.scrollCardContent}
            data-cy="ScheduledPreventiveMaintenanceTable"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">{i18n.t("equipo.nombre")}</TableCell>
                <TableCell align="center">{i18n.t("equipo.zona")}</TableCell>
                <TableCell align="center">
                  {i18n.t("planta.tituloCard")}
                </TableCell>
                <TableCell align="center">{i18n.t("planta.cliente")}</TableCell>
                <TableCell align="center">
                  {i18n.t("maintenance.altualScheduled.date")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("maintenance.altualScheduled.type")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("maintenance.altualScheduled.state")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schePrevMaint.length > 0 &&
                schePrevMaint.map((row) => (
                  <TableRow
                    key={row.id}
                    style={{ height: 45, cursor: "pointer" }}
                    onClick={() =>
                      window
                        .open(
                          "./plantas/" +
                            row.plantaId +
                            "/zonas/" +
                            row.zonaId +
                            "/equipos/" +
                            row.equipoId,
                          "_blank"
                        )
                        .focus()
                    }
                  >
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {row.equipoName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {row.zonaName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {row.plantaName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {row.clienteName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {moment(row.scheduledDate).local().format("DD/MM/yyyy")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <PreventiveMaintenanceType type={row.type} />
                    </TableCell>
                    <TableCell align="center">
                      <PreventiveMaintenanceState
                        state={row.state}
                        isProvisionalDate={row.isProvisionalDate}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {schePrevMaint.length === 0 && (
                <TableRow style={{ height: 45 }}>
                  <TableCell colSpan={7} align="center">
                    {i18n.t(
                      "maintenance.altualScheduled.emptyDataSourceMessage"
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Card>
    </RelativeBackdrop>
  );
}
