import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Room, HomeWorkOutlined } from "@material-ui/icons";
import React from "react";
import withHover from "../../withHover";
import i18n from "../../../locales/i18n";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import DriveIcon from "../../atoms/driveIcon";
const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

export default function ZonaCard(props) {
  const { zona } = props;
  const styles = DetailTabs_Style();

  return (
    <CardWithHover
      {...props}
      style={{ position: "relative" }}
      data-cy="CardInfo"
    >
      <div className={styles.pillContainer}>
        {zona.isWarehouse && (
          <div className={styles.flexPill}>
            <span className={styles.pillText}>{i18n.t("zona.warehouse")}</span>
          </div>
        )}
      </div>

      <CardHeader
        classes={{ content: "overflow-hidden" }}
        titleTypographyProps={{ noWrap: true, variant: "body2" }}
        title={zona.descripcion}
        subheader={
          zona.isWarehouse ? i18n.t("zona.warehouse") : i18n.t("zona.itemTitle")
        }
        avatar={
          <Avatar aria-label="avatar" className={styles.tabHeaderAvatar}>
            {zona.isWarehouse ? <HomeWorkOutlined /> : <Room />}
          </Avatar>
        }
      />
      <CardContent>
        <Typography variant="body2" noWrap>
          <Room
            className="mr-1"
            color="action"
            style={{
              fontSize: 21,
              verticalAlign: "bottom",
            }}
          />
          {zona.numSubZonas +
            " " +
            (zona.numSubZonas === 1
              ? i18n.t("zona.ContSingular")
              : i18n.t("zona.ContPlural"))}
        </Typography>
        <Typography variant="body2" className="mt-2" noWrap>
          <DriveIcon
            className="mr-1"
            color="#757575"
            style={{
              height: 20,
              width: 20,
              verticalAlign: "bottom",
              marginRight: 5,
            }}
          />
          {zona.numEquipos + " " + i18n.t("equipo.Abbreviated")}
        </Typography>
      </CardContent>
    </CardWithHover>
  );
}
