import React, { useEffect, useState, useCallback, useContext } from "react";
import i18n from "../../../locales/i18n";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  Room,
  ViewComfy,
  ViewHeadline,
  AddCircleOutline,
} from "@material-ui/icons";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";
import { ZonasAPI } from "../../../services/API";
import { filter } from "../../../helpers/Filter";
import SearchField from "../../molecule/SearchField/SearchField";
import ZonaCard from "../../molecule/ZonaCard/ZonaCard";
import ZonaRow from "../../molecule/ZonaRow/ZonaRow";
import RelativeBackdrop from "../../RelativeBackdrop";
import AuthService from "../../../services/AuthService";
import ZonaGrid from "../../molecule/ZonaRow/ZonaGrid";
import {
  DetailTabs_Style,
  CardList_Style,
  Common_Style,
} from "../../../assets/css/Styles";
import AppContext from "../../../contexts/AppContext";
import classNames from "classnames";

export default function ZonaSearchAndAdd(props) {
  const { zonaId, plantaId, readOnly, history, goBack } = props;
  const [showRequiredText, setShowRequiredText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newZona, setNewZona] = useState({ descripcion: "" });
  const [zonaDialogOpen, setZonaDialogOpen] = useState(false);
  const [zonas, setZonas] = useState([]);
  const [zonasSearch, setZonasSearch] = useState("");
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [viewMode, setViewMode] = useState(
    localStorage.getItem("viewMode") || "Card"
  );
  const styles = DetailTabs_Style();
  const cardStyles = CardList_Style();
  const commonStyles = Common_Style();

  const getZonas = useCallback(async () => {
    setLoading(true);
    ZonasAPI.getNextLevelOfZones(plantaId, zonaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setZonas(data.zonas);
        setLoading(false);
        AuthService.updateUserAccessPermisions(data.userAccessPermissions);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [plantaId, zonaId]);

  const addZona = async () => {
    let zona = {
      plantaId: plantaId,
      plantaZonaId: zonaId,
      descripcion: newZona.descripcion,
    };

    setLoading(true);

    ZonasAPI.add(zona)
      .then(async (response) => {
        if (response.ok) {
          SnackbarSuccess(setGlobal, i18n.t("zona.snackbar.new.success"));
          return response.json();
        } else {
          setShowRequiredText(newZona.descripcion === "");
          var error = await response.json();
          throw error;
        }
      })
      .then((zona) => {
        setLoading(false);
        setZonaDialogOpen(false);
        setNewZona({ descripcion: "" });
        AuthService.addTempAccessToZone(zona.id);
        getZonas();
      })
      .catch((e) => {
        setLoading(false);
        if (e.message === "unique") {
          setZonaDialogOpen(false);
          setNewZona({ descripcion: "" });
          SnackbarError(setGlobal, i18n.t("zona.snackbar.new.duplicated"));
        } else {
          SnackbarError(setGlobal, i18n.t("zona.snackbar.new.error"));
        }
      });
  };

  const onChangeViewMode = (mode) => {
    if (mode) {
      setViewMode(mode);
      localStorage.setItem("viewMode", mode);
    } else {
      if (viewMode === "Card") {
        setViewMode("List");
        localStorage.setItem("viewMode", "List");
      } else if (viewMode === "List") {
        setViewMode("Grid");
        localStorage.setItem("viewMode", "Grid");
      } else {
        //if (viewMode === "Grid")
        setViewMode("Card");
        localStorage.setItem("viewMode", "Card");
      }
    }
  };

  const onZoneClick = (zona) => {
    zonaId
      ? history.push(`./${zona.id}`)
      : history.push(`./${plantaId}/zonas/${zona.id}`);
  };

  useEffect(() => {
    getZonas();
  }, [getZonas]);

  useEffect(() => {}, []);

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.searchZonesContainer}>
          <div className={commonStyles.searchActionField}>
            <SearchField
              style={{ minWidth: 200 }}
              inputBaseProps={{
                placeholder: i18n.t("common.searchZone"),
                value: zonasSearch,
                onChange: (event) => setZonasSearch(event.target.value),
              }}
              goBack={goBack}
            />
            {!readOnly && (
              <Button
                variant="contained"
                className={commonStyles.primaryButtonRaw}
                onClick={() => {
                  setZonaDialogOpen(true);
                  setNewZona({ descripcion: "" });
                }}
              >
                <AddCircleOutline className={styles.addCircleOutline} />
                {i18n.t("common.new.femenine")} {i18n.t("common.zona")}
              </Button>
            )}
          </div>
          <Paper>
            <Button
              aria-label="view mode"
              disabled={viewMode === "Card"}
              onClick={() => onChangeViewMode("Card")}
              style={{ height: "100%" }}
            >
              <ViewComfy />
            </Button>
            <Button
              aria-label="view mode"
              disabled={viewMode === "Grid"}
              onClick={() => onChangeViewMode("Grid")}
              style={{ height: "100%" }}
            >
              <ViewHeadline />
            </Button>
          </Paper>
        </div>
        {viewMode === "Grid" ? (
          <div
            className={classNames([
              styles.zoneGridContainer,
              commonStyles.scrollCard,
            ])}
          >
            {zonas &&
              filter(zonas, zonasSearch)
                .sort((a, b) => (a.descripcion < b.descripcion ? -1 : 1))
                .map((zona) => {
                  return (
                    <ZonaGrid
                      key={zona.id}
                      zona={zona}
                      onClick={() => onZoneClick(zona)}
                    />
                  );
                })}
          </div>
        ) : (
          <div className={cardStyles.userZoneCardContainer}>
            {zonas &&
              filter(zonas, zonasSearch)
                .sort((a, b) => (a.descripcion < b.descripcion ? -1 : 1))
                .map((zona) => {
                  return viewMode === "Card" ? (
                    <div key={zona.id} className={cardStyles.userZoneCard}>
                      <ZonaCard zona={zona} onClick={() => onZoneClick(zona)} />
                    </div>
                  ) : (
                    //viewMode === "List"
                    <div key={zona.id} className={styles.zoneList}>
                      <ZonaRow zona={zona} onClick={() => onZoneClick(zona)} />
                    </div>
                  );
                })}
          </div>
        )}
        <Dialog
          open={zonaDialogOpen}
          onClose={() => {
            setZonaDialogOpen(false);
            setShowRequiredText(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Room className={styles.dialogIcon} />{" "}
            {i18n.t("common.new.femenine")} {i18n.t("common.zona")}
          </DialogTitle>
          <DialogContent style={{ minWidth: 350 }}>
            <TextField
              required
              name="descripcion"
              className={styles.materialTextField}
              defaultValue={newZona.descripcion}
              autoFocus
              margin="dense"
              label={i18n.t("zona.descripcion")}
              fullWidth
              onChange={(event) =>
                setNewZona({ descripcion: event.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                showRequiredText ? i18n.t("textField.required") : null
              }
              error={newZona.descripcion === ""}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              className={styles.closeDialogButton}
              onClick={() => {
                setZonaDialogOpen(false);
                setShowRequiredText(false);
              }}
              style={{ textTransform: "none" }}
            >
              {i18n.t("common.cancel")}
            </Button>
            <Button
              onClick={() => addZona()}
              className={styles.acceptDialogButton}
            >
              {i18n.t("common.create")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </RelativeBackdrop>
  );
}
