export default class PartnerBase {
  /** @type {number} */ id = undefined;
  /** @type {string} */ cif = null;
  /** @type {string} */ razonSocial = null;
  /** @type {string} */ direccion = null;
  /** @type {string} */ cp = null;
  /** @type {string} */ poblacion = null;
  /** @type {string} */ provincia = null;
  /** @type {number} */ countryId = null;
  /** @type {string} */ email = null;
  /** @type {string} */ nombreAbreviado = null;
  /** @type {string} */ avatar = null;
  /** @type {string} */ logoURL = null;
  /** @type {string} */ loginParameter = null;
  /** @type {boolean} */ isDanfoss = false;
  /** @type {boolean} */ isPremium = false;

  /**@param {Object} IntiObj  */
  constructor(IntiObj) {
    if (IntiObj.id) {
      this.id = IntiObj.id;
    }
    if (IntiObj.cif) {
      this.cif = IntiObj.cif;
    }
    if (IntiObj.razonSocial) {
      this.razonSocial = IntiObj.razonSocial;
    }
    if (IntiObj.direccion) {
      this.direccion = IntiObj.direccion;
    }
    if (IntiObj.cp) {
      this.cp = IntiObj.cp;
    }
    if (IntiObj.poblacion) {
      this.poblacion = IntiObj.poblacion;
    }
    if (IntiObj.provincia) {
      this.provincia = IntiObj.provincia;
    }
    if (IntiObj.countryId) {
      this.countryId = IntiObj.countryId;
    }
    if (IntiObj.email) {
      this.email = IntiObj.email;
    }
    if (IntiObj.nombreAbreviado) {
      this.nombreAbreviado = IntiObj.nombreAbreviado;
    }
    if (IntiObj.avatar) {
      this.avatar = IntiObj.avatar;
    }
    if (IntiObj.logoURL) {
      this.logoURL = IntiObj.logoURL;
    }
    if (IntiObj.loginParameter) {
      this.loginParameter = IntiObj.loginParameter;
    }
    if (IntiObj.isDanfoss) {
      this.isDanfoss = IntiObj.isDanfoss;
    }
    if (IntiObj.isPremium) {
      this.isPremium = IntiObj.isPremium;
    }
  }
}
