import { baseUrl } from "../helpers/Url";
import AuthService from "./AuthService";

export function Get(days) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/apiactivity/${days}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function GetUserCount() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/apiactivity/userscount`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function GetUsersLastDays(days) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/apiactivity/userslasdays/${days}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function GetActiveUsersLastDays(days) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/apiactivity/activeuserslastdays/${days}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}
