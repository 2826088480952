import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function getfilters() {
    const userInfo = AuthService.getUserInfo();
    const access_token = userInfo.access_token;

    return fetch(`${baseUrl}/api/dashboard/filters`, {
        headers: { Authorization: `Bearer ${access_token}` }
    });
}

export function getHeader() {
    const userInfo = AuthService.getUserInfo();
    const access_token = userInfo.access_token;

    return fetch(`${baseUrl}/api/dashboard/dashboardHeader`, {
        headers: { Authorization: `Bearer ${access_token}` }
    });
}