import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import i18n from "../locales/i18n";
import { DetailTabs_Style, Common_Style } from "../assets/css/Styles";

export default function ScrollDialog({ open, text, title, handleClose }) {
  const descriptionElementRef = React.useRef(null);
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { maxWidth: "none" } }}
      scroll="paper"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText
          ref={descriptionElementRef}
          tabIndex={-1}
          style={{ whiteSpace: "pre" }}
        >
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => {
            e.preventDefault();
            /** El API de clipboard usada para este codigo solo
             * funciona en paginas seguras HTTPS o desarrollo */
            navigator.clipboard.writeText(text);
          }}
          variant="outlined"
          className={styles.secondaryButton}
        >
          {i18n.t("common.copyClipboard")}
        </Button>
        <Button
          onClick={handleClose}
          className={commonStyles.primaryButton}
          variant="contained"
        >
          {i18n.t("common.cerrar")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
