import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/countries`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getWithRegion() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/countries/withRegion`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}
