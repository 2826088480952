/* eslint-disable no-loop-func */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import {
  LocalPrintshop,
  Settings,
  FilterList,
  Equalizer,
  Autorenew,
} from "@material-ui/icons";
import Report from "./pdf/ReportPDF";
import { ClientesAPI, EquiposAPI, PartnersAPI } from "../../../services/API";
import DashboardFilters, {
  defaultFilters,
} from "../../organism/dashboardFilters/dashboardFilters";
import ReportOptions from "./ReportOptions";
import GeneralOptions from "./tabOptions/GeneralOptions";
import { TabReportPlantaStyle } from "../../../assets/css/Styles";
import i18n from "../../../locales/i18n";
import CoverOptions from "./tabOptions/CoverOptions";
import AuthService from "../../../services/AuthService";
import ResultGraphs from "./tabOptions/ResultGraphs";
import moment from "moment";
import RelativeBackdrop from "../../RelativeBackdrop";
import { pdf } from "@react-pdf/renderer";
import NoData from "../../atoms/noData";
import {
  CoverOptionsModel,
  SummaryOptionsModel,
  PreManOptionsModel,
  RecommendationOptionsModel,
  AnalysisOptionsModel,
  ReportOptionsModel,
  EmStockOptionsModel,
  ShowTabsOptionsModel,
} from "./pdf/helpers";
import AlertDialog from "../../../components/AlertDialog";
import { SnackbarWarning, splitArray } from "../../../helpers/Common";
import AppContext from "../../../contexts/AppContext";

function TabReportPlanta({ history, plant, isPremium }) {
  const picturesB64 = {
    family: useRef(""),
    year: useRef(""),
    lifeCycle: useRef(""),
    criticality: useRef(""),
    condition: useRef(""),
    risk: useRef(""),
  };

  const pictureRefs = {
    family: useRef(null),
    year: useRef(null),
    lifeCycle: useRef(null),
    criticality: useRef(null),
    condition: useRef(null),
    risk: useRef(null),
  };

  /**
   * Metodo para generar las imagenes de las graficas para la pestaña de resultados (Result).
   */
  const genChart = async () => {
    if (assessment) {
      let family =
        pictureRefs.family.current &&
        (await pictureRefs.family.current.toBase64Image());
      picturesB64.family.current = family;
      let year =
        pictureRefs.year.current &&
        (await pictureRefs.year.current.toBase64Image());
      picturesB64.year.current = year;
      let lifeCycle =
        pictureRefs.lifeCycle.current &&
        (await pictureRefs.lifeCycle.current.toBase64Image());
      picturesB64.lifeCycle.current = lifeCycle;
      let criticality =
        pictureRefs.criticality.current &&
        (await pictureRefs.criticality.current.toBase64Image());
      picturesB64.criticality.current = criticality;
      let condition =
        pictureRefs.condition.current &&
        (await pictureRefs.condition.current.toBase64Image());
      picturesB64.condition.current = condition;
      let risk =
        pictureRefs.risk.current &&
        (await pictureRefs.risk.current.toBase64Image());
      picturesB64.risk.current = risk;
    }
  };

  // @ts-ignore
  const { setGlobal } = useContext(AppContext);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const download = (filename, url) => {
    const element = document.createElement("a");
    element.setAttribute("href", `${url}`);
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);

  const report = (tab, allDrivesSliced, preManDrivesSpliced) =>
    allDrives &&
    assessment &&
    preManDrives && (
      <Report
        tab={tab || 0}
        showTabsOptions={showTabsOptions}
        reportOptions={reportOptions}
        coverOptions={coverOptions}
        analysisOptions={analysisOptions}
        summaryOptions={summaryOptions}
        recommendationOptions={recommendationOptions}
        preManOptions={preManOptions}
        emStockOptions={emStockOptions}
        pictures={picturesB64}
        partnerLogo={partnerLogo}
        clientReportLogo={clientReportLogo}
        allDrives={allDrivesSliced || allDrives}
        assessment={assessment}
        preManDrives={preManDrivesSpliced || preManDrives}
        emStock={emStock}
      />
    );

  const confirmBigPrintDialog = useRef(null);
  const confirmDraftPrintDialog = useRef(null);

  const maxLength = 500;
  const styles = TabReportPlantaStyle();
  const [tabValue, setTabValue] = useState(1);

  const [filterLoading, setFilterLoading] = useState(false);
  const filterDialog = useRef(null);
  const [usedFilters, setUsedFilters] = useState(defaultFilters);
  const [antFilter, setAntFilter] = useState({});
  const [originalFilters, setOriginalFilters] = useState(undefined);

  const [allDrives, setAllDrives] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [preManDrives, setPreManDrives] = useState(null);
  const [emStock, setEmStock] = useState(null);

  const [dataLoading, setDataLoading] = useState(false);
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [clientReportLogo, setClientReportLogo] = useState(null);

  const [reportOptionsOpen, setReportOptionsOpen] = useState(false);
  const [showTabsOptions, setShowTabsOptions] = useState(
    new ShowTabsOptionsModel(isPremium)
  );
  const [reportOptions, setReportOptions] = useState(new ReportOptionsModel());
  const [coverOptions, setCoverOptions] = useState(
    new CoverOptionsModel(plant, AuthService.getUserInfo(), isPremium)
  );
  const [summaryOptions, setSummaryOptions] = useState(
    new SummaryOptionsModel()
  );
  const [recommendationOptions, setRecommendationOptions] = useState(
    new RecommendationOptionsModel()
  );
  const [analysisOptions, setAnalysisOptions] = useState(
    new AnalysisOptionsModel()
  );
  const [preManOptions, setPreManOptions] = useState(new PreManOptionsModel());
  const [emStockOptions, setEmStockOptions] = useState(
    new EmStockOptionsModel()
  );

  const getPartnerLogo = useCallback(() => {
    if (plant?.partnerId) {
      PartnersAPI.getLogo(plant.partnerId)
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 404) {
            return null;
          } else {
            throw new Error(res.statusText);
          }
        })
        .then((data) => setPartnerLogo(data))
        .catch((err) => console.log(err));
    }
  }, [plant.partnerId]);

  const getClientReportLogo = useCallback(() => {
    if (plant?.clienteId && false) {
      // Delete false to enable client logo request
      ClientesAPI.getReportLogo(plant?.clienteId)
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 404) {
            return null;
          } else {
            throw new Error(res.statusText);
          }
        })
        .then((data) => setClientReportLogo(data))
        .catch((err) => console.log(err));
    }
  }, [plant.clienteId]);

  const handleSetDrives = useCallback(
    (data) => {
      if (data.allDrives.length > 0) {
        let assessment = plant?.infoBySalesforce
          ? data.assessment
          : { ...data.assessment, draft: true };
        plant?.infoBySalesforce
          ? data?.assessment.draft &&
            SnackbarWarning(setGlobal, i18n.t("report.snackbar.riskDraft"))
          : SnackbarWarning(
              setGlobal,
              i18n.t("report.snackbar.salesforceDraft")
            );

        setAllDrives(data.allDrives);
        setAssessment(assessment);
        setPreManDrives(
          data.allDrives.filter((d) => d.mantenimientoActivo !== null)
        );
        setEmStock(data.emStock);
        setPreManOptions(
          new PreManOptionsModel(
            data.assessment?.pmStart,
            data.assessment?.pmEnd
          )
        );
      } else {
        setAllDrives(null);
        setAssessment(null);
        setPreManDrives(null);
        setPreManOptions(new PreManOptionsModel());
      }
      const cl = data.client;
      const pt = data.partner;
      if (cl && pt) {
        const clientAddress =
          `${cl.direccion}, ${cl.poblacion}` +
          (cl.cp ? ", " + cl.cp : "") +
          `, ${cl.country.name}`;
        const partnerAddress =
          `${pt.direccion}, ${pt.poblacion}` +
          (pt.cp ? ", " + pt.cp : "") +
          `, ${pt.country.name}`;
        setCoverOptions((old) => ({
          ...old,
          clientName: cl.razonSocial,
          clientAddress,
          partnerName: pt.razonSocial,
          partnerAddress,
          partnerEmail: pt?.email || "",
        }));
      }
    },
    [plant.infoBySalesforce, setGlobal]
  );

  const getDrives = useCallback(() => {
    if (plant.id) {
      setFilterLoading(true);
      setDataLoading(true);
      EquiposAPI.getReport(plant.id)
        .then((res) => (res.ok ? res.json() : console.log(res)))
        .then(handleSetDrives)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setFilterLoading(false);
          setDataLoading(false);
        });
    }
  }, [handleSetDrives, plant.id]);

  const getFilteredDrives = useCallback(
    async (filters) => {
      if (Object.keys(filters).length === 0) {
        return;
      }
      setFilterLoading(true);
      setDataLoading(true);
      EquiposAPI.postReport(plant.id, filters)
        .then((res) => (res.ok ? res.json() : console.log(res)))
        .then(handleSetDrives)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setFilterLoading(false);
          setDataLoading(false);
        });
    },
    [handleSetDrives, plant.id]
  );

  const openFilters = () => {
    setAntFilter(usedFilters);
    filterDialog.current.open();
  };

  const applyFilter = () => {
    getFilteredDrives(usedFilters);
    localStorage.setItem("reportFilterss", JSON.stringify(usedFilters));
    filterDialog.current.close();
  };

  const cancelFilters = () => {
    // @ts-ignore
    setUsedFilters(antFilter);
    filterDialog.current.close();
  };

  /**
   * Metodo utilizado para imprimir si el número de equipos es demasiado grande
   * @param {number} maxLength Cantidad de equipos a partir de la cual el report se divide en partes.
   */
  const handleGiantDownload = async (maxLength) => {
    confirmBigPrintDialog.current.close();
    // Cantidad de equipos en cada PDF
    let splitLength = maxLength * 2;
    let title = `Report-${plant.id}-${moment(new Date()).format(
      "MMDDYY-HHmm"
    )}`;
    // Equipos divididos en partes
    let allDrivesSplited = splitArray(allDrives, splitLength);
    let preManDrivesSplited = splitArray(preManDrives, splitLength);
    let loading =
      (showTabsOptions.summary.value ? allDrivesSplited.length : 0) +
      (showTabsOptions.recommendation.value ? allDrivesSplited.length : 0) +
      (showTabsOptions.preMan.value ? preManDrivesSplited.length : 0) +
      1;
    let partCount = 1;

    await pdf(report(-1))
      .toBlob()
      .then((blob) => {
        download(
          `${title}-P${partCount++}.pdf`,
          window.URL.createObjectURL(blob)
        );
        --loading === 0 && setDownloadLoading(false);
      })
      .catch((err) => {
        console.log(err);
        --loading === 0 && setDownloadLoading(false);
      });

    if (showTabsOptions.summary.value) {
      for (let i = 0; i < allDrivesSplited.length; i++) {
        await pdf(report(-2, allDrivesSplited[i], preManDrivesSplited[i]))
          .toBlob()
          .then((blob) => {
            download(
              `${title}-P${partCount++}.pdf`,
              window.URL.createObjectURL(blob)
            );
            --loading === 0 && setDownloadLoading(false);
          })
          .catch((err) => {
            console.log(err);
            --loading === 0 && setDownloadLoading(false);
          });
      }
    }

    if (showTabsOptions.recommendation.value) {
      for (let i = 0; i < allDrivesSplited.length; i++) {
        await pdf(report(-3, allDrivesSplited[i], preManDrivesSplited[i]))
          .toBlob()
          .then((blob) => {
            download(
              `${title}-P${partCount++}.pdf`,
              window.URL.createObjectURL(blob)
            );
            --loading === 0 && setDownloadLoading(false);
          })
          .catch((err) => {
            console.log(err);
            --loading === 0 && setDownloadLoading(false);
          });
      }
    }

    if (showTabsOptions.preMan.value) {
      for (let i = 0; i < preManDrivesSplited.length; i++) {
        await pdf(report(-4, allDrivesSplited[i], preManDrivesSplited[i]))
          .toBlob()
          .then((blob) => {
            download(
              `${title}-P${partCount++}.pdf`,
              window.URL.createObjectURL(blob)
            );
            --loading === 0 && setDownloadLoading(false);
          })
          .catch((err) => {
            console.log(err);
            --loading === 0 && setDownloadLoading(false);
          });
      }
    }
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 100));
    await genChart();
    if (
      allDrives.length > maxLength &&
      (showTabsOptions.summary.value ||
        showTabsOptions.recommendation.value ||
        showTabsOptions.preMan.value)
    ) {
      confirmBigPrintDialog.current.open();
    } else {
      pdf(report(0))
        .toBlob()
        .then((blob) => {
          download(
            `Report-${plant.id}-${moment(new Date()).format(
              "MMDDYY-HHmm"
            )}.pdf`,
            window.URL.createObjectURL(blob)
          );
          setDownloadLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setDownloadLoading(false);
        });
    }
  };

  const setBlobPreview = async () => {
    pdf(report(tabValue))
      .toBlob()
      .then((blob) => {
        setPdfBlob(URL.createObjectURL(blob));
      })
      .catch((err) => {
        setPdfBlob(null);
      });
  };

  useEffect(() => {
    getDrives();
    getPartnerLogo();
    getClientReportLogo();
  }, [getDrives, getPartnerLogo, getClientReportLogo]);

  useEffect(() => {
    setPdfLoading(true);
    if (tabValue === 3) {
      genChart().then(() => setBlobPreview().then(() => setPdfLoading(false)));
    } else {
      setBlobPreview().then(() => setPdfLoading(false));
    }
    // NO tocar, es necesario para la generacion del pdf
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getDrives,
    getFilteredDrives,
    tabValue,
    reportOptions,
    coverOptions,
    analysisOptions,
    summaryOptions,
    recommendationOptions,
    preManOptions,
    emStockOptions,
    allDrives,
    assessment,
    preManDrives,
    emStock,
  ]);

  if (!plant) {
    return <></>;
  }

  const generatingCurtain = (
    <NoData
      icon={<Autorenew style={{ fontSize: 100 }} />}
      title={i18n.t("report.generatingPDFTitle")}
      text={i18n.t("report.generatingPDFText")}
    />
  );

  return (
    <RelativeBackdrop hidden={!dataLoading && !downloadLoading} absolute>
      <div className={styles.mainContainer}>
        <div className={styles.leftContainer}>
          {tabValue === 1 && (
            <CoverOptions
              options={coverOptions}
              setOptions={setCoverOptions}
              isPremium={isPremium}
              showTabsOptions={showTabsOptions}
              setShowTabsOptions={setShowTabsOptions}
            />
          )}
          {tabValue === 2 && (
            <GeneralOptions
              reportOptions={reportOptions}
              options={analysisOptions}
              setOptions={setAnalysisOptions}
            />
          )}
          {tabValue === 5 && (
            <GeneralOptions
              reportOptions={reportOptions}
              options={summaryOptions}
              setOptions={setSummaryOptions}
            />
          )}
          {tabValue === 6 && (
            <GeneralOptions
              reportOptions={reportOptions}
              options={recommendationOptions}
              setOptions={setRecommendationOptions}
            />
          )}
          {tabValue === 7 && (
            <GeneralOptions
              reportOptions={reportOptions}
              options={preManOptions}
              setOptions={setPreManOptions}
            />
          )}
          {tabValue === 8 && (
            <GeneralOptions
              reportOptions={reportOptions}
              options={emStockOptions}
              setOptions={setEmStockOptions}
            />
          )}
        </div>
        <div className={styles.rightContainer}>
          <Paper square className={styles.tabContainer}>
            <Tabs
              data-testid="report-tabs"
              value={tabValue}
              onChange={async (e, newValue) => {
                setTabValue(newValue);
              }}
              scrollButtons="auto"
              variant="scrollable"
              indicatorColor="secondary"
              textColor="inherit"
              classes={{ indicator: styles.tabsHeader }}
              aria-label="tabs"
            >
              <Tab
                value={1}
                disabled={!assessment || !allDrives || !preManDrives}
                label={<span>{i18n.t("report.tabs.cover")}</span>}
                aria-label="reportTab-0"
              />
              {Object.keys(showTabsOptions).map(
                (row, i) =>
                  showTabsOptions[row].value && (
                    <Tab
                      key={`${i}_${row}`}
                      value={i + 2}
                      disabled={!assessment || !allDrives || !preManDrives}
                      label={<span>{i18n.t(`report.tabs.${row}`)}</span>}
                      aria-label={`reportTab-${i + 1}`}
                    />
                  )
              )}
            </Tabs>
            <div className={styles.iconContainer}>
              <IconButton
                aria-label="print"
                disabled={!assessment || !allDrives || !preManDrives}
                onClick={() => {
                  if (assessment.draft) {
                    confirmDraftPrintDialog.current.open();
                  } else {
                    handleDownload();
                  }
                }}
              >
                <LocalPrintshop />
              </IconButton>
              <IconButton aria-label="filter" onClick={() => openFilters()}>
                <FilterList />
              </IconButton>
              <IconButton
                aria-label="settings"
                onClick={() => setReportOptionsOpen(true)}
              >
                <Settings />
              </IconButton>
            </div>
          </Paper>
          {assessment && allDrives && preManDrives ? (
            <RelativeBackdrop hidden={!pdfLoading} absolute>
              {downloadLoading ? (
                <NoData
                  icon={<LocalPrintshop style={{ fontSize: 100 }} />}
                  title={i18n.t("report.printingTitle")}
                  text={i18n.t("report.printingText")}
                />
              ) : pdfBlob ? (
                <iframe
                  key={pdfBlob}
                  title="reportPreview"
                  src={pdfBlob}
                  className={styles.pdfViewer}
                />
              ) : (
                generatingCurtain
              )}
            </RelativeBackdrop>
          ) : pdfLoading ? (
            <NoData
              icon={<Equalizer style={{ fontSize: 100 }} />}
              title={i18n.t("report.noDataTitle")}
              text={i18n.t("report.noDataText")}
            />
          ) : (
            generatingCurtain
          )}
        </div>
      </div>
      {assessment && (
        <ResultGraphs
          reportOptions={reportOptions}
          pictureRefs={pictureRefs}
          data={assessment}
        />
      )}
      <DashboardFilters
        dialogRef={filterDialog}
        loading={filterLoading}
        setLoading={setFilterLoading}
        usedFilters={usedFilters}
        setUsedFilters={setUsedFilters}
        originalFilters={originalFilters}
        setOriginalFilters={setOriginalFilters}
        applyFilter={applyFilter}
        cancelFilters={cancelFilters}
        plantId={plant.id}
      />
      <ReportOptions
        open={reportOptionsOpen}
        setOpen={setReportOptionsOpen}
        options={reportOptions}
        setOptions={setReportOptions}
      />
      {/* @ts-ignore */}
      <AlertDialog
        ref={confirmBigPrintDialog}
        title={i18n.t("report.bigPrintAlert.title")}
        text={i18n.t("report.bigPrintAlert.text", { maxLength })}
        cancelText={i18n.t("common.cancel")}
        confirmText={i18n.t("report.bigPrintAlert.accept")}
        confirmAction={() => handleGiantDownload(maxLength)}
        cancelAction={() => {
          setDownloadLoading(false);
          confirmBigPrintDialog.current.close();
        }}
      />
      <AlertDialog
        ref={confirmDraftPrintDialog}
        title={i18n.t("report.draftPrintAlert.title")}
        text={i18n.t("report.draftPrintAlert.text", { maxLength })}
        cancelText={i18n.t("common.cancel")}
        confirmText={i18n.t("report.draftPrintAlert.accept")}
        confirmAction={() => {
          handleDownload();
          confirmDraftPrintDialog.current.close();
        }}
        cancelAction={() => {
          setDownloadLoading(false);
          confirmDraftPrintDialog.current.close();
        }}
      />
    </RelativeBackdrop>
  );
}
export default TabReportPlanta;
