import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { SettingsInputAntenna, Send, Cancel } from "@material-ui/icons";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import i18n from "../../../locales/i18n";
import AppContext from "../../../contexts/AppContext";
import { BrandAPI, BroadcastAPI, CountriesAPI } from "../../../services/API";
import {
  SnackbarSuccess,
  SnackbarError,
  SnackbarWarning,
} from "../../../helpers/Common";
import RelativeBackdrop from "../../RelativeBackdrop";
import AlertDialog from "../../AlertDialog";
import VaconMaintenance from "../../../helpers/vacon-maintenance-calculation.json";
import VLTMaintenance from "../../../helpers/vlt-maintenance-calculation.json";
import { isVacon } from "../../../helpers/Common";
import IngSelectorMultiple from "../../atoms/Ing_SelectorMultiple";
import { Autocomplete } from "@material-ui/lab";

const AllCountriesOption = {
  id: -1,
  name: "All countries",
  code: "ALL",
};

const defaultBroadcast = {
  brandId: undefined,
  modelsId: [],
  frameSizes: [],
  newLifeCicle: null,
  countriesId: [AllCountriesOption],
  message: null,
};

export default () => {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const styles = DetailTabs_Style();
  const [broadcast, setBroadcast] = useState(defaultBroadcast);
  const [errors, setErrors] = useState({});
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const confirmDialog = useRef(null);

  const callBrands = useCallback(() => {
    BrandAPI.getDanfossBrands()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setBrands(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const callCountries = useCallback(() => {
    CountriesAPI.get()
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          console.error(response.json());
        }
      })
      .then((data) => {
        data.sort((a, b) =>
          i18n.t(`country.${a.name}`).localeCompare(i18n.t(`country.${b.name}`))
        );
        data.unshift(AllCountriesOption);
        setCountries(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    setGlobal((prev) => ({ ...prev, pageTitle: i18n.t("broadcast.title") }));
    callBrands();
    callCountries();
  }, [callBrands, callCountries, setGlobal]);

  useEffect(() => {
    let actualBrand = brands.find((brand) => brand.id === broadcast.brandId);
    isVacon(actualBrand)
      ? setSizes(VaconMaintenance.frames.map((f) => f.sizes).flat())
      : setSizes(VLTMaintenance.frames.map((f) => f.sizes).flat());
  }, [brands, broadcast.brandId]);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "brandId" && value !== broadcast.brandId) {
      setBroadcast((prevState) => ({
        ...prevState,
        modelsId: undefined,
        frameSizes: null,
      }));
    }

    setBroadcast((prevState) => ({ ...prevState, [name]: value }));

    if (errors[name]) {
      setErrors((e) => ({ ...errors, [name]: undefined }));
    }
  };

  const handleInputChangeMultiCountry = (event, value) => {
    let resultado = value;
    if (value.length > 0) {
      const prevWithAllC =
        broadcast.countriesId.filter((a) => a.id === -1).length > 0;
      const newWithAllC = value.filter((a) => a.id === -1).length > 0;

      if (!prevWithAllC && newWithAllC) {
        resultado = [AllCountriesOption];
      } else {
        // Quitamos los duplicados
        const elementosUnicos = {};
        resultado = value.filter((elemento) => {
          const clave = elemento.id;
          if (!elementosUnicos[clave]) {
            elementosUnicos[clave] = true;
            return true;
          }
          return false;
        });
        if (resultado.length > 1) {
          resultado = resultado.filter((v) => v.id > 0);
        }
      }
    } else {
      resultado = [AllCountriesOption];
    }

    setBroadcast((prevState) => ({ ...prevState, countriesId: resultado }));
  };

  const confirmWhetherThrowNotice = () => {
    confirmDialog.current.open();
  };

  const throwNotice = () => {
    confirmDialog.current.close();
    setLoading(true);
    const data = {
      ...broadcast,
      countriesId: broadcast.countriesId.map((c) => c.id),
      modelsId: broadcast.modelsId.map((m) => m.id),
    };
    BroadcastAPI.throwNotice(data)
      .then(async (response) => {
        let json = await response.json();
        if (response.ok) {
          resetForm();
          setLoading(false);
          if (json === 1) {
            SnackbarSuccess(
              setGlobal,
              i18n.t("broadcast.sentDialog.bodySing", { number: json })
            );
          } else if (json > 1) {
            SnackbarSuccess(
              setGlobal,
              i18n.t("broadcast.sentDialog.bodyPlur", { number: json })
            );
          } else {
            SnackbarWarning(
              setGlobal,
              i18n.t("broadcast.sentDialog.bodyZero", { number: json })
            );
          }
        } else {
          setLoading(false);
          setErrors(json["errors"]);
        }
      })
      .catch((error) => {
        SnackbarError(setGlobal, i18n.t("broadcast.error"));
        setLoading(false);
        console.error(error);
      });
  };

  const resetForm = () => {
    setBroadcast(defaultBroadcast);
  };

  return (
    <Card>
      <RelativeBackdrop hidden={!loading}>
        <div className={styles.mainFormContainer}>
          <form autoComplete="off" className={styles.userFormContainerWidth}>
            <div className={styles.flexRowForm}>
              <CardHeader
                title={i18n.t("broadcast.title")}
                subheader={i18n.t("broadcast.subtitle")}
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    <SettingsInputAntenna />
                  </Avatar>
                }
              />
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label={i18n.t("broadcast.brand")}
                    value={broadcast.brandId || ""}
                    className={styles.materialTextField}
                    error={errors["brandId"] !== undefined}
                    helperText={
                      errors["brandId"] ? i18n.t("textField.required") : null
                    }
                    required
                    name="brandId"
                    onChange={handleInputChange}
                  >
                    {brands.map((brand) => (
                      <MenuItem
                        key={brand.id}
                        value={brand.id}
                        onClick={() => setModels(brand.models)}
                      >
                        {brand.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </div>
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    options={models}
                    value={broadcast.modelsId || []}
                    getOptionLabel={(row) => row.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={styles.materialTextField}
                        variant="standard"
                        label={i18n.t("broadcast.model")}
                      />
                    )}
                    onChange={(e, newValue) => {
                      let fakeEvent = {
                        target: {
                          value: newValue,
                          type: "list",
                          name: "modelsId",
                        },
                      };
                      handleInputChange(fakeEvent);
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    options={sizes}
                    value={broadcast.frameSizes || []}
                    getOptionLabel={(row) => row}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={styles.materialTextField}
                        variant="standard"
                        label={i18n.t("broadcast.frameSize")}
                      />
                    )}
                    onChange={(e, newValue) => {
                      let fakeEvent = {
                        target: {
                          value: newValue,
                          type: "list",
                          name: "frameSizes",
                        },
                      };
                      handleInputChange(fakeEvent);
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <FormControl fullWidth required>
                  <TextField
                    select
                    label={i18n.t("broadcast.newLifeCicle")}
                    value={
                      broadcast.newLifeCicle !== undefined &&
                      broadcast.newLifeCicle !== null
                        ? broadcast.newLifeCicle
                        : ""
                    }
                    className={styles.materialTextField}
                    error={errors["newLifeCicle"] !== undefined}
                    helperText={
                      errors["newLifeCicle"]
                        ? i18n.t("textField.required")
                        : null
                    }
                    required
                    name="newLifeCicle"
                    onChange={handleInputChange}
                  >
                    <MenuItem value={0}>
                      {i18n.t("common.activo.singularM")}
                    </MenuItem>
                    <MenuItem value={1}>{i18n.t("common.limitado")}</MenuItem>
                    <MenuItem value={2}>{i18n.t("common.inactivo")}</MenuItem>
                  </TextField>
                </FormControl>
              </div>
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <FormControl fullWidth>
                  <IngSelectorMultiple
                    className={styles.materialTextField}
                    options={countries}
                    getOptionLabel={(option) =>
                      i18n.t(`country.${option.name}`)
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value
                    }
                    label={i18n.t("broadcast.country")}
                    value={broadcast.countriesId}
                    onChange={handleInputChangeMultiCountry}
                    disabled={false}
                  />
                </FormControl>
              </div>
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <TextField
                  required
                  fullWidth
                  className={styles.materialTextField}
                  value={broadcast.message || ""}
                  label={i18n.t("broadcast.message")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  rows={10}
                  error={errors["message"] !== undefined}
                  helperText={
                    errors["message"] ? i18n.t("textField.required") : null
                  }
                  name="message"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <Button
                  variant="contained"
                  className={styles.primaryFormButton}
                  onClick={() => confirmWhetherThrowNotice()}
                >
                  <Send className={styles.saveIcon} />
                  {i18n.t("broadcast.confirmDialog.confirmText")}
                </Button>
                <Button
                  variant="outlined"
                  className={styles.secondaryFormButton}
                  onClick={() => resetForm()}
                >
                  <Cancel className={styles.saveIcon} />
                  {i18n.t("common.cancel")}
                </Button>
              </div>
            </div>
          </form>
        </div>
        {/*@ts-ignore*/}
        <AlertDialog
          ref={confirmDialog}
          title={i18n.t("broadcast.confirmDialog.title")}
          text={i18n.t("broadcast.confirmDialog.text")}
          cancelText={i18n.t("broadcast.confirmDialog.cancelText")}
          confirmText={i18n.t("broadcast.confirmDialog.confirmText")}
          confirmAction={() => throwNotice()}
        />
      </RelativeBackdrop>
    </Card>
  );
};
