import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { WaterMarkStyles } from "../ReportPDFStyles";

/**
 * Marca de agua para el PDF por si este se debe marcar como borrador (Draft).
 * @param {Object} props
 * @param {string} props.text Texto de la marca de agua.
 * @param {string} props.orientation Orientacion de la página.
 */
function WaterMark({ text, orientation }) {
  if (!text || text === "") return <></>;

  const fontSize = orientation === "portrait" ? 45 : 30;

  let containerStyle = WaterMarkStyles.container;

  return (
    <View fixed style={containerStyle}>
      <Text
        style={{
          ...WaterMarkStyles.text,
          fontSize: (fontSize / text.length) * 3 + "vw",
        }}
      >
        {text}
      </Text>
    </View>
  );
}

export default WaterMark;
