import React from "react";
import i18n from "../../../locales/i18n";
import { Typography } from "@material-ui/core";
import MaterialTable from "material-table";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { DetailTabs_Style } from "../../../assets/css/Styles";
export default function EquipoRepuestosEnAlmacen(props) {
  const { equipo, sustituciones } = props;
  const styles = DetailTabs_Style();

  const materialTableTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#000000",
      },
    },
  });

  return (
    <>
      {equipo.pn && equipo.pn !== "PROVISIONAL" && (
        <>
          {/** @ts-ignore */}
          <ThemeProvider theme={materialTableTheme}>
            {/** @ts-ignore */}
            <MaterialTable
              style={{ width: "100%" }}
              title={i18n.t("equipo.sustitutos.equal")}
              // @ts-ignore
              localization={i18n.t("dataTableProps")}
              data={sustituciones}
              options={{
                actionsColumnIndex: -1,
                showTitle: true,
                search: false,
                paging: false,
                searchFieldAlignment: "left",
              }}
              columns={[
                {
                  title: i18n.t("equipo.emergencyStockReference"),
                  field: "pn",
                },
                {
                  title: "TC / TYPE",
                  field: "tc",
                },
                {
                  title: "SN",
                  field: "sn",
                  render: (rowData) => (
                    <a
                      // @ts-ignore
                      href={`/plantas/${rowData.zona.plantaId}/zonas/${rowData.zonaId}/equipos/${rowData.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={rowData.sn}
                      className={styles.linkColor}
                    >
                      <Typography>{rowData.sn}</Typography>
                    </a>
                  ),
                },
                {
                  title: i18n.t("equipo.sustitutos.found_in"),
                  field: "zona.descripcion",
                },
              ]}
            />
          </ThemeProvider>
        </>
      )}
    </>
  );
}
