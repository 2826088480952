import * as React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import i18n from "../../locales/i18n";

/**
 * Ing Selector Múltiple personalizado
 * @Component
 * @param {Object} props – parametros de la clase
 * @param {String} [props.className] - estilo personalizado del selector
 * @param {any[]} props.options - array de opciones
 * @param {boolean} props.disabled - disabled or not
 * @param {(option: any) => string} props.getOptionLabel - parámetro que sirva de título de la opción del array de opciones
 * @param {any} [props.value] - value asociado al selector
 * @param {(event: object, value: object | object[], reason: string) => void} props.onChange - función onChange del selector
 * @param {(option: any, value: any) => boolean} [props.isOptionEqualToValue] - función para comprobar si la opción y el valor hacen match para precarga de selectores
 * @param {String} props.label - label para el textField del selector
 * @param {String} [props.error] - texto de error para validadores
 */
export default function IngSelectorMultiple(props) {
  return (
    <Autocomplete
      multiple={true}
      options={props.options}
      disabled={props.disabled}
      value={props.value}
      onChange={props.onChange}
      //noOptionsText={i18n.t("autocompleteProps.noOptionsText")}
      getOptionLabel={props.getOptionLabel}
      getOptionSelected={props.isOptionEqualToValue}
      //renderOption={(option) => <Typography>{option}</Typography>}
      //renderOption={(option) => option}
      renderInput={(params) => (
        <TextField
          className={props.className}
          {...params}
          fullWidth
          label={props.label}
          error={props.error !== undefined}
          helperText={props.error ? i18n.t("textField.required") : null}
        />
      )}
    />
  );
}
