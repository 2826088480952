import React, { useState, useEffect, useContext, useCallback } from "react";
import { PlantasAPI, SiteAssessmentVersionsAPI } from "../../../services/API";
import PlantaBase from "../../../models/plantaBase";
import AppContext from "../../../contexts/AppContext";
import i18n from "../../../locales/i18n";
import MaterialTable from "material-table";
import { Avatar, Card, CardHeader, TextField } from "@material-ui/core";
import { History } from "@material-ui/icons";
import { Common_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import moment from "moment";
import className from "classnames";

export default function SiteAssessmentVersionsDetail(props) {
  const { match } = props;
  const { plantaId, id } = match.params;

  // @ts-ignore
  const { setGlobal } = useContext(AppContext);

  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  const [loading, setLoading] = useState(false);
  const [planta, setPlanta] = useState(new PlantaBase({}));
  const [siteAssessmentVersion, setSiteAssessmentVersion] = useState({
    equipment: [],
    appUser: {},
    created: "",
    id: "",
  });

  const getPlanta = useCallback(() => {
    setLoading(true);

    PlantasAPI.get(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Get planta siteAssessment error");
        }
      })
      .then((data) => {
        setPlanta(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [plantaId]);

  const getSiteAssessmentVersion = useCallback(() => {
    setLoading(true);
    SiteAssessmentVersionsAPI.getSiteAssessmentVersion(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Get siteAssessment error");
        }
      })
      .then((data) => {
        setSiteAssessmentVersion(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    getPlanta();
    getSiteAssessmentVersion();
  }, [getPlanta, getSiteAssessmentVersion]);

  useEffect(() => {
    if (planta.denominacion) {
      setGlobal((prev) => ({
        ...prev,
        pageTitle: "Site Assessment " + planta.denominacion,
      }));
    } else {
      setGlobal((prev) => ({ ...prev, pageTitle: i18n.t("planta.titulo") }));
    }
  }, [setGlobal, planta.denominacion]);

  const materialHeaderStyle = {
    fontSize: 14,
    backgroundColor: "#F4F4F4",
    fontWeight: "bold",
  };

  return (
    <div className={commonStyles.mainContainer}>
      <Card style={{ paddingBottom: "1em" }}>
        <div className={styles.mainFormContainer}>
          <div className={styles.flexRowForm}>
            <CardHeader
              title={"Site Assessment " + planta.denominacion}
              subheader={"v" + id}
              avatar={
                <Avatar aria-label="avatar" className={styles.tabHeaderAvatar}>
                  <History />
                </Avatar>
              }
            />
          </div>
          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                label={"Site Assessment ID"}
                value={siteAssessmentVersion.id}
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={styles.materialTextField}
                name="assessmentId"
              />
            </div>
          </div>
          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                label={i18n.t("planta.denominacion")}
                value={planta.denominacion}
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={styles.materialTextField}
                name="denomination"
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                label={i18n.t("siteAssessment.equipmentNumber")}
                value={siteAssessmentVersion.equipment?.length}
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={styles.materialTextField}
                name="nDrives"
              />
            </div>
          </div>
          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                label={i18n.t("siteAssessment.created")}
                value={moment(siteAssessmentVersion.created).format("L LT")}
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={styles.materialTextField}
                name="created"
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                label={i18n.t("siteAssessment.user")}
                value={
                  siteAssessmentVersion?.appUser
                    ? `${siteAssessmentVersion?.appUser?.nombre} ${siteAssessmentVersion?.appUser?.apellidos} (${siteAssessmentVersion?.appUser?.email})`
                    : i18n.t("common.n/a")
                }
                inputProps={{ readOnly: true }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                className={styles.materialTextField}
                name="user"
              />
            </div>
          </div>
        </div>
      </Card>
      <div>
        {/* @ts-ignore */}
        <MaterialTable
          title={null}
          // @ts-ignore
          localization={i18n.t("dataTableProps")}
          isLoading={loading}
          data={siteAssessmentVersion?.equipment || []}
          options={{
            sorting: true,
            showTitle: false,
            searchFieldAlignment: "left",
            pageSize: 10,
            actionsColumnIndex: -1,
            tableLayout: "fixed",
          }}
          columns={[
            {
              field: "idCliente",
              title: i18n.t("siteAssessment.idCliente"),
              width: "12%",
              cellStyle: { fontSize: 14, wordWrap: "break-word" },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "zonesPath",
              title: i18n.t("siteAssessment.zonesPath"),
              width: "12%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "sn",
              title: i18n.t("siteAssessment.sn"),
              width: "12%",
              cellStyle: { fontSize: 14, wordWrap: "break-word" },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "pn",
              title: i18n.t("siteAssessment.pn"),
              width: "12%",
              cellStyle: { fontSize: 14, wordWrap: "break-word" },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "tc",
              title: i18n.t("siteAssessment.tc"),
              width: "17%",
              cellStyle: { fontSize: 14, wordWrap: "break-word" },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "weekOfManufacturing",
              title: i18n.t("siteAssessment.weekOfManufacturing"),
              type: "numeric",
              width: "9%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "yearOfManufacturing",
              title: i18n.t("siteAssessment.yearOfManufacturing"),
              type: "numeric",
              width: "9%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "creationDate",
              title: i18n.t("siteAssessment.creationDate"),
              type: "date",
              width: "9%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "lifeCycle",
              title: i18n.t("siteAssessment.lifeCycle"),
              width: "8%",
              cellStyle: { fontSize: 14 },
              render: (rowData) => (
                <div
                  className={className(
                    rowData.lifeCycle === 0
                      ? styles.siteAssessmentStatusGood
                      : rowData.lifeCycle === 1
                      ? styles.siteAssessmentStatusMedium
                      : rowData.lifeCycle === 2
                      ? styles.siteAssessmentStatusBad
                      : styles.siteAssessmentStatusNone,
                    styles.siteAssessmentStatusCell
                  )}
                >
                  {rowData.lifeCycle === 0
                    ? i18n.t("common.activo.singularM")
                    : rowData.lifeCycle === 1
                    ? i18n.t("common.limitado")
                    : rowData.lifeCycle === 2
                    ? i18n.t("common.inactivo")
                    : i18n.t("common.indefinido")}
                </div>
              ),
              headerStyle: materialHeaderStyle,
            },
            {
              field: "status",
              title: i18n.t("siteAssessment.status"),
              width: "8%",
              cellStyle: { fontSize: 14 },
              render: (rowData) => (
                <div
                  className={className(
                    rowData.status === 0
                      ? styles.siteAssessmentStatusGood
                      : rowData.status === 1
                      ? styles.siteAssessmentStatusMedium
                      : rowData.status === 2
                      ? styles.siteAssessmentStatusBad
                      : styles.siteAssessmentStatusNone,
                    styles.siteAssessmentStatusCell
                  )}
                >
                  {rowData.status === 0
                    ? i18n.t("common.bueno")
                    : rowData.status === 1
                    ? i18n.t("common.medio")
                    : rowData.status === 2
                    ? i18n.t("common.pobre")
                    : i18n.t("common.indefinido")}
                </div>
              ),
              headerStyle: materialHeaderStyle,
            },
            {
              field: "criticality",
              title: i18n.t("siteAssessment.criticality"),
              width: "8%",
              cellStyle: { fontSize: 14 },
              render: (rowData) => (
                <div
                  className={className(
                    rowData.criticality === 2
                      ? styles.siteAssessmentStatusGood
                      : rowData.criticality === 1
                      ? styles.siteAssessmentStatusBad
                      : rowData.criticality === 0
                      ? styles.siteAssessmentStatusBad
                      : styles.siteAssessmentStatusNone,
                    styles.siteAssessmentStatusCell
                  )}
                >
                  {rowData.criticality === 0
                    ? i18n.t("common.alto")
                    : rowData.criticality === 1
                    ? i18n.t("common.medio")
                    : rowData.criticality === 2
                    ? i18n.t("common.bajo")
                    : i18n.t("common.indefinido")}
                </div>
              ),
              headerStyle: materialHeaderStyle,
            },
            {
              field: "risk",
              title: i18n.t("siteAssessment.risk"),
              width: "8%",
              cellStyle: { fontSize: 14 },
              render: (rowData) => (
                <div
                  className={className(
                    rowData.risk === 2
                      ? styles.siteAssessmentStatusGood
                      : rowData.risk === 1
                      ? styles.siteAssessmentStatusMedium
                      : rowData.risk === 0
                      ? styles.siteAssessmentStatusBad
                      : styles.siteAssessmentStatusNone,
                    styles.siteAssessmentStatusCell
                  )}
                >
                  {rowData.risk === 0
                    ? i18n.t("common.alto")
                    : rowData.risk === 1
                    ? i18n.t("common.medio")
                    : rowData.risk === 2
                    ? i18n.t("common.bajo")
                    : i18n.t("common.indefinido")}
                </div>
              ),
              headerStyle: materialHeaderStyle,
            },
            {
              field: "recommendation",
              title: i18n.t("siteAssessment.recomendation"),
              width: "12%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "powerSize",
              title: i18n.t("siteAssessment.powerSize"),
              width: "7%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "voltage",
              title: i18n.t("siteAssessment.voltage"),
              width: "7%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "frame",
              title: i18n.t("siteAssessment.frame"),
              width: "10%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "model.name",
              title: i18n.t("siteAssessment.model"),
              width: "10%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
            {
              field: "model.brand.name",
              title: i18n.t("siteAssessment.brand"),
              width: "10%",
              cellStyle: { fontSize: 14 },
              headerStyle: materialHeaderStyle,
            },
          ]}
        />
      </div>
    </div>
  );
}
