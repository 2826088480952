import React, { useCallback, useEffect, useState, useContext } from "react";
import MaterialTable from "material-table";
import i18n from "../../../locales/i18n";
import { StatsAPI } from "../../../services/API";
import moment from "moment";
import RelativeBackdrop from "../../RelativeBackdrop";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { Group } from "@material-ui/icons";
import AppContext from "../../../contexts/AppContext";
import { createMuiTheme } from "@material-ui/core/styles";
import { DetailTabs_Style, Common_Style } from "../../../assets/css/Styles";
import { ThemeProvider } from "@material-ui/styles";

export default function Stats() {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [use, setUse] = useState([]);
  const [loading, setLoading] = useState(false);
  const commonStyles = Common_Style();
  const styles = DetailTabs_Style();
  const materialTableTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#000000",
      },
    },
  });

  const [numeroUsuariosUltimos30Dias, setNumeroUsuariosUltimos30Dias] =
    useState(0);
  const [numeroUsuariosUltimos60Dias, setNumeroUsuariosUltimos60Dias] =
    useState(0);
  const [numeroUsuariosUltimos90Dias, setNumeroUsuariosUltimos90Dias] =
    useState(0);
  const [
    numeroUsuariosactivosUltimos30Dias,
    setNumeroUsuariosActivosUltimos30Dias,
  ] = useState(0);
  const [
    numeroUsuariosactivosUltimos60Dias,
    setNumeroUsuariosActivosUltimos60Dias,
  ] = useState(0);
  const [
    numeroUsuariosactivosUltimos90Dias,
    setNumeroUsuariosActivosUltimos90Dias,
  ] = useState(0);

  const getUse = useCallback((days) => {
    setLoading(true);
    StatsAPI.Get(days)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setUse(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const getNumUsersLastDays = useCallback((days) => {
    setLoading(true);
    StatsAPI.GetUsersLastDays(days)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (days === 90) {
          setNumeroUsuariosUltimos90Dias(data);
        } else if (days === 60) {
          setNumeroUsuariosUltimos60Dias(data);
        } else {
          setNumeroUsuariosUltimos30Dias(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const getNumActiveUsersLastDays = useCallback((days) => {
    setLoading(true);
    StatsAPI.GetActiveUsersLastDays(days)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (days === 90) {
          setNumeroUsuariosActivosUltimos90Dias(data);
        } else if (days === 60) {
          setNumeroUsuariosActivosUltimos60Dias(data);
        } else {
          setNumeroUsuariosActivosUltimos30Dias(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setGlobal((prev) => ({ ...prev, pageTitle: i18n.t("kpi.title") }));
  }, [setGlobal]);

  useEffect(() => {
    getUse(90);
    getNumUsersLastDays(30);
    getNumUsersLastDays(60);
    getNumUsersLastDays(90);
    getNumActiveUsersLastDays(30);
    getNumActiveUsersLastDays(60);
    getNumActiveUsersLastDays(90);
  }, [getNumActiveUsersLastDays, getNumUsersLastDays, getUse]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.infoCardContainer}>
          <div className={commonStyles.infoCard}>
            <Card>
              <CardHeader
                title={i18n.t("kpi.usersLast30days")}
                className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    <Group />
                  </Avatar>
                }
              />
              <CardContent>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {numeroUsuariosUltimos30Dias}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className={commonStyles.infoCard}>
            <Card>
              <CardHeader
                title={i18n.t("kpi.usersLast60days")}
                className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    {/* <Group /> */}
                  </Avatar>
                }
              />
              <CardContent>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {numeroUsuariosUltimos60Dias}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className={commonStyles.infoCard}>
            <Card>
              <CardHeader
                title={i18n.t("kpi.usersLast90days")}
                className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    {/* <Group /> */}
                  </Avatar>
                }
              />
              <CardContent>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {numeroUsuariosUltimos90Dias}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className={commonStyles.infoCardContainer}>
          <div className={commonStyles.infoCard}>
            <Card>
              <CardHeader
                title={i18n.t("kpi.activeUsersLast30days")}
                className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    <Group />
                  </Avatar>
                }
              />
              <CardContent>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {numeroUsuariosactivosUltimos30Dias}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className={commonStyles.infoCard}>
            <Card>
              <CardHeader
                title={i18n.t("kpi.activeUsersLast60days")}
                className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    {/* <Group /> */}
                  </Avatar>
                }
              />
              <CardContent>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {numeroUsuariosactivosUltimos60Dias}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className={commonStyles.infoCard}>
            <Card>
              <CardHeader
                title={i18n.t("kpi.activeUsersLast90days")}
                className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    {/* <Group /> */}
                  </Avatar>
                }
              />
              <CardContent>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {numeroUsuariosactivosUltimos90Dias}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>
        {/* @ts-ignore */}
        <ThemeProvider theme={materialTableTheme}>
          {/* @ts-ignore */}
          <MaterialTable
            style={{ width: "100%" }}
            // @ts-ignore
            localization={i18n.t("dataTableProps")}
            title={i18n.t("kpi.table.title")}
            isLoading={loading}
            data={use}
            options={{
              actionsColumnIndex: -1,
              sorting: true,
              showTitle: true,
              paging: true,
              search: true,
              grouping: true,
              searchFieldAlignment: "right",
            }}
            columns={[
              {
                field: "creationDateTime",
                title: i18n.t("kpi.table.created"),
                editable: "never",
                render: (rowData) =>
                  rowData.creationDateTime
                    ? moment(rowData.creationDateTime).format(
                        "DD/MM/YYYY, HH:mm"
                      )
                    : "",
              },
              {
                field: "userId",
                title: "UserId",
                editable: "never",
                hidden: true,
              },
              {
                field: "userName",
                title: i18n.t("kpi.table.userName"),
                editable: "never",
                defaultGroupOrder: 1,
              },
              {
                field: "controllerName",
                title: i18n.t("kpi.table.controllerName"),
                editable: "never",
              },
              {
                field: "actionName",
                title: i18n.t("kpi.table.action"),
                editable: "never",
              },
              {
                field: "requestMethod",
                title: i18n.t("kpi.table.verb"),
                editable: "never",
              },
              {
                field: "requestPath",
                title: i18n.t("kpi.table.endpoint"),
                editable: "never",
              },
            ]}
          />
        </ThemeProvider>
      </div>
    </RelativeBackdrop>
  );
}
