import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Select,
  InputLabel,
} from "@material-ui/core";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Cancel,
  Save,
} from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { isVacon } from "../../../helpers/Common";
import i18n from "../../../locales/i18n";
import { EquiposAPI } from "../../../services/API";
import AlertDialog from "../../AlertDialog";
import CardImagenesVariador from "../../molecule/cardImgVariador/cardImgVariador";
import RelativeBackdrop from "../../RelativeBackdrop";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import DriveIcon from "../../atoms/driveIcon";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

/**
 * Dialog para Revision de variadores
 * @Component
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {Boolean} props.readOnly
 * @param {() => void} props.closeFunction
 * @param {Object} props.equipo
 * @param {function} props.setEquipo
 * @param {Object} props.brands
 * @param {() => void} props.siguienteEquipo
 * @param {() => void} props.anteriorEquipo
 * @param {() => void} props.handleInputChange
 * @param {(inactivo, provisional) => void} props.guardarEquipo
 *
 */
export default function DialogRevisionEquipos(props) {
  const {
    open,
    closeFunction,
    equipo,
    setEquipo,
    brands,
    siguienteEquipo,
    anteriorEquipo,
    handleInputChange,
    guardarEquipo,
    readOnly,
  } = props;
  const colWith = 6;
  const errors = [];

  const [equipoId, setEquipoId] = useState(0);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const styles = DetailTabs_Style();

  const provisionalDialog = useRef(null);

  useEffect(() => {
    if (equipoId) {
      setImages([]);
      setLoading(true);
      EquiposAPI.getEquipoImages(equipoId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          setImages(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [equipoId]);

  useEffect(() => {
    if (equipo && equipo.id !== equipoId) {
      setEquipoId(equipo.id);
    }
  }, [equipo, equipoId]);

  return (
    <>
      <Dialog
        open={open}
        onClose={closeFunction}
        fullWidth={true}
        maxWidth={"md"}
        style={{ marginTop: 50 }}
      >
        <RelativeBackdrop hidden={!loading}>
          <DialogTitle>
            <Grid container spacing={3}>
              <Grid item>
                <Avatar aria-label="avatar" className={styles.tabHeaderAvatar}>
                  <DriveIcon />
                </Avatar>
              </Grid>
              <Grid item>{equipo?.nombre}</Grid>
              <div style={{ marginLeft: "auto" }}>
                {equipo?.zona && equipo?.zona.isWarehouse && (
                  <div className={styles.flexPill}>
                    <span className={styles.pillText}>
                      {i18n.t("zona.warehouse")}
                    </span>
                  </div>
                )}
                {equipo?.riesgo === 3 &&
                  !equipo.zona.isWarehouse &&
                  !equipo.inactivo &&
                  !equipo.provisional && (
                    <div
                      className={styles.flexPill}
                      style={{ backgroundColor: "#FFAA00" }}
                    >
                      <span className={styles.pillText}>
                        {i18n.t("equipo.undefinedRisk")}
                      </span>
                    </div>
                  )}
                {equipo?.equipoMantenimientos &&
                  equipo?.equipoMantenimientos.length !== 0 && (
                    <div className={styles.flexPill}>
                      <span className={styles.pillText}>
                        {i18n.t("common.manteinance")}
                      </span>
                    </div>
                  )}
                {equipo?.provisional && (
                  <div className={styles.flexWarningPill}>
                    <span className={styles.pillProvisionalText}>
                      {i18n.t("equipo.provisional")}
                    </span>
                  </div>
                )}
                {equipo?.inactivo && (
                  <div className={styles.flexPill}>
                    <span className={styles.pillText}>
                      {i18n.t("equipo.inactive")}
                    </span>
                  </div>
                )}
              </div>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ height: "700px" }}>
            {/*@ts-ignore*/}
            <PerfectScrollbar>
              <Grid container>
                <Grid item lg={6}>
                  <form autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          required
                          fullWidth
                          value={equipo?.sn || ""}
                          label={i18n.t("equipo.sn")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="sn"
                          error={
                            errors["SN"] !== undefined ||
                            errors["SN_Unique"] !== undefined
                          }
                          helperText={
                            errors["SN"]
                              ? i18n.t("textField.required")
                              : errors["SN_Unique"]
                              ? i18n.t("textField.unique", { name: "SN" })
                              : null
                          }
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          required
                          fullWidth
                          value={equipo?.pn || ""}
                          label={i18n.t("equipo.pn")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="pn"
                          id="pn"
                          error={errors["PN"] !== undefined}
                          helperText={
                            errors["PN"] ? i18n.t("textField.required") : null
                          }
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          fullWidth
                          value={equipo?.tc || ""}
                          label={
                            isVacon(equipo?.brand)
                              ? i18n.t("equipo.vType")
                              : i18n.t("equipo.tc")
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="tc"
                          id="tc"
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          fullWidth
                          value={equipo?.idCliente || ""}
                          label={i18n.t("equipo.idCliente")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="idCliente"
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          fullWidth
                          value={equipo?.nombre || ""}
                          label={i18n.t("equipo.nombre")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="nombre"
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <FormControl fullWidth>
                          <TextField
                            select
                            label={i18n.t("maintenance.fields.brand")}
                            value={equipo?.brandId || ""}
                            className={styles.materialTextField}
                            inputProps={{
                              readOnly: readOnly,
                              name: "brandId",
                              required: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handleInputChange}
                          >
                            {brands.map((row) => (
                              <MenuItem
                                key={`brand-${row.id}-${row.name}`}
                                value={row.id}
                                onClick={() =>
                                  setEquipo((old) => ({ ...old, brand: row }))
                                }
                              >
                                {row.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <FormControl
                          fullWidth
                          className={styles.materialTextField}
                        >
                          <InputLabel shrink>
                            {i18n.t("maintenance.fields.model")}
                          </InputLabel>
                          <Select
                            label={i18n.t("maintenance.fields.model")}
                            value={equipo?.modelId || ""}
                            inputProps={{
                              readOnly: readOnly,
                              name: "modelId",
                              required: true,
                            }}
                            onChange={handleInputChange}
                          >
                            {equipo?.brand?.models?.map((row) => (
                              <MenuItem
                                key={`model-${row.id}-${row.name}`}
                                value={row.id}
                                onClick={() =>
                                  setEquipo((old) => ({ ...old, model: row }))
                                }
                              >
                                {row.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={colWith} lg={11}>
                        <TextField
                          className={styles.materialTextField}
                          fullWidth
                          value={equipo?.descripcion || ""}
                          label={i18n.t("equipo.descripcion")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="descripcion"
                          error={errors["Descripcion"] !== undefined}
                          helperText={
                            errors["Descripcion"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Grid
                  item
                  lg={6}
                  style={{ overflowX: "auto", maxHeight: "650px" }}
                >
                  <RelativeBackdrop hidden={!loading}>
                    {images.length !== 0 ? (
                      <CardImagenesVariador
                        images={images}
                        isExchange={true}
                        hideHeader={true}
                        hideBorder={true}
                        isReview={true}
                      />
                    ) : (
                      <div style={{ alignSelf: "center" }}>
                        <Typography variant="button">
                          {i18n.t("planta.revisionEquipos.noImage")}
                        </Typography>
                      </div>
                    )}
                  </RelativeBackdrop>
                </Grid>
              </Grid>
            </PerfectScrollbar>
          </DialogContent>

          <DialogActions>
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Button
                  startIcon={<ArrowBackIos />}
                  onClick={() => {
                    anteriorEquipo();
                  }}
                  className={styles.navigationButton}
                >
                  {i18n.t("planta.revisionEquipos.prev")}
                </Button>
              </Grid>
              <Grid item>
                {!readOnly && (
                  <Button
                    onClick={() =>
                      equipo.provisional
                        ? provisionalDialog.current.open()
                        : guardarEquipo()
                    }
                    className={styles.saveDialogEquipmentButton}
                    variant="contained"
                    endIcon={<Save />}
                  >
                    {i18n.t("planta.revisionEquipos.save")}
                  </Button>
                )}
                <Button
                  onClick={() => closeFunction()}
                  endIcon={<Cancel />}
                  variant="outlined"
                  className={styles.closeDialogEquipmentButton}
                >
                  {i18n.t("planta.revisionEquipos.close")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    siguienteEquipo();
                  }}
                  className={styles.navigationButton}
                  endIcon={<ArrowForwardIos />}
                >
                  {i18n.t("planta.revisionEquipos.next")}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </RelativeBackdrop>
      </Dialog>
      {/*@ts-ignore*/}
      <AlertDialog
        ref={provisionalDialog}
        loading={loading}
        title={i18n.t("equipo.provisionalTitle")}
        text={i18n.t("equipo.provisionalText")}
        cancelText={i18n.t("common.cancelar")}
        confirmText={i18n.t("common.confirmar")}
        confirmAction={() => {
          guardarEquipo(undefined, false);
          provisionalDialog.current.close();
        }}
      />
    </>
  );
}
