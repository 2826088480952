import { baseUrl } from "../helpers/Url";
import AuthService from "./AuthService";

export function getSiteAssessmentVersions(plantId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  return fetch(`${baseUrl}/api/siteassessmentversion/plant/${plantId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function postSiteAssessmentVersions(plantId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  return fetch(`${baseUrl}/api/siteassessmentversion/${plantId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function getSiteAssessmentVersion(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  return fetch(`${baseUrl}/api/siteassessmentversion/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}