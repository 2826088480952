import React from "react";
import i18n from "../locales/i18n";
import {
  Typography,
} from "@material-ui/core";
import {
  Clear,
  Done,
  Schedule,
  Event,
 } from "@material-ui/icons";

export default (props) => {
  const {state, isProvisionalDate} = props;

  return (
    <>
      {state === 2 ? (
        <div>
          <Clear style={{ color: "#a04743" }} />
          <Typography variant="body2" style={{ color: "#a04743" }}>
            {i18n.t("maintenance.altualScheduled.cancelled")}
          </Typography>
        </div>
      ) : state === 1 ? (
        <div>
          <Done style={{ color: "#43a047" }} />
          <Typography variant="body2" style={{ color: "#43a047" }}>
            {i18n.t("maintenance.altualScheduled.done")}
          </Typography>
        </div>
      ) : isProvisionalDate ? (
        <div>
          <Schedule />
          <Typography variant="body2">
            {i18n.t("maintenance.altualScheduled.pending")}
          </Typography>
        </div>
      ) : (
        <div>
          <Event />
          <Typography variant="body2">
            {i18n.t("maintenance.altualScheduled.scheduled")}
          </Typography>
        </div>
      )}
    </>
  );
};
