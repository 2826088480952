// @ts-nocheck
// eslint-disable-next-line no-unused-vars
import { AnalysisOptionsModel, ReportOptionsModel } from "../helpers";
import React from "react";
import { Page, Text, View } from "@react-pdf/renderer";
import { GeneralStyles, AnalysisStyles, TableStyles } from "../ReportPDFStyles";
import getText from "../helpers/traductions";
import { green, orderList, red, sliceText, yellow } from "../helpers";
import WaterMark from "./WaterMarkPDF";
import Footer from "./FooterPDF";

/**
 * Pestaña de análisis (Site Analysis) del PDF.
 * @param {object} props
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {AnalysisOptionsModel} props.options Opciones de la pestaña.
 * @param {any} props.data Datos del report.
 * @param {any} props.header Header del report.
 * @param {any} props.waterMark Marca de agua por si algun equipo tiene riesgo indefinido.
 */
function Analysis({ reportOptions, options, data, header, waterMark }) {
  const lang = reportOptions.language || "en";

  const renderContent = () => {
    if (!data.assessmentList) {
      return <></>;
    }

    let families = orderList(
      options.orderBy,
      options.orderType,
      data.assessmentList
    );
    let result = [];

    for (let i = 0; i < families.length; i++) {
      result.push(
        <View
          wrap={false}
          style={AnalysisStyles.tableRow}
          key={`${
            families[i].brand
              ? families[i].brand?.name
              : getText(lang, "common.other.fs")
          }_${families[i].family}`}
        >
          <View style={AnalysisStyles.tableCellBrand}>
            <Text>
              {families[i].brand
                ? sliceText(families[i].brand?.name)
                : getText(lang, "common.other.fs")}
            </Text>
          </View>
          <View style={AnalysisStyles.tableCellFamily}>
            <Text>
              {families[i].family !== "other"
                ? sliceText(families[i].family) || getText(lang, "common.na")
                : getText(lang, "common.other.ms")}
            </Text>
          </View>
          <View style={AnalysisStyles.tableCellCount}>
            <Text>{families[i].driveCount || "0"}</Text>
          </View>
          <View style={AnalysisStyles.tableAsset}>
            <View style={AnalysisStyles.tableCellAssetContainer}>
              <View style={AnalysisStyles.tableCellAsset}>
                <Text>{families[i].lifeCycle.active}</Text>
              </View>
              <View style={AnalysisStyles.tableCellAsset}>
                <Text>{families[i].lifeCycle.limited}</Text>
              </View>
              <View style={AnalysisStyles.tableCellAsset}>
                <Text>{families[i].lifeCycle.inactive}</Text>
              </View>
            </View>
            <View style={AnalysisStyles.tableCellAssetContainer}>
              <View style={AnalysisStyles.tableCellAsset}>
                <Text>{families[i].criticality.low}</Text>
              </View>
              <View style={AnalysisStyles.tableCellAsset}>
                <Text>{families[i].criticality.medium}</Text>
              </View>
              <View style={AnalysisStyles.tableCellAsset}>
                <Text>{families[i].criticality.high}</Text>
              </View>
            </View>
            <View style={AnalysisStyles.tableCellAssetContainer}>
              <View style={AnalysisStyles.tableCellAsset}>
                <Text>{families[i].condition.good}</Text>
              </View>
              <View style={AnalysisStyles.tableCellAsset}>
                <Text>{families[i].condition.medium}</Text>
              </View>
              <View style={AnalysisStyles.tableCellAsset}>
                <Text>{families[i].condition.poor}</Text>
              </View>
            </View>
            <View style={AnalysisStyles.tableCellAssetContainer}>
              <View
                style={{
                  ...AnalysisStyles.tableCellAsset,
                  ...AnalysisStyles.bGreen,
                }}
              >
                <Text>{families[i].risk.low}</Text>
              </View>
              <View
                style={{
                  ...AnalysisStyles.tableCellAsset,
                  ...AnalysisStyles.bYellow,
                }}
              >
                <Text>{families[i].risk.medium}</Text>
              </View>
              <View
                style={{
                  ...AnalysisStyles.tableCellAsset,
                  ...AnalysisStyles.bRed,
                }}
              >
                <Text>{families[i].risk.high}</Text>
              </View>
            </View>
          </View>
        </View>
      );
    }

    return result;
  };

  return (
    <Page
      style={GeneralStyles.body}
      size={reportOptions.pageSize || "A4"}
      orientation={reportOptions.pageOrientation || "portrait"}
    >
      {header && header}
      <View style={TableStyles.borderTop}></View>
      <View style={AnalysisStyles.table}>
        <View style={AnalysisStyles.tableTitle}>
          <Text>{getText(lang, "analysis.tableTitle")}</Text>
        </View>
        <View fixed style={AnalysisStyles.tableHeader}>
          <View
            style={{
              ...AnalysisStyles.tableHeaderCell,
              ...AnalysisStyles.tableHeaderBrand,
            }}
          >
            <Text>{getText(lang, "drive.brand")}</Text>
          </View>
          <View
            style={{
              ...AnalysisStyles.tableHeaderCell,
              ...AnalysisStyles.tableHeaderFamily,
            }}
          >
            <Text>{getText(lang, "drive.model")}</Text>
          </View>
          <View
            style={{
              ...AnalysisStyles.tableHeaderCell,
              ...AnalysisStyles.tableHeaderCount,
            }}
          >
            <Text>{getText(lang, "analysis.count")}</Text>
          </View>
          <View
            style={{
              ...AnalysisStyles.tableHeaderCell,
              ...AnalysisStyles.tableHeaderAsset,
            }}
          >
            <View style={AnalysisStyles.tableHeaderAssetTitle}>
              <Text>{getText(lang, "analysis.factors")}</Text>
            </View>
            <View style={AnalysisStyles.tableHeaderAssetNameContainer}>
              <Text style={AnalysisStyles.tableHeaderAssetName}>
                {getText(lang, "drive.lifecycle")}
              </Text>
              <Text style={AnalysisStyles.tableHeaderAssetName}>
                {getText(lang, "drive.criticality")}
              </Text>
              <Text style={AnalysisStyles.tableHeaderAssetName}>
                {getText(lang, "drive.condition")}
              </Text>
              <Text style={AnalysisStyles.tableHeaderAssetName}>
                {getText(lang, "drive.risk")}
              </Text>
            </View>
            <View style={AnalysisStyles.tableHeaderAssetStateContainer}>
              <View style={AnalysisStyles.tableHeaderAssetState}>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: green,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.active2")
                    : getText(lang, "analysis.active")}
                </Text>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: yellow,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.limited2")
                    : getText(lang, "analysis.limited")}
                </Text>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: red,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.inactive2")
                    : getText(lang, "analysis.inactive")}
                </Text>
              </View>
              <View style={AnalysisStyles.tableHeaderAssetState}>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: green,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.low2")
                    : getText(lang, "analysis.low")}
                </Text>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: yellow,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.medium2")
                    : getText(lang, "analysis.medium")}
                </Text>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: red,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.high2")
                    : getText(lang, "analysis.high")}
                </Text>
              </View>
              <View style={AnalysisStyles.tableHeaderAssetState}>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: green,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.good2")
                    : getText(lang, "analysis.good")}
                </Text>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: yellow,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.medium2")
                    : getText(lang, "analysis.medium")}
                </Text>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: red,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.poor2")
                    : getText(lang, "analysis.poor")}
                </Text>
              </View>
              <View style={AnalysisStyles.tableHeaderAssetState}>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: green,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.low2")
                    : getText(lang, "analysis.low")}
                </Text>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: yellow,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.medium2")
                    : getText(lang, "analysis.medium")}
                </Text>
                <Text
                  style={{
                    ...AnalysisStyles.tableHeaderAssetRow,
                    backgroundColor: red,
                  }}
                >
                  {reportOptions.pageOrientation === "portrait"
                    ? getText(lang, "analysis.high2")
                    : getText(lang, "analysis.high")}
                </Text>
              </View>
            </View>
          </View>
        </View>
        {renderContent()}
      </View>
      <Footer lang={lang} />
      <WaterMark text={waterMark} orientation={reportOptions.pageOrientation} />
    </Page>
  );
}

export default Analysis;
