import React, { useRef } from "react";
import { withRouter } from "react-router-dom";

import {
  IconButton,
  makeStyles,
  Paper,
  InputBase,
  Divider,
} from "@material-ui/core";
import { SearchOutlined, Clear } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    flex: 1,
    alignItems: "center",
    minWidth: 150,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  searchIcon: {
    margin: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function SearchField(props) {
  const classes = useStyles();
  const { inputBaseProps } = props;

  const clear = () => {
    const event = { target: { name: props.name, value: "" } };
    inputBaseProps.onChange(event);
  };

  // const goBack = () => {
  //   const { match } = props;

  //   if (match.path === "/plantas/:plantaId/equipos/:id?") {
  //     const { plantaId } = match.params;
  //     props.history.push(`/plantas/${plantaId}`);
  //   } else {
  //     props.history.goBack();
  //   }
  // };

  let inputRef = useRef(null);

  return (
    <Paper className={classes.root} style={props.style}>
      {/* {!props.disableBackButton && (
        <>
          <IconButton
            className={classes.iconButton}
            onClick={goBack}
            aria-label="back"
          >
            <ArrowBack />
          </IconButton>
          <Divider className={classes.divider} orientation="vertical" />
        </>
      )} */}

      <SearchOutlined
        className={classes.searchIcon}
        fontSize="small"
        titleAccess="Search"
      />
      <InputBase
        inputRef={inputRef}
        className={classes.input}
        inputProps={{ ...inputBaseProps }}
      />
      <IconButton
        className={classes.iconButton}
        disabled={!inputBaseProps.value}
        onClick={clear}
        aria-label="search"
      >
        <Clear fontSize="small" />
      </IconButton>
      {props.endButton ? (
        <>
          <Divider className={classes.divider} orientation="vertical" />
          {props.endButton}
        </>
      ) : null}
    </Paper>
  );
}

export default withRouter(SearchField);
