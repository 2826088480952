import React, { useEffect, useContext } from "react";
import Auth from "../../layout/Auth";
import { CircularProgress } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import AuthService from "../../../services/AuthService";
import AppContext from "../../../contexts/AppContext";
import AuthLoading from "../../atoms/AuthLoading";

export default function AfterLoginPage(props) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    getIdTokenClaims().then((claims) => {
      if (claims) {
        AuthService.login({ token: claims.__raw })
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            }
          })
          .then((data) => {
            if (data.access_token !== undefined) {
              localStorage.setItem("tokenInfo", JSON.stringify(data));
              // Navigate to the requested source OR home
              if (props.location.state && props.location.state.referrer) {
                props.history.push(props.location.state.referrer);
              } else {
                props.history.push("/");
              }
            } else {
              props.history.push("/request");
            }
          });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIdTokenClaims, props.history, setGlobal]);

  return (
    <Auth>
      <AuthLoading hidden={props.hidden} />
    </Auth>
  );
}
