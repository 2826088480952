import { StyleSheet } from "@react-pdf/renderer";
import {
  globalColors,
  objectColors,
  surfaceColors,
  surfaceStatusColors,
} from "../../../../assets/css/Styles";
// OJO: Esto no son estilos de Material-UI, son estilos de react-pdf

const GeneralStyles = StyleSheet.create({
  body: {
    padding: 24,
    fontSize: 10,
    fontFamily: "MyriadPro",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  centerText: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  leftCenterText: {
    display: "flex",
    justifyContent: "center",
    textAlign: "left",
  },
});

const CoverStyles = StyleSheet.create({
  container: {
    color: "white",
    fontFamily: "MyriadPro",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 16,
  },

  backgroundImageContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  backgroundImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
  parterLogo: {
    height: 100,
    width: 100,
    padding: 3,
  },
  parterLogoHidden: {
    padding: 3,
    height: 100,
  },
  headerTextContainer: {
    display: "flex",
    justifyContent: "center",
    fontSize: 10,
    padding: 2,
    gap: 2,
  },
  headerTextRow: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "light",
  },
  headerTextLabel: {
    fontWeight: "normal",
  },
  titleContainer: {
    paddingLeft: 6,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 40,
  },
  d360Logo: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
  subtitle: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "bold",
    alignItems: "center",
    fontSize: 15,
  },
  registeredImage: {
    padding: 1,
    height: 7,
    width: 7,
    marginTop: -4,
    marginLeft: -1,
    marginRight: 5,
  },
  description: {
    marginLeft: 38,
    maxWidth: 350,
    fontSize: 12,
  },
  infoFooterContainer: { display: "flex", flexDirection: "column", gap: 20 },
  infoContainer: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 10,
    paddingLeft: 6,
    gap: 4,
  },
  infoRow: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "light",
  },
  infoLabel: {
    fontWeight: "normal",
  },
  footerContainer: {
    backgroundColor: globalColors.accentColor,
    alignItems: "flex-end",
  },
  footerGradientContainer: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  footerGradient: {
    height: "100%",
    width: "auto",
    opacity: 0.5,
  },
  footerLogo: { height: 30, margin: 15, marginRight: 10, padding: 2 },
});

const HeaderStyles = StyleSheet.create({
  container: {
    marginHorizontal: -8,
    marginTop: -8,
    fontSize: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  parterLogo: {
    height: 41,
    width: 41,
  },
  infoContainer: {},
  infoRow: {
    display: "flex",
    flexDirection: "row",
    minWidth: 230,
  },
  rowOdd: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 5,
    minWidth: 230,
    backgroundColor: surfaceColors.surfaceStrongColor,
  },
  infoLabel: {
    width: 100,
    marginLeft: 5,
    fontWeight: "bold",
  },
  title: {
    fontSize: 10,
    marginVertical: 8,
    marginHorizontal: -8,
    backgroundColor: surfaceColors.surfaceStrongColor,
    textAlign: "center",
  },
});

const FooterStyles = StyleSheet.create({
  container: {
    marginTop: "auto",
    marginHorizontal: -8,
    marginBottom: -16,
    paddingTop: 8,
    display: "flex",
    textAlign: "center",
  },
  footer: {
    width: "100%",
    borderTop: 0.5,
    borderColor: objectColors.objectWeakColor,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 8,
    paddingTop: 12,
  },
  d360Logo: {
    width: 25,
    height: 25,
    marginRight: 8,
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 12,
  },
  registeredImage: {
    padding: 1,
    height: 7,
    width: 7,
    marginTop: -4,
    marginLeft: -1,
    marginRight: 5,
  },
  danfossLogo: { height: 25 },
});

const AnalysisStyles = StyleSheet.create({
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
    fontSize: 12,
  },
  tableTitle: {
    borderLeft: 0.5,
    borderRight: 0.5,
    padding: 2,
    backgroundColor: objectColors.objectWeakerColor,
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    borderTop: 0.5,
    borderLeft: 0.5,
    borderBottom: 0,
    backgroundColor: objectColors.objectWeakerColor,
  },
  tableHeaderCell: {
    borderBottom: 0.5,
    borderRight: 0.5,
    padding: 2,
  },
  tableHeaderBrand: {
    width: "10%",
    ...GeneralStyles.centerText,
  },
  tableHeaderFamily: {
    width: "17%",
    ...GeneralStyles.centerText,
  },
  tableHeaderCount: {
    width: "6%",
    ...GeneralStyles.centerText,
  },
  tableHeaderAsset: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
    borderRight: 0,
  },
  tableHeaderAssetTitle: {
    borderBottom: 0.5,
    borderRight: 0.5,
    paddingVertical: 2,
    marginTop: -2,
    marginHorizontal: -2,
  },
  tableHeaderAssetNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottom: 0.5,
    marginHorizontal: -2,
  },
  tableHeaderAssetName: {
    borderRight: 0.5,
    width: "25%",
  },
  tableHeaderAssetStateContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: -2,
    marginHorizontal: -2,
  },
  tableHeaderAssetState: {
    width: "25%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  tableHeaderAssetRow: {
    borderRight: 0.5,
    width: "calc(100% / 3)",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    borderLeft: 0.5,
    borderBottom: 0.5,
  },
  tableCellBrand: {
    width: "10%",
    borderRight: 0.5,
    ...GeneralStyles.centerText,
  },
  tableCellFamily: {
    width: "17%",
    borderRight: 0.5,
    ...GeneralStyles.centerText,
  },
  tableCellCount: {
    width: "6%",
    borderRight: 0.5,
    ...GeneralStyles.centerText,
  },
  tableAsset: {
    width: "75%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  tableCellAssetContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "25%",
  },
  tableCellAsset: {
    width: "33%",
    borderRight: 0.5,
    ...GeneralStyles.centerText,
  },
  bRed: {
    backgroundColor: surfaceStatusColors.surfaceErrorColor,
  },
  bYellow: {
    backgroundColor: surfaceStatusColors.surfaceWarningColor,
  },
  bGreen: {
    backgroundColor: surfaceStatusColors.surfaceSuccessColor,
  },
});

const ResultStyles = StyleSheet.create({
  container: {
    display: "flex",
    justifyContent: "space-between",
    gap: 8,
  },
  title: {
    paddingHorizontal: 2,
    fontSize: 8,
    width: "100%",
    borderBottom: 0.25,
    backgroundColor: objectColors.objectWeakerColor,
  },
  stackContainer: {
    border: 0.5,
    width: "100%",
  },
  stackImage: {
    paddingHorizontal: 2,
  },
  pieRow: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
  pieContainer: {
    border: 0.5,
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  pieDefault: { marginVertical: -15 },
  pieLetter: { maxHeight: 120, maxWidth: 120 },
  pieA4: { maxHeight: 150, maxWidth: 150 },
  pieA3: { maxHeight: 220, maxWidth: 220 },
  pieA2: { maxHeight: 340, maxWidth: 340 },
});

const InformationStyles = StyleSheet.create({
  container: {
    border: 0.5,
    marginBottom: 15,
  },
  title: {
    fontSize: 12,
    textAlign: "center",
    padding: 3,
    backgroundColor: objectColors.objectWeakerColor,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: 0.5,
  },
  header: {
    width: "10%",
    borderRight: 0.5,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    width: "90%",
    padding: 2,
  },
});

const TableStyles = StyleSheet.create({
  borderTop: {
    width: "100%",
    borderBottom: 0.5,
  },
  table: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tableHeaderRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottom: 0.5,
    borderLeft: 0.5,
    backgroundColor: objectColors.objectWeakerColor,
  },
  tableHeaderCell: {
    width: "100%",
    borderRight: 0.5,
    textAlign: "center",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottom: 0.5,
    borderLeft: 0.5,
  },
  tableCell: {
    width: "100%",
    borderRight: 0.5,
    paddingLeft: 3,
    paddingRight: 3,
  },
  isSliced: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

const PreManStyles = StyleSheet.create({
  legendContainer: {
    display: "flex",
    width: "100%",
    fontSize: 8,
  },
  legendIconsContainer: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    marginBottom: 2,
  },
  legendIcon: { height: 8, width: 8, marginLeft: 5, marginRight: 2 },

  yearRow: { display: "flex", flexDirection: "row" },
  PMCell: {
    display: "flex",
    flexDirection: "row",
    width: "40%",
    borderRight: 0.5,
  },
  backgroundM1: { backgroundColor: "#92d050" },
  backgroundM2: { backgroundColor: "#8ea9db" },
  backgroundM3: { backgroundColor: "#ffc000" },
  backgroundNone: { backgroundColor: "#d9d9d9" },
});

const WaterMarkStyles = StyleSheet.create({
  container: {
    position: "absolute",
    opacity: 0.2,
    left: "1%",
    right: "1%",
    top: "1%",
    bottom: "1%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    fontFamily: "MyriadPro",
    transform: "rotate(-45)",
  },
});

export {
  GeneralStyles,
  CoverStyles,
  HeaderStyles,
  FooterStyles,
  AnalysisStyles,
  ResultStyles,
  InformationStyles,
  TableStyles,
  PreManStyles,
  WaterMarkStyles,
};
