// @ts-nocheck
// eslint-disable-next-line no-unused-vars
import { CoverOptionsModel, ReportOptionsModel } from "../helpers";
import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import { CoverStyles } from "../ReportPDFStyles";
import backgroundPicture from "../../../../../assets/images/report-cover-background.jpg";
import danfossLogo from "../../../../../assets/images/danfoss-logo-right-white-big.png";
import gradient from "../../../../../assets/images/gradient.png";
import registeredChar from "../../../../../assets/images/registered-brand-white.png";
import d360LiveLogo from "../../../../../assets/images/logo-360live.png";
import moment from "moment";
import getText from "../helpers/traductions";
import WaterMark from "./WaterMarkPDF";

/**
 * Portada del report
 * @param {object} props
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {CoverOptionsModel} props.options Opciones de la cubierta del report.
 * @param {number} props.driveNum Numero de equipos.
 * @param {string} props.partnerLogo Logo del partner.
 * @param {any} props.waterMark Marca de agua por si algun equipo tiene riesgo indefinido.
 */
function Cover({ reportOptions, options, driveNum, partnerLogo, waterMark }) {
  const lang = reportOptions.language;

  return (
    <Page
      size={reportOptions.pageSize || "A4"}
      orientation={reportOptions.pageOrientation || "portrait"}
    >
      <View style={CoverStyles.backgroundImageContainer}>
        <Image
          style={CoverStyles.backgroundImage}
          src={options?.customBackground?.base64 || backgroundPicture}
        />
      </View>
      <View style={CoverStyles.container}>
        <View style={CoverStyles.headerContainer}>
          {partnerLogo && options.displayPartnerLogo ? (
            <Image style={CoverStyles.parterLogo} src={partnerLogo} />
          ) : (
            <View style={CoverStyles.parterLogoHidden}></View>
          )}
          <View style={CoverStyles.headerTextContainer}>
            <View style={CoverStyles.headerTextRow}>
              <Text style={CoverStyles.headerTextLabel}>
                {getText(lang, "cover.header.partnerName")}
              </Text>
              <Text>{options.partnerName}</Text>
            </View>
            <View style={CoverStyles.headerTextRow}>
              <Text style={CoverStyles.headerTextLabel}>
                {getText(lang, "cover.header.partnerAddress")}
              </Text>
              <Text>{options.partnerAddress}</Text>
            </View>
            <View style={CoverStyles.headerTextRow}>
              <Text style={CoverStyles.headerTextLabel}>
                {getText(lang, "cover.header.email")}
              </Text>
              <Text>{options.partnerEmail || ""}</Text>
            </View>
          </View>
        </View>
        <Text>{""}</Text> {/* No borrar esta línea */}
        <View style={CoverStyles.titleContainer}>
          <Text style={CoverStyles.title}>{getText(lang, "cover.title")}</Text>
          <View style={CoverStyles.subtitle}>
            <Image style={CoverStyles.d360Logo} src={d360LiveLogo} />
            <Text>{getText(lang, "cover.subtitleName1")}</Text>
            <Image style={CoverStyles.registeredImage} src={registeredChar} />
            <Text>{getText(lang, "cover.subtitleName2")}</Text>
          </View>
          <Text style={CoverStyles.description}>
            {getText(lang, "cover.titleDesc")}
          </Text>
        </View>
        <View style={CoverStyles.infoFooterContainer}>
          <View style={CoverStyles.infoContainer}>
            <View style={CoverStyles.infoRow}>
              <Text style={CoverStyles.infoLabel}>
                {getText(lang, "cover.customerName")}
              </Text>
              <Text>{options.clientName}</Text>
            </View>
            <View style={CoverStyles.infoRow}>
              <Text style={CoverStyles.infoLabel}>
                {getText(lang, "cover.customerAddress")}
              </Text>
              <Text>{options.clientAddress}</Text>
            </View>
            {(options.plantName !== options.clientName ||
              options.plantAddress !== options.clientAddress) && (
              <>
                <Text>{"\n"}</Text>
                <View style={CoverStyles.infoRow}>
                  <Text style={CoverStyles.infoLabel}>
                    {getText(lang, "cover.plantName")}
                  </Text>
                  <Text>{options.plantName}</Text>
                </View>
                <View style={CoverStyles.infoRow}>
                  <Text style={CoverStyles.infoLabel}>
                    {getText(lang, "cover.plantAddress")}
                  </Text>
                  <Text>{options.plantAddress}</Text>
                </View>
                <Text>{"\n"}</Text>
              </>
            )}
            <View style={CoverStyles.infoRow}>
              <Text style={CoverStyles.infoLabel}>
                {getText(lang, "cover.date")}
              </Text>
              <Text>
                {moment(options.reportDate).format(reportOptions.dateFormat) ||
                  ""}
              </Text>
            </View>
            <Text>{"\n"}</Text>
            <View style={CoverStyles.infoRow}>
              <Text style={CoverStyles.infoLabel}>
                {getText(lang, "cover.count")}
              </Text>
              <Text>{driveNum}</Text>
            </View>
            <View style={CoverStyles.infoRow}>
              <Text style={CoverStyles.infoLabel}>
                {getText(lang, "cover.assessmentBy")}
              </Text>
              <Text>{options.assessmentBy}</Text>
            </View>
            <View style={CoverStyles.infoRow}>
              <Text style={CoverStyles.infoLabel}>
                {getText(lang, "cover.analBy")}
              </Text>
              <Text>{options.analysedBy}</Text>
            </View>
          </View>
          <View style={CoverStyles.footerContainer}>
            <View style={CoverStyles.footerGradientContainer}>
              <Image style={CoverStyles.footerGradient} src={gradient} />
            </View>
            <Image style={CoverStyles.footerLogo} src={danfossLogo} />
          </View>
        </View>
      </View>
      <WaterMark text={waterMark} orientation={reportOptions.pageOrientation} />
    </Page>
  );
}

export default Cover;
