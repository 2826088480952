import React from "react";

import Auth from "../components/layout/Auth";
import { Button } from "@material-ui/core";
import { PanToolRounded } from "@material-ui/icons";
import { Auth_Style } from "../assets/css/Styles";
import i18n from "../locales/i18n";

export default function Unauthorized(props) {
  const styles = Auth_Style();
  return (
    <Auth>
      <h3 className={styles.authTitle}>
        {i18n.t("unauthorized.title")}
        <PanToolRounded />
      </h3>
      <div className={styles.authMessage}>{i18n.t("unauthorized.text")}</div>
      <div className={styles.authButtonContainer}>
        <Button
          variant="contained"
          className={styles.authButton}
          onClick={() => props.history.goBack()}
        >
          {i18n.t("unauthorized.btn_goback")}
        </Button>
      </div>
    </Auth>
  );
}
