import React, { useEffect, useState } from "react";
import { Avatar, Card, CardContent, CardHeader } from "@material-ui/core";
import {
  AssignmentTurnedIn,
  Cached,
  Equalizer,
  Report,
  Warning,
  Stop,
} from "@material-ui/icons";
import { ResponsiveBar } from "@nivo/bar";
import RelativeBackdrop from "./RelativeBackdrop";
import { ResponsivePie } from "@nivo/pie";
import i18n from "../locales/i18n";
import { DetailTabs_Style, Common_Style } from "../assets/css/Styles";
import NoData from "./atoms/noData";
import DriveIcon from "./atoms/driveIcon";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

const dataBarChart = (assessmentList) => {
  var data = [];
  for (let i = 0; i < assessmentList.length; i++) {
    data.push({
      name:
        assessmentList[i].family !== "other"
          ? assessmentList[i].family
          : assessmentList[i].brand !== null
          ? `${i18n.t("common.other.singularF")} ${
              assessmentList[i].brand.name
            }`
          : i18n.t("common.other.singularF"),
      [i18n.t("common.alto")]: assessmentList[i].risk.high,
      [i18n.t("common.alto") + "Color"]: "#E2000F",
      [i18n.t("common.medio")]: assessmentList[i].risk.medium,
      [i18n.t("common.medio") + "Color"]: "#FFAA00",
      [i18n.t("common.bajo")]: assessmentList[i].risk.low,
      [i18n.t("common.bajo") + "Color"]: "#14C510",
      [i18n.t("common.indefinido")]: assessmentList[i].risk.undefined,
      [i18n.t("common.indefinido") + "Color"]: "#BFBEBE",
      //Keys para el tooltip
      barHighValue: assessmentList[i].risk.high,
      barHighColor: "#E2000F",
      barMediumValue: assessmentList[i].risk.medium,
      barMediumColor: "#FFAA00",
      barLowValue: assessmentList[i].risk.low,
      barLowColor: "#14C510",
      barUndefinedValue: assessmentList[i].risk.undefined,
      barUndefinedColor: "#BFBEBE",
    });
  }
  return data;
};

const dataPieLCS = (lifeCycle) => {
  var data = [
    {
      id: i18n.t("common.activo.singularM"),
      label: i18n.t("common.activo.singularM"),
      value: lifeCycle.active,
      color: "#14C510",
    },
    {
      id: i18n.t("common.limitado"),
      label: i18n.t("common.limitado"),
      value: lifeCycle.limited,
      color: "#FFAA00",
    },
    {
      id: i18n.t("common.inactivo"),
      label: i18n.t("common.inactivo"),
      value: lifeCycle.inactive,
      color: "#E2000F",
    },
    {
      id: i18n.t("common.indefinido"),
      label: i18n.t("common.indefinido"),
      value: lifeCycle.undefined,
      color: "#BFBEBE",
    },
  ];
  return data;
};

const dataPieAC = (criticality) => {
  var data = [
    {
      id: i18n.t("common.alto"),
      label: i18n.t("common.alto"),
      value: criticality.high,
      color: "#E2000F",
    },
    {
      id: i18n.t("common.medio"),
      label: i18n.t("common.medio"),
      value: criticality.medium,
      color: "#FFAA00",
    },
    {
      id: i18n.t("common.bajo"),
      label: i18n.t("common.bajo"),
      value: criticality.low,
      color: "#14C510",
    },
    {
      id: i18n.t("common.indefinido"),
      label: i18n.t("common.indefinido"),
      value: criticality.undefined,
      color: "#BFBEBE",
    },
  ];
  return data;
};

const dataPieACo = (condition) => {
  var data = [
    {
      id: i18n.t("common.bueno"),
      label: i18n.t("common.bueno"),
      value: condition.good,
      color: "#14C510",
    },
    {
      id: i18n.t("common.medio"),
      label: i18n.t("common.medio"),
      value: condition.medium,
      color: "#FFAA00",
    },
    {
      id: i18n.t("common.pobre"),
      label: i18n.t("common.pobre"),
      value: condition.poor,
      color: "#E2000F",
    },
    {
      id: i18n.t("common.indefinido"),
      label: i18n.t("common.indefinido"),
      value: condition.undefined,
      color: "#BFBEBE",
    },
  ];
  return data;
};

const dataPieAR = (risk) => {
  var data = [
    {
      id: i18n.t("common.alto"),
      label: i18n.t("common.alto"),
      value: risk.high,
      color: "#E2000F",
    },
    {
      id: i18n.t("common.medio"),
      label: i18n.t("common.medio"),
      value: risk.medium,
      color: "#FFAA00",
    },
    {
      id: i18n.t("common.bajo"),
      label: i18n.t("common.bajo"),
      value: risk.low,
      color: "#14C510",
    },
    {
      id: i18n.t("common.indefinido"),
      label: i18n.t("common.indefinido"),
      value: risk.undefined,
      color: "#BFBEBE",
    },
  ];
  return data;
};

function AssessmentChart(props) {
  const { loading, data } = props;

  const [datosBar, setDatosBar] = useState([]);
  const [datosPieLCS, setdatosPieLCS] = useState([]);
  const [datosPieAC, setDatosPieAC] = useState([]);
  const [datosPieACo, setDatosPieACo] = useState([]);
  const [datosPieAR, setDatosPieAR] = useState([]);

  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  useEffect(() => {
    if (
      data.assessmentList &&
      data.condition &&
      data.criticality &&
      data.lifeCycle &&
      data.risk
    ) {
      setDatosBar(dataBarChart(data.assessmentList));
      setdatosPieLCS(dataPieLCS(data.lifeCycle));
      setDatosPieAC(dataPieAC(data.criticality));
      setDatosPieACo(dataPieACo(data.condition));
      setDatosPieAR(dataPieAR(data.risk));
    }
  }, [data]);

  return datosBar.length > 0 || loading ? (
    <>
      <RelativeBackdrop hidden={!loading} absolute>
        <Card>
          <CardHeader
            title={i18n.t("assessment.productFamily")}
            avatar={
              <Avatar aria-label="avatar" className={styles.tabHeaderAvatar}>
                <DriveIcon />
              </Avatar>
            }
          />
          <PerfectScrollbar className={commonStyles.scrollCard}>
            <CardContent
              style={{
                height: 250,
                padding: 0,
                minWidth: datosBar.length * 60 + 100,
              }}
              data-cy="FamilyChart"
            >
              {/* @ts-ignore */}
              <ResponsiveBar
                //altura minima para mostrar el valor
                labelSkipHeight={12}
                data={datosBar}
                keys={[
                  i18n.t("common.alto"),
                  i18n.t("common.medio"),
                  i18n.t("common.bajo"),
                  i18n.t("common.indefinido"),
                ]}
                colors={({ id, data }) => String(data[`${id}Color`])}
                indexBy="name"
                margin={{ top: 10, right: 10, bottom: 30, left: 60 }}
                minValue={0}
                // @ts-ignore-next-line
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: i18n.t("assessment.numAssets"),
                  legendPosition: "middle",
                  legendOffset: -45,
                }}
                //tooltip al pasar el raton custom
                tooltip={({ data }) => (
                  <div>
                    <b style={{ marginLeft: 8 }}>{data?.name}</b>
                    <div>
                      {data?.barHighValue !== 0 && (
                        <div>
                          <Stop
                            style={{
                              color: `${data.barHighColor}`,
                              paddingBottom: 3,
                            }}
                          />
                          {i18n.t("common.alto")}: <b>{data.barHighValue}</b>
                        </div>
                      )}
                    </div>
                    <div>
                      {data?.barMediumValue !== 0 && (
                        <div>
                          <Stop
                            style={{
                              color: `${data.barMediumColor}`,
                              paddingBottom: 3,
                            }}
                          />
                          {i18n.t("common.medio")}: <b>{data.barMediumValue}</b>
                        </div>
                      )}
                    </div>
                    <div>
                      {data?.barLowValue !== 0 && (
                        <div>
                          <Stop
                            style={{
                              color: `${data.barLowColor}`,
                              paddingBottom: 3,
                            }}
                          />
                          {i18n.t("common.bajo")}: <b>{data.barLowValue}</b>
                        </div>
                      )}
                    </div>
                    <div>
                      {data?.barUndefinedValue !== 0 && (
                        <div>
                          <Stop
                            style={{
                              color: `${data.barUndefinedColor}`,
                              paddingBottom: 3,
                            }}
                          />
                          {i18n.t("common.indefinido")}:{" "}
                          <b>{data.barUndefinedValue}</b>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              />
            </CardContent>
          </PerfectScrollbar>
        </Card>
      </RelativeBackdrop>
      <div className={commonStyles.infoCardContainer}>
        <div className={commonStyles.infoCard}>
          <RelativeBackdrop hidden={!loading} absolute>
            <Card>
              <CardHeader
                title={i18n.t("assessment.LCS")}
                //className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    <Cached />
                  </Avatar>
                }
              />
              <CardContent
                style={{ height: 300, padding: 0 }}
                data-cy="LifeCycleStatusChart"
              >
                {/* @ts-ignore */}
                <ResponsivePie
                  data={datosPieLCS}
                  margin={{ bottom: 60 }}
                  innerRadius={0.5}
                  colors={{ datum: "color" }}
                  enableRadialLabels={false}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      translateX: 1,
                      translateY: 46,
                      itemsSpacing: 5,
                      itemWidth: 72,
                      itemHeight: 30,
                      itemTextColor: "#999",
                      itemDirection: "left-to-right",
                      itemOpacity: 1,
                      symbolSize: 20,
                      symbolShape: "circle",
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </RelativeBackdrop>
        </div>
        <div className={commonStyles.infoCard}>
          <RelativeBackdrop hidden={!loading} absolute>
            <Card>
              <CardHeader
                title={i18n.t("assessment.AC")}
                //className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    <Report />
                  </Avatar>
                }
              />
              <CardContent
                style={{ height: 300, padding: 0 }}
                data-cy="AssetCriticallityChart"
              >
                {/* @ts-ignore */}
                <ResponsivePie
                  data={datosPieAC}
                  margin={{ bottom: 60 }}
                  innerRadius={0.5}
                  colors={{ datum: "color" }}
                  enableRadialLabels={false}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      translateX: 1,
                      translateY: 46,
                      itemsSpacing: 5,
                      itemWidth: 72,
                      itemHeight: 30,
                      itemTextColor: "#999",
                      itemDirection: "left-to-right",
                      itemOpacity: 1,
                      symbolSize: 20,
                      symbolShape: "circle",
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </RelativeBackdrop>
        </div>
      </div>
      <div className={commonStyles.infoCardContainer}>
        <div className={commonStyles.infoCard}>
          <RelativeBackdrop hidden={!loading} absolute>
            <Card>
              <CardHeader
                title={i18n.t("assessment.ACo")}
                //className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    <AssignmentTurnedIn />
                  </Avatar>
                }
              />
              <CardContent
                style={{ height: 300, padding: 0 }}
                data-cy="AssetConditionChart"
              >
                {/* @ts-ignore */}
                <ResponsivePie
                  data={datosPieACo}
                  margin={{ bottom: 60 }}
                  innerRadius={0.5}
                  colors={{ datum: "color" }}
                  enableRadialLabels={false}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      translateX: 1,
                      translateY: 46,
                      itemsSpacing: 5,
                      itemWidth: 72,
                      itemHeight: 30,
                      itemTextColor: "#999",
                      itemDirection: "left-to-right",
                      itemOpacity: 1,
                      symbolSize: 20,
                      symbolShape: "circle",
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </RelativeBackdrop>
        </div>
        <div className={commonStyles.infoCard}>
          <RelativeBackdrop hidden={!loading} absolute>
            <Card>
              <CardHeader
                title={i18n.t("assessment.AR")}
                //className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    className={styles.tabHeaderAvatar}
                  >
                    <Warning />
                  </Avatar>
                }
              />
              <CardContent
                style={{ height: 300, padding: 0 }}
                data-cy="AssetRiskChart"
              >
                {/* @ts-ignore */}
                <ResponsivePie
                  data={datosPieAR}
                  margin={{ bottom: 60 }}
                  innerRadius={0.5}
                  colors={{ datum: "color" }}
                  enableRadialLabels={false}
                  legends={[
                    {
                      anchor: "bottom",
                      direction: "row",
                      translateX: 1,
                      translateY: 46,
                      itemsSpacing: 5,
                      itemWidth: 72,
                      itemHeight: 30,
                      itemTextColor: "#999",
                      itemDirection: "left-to-right",
                      itemOpacity: 1,
                      symbolSize: 20,
                      symbolShape: "circle",
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </RelativeBackdrop>
        </div>
      </div>
    </>
  ) : (
    <NoData
      icon={<Equalizer style={{ fontSize: 100 }} />}
      title={i18n.t("dashboard.noDataTitle")}
      text={i18n.t("dashboard.noDataText")}
    />
  );
}

export default AssessmentChart;
