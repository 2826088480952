import React, { useEffect, useRef, useState, useContext } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  Assignment,
  AssignmentInd,
  AssignmentTurnedIn,
  Person,
  AddCircleOutline,
} from "@material-ui/icons";
import { SnackbarSuccess, SnackbarError } from "../helpers/Common";
import AppContext from "../contexts/AppContext";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchField from "./molecule/SearchField/SearchField";
import withHover from "./withHover";
import i18n from "../locales/i18n";
import moment from "moment";
import AlertDialog from "./AlertDialog";
import { UsersAPI, EquipoActividadesAPI } from "../services/API";
import { DetailTabs_Style, Common_Style } from "../assets/css/Styles";

const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

export default function Actividades({
  id,
  equipoActividades,
  readOnly,
  goBack,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [users, setUsers] = useState([]);
  const [actividades, setActividades] = useState(equipoActividades);

  const [search, setSearch] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  const dialogRef = useRef(null);
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  const getUsers = () => {
    setLoading(true);
    setLoadingUsers(true);

    return UsersAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setUsers(data.users);
        setLoading(false);
        setLoadingUsers(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoadingUsers(false);
      });
  };

  const addActividad = () => {
    let actividadesAPI = EquipoActividadesAPI;

    setLoading(true);
    return actividadesAPI
      .add(currentItem)
      .then((response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("actividades.snackbar.new.success")
          );
          return response.json();
        } else {
          SnackbarError(setGlobal, i18n.t("actividades.snackbar.new.error"));
          if (response.status === 404 || response.status === 401) {
            goBack();
            throw new Error("Something went wrong on Actividades");
          }
          setLoading(false);
        }
      })
      .then((data) => {
        setLoading(false);
        setActividades((prevState) => [
          ...prevState,
          { ...currentItem, id: data.id },
        ]);

        dialogRef.current.close();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const updateActividad = () => {
    let actividadesAPI = EquipoActividadesAPI;

    setLoading(true);
    return actividadesAPI
      .update(currentItem)
      .then((response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("actividades.snackbar.update.success")
          );
          setLoading(false);
          setActividades((prevState) =>
            prevState.map((a) => (a.id === currentItem.id ? currentItem : a))
          );
          dialogRef.current.close();
        } else {
          SnackbarError(setGlobal, i18n.t("actividades.snackbar.update.error"));
          if (response.status === 404 || response.status === 401) {
            goBack();
            throw new Error("Something went wrong on Update Actividades");
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteActividad = () => {
    let actividadesAPI = EquipoActividadesAPI;

    setLoading(true);
    return actividadesAPI
      .remove(currentItem.id)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          setActividades((prevState) =>
            prevState.filter((a) => a.id !== currentItem.id)
          );
          dialogRef.current.close();
          SnackbarSuccess(
            setGlobal,
            i18n.t("actividades.snackbar.deleted.success")
          );
        } else {
          SnackbarError(
            setGlobal,
            i18n.t("actividades.snackbar.deleted.error")
          );
          if (response.status === 404 || response.status === 401) {
            goBack();
            throw new Error("Something went wrong on delete Actividades");
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const openActividad = (actividad) => {
    if (!actividad) {
      var appUser = users.length > 0 ? users[0] : null;
      var appUserId = appUser ? appUser.id : null;
      actividad = {
        appUser: appUser,
        appUserId: appUserId,
        estado: 0,
        fecha: new Date(),
        descripcion: "",
        observaciones: "",
      };

      if (equipoActividades) {
        actividad.equipoId = id;
      } else {
        actividad.fisicalElementId = id;
      }
    }
    setCurrentItem(actividad);
    dialogRef.current.open(actividad);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className={commonStyles.mainContainer}>
      <div className={commonStyles.searchFieldContainer}>
        <div className={commonStyles.searchField}>
          <SearchField
            inputBaseProps={{
              placeholder: i18n.t("common.search"),
              value: search,

              onChange: (event) => {
                setSearch(event.target.value);
              },
            }}
            goBack={goBack}
          />
          {!readOnly && (
            <Button
              variant="contained"
              className={commonStyles.primaryButtonRaw}
              onClick={() => openActividad()}
            >
              <AddCircleOutline className={styles.addCircleOutline} />
              {i18n.t("common.new.femenine")}
            </Button>
          )}
        </div>
      </div>
      <Grid item container spacing={3} style={{ flexWrap: "wrap" }}>
        <Grid item xs={6} md={4} style={{ minWidth: "22em" }}>
          <Card variant="outlined">
            <CardHeader
              title={i18n.t("actividades.tituloPendientes")}
              subheader={i18n.t("actividades.subtituloPendientes")}
              avatar={
                <Avatar className={styles.tabHeaderAvatar}>
                  <Assignment />
                </Avatar>
              }
            />
            <CardContent className={styles.tabSurfaceColor}>
              <Grid container spacing={2}>
                {actividades
                  .filter(
                    (a) =>
                      a.estado === 0 &&
                      a.descripcion.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((actividad) => (
                    <Grid item xs={12} key={actividad.id.toString()}>
                      <CardWithHover
                        onClick={() => openActividad(actividad)}
                        data-cy="CardInfo"
                      >
                        <CardHeader
                          title={`${actividad.appUser.nombre} ${actividad.appUser.apellidos}`}
                          subheader={moment(actividad.fecha)
                            .local()
                            .format("DD/MM/yyyy")}
                          avatar={
                            <Avatar className={styles.tabHeaderAvatar}>
                              <Person />
                            </Avatar>
                          }
                        />
                        <CardContent>{actividad.descripcion}</CardContent>
                      </CardWithHover>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={4} style={{ minWidth: "22em" }}>
          <Card variant="outlined">
            <CardHeader
              title={i18n.t("actividades.tituloRealizandose")}
              subheader={i18n.t("actividades.subtituloRealizandose")}
              avatar={
                <Avatar className={styles.tabHeaderAvatar}>
                  <AssignmentInd />
                </Avatar>
              }
            />

            <CardContent className={styles.tabSurfaceColor}>
              <Grid container spacing={2}>
                {actividades
                  .filter(
                    (a) =>
                      a.estado === 1 &&
                      a.descripcion.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((actividad) => (
                    <Grid item xs={12} key={actividad.id.toString()}>
                      <CardWithHover onClick={() => openActividad(actividad)}>
                        <CardHeader
                          title={`${actividad.appUser.nombre} ${actividad.appUser.apellidos}`}
                          subheader={moment(actividad.fecha)
                            .local()
                            .format("DD/MM/yyyy")}
                          avatar={
                            <Avatar className={styles.tabHeaderAvatar}>
                              <Person />
                            </Avatar>
                          }
                        />
                        <CardContent>{actividad.descripcion}</CardContent>
                      </CardWithHover>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={4} style={{ minWidth: "22em" }}>
          <Card variant="outlined">
            <CardHeader
              title={i18n.t("actividades.tituloFinalizadas")}
              subheader={i18n.t("actividades.subtituloFinalizadas")}
              avatar={
                <Avatar className={styles.tabHeaderAvatar}>
                  <AssignmentTurnedIn />
                </Avatar>
              }
            />
            <CardContent style={{ backgroundColor: "#ececec" }}>
              <Grid container spacing={2}>
                {actividades
                  .filter(
                    (a) =>
                      a.estado === 2 &&
                      a.descripcion.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((actividad) => (
                    <Grid item xs={12} key={actividad.id.toString()}>
                      <CardWithHover onClick={() => openActividad(actividad)}>
                        <CardHeader
                          title={`${actividad.appUser.nombre} ${actividad.appUser.apellidos}`}
                          subheader={moment(actividad.fecha)
                            .local()
                            .format("DD/MM/yyyy")}
                          avatar={
                            <Avatar className={styles.tabHeaderAvatar}>
                              <Person />
                            </Avatar>
                          }
                        />
                        <CardContent>{actividad.descripcion}</CardContent>
                      </CardWithHover>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {/* @ts-ignore */}
      <AlertDialog
        ref={dialogRef}
        loading={loading || loadingUsers}
        title={
          currentItem
            ? `${currentItem.appUser ? currentItem.appUser.nombre : ""} ${
                currentItem.appUser ? currentItem.appUser.apellidos + " - " : ""
              }${moment(currentItem.fecha).local().format("DD/MM/yyyy")}`
            : i18n.t("actividades.nueva")
        }
        text={
          <Grid container spacing={3} style={{ margin: 0, width: "100%" }}>
            <Grid item xs={12}>
              <Autocomplete
                autoHighlight={true}
                disabled={readOnly}
                value={
                  currentItem
                    ? currentItem.appUser
                    : users.length > 0
                    ? users[0]
                    : null
                }
                options={users}
                getOptionLabel={(option) =>
                  `${option.nombre} ${option.apellidos}`
                }
                renderInput={(params) => (
                  <TextField
                    name="responsable"
                    className={styles.materialTextField}
                    {...params}
                    placeholder={loadingUsers ? i18n.t("common.cargando") : ""}
                    label={i18n.t("actividades.responsable")}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(event, value) => {
                  setCurrentItem((prevItem) => {
                    return {
                      ...prevItem,
                      appUserId: value ? value.id : currentItem.appUserId,
                      appUser: value ? value : currentItem.appUser,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                required
                className={styles.materialTextField}
              >
                <InputLabel shrink>{i18n.t("actividades.estado")}</InputLabel>
                <Select
                  name="estado"
                  id="estado"
                  inputProps={{
                    readOnly: readOnly,
                  }}
                  required
                  value={currentItem ? currentItem.estado : "0"}
                  onChange={(e) =>
                    setCurrentItem((prevItem) => {
                      return {
                        ...prevItem,
                        estado: parseInt(e.target.value.toString()),
                      };
                    })
                  }
                >
                  <MenuItem value="0">
                    {i18n.t("actividades.estados.pendiente")}
                  </MenuItem>
                  <MenuItem value="1">
                    {i18n.t("actividades.estados.realizandose")}
                  </MenuItem>
                  <MenuItem value="2">
                    {i18n.t("actividades.estados.finalizada")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                required
                className={styles.materialTextField}
              >
                <InputLabel shrink>{i18n.t("actividades.fecha")}</InputLabel>
                <TextField
                  className={styles.materialTextField}
                  name="fecha"
                  fullWidth
                  type="date"
                  margin="normal"
                  value={
                    currentItem
                      ? moment(currentItem.fecha).format("YYYY-MM-DD")
                      : moment(new Date()).format("YYYY-MM-DD")
                  }
                  onChange={(e) => {
                    const { value } = e.target;
                    setCurrentItem((old) => ({
                      ...old,
                      fecha: new Date(value),
                    }));
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={
                  currentItem && !currentItem.descripcion
                    ? styles.materialTextFieldRequired
                    : styles.materialTextField
                }
                name="descripcion"
                required
                disabled={readOnly}
                fullWidth
                multiline
                value={currentItem ? currentItem.descripcion : ""}
                label={i18n.t("actividades.descripcion")}
                onChange={(e) => {
                  e.persist();
                  setCurrentItem((prevItem) => {
                    return { ...prevItem, descripcion: e.target.value };
                  });
                }}
                error={currentItem && !currentItem.descripcion}
                helperText={
                  currentItem &&
                  !currentItem.descripcion &&
                  i18n.t("textField.required")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="observaciones"
                className={styles.materialTextField}
                fullWidth
                disabled={readOnly}
                multiline
                value={currentItem ? currentItem.observaciones : ""}
                label={i18n.t("actividades.observaciones")}
                onChange={(e) => {
                  e.persist();
                  setCurrentItem((prevItem) => {
                    return { ...prevItem, observaciones: e.target.value };
                  });
                }}
              />
            </Grid>
          </Grid>
        }
        extraButton={
          <Button
            variant="outlined"
            className={styles.closeDialogButton}
            style={{ fontWeight: "bold" }}
            disabled={
              readOnly
                ? true
                : currentItem
                ? currentItem.id
                  ? false
                  : true
                : true
            }
            onClick={() => deleteActividad()}
          >
            {i18n.t("common.delete")}
          </Button>
        }
        cancelText={i18n.t("common.cancelar")}
        confirmText={i18n.t("common.guardar")}
        confirmDisabled={
          readOnly ||
          (currentItem &&
            (!currentItem.descripcion || !moment(currentItem.fecha).isValid()))
        }
        confirmAction={() =>
          currentItem.id ? updateActividad() : addActividad()
        }
      />
    </div>
  );
}
