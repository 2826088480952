import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function getByEquipo(equipoId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoStockEmergencia/equipo/${equipoId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function add(stock) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoStockEmergencia`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(stock),
  });
}

export function update(stock) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoStockEmergencia/${stock.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(stock),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoStockEmergencia/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function postPNStockEmergencia(
  id,
  driversChecked,
  partNumbers,
  cardNumbers
) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  const bodyRequest = {
    driversChecked: driversChecked,
    partNumbers: partNumbers,
    cardNumbers: cardNumbers,
  };

  return fetch(`${baseUrl}/api/equipoStockEmergencia/driveList/${id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyRequest),
  });
}
