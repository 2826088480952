import { ListItem, Typography } from "@material-ui/core";
import { Room, HomeWorkOutlined } from "@material-ui/icons";
import React from "react";
import i18n from "../../../locales/i18n";
import DriveIcon from "../../atoms/driveIcon";
import { Common_Style } from "../../../assets/css/Styles";

export default function ZonaGrid(props) {
  const { zona } = props;

  return (
    <ListItem
      button
      divider={true}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: 5,
        paddingBottom: 5,
      }}
      className={Common_Style().scrollCardContent}
      {...props}
    >
      <div style={{ display: "flex", alignItems: "center", marginLeft: 5 }}>
        {zona.isWarehouse ? (
          <HomeWorkOutlined className="mr-2" />
        ) : (
          <Room className="mr-2" />
        )}
        <Typography variant="h6" color="textSecondary" className="mr-2">
          {zona.isWarehouse
            ? i18n.t("zona.warehouse")
            : i18n.t("zona.itemTitle")}
        </Typography>
        <Typography variant="h6">{zona.descripcion}</Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body2" style={{ width: 120 }}>
          <Room className="mr-1" color="action" style={{ fontSize: 21 }} />
          {zona.numSubZonas +
            " " +
            (zona.numSubZonas === 1
              ? i18n.t("zona.ContSingular")
              : i18n.t("zona.ContPlural"))}
        </Typography>
        <Typography variant="body2" style={{ width: 140 }}>
          <DriveIcon
            className="mr-1"
            color="#757575"
            style={{
              height: 20,
              width: 20,
              verticalAlign: "bottom",
              marginRight: 5,
            }}
          />
          {zona.numEquipos + " " + i18n.t("equipo.Abbreviated")}
        </Typography>
      </div>
    </ListItem>
  );
}
