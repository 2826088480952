import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function throwNotice(data) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/broadcast`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}
