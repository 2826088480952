export default class Equipo {
  /** @type {string} */ appUserId = null;
  /** @type {object} */ appUser = null;
  /** @type {number} */ añoFabricacion = undefined;
  /** @type {string} */ barcode = null;
  base64ExtraImages = null;
  base64Image = null;
  /** @type {string} */ chassis = null;
  /** @type {number} */ cicloVida = undefined;
  /** @type {boolean} */ cicloVidaDanfossDbLock = false;
  /** @type {number} */ criticidad = undefined;
  /** @type {string} */ descripcion = null;
  /** @type {Object} */ equipoActividades = [];
  /** @type {Object} */ equipoMantenimientos = [];
  /** @type {string} */ fechaCreacion = null;
  /** @type {string} */ fotoUrl = null;
  /** @type {number} */ id = undefined;
  /** @type {string} */ idCliente = null;
  /** @type {string} */ int = null;
  /** @type {Boolean} */ inactivo = false;
  /** @type {Object} */ mantenimientoActivo = {};
  /** @type {string} */ marca = null;
  /** @type {string} */ nombre = null;
  /** @type {string} */ out = null;
  /** @type {string} */ pn = null;
  /** @type {Boolean} */ provisional = false;
  /** @type {string} */ retrofit = null;
  /** @type {string} */ rfidTag = null;
  /** @type {number} */ riesgo = undefined;
  /** @type {Object} */ scheduledPreventiveMaintenances = [];
  /** @type {number} */ semanaFabricacion = undefined;
  /** @type {string} */ sn = null;
  stockEmergencia = null;
  /** @type {string} */ tc = "";
  tempAmbiental = null;
  /** @type {string} */ textoOCR = null;
  tipo = null;
  /** @type {string} */ in = null;
  /** @type {string} */ powerSize = null;
  /** @type {string} */ voltage = null;
  /** @type {string} */ frame = null;
  /** @type {string} */ recommendation = null;

  /** @type {object} */ zona = null;
  /** @type {number} */ zonaId = undefined;
  /** @type {Object} */ brand = null;
  /** @type {number} */ brandId = undefined;
  /** @type {Object} */ model = null;
  /** @type {number} */ modelId = undefined;
  /** @type {number} */ equipmentStatus = 0;
}
