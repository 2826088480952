import React, { useEffect, useState } from "react";
import { Checkbox, Select, MenuItem, FormControl } from "@material-ui/core";
import i18n from "../../../locales/i18n";
import { CardList_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import RiskCalculation from "../../../helpers/riskCalculation.json";
import { HomeWork, Lock } from "@material-ui/icons";

export default function DriveListView(props) {
  const {
    equipo: equipoProp,
    zonaId,
    plantaId,
    history,
    handleCheckDrives,
    drivesChecked,
    fromTreeView,
  } = props;
  const isChecked = drivesChecked.includes(equipoProp.id);
  const styles = DetailTabs_Style();
  const cardStyles = CardList_Style();
  const [equipo, setEquipo] = useState(equipoProp);
  const handleNavigation = (driveId) => {
    if (!fromTreeView) {
      if (zonaId) {
        history.push(`./${zonaId}/equipos/${driveId}`);
      } else {
        history.push(`./${plantaId}/equipos/${driveId}`);
      }
    } else {
      //Para evitar el Unauthorised
      history.push(`./${plantaId}/equipos/${driveId}`);
    }
  };

  //equipo?.equipoRetrofits
  // ?.map((retrofit) => retrofit?.pntc?.pn)
  // .join(";") || i18n.t("common.n/a")}
  const getRisk = () => {
    var risk = RiskCalculation.find(
      (c) =>
        c.lifeCycle === equipo.cicloVida &&
        c.criticality === equipo.criticidad &&
        c.condition === equipo.estado
    );

    if (!risk)
      return {
        value: -1,
        text: i18n.t("common.indefinido"),
      };
    return risk.result === 0
      ? {
          value: 0,
          text: i18n.t(`common.alto`),
        }
      : risk.result === 1
      ? {
          value: 1,
          text: i18n.t(`common.medio`),
        }
      : risk.result === 2
      ? { value: 2, text: i18n.t(`common.bajo`) }
      : {
          value: -1,
          text: i18n.t(`common.indefinido`),
        };
  };

  const classNameIndex = [
    {
      lifeState: cardStyles.driveListSelectorSuscess,
      critRisk: cardStyles.driveListSelectorError,
    },
    {
      lifeState: cardStyles.driveListSelectorWarning,
      critRisk: cardStyles.driveListSelectorWarning,
    },
    {
      lifeState: cardStyles.driveListSelectorError,
      critRisk: cardStyles.driveListSelectorSuscess,
    },
  ];

  const handleInputChange = (event) => {
    event.stopPropagation();
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setEquipo((prevEquipo) => ({ ...prevEquipo, [name]: value }));
  };

  useEffect(() => {
    setEquipo(equipoProp);
  }, [equipoProp]);

  return (
    <div
      key={equipo.id}
      onClick={() => {
        handleNavigation(equipo.id);
      }}
      data-cy="ListInfo"
      data-testid={"drive" + equipo.sn}
      className={cardStyles.driveList}
    >
      <div className={cardStyles.driveListCheckBoxContainer}>
        <Checkbox
          data-testid={"checkbox-drive-" + equipo.sn}
          className={styles.checkBoxForm}
          color="default"
          checked={isChecked}
          onClick={(event) => {
            handleCheckDrives(event, equipo);
          }}
          disableRipple
        />
      </div>
      <div className={cardStyles.driveListSNContainer}>
        <div className={cardStyles.driveListTextContainer}>
          <div className={cardStyles.driveListTextContainer}>
            <span onClick={(e) => e.stopPropagation()}>
              {equipo.brand?.name || i18n.t("common.n/a")}{" "}
            </span>
            <span onClick={(e) => e.stopPropagation()}>
              {equipo.model?.name || i18n.t("common.n/a")}{" "}
            </span>
            <div style={{ display: "inline-block", fontSize: "10px" }}>
              <span onClick={(e) => e.stopPropagation()}>
                {equipo.añoFabricacion || ""}
              </span>
            </div>
          </div>
          <div className={cardStyles.driveListTextContainer}>
            <span onClick={(e) => e.stopPropagation()}>
              S/N: <b>{equipo.sn}</b> P/N: {equipo.pn}
            </span>
          </div>
          <div className={cardStyles.driveListTextContainer}>
            <span onClick={(e) => e.stopPropagation()}>{equipo.tc}</span>
          </div>
        </div>
      </div>
      <div className={cardStyles.driveListSizeContainer}>
        <div className={cardStyles.driveListTextContainer}>
          <div className={cardStyles.driveListTextContainer}>
            <span onClick={(e) => e.stopPropagation()}>
              {equipo.powerSize || i18n.t("common.n/a")}
            </span>
          </div>
          <div className={cardStyles.driveListTextContainer}>
            <span onClick={(e) => e.stopPropagation()}>
              {equipo.frame || i18n.t("common.n/a")}
            </span>
          </div>
        </div>
      </div>
      <div className={cardStyles.driveListNameContainer}>
        <div className={cardStyles.driveListTextContainer}>
          <span onClick={(e) => e.stopPropagation()}>
            {equipo.idCliente || i18n.t("common.n/a")}
          </span>
        </div>
      </div>
      <div className={cardStyles.driveListNameContainer}>
        <div className={cardStyles.driveListTextContainer}>
          {equipo?.zona?.isWarehouse && <HomeWork fontSize="small" />}{" "}
          <span onClick={(e) => e.stopPropagation()}>
            {equipo?.zona?.descripcion}
          </span>
        </div>
      </div>
      {/* Estados */}
      {/* Lifecycle */}
      <div className={cardStyles.driveListStatusContainer}>
        <FormControl
          fullWidth
          required
          className={cardStyles.driveListFormControl}
        >
          <Select
            //cambiar para poder modificar
            readOnly
            fullWidth
            variant="outlined"
            required
            value={
              equipo.cicloVida !== undefined ? equipo.cicloVida.toString() : 3
            }
            className={
              classNameIndex[equipo.cicloVida]?.lifeState ||
              cardStyles.driveListSelector
            }
            name="cicloVida"
            displayEmpty
            onChange={handleInputChange}
            IconComponent={() =>
              equipo.cicloVidaDanfossDbLock ? (
                <Lock style={{ marginRight: "10px" }} />
              ) : null
            }
          >
            <MenuItem value={0}>{i18n.t("common.activo.singularM")}</MenuItem>
            <MenuItem value={1}>{i18n.t("common.limitado")}</MenuItem>
            <MenuItem value={2}>{i18n.t("common.inactivo")}</MenuItem>
            <MenuItem value={3}>{i18n.t("common.indefinido")}</MenuItem>
          </Select>
        </FormControl>
      </div>
      {/* Asset condition */}
      <div className={cardStyles.driveListStatusContainer}>
        {/* <div>{equipo.estado}</div> */}
        <FormControl
          fullWidth
          required
          className={cardStyles.driveListFormControl}
        >
          <Select
            //cambiar para poder modificar
            readOnly
            fullWidth
            variant="outlined"
            required
            value={equipo.estado !== undefined ? equipo.estado.toString() : 3}
            className={
              classNameIndex[equipo.estado]?.lifeState ||
              cardStyles.driveListSelector
            }
            name="estado"
            displayEmpty
            onChange={handleInputChange}
            IconComponent={() => null}
          >
            <MenuItem value={0}>{i18n.t("common.bueno")}</MenuItem>
            <MenuItem value={1}>{i18n.t("common.medio")}</MenuItem>
            <MenuItem value={2}>{i18n.t("common.pobre")}</MenuItem>
            <MenuItem value={3}>{i18n.t("common.indefinido")}</MenuItem>
          </Select>
        </FormControl>
      </div>
      {/* Asset criticality */}
      <div className={cardStyles.driveListStatusContainer}>
        <FormControl
          fullWidth
          required
          className={cardStyles.driveListFormControl}
        >
          <Select
            //cambiar para poder modificar
            readOnly
            fullWidth
            variant="outlined"
            required
            value={
              equipo.criticidad !== undefined ? equipo.criticidad.toString() : 3
            }
            className={
              classNameIndex[equipo.criticidad]?.critRisk ||
              cardStyles.driveListSelector
            }
            name="criticidad"
            displayEmpty
            onChange={handleInputChange}
            IconComponent={() => null}
          >
            <MenuItem value={0}>{i18n.t("common.alto")}</MenuItem>
            <MenuItem value={1}>{i18n.t("common.medio")}</MenuItem>
            <MenuItem value={2}>{i18n.t("common.bajo")}</MenuItem>
            <MenuItem value={3}>{i18n.t("common.indefinido")}</MenuItem>
          </Select>
        </FormControl>
      </div>
      {/* Asset risk */}
      <div className={cardStyles.driveListStatusContainer}>
        <FormControl
          fullWidth
          required
          className={cardStyles.driveListFormControl}
        >
          <Select
            readOnly
            fullWidth
            variant="outlined"
            required
            value={getRisk().value}
            className={
              classNameIndex[getRisk().value]?.critRisk ||
              cardStyles.driveListSelector
            }
            name="inactivo"
            displayEmpty
            IconComponent={() => null}
          >
            <MenuItem value={0}>{i18n.t("common.alto")}</MenuItem>
            <MenuItem value={1}>{i18n.t("common.medio")}</MenuItem>
            <MenuItem value={2}>{i18n.t("common.bajo")}</MenuItem>
            <MenuItem value={-1}>{i18n.t("common.indefinido")}</MenuItem>
          </Select>
        </FormControl>
      </div>
      {/* Active / inactive */}
      <div className={cardStyles.driveListStatusContainer}>
        <FormControl
          fullWidth
          required
          className={cardStyles.driveListFormControl}
        >
          <Select
            readOnly
            fullWidth
            variant="outlined"
            required
            value={equipo.equipmentStatus}
            className={
              equipo.equipmentStatus !== 0
                ? cardStyles.driveListSelectorInactive
                : cardStyles.driveListSelectorActive
            }
            name="riesgo"
            displayEmpty
            IconComponent={() => null}
          >
            <MenuItem value={0}>
              {i18n.t("common.driveStatus.inOperation")}
            </MenuItem>
            <MenuItem value={1}>
              {i18n.t("common.driveStatus.notInOperation")}
            </MenuItem>
            <MenuItem value={2}>
              {i18n.t("common.driveStatus.dispossed")}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={cardStyles.driveListRecomendationContainer}>
        <div className={cardStyles.driveListTextRecomendation}>
          <span onClick={(e) => e.stopPropagation()}>
            {equipo.recommendation || i18n.t("common.noRecomendation")}
          </span>
        </div>
      </div>
      <div className={cardStyles.driveListRetrofitContainer}>
        <div
          className={cardStyles.driveListTextContainer}
          style={{
            textAlign: "center",
          }}
        >
          <span onClick={(e) => e.stopPropagation()}>
            {equipo?.equipoRetrofits
              ?.map((retrofit) => retrofit?.pntc?.pn)
              .join(";") || i18n.t("common.n/a")}
          </span>
        </div>
      </div>
    </div>
  );
}
