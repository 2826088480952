import React from "react";
import { MenuItem, TextField } from "@material-ui/core";
import i18n from "../../../locales/i18n";
import { DetailTabs_Style } from "../../../assets/css/Styles";

/**
 * @param {{ activeFilter: number; setActiveFilter: function(number):void; handleSelectorChange: function(number):void;}} props
 */
export default function Select_FrequencyConvertersSearchEngineFilter(props) {
  const styles = DetailTabs_Style();
  const { activeFilter, setActiveFilter, handleSelectorChange } = props;

  return (
    <TextField
      fullWidth
      name="driverFilter"
      select
      label={i18n.t("planta.filtrarEquipos.label")}
      className={styles.materialTextField}
      value={activeFilter}
      onChange={(e) => {
        const valueNumber = Number.parseInt(e.target.value.toString());
        if (Number(valueNumber) === valueNumber) {
          setActiveFilter(valueNumber);
        }
        handleSelectorChange(valueNumber);
      }}
    >
      <MenuItem value={0}>{i18n.t("planta.filtrarEquipos.todos")}</MenuItem>
      <MenuItem value={1}>{i18n.t("planta.filtrarEquipos.inoperation")}</MenuItem>
      <MenuItem value={2}>{i18n.t("planta.filtrarEquipos.notInOperation")}</MenuItem>
      <MenuItem value={3}>{i18n.t("planta.filtrarEquipos.disposed")}</MenuItem>
      <MenuItem value={4}>
        {i18n.t("planta.filtrarEquipos.provisionales")}
      </MenuItem>
      <MenuItem value={5}>{i18n.t("planta.filtrarEquipos.obsoletos")}</MenuItem>
    </TextField>
  );
}
