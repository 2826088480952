import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getWhitPlantas() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/withplantas`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getWithUserAccessPermisions() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/withUserAccessPermisions`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getInfiniteClientes(clientsRendered = 0, searchQuery = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/clientes/withUserAccessPermisions/${clientsRendered}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(searchQuery),
    }
  );
}

export function getInfClientsRefined(
  clientsRendered = 0,
  searchQuery = "",
  arrayException = []
) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  const bodyRequest = { searchQuery, arrayException };

  return fetch(`${baseUrl}/api/clientes/partnersClients/${clientsRendered}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyRequest),
  });
}

export function add(clienteB) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(clienteB),
  });
}

export function update(cliente, salesforceIdChanged) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${cliente.id}/${salesforceIdChanged}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...cliente, plantas: null }),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function removeFromPartner(id, partnerId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${id}/partner/${partnerId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getIfCIFExist(cif) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/verifycif`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(cif),
  });
}

export function getSalesForceExist(salesfoceId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/verifySalesForce`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(salesfoceId),
  });
}

export function getClientInfoBySalesForce(salesforceId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/salesforceId/${salesforceId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getReportLogo(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${id}/reportlogo`, {
    method: "GET",
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function addReportLogo(id, base64, extension) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${id}/reportlogo`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ base64, extension }),
  });
}

export function deleteReportLogo(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${id}/reportlogo`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${access_token}` },
  });
}
