import React, { useEffect, useState, useContext, useCallback } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { PlantasAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import AuthService from "../../../services/AuthService";
import AppContext from "../../../contexts/AppContext";
import { Common_Style } from "../../../assets/css/Styles";
import CardPlantClient from "../../../components/molecule/cardPlantClient/cardPlantClient";
import InfiniteScroll from "react-infinite-scroll-component";

export default function PlantasList(props) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const [plantas, setPlantas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isNewQuery, setIsNewQuery] = useState(false);
  const [hasMorePlants, setHasMorePlants] = useState(true);
  const [isFirstInput, setIsFirstInput] = useState(false);
  const commonStyles = Common_Style();

  //getPlantas
  const getPlantas = useCallback((actualLength, searchQuery, isNewQuery) => {
    setLoading(true);
    PlantasAPI.getInfinitePlants(actualLength, searchQuery)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (isNewQuery) {
          setPlantas(data.plantas);
          setIsNewQuery(false);
        } else {
          setPlantas((old) => old.concat(data.plantas));
        }
        setHasMorePlants(data.hasMorePlants);
        AuthService.updateUserAccessPermisions(data.userAccessPermissions);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  //Busqueda con delay
  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (isFirstInput) {
        setPlantas([]);
        setIsNewQuery(true);
        getPlantas(0, searchQuery, true);
      }
    }, 600);
    return () => clearTimeout(searchDelay);
  }, [searchQuery, getPlantas, isFirstInput]);

  //Titulo de la pagina
  useEffect(() => {
    setGlobal((prev) => ({
      ...prev,
      pageTitle: i18n.t("planta.tituloPaginaLista"),
    }));
  }, [setGlobal]);

  //Primer getPlantas
  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
    if (
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_PlantasDetails !== undefined
    ) {
      window["AltidVars"].Tabs_PlantasDetails = undefined;
    }
    //Limpeza de filtros de drives
    localStorage.removeItem("savedFilters");
    localStorage.removeItem("storageZoneInfo");
    getPlantas();
  }, [getPlantas]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.searchFieldContainer}>
          <SearchField
            inputBaseProps={{
              placeholder: i18n.t("common.search"),
              value: searchQuery,
              onChange: (event) => {
                setIsFirstInput(true);
                setSearchQuery(event.target.value);
              },
            }}
          />
        </div>

        <InfiniteScroll
          scrollableTarget="main-content"
          className={commonStyles.cardsContainerBase}
          style={{ overflow: "ignore" }}
          dataLength={plantas.length}
          next={() => getPlantas(plantas.length, searchQuery, isNewQuery)}
          hasMore={hasMorePlants}
          loader
        >
          {plantas.map((planta, indx) => (
            <div key={indx}>
              <CardPlantClient
                isDanfossOwned={false}
                title={planta.denominacion}
                subtitle={planta.cliente.razonSocial}
                contents={[
                  { key: "direccion", value: planta.direccion },
                  { key: "cp", value: planta.cp },
                  { key: "poblacion", value: planta.poblacion },
                  { key: "provincia", value: planta.provincia },
                  {
                    key: "pais",
                    value: i18n.t(`country.${planta.country.name || "null"}`),
                  },
                  { key: "nDrives", value: planta.nDrivers},
                ]}
                type="planta"
                className={
                  planta?.infoBySalesforce
                    ? commonStyles.cardBase
                    : commonStyles.cardBaseNotValidated
                }
                onClick={() => {
                  props.history.push(`./plantas/${planta.id}`);
                }}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </RelativeBackdrop>
  );
}
