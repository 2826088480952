import React from "react";
import { NoDataStyle } from "../../assets/css/Styles";
import { DiscFullSharp } from "@material-ui/icons";
import { Card } from "@material-ui/core";

function NoData(props) {
  const styles = NoDataStyle();
  const { icon, title, text, style } = props;

  return (
    <Card style={style} className={styles.noDataContainer}>
      <div className={styles.noDataIconContainer}>
        {icon || <DiscFullSharp style={{ fontSize: 80 }} />}
      </div>
      <div className={styles.noDataTitle}>{title && title}</div>
      <div className={styles.noDataText}>{text && text}</div>
    </Card>
  );
}

export default NoData;
