import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { FooterStyles } from "../ReportPDFStyles";
import registeredChar from "../../../../../assets/images/registered-brand.png";
import d360LiveLogo from "../../../../../assets/images/logo-360live.png";
import danfossLogo from "../../../../../assets/images/danfoss-logo.png";
import getText from "../helpers/traductions";

function Footer({ lang }) {
  return (
    <>
      <View fixed style={FooterStyles.container}>
        <View style={FooterStyles.footer}>
          <View style={FooterStyles.title}>
            <Image style={FooterStyles.d360Logo} src={d360LiveLogo} />
            <Text>{getText(lang, "footer.appName1")}</Text>
            <Image style={FooterStyles.registeredImage} src={registeredChar} />
            <Text>{getText(lang, "footer.appName2")}</Text>
          </View>
          <Image style={FooterStyles.danfossLogo} src={danfossLogo} />
        </View>
        <Text>{getText(lang, "footer.business")}</Text>
      </View>
    </>
  );
}

export default Footer;
