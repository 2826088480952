import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/brand`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getVacon() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/brand/vacon`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getVlt() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/brand/vlt`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getDanfossBrands() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/brand/danfossbrands`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export async function getIC() {
  const userInfo = await AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/brand/ic`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}
