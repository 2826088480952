export default class PlantaStockEmergencia {
  /** @type {number} */ id = undefined;
  /** @type {number} */ plantaId = undefined;
  /** @type {number} */ pntcId = undefined;
  /** @type {object} */ planta = null;
  /** @type {object} */ pntc = null;

  /**@param {Object} IntiObj  */
  constructor(IntiObj) {
    if (IntiObj.id) {
      this.id = IntiObj.id;
    }
    if (IntiObj.plantaId) {
      this.plantaId = IntiObj.plantaId;
    }
    if (IntiObj.pntcId) {
      this.pntcId = IntiObj.pntcId;
    }
    if (IntiObj.planta) {
      this.planta = IntiObj.planta;
    }
    if (IntiObj.pntc) {
      this.pntc = IntiObj.pntc;
    }
  }
}
