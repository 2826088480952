import React from "react";

function DriveIcon(props) {
  const { style, color } = props;

  return (
    <svg
      style={{ ...style, marginLeft: 0.8 }}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3213 0H5.87875C5.87875 0 5.785 0 5.64438 0.0196875C5.03031 0.09375 4 0.414375 4 1.69688V22.3219C4 22.3828 4.02344 23.8275 5.56656 24H18.4891C19.12 23.925 20.2 23.6063 20.2 22.3228V1.69688C20.2 1.6275 20.1738 0 18.3213 0ZM8.89094 22.5H5.96125V1.5H8.89094V22.5ZM12.0897 9.63C11.1916 9.63 10.4603 8.9625 10.4603 8.14125C10.4603 7.32 11.1916 6.65625 12.0897 6.65625C12.9878 6.65625 13.7191 7.32469 13.7191 8.14406C13.7191 8.96344 12.9878 9.62906 12.0897 9.62906V9.63ZM14.0584 4.98281C14.0584 5.37375 13.7069 5.47687 13.5213 5.47687H10.6938C10.2691 5.47687 10.1566 5.15344 10.1566 4.98281V3.6375C10.1566 3.24656 10.5081 3.14344 10.6938 3.14344H13.525C13.9497 3.14344 14.0622 3.46687 14.0622 3.6375L14.0584 4.98281ZM18.2388 22.5H15.31V1.5H18.2388V22.5Z"
        fill={color || "#fafafa"}
      />
    </svg>
  );
}

export default DriveIcon;
