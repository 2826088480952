import {
  Avatar,
  Button,
  CardHeader,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Delete, Save, Cancel, FileCopyOutlined } from "@material-ui/icons";
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from "react";
import AlertDialog from "../../AlertDialog";
import RelativeBackdrop from "../../RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { PartnersAPI } from "../../../services/API";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AuthService from "../../../services/AuthService";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";
import PartnerBase from "../../../models/partnerBase";
import Partner from "../../../models/partner";
import AppContext from "../../../contexts/AppContext";
import { Common_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import LogoInput from "../../molecule/logoInput/LogoInput";
import { baseUrl } from "../../../helpers/Url";

export default function PartnerFormInList(props) {
  const {
    partnerId,
    isNewPartner,
    keyuser,
    readOnly,
    partnerDeleted,
    partnerAdded,
    partnerUpdated,
    countries,
    notFound,
  } = props;
  {
    const isDSC = AuthService.hasRoleDSC();
    const userCountryId = AuthService.getUserInfo().countryid;
    const DSCisRegionManager = AuthService.getUserInfo().isRegionManager;
    const isGestor = AuthService.isGestorFromPartner(partnerId);

    const allFormEditable = !readOnly && (keyuser || isDSC);
    const partialFormEditable = !readOnly && (isGestor || keyuser || isDSC);

    const [partner, setPartner] = useState(new Partner({}));
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [firstRender, setFirstRender] = useState(true);

    const styles = DetailTabs_Style();
    const commonStyles = Common_Style();
    // @ts-ignore
    const { setGlobal } = useContext(AppContext);
    const deleteDialog = useRef(null);

    const getPartner = useCallback(async (partnerId) => {
      setLoading(true);
      PartnersAPI.get(partnerId.toString())
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          setPartner(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          window.location.reload();
        });
    }, []);

    const addUpdatePartner = () => {
      setLoading(true);

      const ptr = new PartnerBase(partner);
      const request = partnerId
        ? PartnersAPI.update(ptr)
        : PartnersAPI.add(ptr);

      request
        .then(async (res) => {
          if (res.status === 200 || res.status === 400) {
            return { status: res.status, body: await res.json() };
          } else {
            if (res.status === 404) {
              deletePartner();
              SnackbarError(setGlobal, i18n.t("partner.snackbar.update.error"));
            } else {
              throw new Error("Something went wrong in PartnersDetails");
            }
          }
        })
        .then((data) => {
          const { status, body } = data;
          if (status === 200) {
            setErrors({});
            if (!partnerId) {
              SnackbarSuccess(
                setGlobal,
                i18n.t("partner.snackbar.new.success")
              );
              // We need add the access to the new partner locally because we don't have access until it is refreshed
              AuthService.addTempAccessToPartner(body.id);
              partnerAdded(body);
              document.getElementById("main-content").scrollTop = 0;
            } else if (partnerId) {
              //actualizar el partner en la lista
              partnerUpdated(body);
              SnackbarSuccess(
                setGlobal,
                i18n.t("partner.snackbar.update.success")
              );
            }
          } else if (status === 400) {
            let errors = {};
            // Se mapea porque si los errores vienen directamente de .NET, necesitamos convertirlos para poder usar las traducciones. (Solo se utiliza para los campos required)
            Object.keys(body.errors).map((row) =>
              typeof body.errors[row] === "string"
                ? (errors[row] = body.errors[row])
                : (errors[row] = "required")
            );
            setErrors(errors);
            SnackbarError(setGlobal, i18n.t("partner.snackbar.update.error"));
          }
        })
        .catch((error) => {
          SnackbarError(setGlobal, i18n.t("partner.snackbar.update.error"));
          console.error(error);
        })
        .finally(() => setLoading(false));
    };

    const deletePartner = async () => {
      setLoading(true);
      PartnersAPI.remove(partnerId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            if (response.status === 404) {
              partnerDeleted();
            }
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          SnackbarSuccess(
            setGlobal,
            i18n.t("partner.snackbar.deleted.success")
          );
          partnerDeleted();
        })
        .catch((error) => {
          SnackbarError(setGlobal, i18n.t("partner.snackbar.deleted.error"));
          setLoading(false);
        });
    };

    const UserCountry = countries?.find(
      (c) => c.id.toString() === userCountryId.toString()
    );

    const handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;

      if (name === "isDanfoss") {
        setPartner((prevPartner) => ({
          ...prevPartner,
          [name]: value,
          isPremium: false,
        }));
      } else {
        setPartner((prevPartner) => ({ ...prevPartner, [name]: value }));
      }
    };

    const restartPartner = () => {
      if (partnerId) {
        getPartner(partnerId);
      } else {
        if (isDSC) {
          setPartner(new Partner({ countryId: userCountryId }));
        } else {
          setPartner(new Partner({}));
        }
      }
      setErrors({});
    };

    const getAvatarText = (title) => {
      if (!title) return "";
      const abbreviatonLetters = title.split(" ");
      const abbreviaton =
        abbreviatonLetters.length > 1
          ? (abbreviatonLetters[0][0] + abbreviatonLetters[1][0]).toUpperCase()
          : title.substr(0, 2).toUpperCase();
      return abbreviaton;
    };

    const copyLoginURL = () =>
      navigator.clipboard
        .writeText(`${baseUrl}/login?ptr=${partner.loginParameter}`)
        .then(() =>
          SnackbarSuccess(setGlobal, i18n.t("partner.snackbar.copy.success"))
        )
        .catch(() =>
          SnackbarError(setGlobal, i18n.t("partner.snackbar.copy.error"))
        );

    const filteredOptions =
      isDSC && DSCisRegionManager
        ? countries.filter((c) => c?.regionId === UserCountry?.regionId)
        : countries.sort((a, b) => (a.regionId > b.regionId ? 1 : -1));

    //Si es DSC y no tiene pais asignado, se asigna el pais del usuario
    useEffect(() => {
      if (!partner.countryId && isDSC && firstRender && isNewPartner) {
        setPartner((old) => ({ ...old, countryId: userCountryId }));
        setFirstRender(false);
      }
    }, [userCountryId, isDSC, partner.countryId, firstRender, isNewPartner]);

    //Cargar partner al seleccionarse
    useEffect(() => {
      if (!isNewPartner && partnerId) {
        setLoading(true);
        getPartner(partnerId);
        setFirstRender(false);
      }
    }, [getPartner, isNewPartner, partnerId]);

    //Cargar Info para crear nuevo partner
    useEffect(() => {
      if (isNewPartner && !partnerId) {
        setPartner(new Partner({ id: partnerId, countryId: userCountryId }));
        setFirstRender(true);
      }
    }, [isNewPartner, partnerId, userCountryId]);

    return (
      <div className={commonStyles.mainContainer}>
        <RelativeBackdrop hidden={!loading}>
          <div>
            <div className={styles.mainCardFormContainer}>
              <div className={styles.partnerClientHeader}>
                <CardHeader
                  title={i18n.t("partner.tituloCard")}
                  subheader={partner.razonSocial}
                  avatar={
                    <Avatar
                      aria-label="avatar"
                      className={styles.tabHeaderAvatar}
                    >
                      {isNewPartner || notFound
                        ? partner.avatar
                        : getAvatarText(partner.razonSocial)}
                    </Avatar>
                  }
                />
              </div>
              <div className={styles.formCardContainer}>
                <div className={styles.formContainerWithLogo}>
                  <div style={{ width: "100%" }}>
                    <div className={styles.flexRowForm}>
                      <div className={styles.flexCellForm}>
                        <TextField
                          required
                          fullWidth
                          className={styles.materialTextField}
                          value={partner.razonSocial || ""}
                          label={i18n.t("partner.razonSocial")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={notFound}
                          inputProps={{
                            readOnly: !allFormEditable,
                          }}
                          error={errors["RazonSocial"] !== undefined}
                          helperText={
                            errors["RazonSocial"]
                              ? i18n.t(
                                  `partner.formErrors.${errors["RazonSocial"]}`
                                )
                              : undefined
                          }
                          name="razonSocial"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={styles.flexCellForm}>
                        {/*Address */}
                        <TextField
                          required
                          fullWidth
                          disabled={notFound}
                          className={styles.materialTextField}
                          value={partner.direccion || ""}
                          label={i18n.t("partner.direccion")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: !allFormEditable,
                          }}
                          error={errors["Direccion"] !== undefined}
                          helperText={
                            errors["Direccion"]
                              ? i18n.t("textField.required")
                              : undefined
                          }
                          name="direccion"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className={styles.flexRowForm}>
                      <div className={styles.flexCellForm}>
                        {/*Country */}
                        <Autocomplete
                          open={
                            !keyuser && !DSCisRegionManager ? false : undefined
                          }
                          disabled={notFound}
                          autoComplete={!keyuser && !DSCisRegionManager}
                          closeIcon={
                            !keyuser && !DSCisRegionManager ? false : undefined
                          }
                          freeSolo={!keyuser && !DSCisRegionManager}
                          groupBy={(option) =>
                            keyuser ? option?.region?.regionCode : undefined
                          }
                          fullWidth
                          autoHighlight
                          options={filteredOptions}
                          getOptionLabel={(c) =>
                            i18n.t(`country.${c.name || "null"}`)
                          }
                          value={
                            partner.countryId
                              ? countries?.find(
                                  (c) =>
                                    c.id.toString() ===
                                    partner?.countryId?.toString()
                                ) || undefined
                              : null
                          }
                          className={styles.materialTextField}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              disabled={notFound}
                              label={i18n.t("planta.pais")}
                              error={errors["CountryId"] !== undefined}
                              name="countryId"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: !keyuser && !DSCisRegionManager,
                              }}
                              helperText={
                                errors["CountryId"]
                                  ? i18n.t("textField.required")
                                  : undefined
                              }
                            />
                          )}
                          onChange={(event, newValue) => {
                            handleInputChange({
                              target: {
                                name: "countryId",
                                value: newValue ? newValue.id : undefined,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className={styles.flexCellForm}>
                        {/*City */}
                        <TextField
                          required
                          fullWidth
                          className={styles.materialTextField}
                          value={partner.poblacion || ""}
                          label={i18n.t("partner.poblacion")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          disabled={notFound}
                          inputProps={{
                            readOnly: !allFormEditable,
                          }}
                          error={errors["Poblacion"] !== undefined}
                          helperText={
                            errors["Poblacion"]
                              ? i18n.t("textField.required")
                              : undefined
                          }
                          name="poblacion"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className={styles.flexRowForm}>
                      <div className={styles.flexCellForm}>
                        {/*Province */}
                        <TextField
                          fullWidth
                          disabled={notFound}
                          value={partner.provincia || ""}
                          className={styles.materialTextField}
                          label={i18n.t("partner.provincia")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: !allFormEditable,
                          }}
                          error={errors["Provincia"] !== undefined}
                          helperText={
                            errors["Provincia"]
                              ? i18n.t("textField.required")
                              : undefined
                          }
                          name="provincia"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={styles.flexCellForm}>
                        {/*Zip Code*/}
                        <TextField
                          fullWidth
                          disabled={notFound}
                          value={partner.cp || ""}
                          className={styles.materialTextField}
                          label={i18n.t("partner.cp")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: !allFormEditable,
                          }}
                          error={errors["CP"] !== undefined}
                          helperText={
                            errors["CP"]
                              ? i18n.t("textField.required")
                              : undefined
                          }
                          name="cp"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={styles.flexCellForm}>
                        {/*Company Tax Code */}
                        <TextField
                          fullWidth
                          disabled={notFound}
                          className={styles.materialTextField}
                          value={partner.cif || ""}
                          label={i18n.t("partner.cif")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: !allFormEditable,
                          }}
                          error={
                            errors["CIF"] !== undefined ||
                            errors["CIF_Unique"] !== undefined
                          }
                          helperText={
                            errors["CIF"]
                              ? i18n.t("textField.required")
                              : errors["CIF_Unique"]
                              ? i18n.t("partner.cifInUse")
                              : undefined
                          }
                          name="cif"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className={styles.flexRowForm}>
                      <div className={styles.flexCellForm}>
                        <TextField
                          fullWidth
                          disabled={notFound}
                          value={partner.email || ""}
                          label={i18n.t("partner.email")}
                          className={styles.materialTextField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: !partialFormEditable,
                          }}
                          error={errors["Email"] !== undefined}
                          helperText={
                            errors["Email"]
                              ? i18n.t("textField.required")
                              : undefined
                          }
                          name="email"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className={styles.flexCellForm}>
                        <Tooltip
                          title={i18n.t("common.isPremium")}
                          placement={"bottom"}
                          disableHoverListener={partner.isPremium}
                          disableFocusListener={partner.isPremium}
                          arrow
                        >
                          <TextField
                            fullWidth
                            value={partner.loginParameter || ""}
                            label={i18n.t("partner.loginParameter")}
                            className={styles.materialTextField}
                            disabled={
                              !partialFormEditable || !partner.isPremium
                            }
                            InputProps={{
                              readOnly: readOnly,
                              endAdornment: partner.isPremium && (
                                <IconButton
                                  size="small"
                                  disabled={loading}
                                  onClick={copyLoginURL}
                                >
                                  <FileCopyOutlined fontSize="small" />
                                </IconButton>
                              ),
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={errors["LoginParameter"] !== undefined}
                            helperText={
                              errors["LoginParameter"]
                                ? i18n.t(
                                    `partner.formErrors.${errors["LoginParameter"]}`
                                  )
                                : undefined
                            }
                            name="loginParameter"
                            onChange={handleInputChange}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className={styles.flexRowForm}>
                      {(AuthService.hasRoleDSC() ||
                        AuthService.hasRoleAdmin()) && (
                        <div className={styles.flexCellForm}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isDanfoss"
                                disabled={notFound}
                                inputProps={{
                                  readOnly: !allFormEditable && !notFound,
                                }}
                                className={styles.checkBoxForm}
                                checked={partner.isDanfoss || false}
                                onChange={handleInputChange}
                              />
                            }
                            label={i18n.t("partner.isDanfoss")}
                            disabled={readOnly}
                          />
                        </div>
                      )}
                      {(AuthService.hasRoleDSC() ||
                        AuthService.hasRoleAdmin()) && (
                        <div className={styles.flexCellForm}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="isPremium"
                                inputProps={{
                                  readOnly: !allFormEditable && !notFound,
                                }}
                                className={
                                  partner.isDanfoss
                                    ? styles.checkBoxFormDisabled
                                    : styles.checkBoxForm
                                }
                                checked={partner.isPremium || false}
                                onChange={handleInputChange}
                                disabled={
                                  readOnly || partner.isDanfoss || notFound
                                }
                              />
                            }
                            label={i18n.t("partner.isPremium")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {partnerId && (
                    <LogoInput
                      id={partner.id}
                      mode={"partner"}
                      logoURL={partner.logoURL}
                      setLogoURL={(url) =>
                        setPartner((old) => ({ ...old, logoURL: url }))
                      }
                      readOnly={!partialFormEditable}
                    />
                  )}
                </div>
                {!readOnly && !notFound && (
                  <div className={styles.flexRowForm}>
                    <div className={styles.flexCellForm}>
                      {partialFormEditable && (
                        <>
                          <Button
                            variant="contained"
                            className={styles.primaryFormButton}
                            onClick={addUpdatePartner}
                          >
                            <Save className={styles.saveIcon} />
                            {i18n.t("common.save")}
                          </Button>
                          <Button
                            variant="outlined"
                            className={styles.secondaryFormButton}
                            onClick={() => restartPartner()}
                          >
                            <Cancel className={styles.saveIcon} />
                            {i18n.t("common.cancel")}
                          </Button>

                          {allFormEditable && partnerId && (
                            <Button
                              variant="outlined"
                              className={styles.secondaryFormButton}
                              onClick={() => deleteDialog.current.open()}
                            >
                              <Delete className={styles.deleteIcon} />
                              {i18n.t("common.delete")}
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </RelativeBackdrop>

        {/* @ts-ignore */}
        <AlertDialog
          ref={deleteDialog}
          title={i18n.t("partner.alertDialog.title")}
          text={i18n.t("partner.alertDialog.text")}
          cancelText={i18n.t("partner.alertDialog.cancelText")}
          confirmText={i18n.t("partner.alertDialog.confirmText")}
          confirmAction={() => {
            deletePartner();
            deleteDialog.current.close();
          }}
        />
      </div>
    );
  }
}
