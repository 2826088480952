import React, { useCallback, useContext, useEffect, useState } from "react";
import Auth from "../../layout/Auth";
import AppContext from "../../../contexts/AppContext";
import { Avatar, Button, Checkbox, FormControlLabel } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";
import i18n from "../../../locales/i18n";
import { Auth_Style } from "../../../assets/css/Styles";
import { ClientesSolicitudesAPI, UsersAPI } from "../../../services/API";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";
import AuthLoading from "../../atoms/AuthLoading";

export default function RequestAccessPage(props) {
  // @ts-ignore
  const { global, setGlobal } = useContext(AppContext);
  const [locale, setLocale] = useState(i18n.locale || i18n.defaultLocale);
  const { user, getAccessTokenSilently, getIdTokenClaims, logout } = useAuth0();
  const [request, setRequest] = useState(undefined);
  const [isPartner, setIsPartner] = useState(false);
  const [accesToken, setAccesToken] = useState("");
  const [userToken, setUserToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("ES");
  const [company, setCompany] = useState("");

  if (global.ptr) {
    const url = new URL(window.location.href);
    url.searchParams.set("ptr", global.ptr);
    window.history.replaceState(null, null, url);
  }

  const getExtraData = useCallback(() => {
    if (user) {
      setLoading(true);
      UsersAPI.getExtraData(accesToken, user.email)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("DIP extra data error");
          }
        })
        .then((data) => {
          setCompany(data.company);
          setCountry(data.country.id);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [accesToken, user]);

  const getRequest = useCallback(() => {
    if (user) {
      setLoading(true);
      ClientesSolicitudesAPI.getByDipId(user["https://danfoss.com/identity_id"])
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Get request error");
          }
        })
        .then((data) => {
          setRequest(data);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, [user]);

  useEffect(() => {
    getExtraData();
    getRequest();
  }, [getExtraData, getRequest]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getIdTokenClaims().then((data) => {
        if (data) {
          setUserToken(data.__raw);
        }
      }),
      getAccessTokenSilently().then((data) => setAccesToken(data)),
    ]).then(() => {
      setLoading(false);
    });
  }, [getAccessTokenSilently, getIdTokenClaims]);

  try {
    const styles = Auth_Style();
    const partner = global?.partnerLogin?.id || 0;

    const requestAcces = (event) => {
      event.preventDefault();
      setLoading(true);

      const solicitud = {
        DIPid: user["https://danfoss.com/identity_id"],
        company: company,
        country: { code: country, name: country },
        partnerId: !user["https://danfoss.com/is_workforce"] ? partner : 0,
        name: user.given_name,
        surname: user.family_name,
        mail: user.email,
        level: user["https://danfoss.com/is_workforce"]
          ? "DSC"
          : isPartner
          ? "partner"
          : "end customer",
      };

      ClientesSolicitudesAPI.add(solicitud, userToken)
        .then((response) => {
          if (response.ok) {
            SnackbarSuccess(setGlobal, i18n.t("request.alert.text"));
            props.history.push("/callback");
          } else {
            throw new Error(
              response.statusText +
                "Something went wrong log out and try again."
            );
          }
        })
        .catch((error) => {
          console.error(error);
          SnackbarError(setGlobal, i18n.t("request.alert.error"));
        })
        .finally(() => setLoading(false));
    };

    const partnerCard = (partner) => {
      if (!partner) {
        return <></>;
      }

      return (
        <div className={styles.authPartnerAvatarContainer}>
          <Avatar
            className={styles.authPartnerAvatar}
            src={partner.logo}
            alt="partner logo"
            variant="rounded"
          >
            {partner?.razonSocial
              .split(" ")
              .map((row) => row[0] || "U")
              .splice(0, 2)}
          </Avatar>
          <div>
            <h4>{partner.razonSocial}</h4>
            <h6>{i18n.t(`country.${partner.country}`)}</h6>
          </div>
        </div>
      );
    };

    return (
      <Auth title={i18n.t("request.requestTitle")} setChildrenLang={setLocale}>
        {loading ? (
          <AuthLoading hidden={false} />
        ) : request ? (
          <>
            <div className={styles.requestContainer}>
              {request.status === 0 ? (
                user && !user["https://danfoss.com/is_workforce"] ? (
                  <>
                    <div>{i18n.t("request.request")}</div>
                    {partnerCard(global?.partnerLogin)}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPartner}
                          onChange={(e) => setIsPartner(e.target.checked)}
                          name="isPartner"
                          className={styles.checkBoxForm}
                          color="default"
                        />
                      }
                      label={i18n.t("request.userPartner")}
                    />
                  </>
                ) : (
                  <div>{i18n.t("request.request")}</div>
                )
              ) : (
                <>
                  {partnerCard(request.partner)}
                  <div className={styles.authMessage}>
                    <div className={styles.requestStatusLabel}>
                      {i18n.t("request.statusLabel")}:
                    </div>
                    <div>
                      {request.status === 1
                        ? i18n.t("request.pending")
                        : request.status === 2
                        ? i18n.t("request.accepted")
                        : request.status === 3 && i18n.t("request.rejected")}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={styles.authButtonContainer}>
              <Button
                variant="outlined"
                className={styles.authSecondaryButton}
                onClick={() => {
                  localStorage.setItem("fromLogOut", "true");
                  logout({
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  });
                }}
              >
                {i18n.t("sidebar.cerrarSesion")}
              </Button>

              {request.status === 0 && (
                <Button
                  variant="contained"
                  type="submit"
                  className={styles.authButton}
                  onClick={(e) => requestAcces(e)}
                >
                  {i18n.t("request.requestButton")}
                </Button>
              )}
            </div>
          </>
        ) : (
          <div className={styles.authLoadingContainer}></div>
        )}
      </Auth>
    );
  } catch (error) {
    const ptr = localStorage.getItem("ptr");
    props.history.push(!ptr ? "/login" : `/login?ptr=${ptr}`);

    return <></>;
  }
}
