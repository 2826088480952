import React, { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";

import Auth from "../components/layout/Auth";
import { useAuth0 } from "@auth0/auth0-react";

import i18n from "../locales/i18n";
import { Auth_Style } from "../assets/css/Styles";
import AppContext from "../contexts/AppContext";

export default function Login(props) {
  // @ts-ignore
  const { global } = useContext(AppContext);
  const [locale, setLocale] = useState(i18n.locale || i18n.defaultLocale);
  const styles = Auth_Style();
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      props.history.push("/callback");
    }
  }, [isAuthenticated, props.history]);

  return (
    <Auth title={i18n.t("login.welcome")} setChildrenLang={setLocale}>
      <div className={styles.authMessage}>{i18n.t("login.welcomeMessage")}</div>
      <div className={styles.authButtonContainer}>
        <Button
          variant="contained"
          className={styles.authButton}
          onClick={() => {
            // Save the partner login URL to localStorage
            if (global?.ptr) {
              localStorage.setItem("ptr", global?.ptr);
            } else {
              localStorage.removeItem("ptr");
            }
            loginWithRedirect({
              authorizationParams: {
                ui_locales: locale,
                redirect_uri: global?.ptr
                  ? `${window.location.origin}?ptr=${global?.ptr
                      ?.toString()
                      ?.replaceAll(" ", "%20")}`
                  : window.location.origin,
              },
            });
          }}
        >
          {i18n.t("login.login")}
        </Button>
      </div>
    </Auth>
  );
}
