import React, { useCallback, useEffect, useState } from "react";
import { Card, CardHeader, Avatar } from "@material-ui/core";
import {
  DetailTabs_Style,
  DashboardHeaderStyle,
} from "../../../assets/css/Styles";
import {
  Group,
  Business,
  Room,
  CalendarToday,
  PeopleOutline,
  Build,
  HomeWorkOutlined,
} from "@material-ui/icons";
import i18n from "../../../locales/i18n";
import { DashboardAPI } from "../../../services/API";
import DriveIcon from "../../atoms/driveIcon";
import AuthService from "../../../services/AuthService";

export default function DashboardHeader(props) {
  const styles = DetailTabs_Style();
  const isDSC = AuthService.hasRoleDSC();
  const isAdmin = AuthService.hasRoleAdmin();

  const [data, setData] = useState([]);

  const getCount = useCallback(() => {
    DashboardAPI.getHeader()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setData(data.header);
        AuthService.updateUserAccessPermisions(data.userAccess);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getCount();
  }, [getCount]);

  const getAvatar = (key) => {
    switch (key) {
      case "clients":
        return <Group />;
      case "plants":
        return <Business />;
      case "zones":
        return <Room />;
      case "totalDrives":
      case "avgDrives":
      case "retrofitedDrives":
        return <DriveIcon />;
      case "schedulledPm":
      case "completedPm":
        return <CalendarToday />;
      case "partenrs":
      case "standarPartners":
      case "advancedPartners":
        return <PeopleOutline />;
      case "emergencyStockDrives":
        return <Build />;
      case "wharehouseUnits":
        return <HomeWorkOutlined />;
      default:
        return <Group />;
    }
  };

  const DashboardHeaderStyles = DashboardHeaderStyle();

  return (
    <div className={DashboardHeaderStyles.container}>
      {data.map((item) => (
        <div
          className={
            isDSC || isAdmin
              ? DashboardHeaderStyles.cardContainerDSC
              : DashboardHeaderStyles.cardContainerPartner
          }
          key={item.key}
        >
          <Card>
            <CardHeader
              title={
                <div className={DashboardHeaderStyles.cardHeaderContent}>
                  <div data-testid={`dashboard-card-${item.key}`}>
                    {i18n.t("dashboard.header." + item.key)}
                  </div>
                  <div
                    className={DashboardHeaderStyles.cardHeaderNum}
                    data-testid={`dashboard-card-value-${item.key}`}
                  >
                    {item.value}
                  </div>
                </div>
              }
              avatar={
                <Avatar aria-label="avatar" className={styles.tabHeaderAvatar}>
                  {getAvatar(item.key)}
                </Avatar>
              }
            />
            {/* <CardContent>
              <h4 className={DashboardHeaderStyles.h4} data-cy="ClientsNumber">
                {item.value}
              </h4>
            </CardContent> */}
          </Card>
        </div>
      ))}
    </div>
  );
}
