import { Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import { orderList, sliceText } from "../helpers";
import getText from "../helpers/traductions";
import { GeneralStyles, TableStyles } from "../ReportPDFStyles";
import Footer from "./FooterPDF";
import WaterMark from "./WaterMarkPDF";

function EmStockTable({
  tab,
  options,
  reportOptions,
  data,
  header,
  waterMark,
}) {
  const lang = reportOptions.language || "en";

  const renderContent = () => {
    let result = [];
    let orderedEmeSto = orderList(options.orderBy, options.orderType, data);
    for (let i = 0; i < orderedEmeSto.length; i++) {
      result.push(
        <View key={i} style={TableStyles.tableRow}>
          <Text style={{ ...TableStyles.tableCell, width: "40%" }}>
            {orderedEmeSto[i].pn}
          </Text>
          <Text style={TableStyles.tableCell}>
            {sliceText(orderedEmeSto[i].tc)}
          </Text>
          <Text style={{ ...TableStyles.tableCell, width: "40%" }}>
            {orderedEmeSto[i].countEquipos}
          </Text>
          <Text style={{ ...TableStyles.tableCell, width: "40%" }}>
            {orderedEmeSto[i].countAlmacen}
          </Text>
        </View>
      );
    }
    if (orderedEmeSto.length === 0) {
      result.push(
        <View
          key={"empty"}
          wrap={false}
          style={{ ...TableStyles.tableRow, fontSize: options.font }}
        >
          <Text
            style={{
              ...TableStyles.tableCell,
              width: "100%",
              textAlign: "center",
            }}
          >
            {getText(lang, "common.noData")}
          </Text>
        </View>
      );
    }

    return result;
  };

  return (
    <Page
      style={GeneralStyles.body}
      size={reportOptions.pageSize || "A4"}
      orientation={reportOptions.pageOrientation || "portrait"}
    >
      {header && header}

      <View fixed style={TableStyles.borderTop}></View>
      <View style={TableStyles.table}>
        <View fixed style={TableStyles.tableHeaderRow}>
          <Text style={{ ...TableStyles.tableHeaderCell, width: "40%" }}>
            {getText(lang, "emeSto.pn")}
          </Text>
          <Text style={TableStyles.tableHeaderCell}>
            {getText(lang, "emeSto.tc")}
          </Text>
          <Text style={{ ...TableStyles.tableHeaderCell, width: "40%" }}>
            {getText(lang, "emeSto.countEquipos")}
          </Text>
          <Text style={{ ...TableStyles.tableHeaderCell, width: "40%" }}>
            {getText(lang, "emeSto.countAlmacen")}
          </Text>
        </View>
      </View>
      {renderContent()}
      <Footer lang={lang} />
      <WaterMark
        text={waterMark}
        orientation={reportOptions.pageOrientation || "portrait"}
      />
    </Page>
  );
}

export default EmStockTable;
