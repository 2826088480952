// eslint-disable-next-line no-unused-vars
import { CoverOptionsModel, ReportOptionsModel } from "../helpers";
import React from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import moment from "moment";
import getText from "../helpers/traductions";
import { HeaderStyles } from "../ReportPDFStyles";

/**
 * Header para todas las pestanas del PDF excepto la portada (Cover).
 * @param {object} props
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {CoverOptionsModel} props.coverOptions Opciones de la pestaña.
 * @param {number} props.driveNum Numero de equipos.
 * @param {string} props.title Titulo del report.
 * @param {string} props.partnerLogo Logo del partner.
 */
function Header({ reportOptions, coverOptions, driveNum, title, partnerLogo }) {
  const lang = reportOptions.language;
  if (!reportOptions || !coverOptions || !driveNum || !title) {
    return <View></View>;
  }
  return (
    <>
      <View fixed style={HeaderStyles.container}>
        <View style={HeaderStyles.infoContainer}>
          <View style={HeaderStyles.infoRow}>
            <Text style={HeaderStyles.infoLabel}>
              {"  " + getText(lang, "header.clientName")}
            </Text>
            <Text>{coverOptions.clientName || ""}</Text>
          </View>
          <View style={HeaderStyles.rowOdd}>
            <Text style={HeaderStyles.infoLabel}>
              {"  " + getText(lang, "header.plantAddress")}
            </Text>
            <Text>{coverOptions.plantAddress || ""}</Text>
          </View>
          <View style={HeaderStyles.infoRow}>
            <Text style={HeaderStyles.infoLabel}>
              {"  " + getText(lang, "header.count")}
            </Text>
            <Text>{driveNum || ""}</Text>
          </View>
          <View style={HeaderStyles.rowOdd}>
            <Text style={HeaderStyles.infoLabel}>
              {getText(lang, "header.date")}
            </Text>
            <Text>
              {moment(coverOptions.reportDate).format(
                reportOptions.dateFormat
              ) || ""}
            </Text>
          </View>
        </View>
        {partnerLogo && coverOptions.displayPartnerLogo && (
          <Image style={HeaderStyles.parterLogo} src={partnerLogo} />
        )}
      </View>
      <Text fixed style={HeaderStyles.title}>
        {title}
      </Text>
    </>
  );
}

export default Header;
