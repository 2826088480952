import MaterialTable from "material-table";
import React, { useCallback, useEffect, useState } from "react";
import i18n from "../../../locales/i18n";
import { BrandAPI, EquiposAPI, PlantasAPI } from "../../../services/API";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

export default function TabEquipoLog(props) {
  const { equipmentId, plantaId } = props;
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [zones, setZones] = useState([]);
  const materialTableTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#000000",
      },
    },
  });

  const getLogs = useCallback(() => {
    setLoading(true);
    EquiposAPI.getEquipmentLogs(equipmentId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .then((data) => {
        setLogs(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [equipmentId]);

  const getBrands = useCallback(() => {
    setLoading(true);
    BrandAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .then((data) => {
        setBrands(data);
        let models = [];
        data.forEach((brand) => {
          models = models.concat(brand.models);
        });
        setModels(models);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const getZones = useCallback(async () => {
    return PlantasAPI.getZonas(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setZones(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [plantaId]);

  useEffect(() => {
    getLogs();
    getBrands();
    getZones();
  }, [getLogs, getBrands, getZones]);

  const getValueFromData = (data, oldOrNew) => {
    switch (data.property) {
      case "Estado":
      case "Criticidad":
      case "CicloVida":
        return i18n.t("logs." + data[oldOrNew].toLowerCase());
      case "BrandId":
        return brands.filter((b) => b.id.toString() === data[oldOrNew])[0]
          ?.name;
      case "ModelId":
        return models.filter((m) => m.id.toString() === data[oldOrNew])[0]
          ?.name;
      case "ZonaId":
        return zones.filter((z) => z.id.toString() === data[oldOrNew])[0]
          ?.descripcion;
      case "Inactivo":
      case "Provisional":
        return data[oldOrNew] === "True"
          ? i18n.t("logs.afirmative")
          : i18n.t("logs.negative");
      case "NotProperlyInstalled":
        return data[oldOrNew] === "False"
          ? i18n.t("logs.afirmative")
          : i18n.t("logs.negative");
      default:
        return data[oldOrNew];
    }
  };

  return (
    <div>
      <div>
        {/** @ts-ignore */}
        <ThemeProvider theme={materialTableTheme}>
          {/* @ts-ignore */}
          <MaterialTable
            style={{ width: "100%" }}
            title={null}
            // @ts-ignore
            localization={i18n.t("dataTableProps")}
            isLoading={loading}
            data={logs.length !== 0 ? logs : []}
            options={{
              sorting: true,
              showTitle: false,
              searchFieldAlignment: "left",
              pageSize: 10,
              actionsColumnIndex: -1,
              tableLayout: "fixed",
            }}
            columns={[
              {
                field: "changeDate",
                title: i18n.t("logs.changeDate"),
                type: "datetime",
              },
              {
                field: "userId",
                title: i18n.t("logs.user"),
                render: (rowData) =>
                  rowData.appUser
                    ? rowData.appUser.nombre +
                      " " +
                      rowData.appUser.apellidos +
                      " (" +
                      rowData.appUser.email +
                      ")"
                    : "",
              },
              {
                field: "property",
                title: i18n.t("logs.property"),
                render: (rowData) =>
                  i18n.t("logs." + rowData.property.toLowerCase()),
              },
              {
                field: "oldValue",
                title: i18n.t("logs.oldValue"),
                render: (rowData) => {
                  return getValueFromData(rowData, "oldValue");
                },
              },
              {
                field: "newValue",
                title: i18n.t("logs.newValue"),
                render: (rowData) => {
                  return getValueFromData(rowData, "newValue");
                },
              },
            ]}
          />
        </ThemeProvider>
      </div>
    </div>
  );
}
