import React from "react";
import i18n from "../locales/i18n";

export default (props) => {
  const { type } = props;

  return (
    <div>
      {type === 0
        ? i18n.t("maintenance.altualScheduled.module1")
        : type === 1
        ? i18n.t("maintenance.altualScheduled.module2")
        : type === 2
        ? i18n.t("maintenance.altualScheduled.module3")
        : i18n.t("maintenance.altualScheduled.moduleBase")}
    </div>
  );
};
