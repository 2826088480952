import React, { useCallback, useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import moment from "moment";
import i18n from "../../../locales/i18n";
import { Common_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import { EquipoMantenimientosAPI } from "../../../services/API";

const formatYear = (year) => parseInt(moment(year).format("YYYY"));

/**
 * Componente de tabla del previsto del mantenimiento
 * @Component
 * @param {Object} props
 * @param {Object} props.mantenimiento //Estado del componente Padre
 * @param {Function} props.getModule2Years
 * @param {Function} props.getModule3Years
 * @param {boolean} props.shouldRenderMaintenance
 * @param {boolean} props.readOnly
 * @param {boolean} props.isPremium
 * @param {Number} props.equipoId
 */
function MaintenanceTimeline({
  mantenimiento,
  getModule2Years,
  getModule3Years,
  shouldRenderMaintenance,
  readOnly,
  isPremium,
  equipoId,
}) {
  const [maintenancePart, setMaintenancePart] = useState("");
  const { startup } = mantenimiento;
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();
  const startDate = mantenimiento.personalizado
    ? mantenimiento.fechaModulo1
      ? moment(mantenimiento.fechaModulo1)
      : null
    : startup
    ? moment(startup)
    : null;

  var rows = [];
  if (startDate) {
    for (let i = 0; i <= 20; i++) {
      rows.push({
        module1: false,
        module2: false,
        module3: false,
        dueDate: formatYear(startDate) + i,
      });
    }

    const moudle1Interval = mantenimiento.personalizado
      ? parseInt(mantenimiento.customIntervaloModulo1)
      : 1;

    const yearsDifmodulo1 = 0;

    if (moudle1Interval > 0) {
      for (let i = yearsDifmodulo1; i <= rows.length; i += moudle1Interval) {
        if (rows[i] !== undefined) {
          rows[i].module1 = true;
        }
      }
    }

    const moudle2Interval = mantenimiento.personalizado
      ? parseInt(mantenimiento.customIntervaloModulo2)
      : getModule2Years();

    const yearsDifmodulo2 = mantenimiento.personalizado
      ? formatYear(mantenimiento.fechaModulo2) -
        formatYear(mantenimiento.fechaModulo1)
      : 0;

    if (moudle2Interval > 0) {
      for (let i = yearsDifmodulo2; i <= rows.length; i += moudle2Interval) {
        if (rows[i] !== undefined) {
          rows[i].module2 = true;
        }
      }
    }

    const moudle3Interval = mantenimiento.personalizado
      ? parseInt(mantenimiento.customIntervaloModulo3)
      : getModule3Years();

    const yearsDifmodulo3 = mantenimiento.personalizado
      ? formatYear(mantenimiento.fechaModulo3) -
        formatYear(mantenimiento.fechaModulo1)
      : 0;

    if (moudle3Interval > 0) {
      for (let i = yearsDifmodulo3; i <= rows.length; i += moudle3Interval) {
        if (rows[i] !== undefined) {
          rows[i].module3 = true;
        }
      }
    }

    if (mantenimiento.personalizado) {
      rows.pop();
    } else {
      rows.splice(0, 1);
    }
  }
  const getSparePart = useCallback(() => {
    EquipoMantenimientosAPI.getSparePart(equipoId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error get Sopare Part");
        }
      })
      .then((data) => {
        setMaintenancePart(data.partNumber);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [equipoId]);

  useEffect(() => {
    getSparePart();
  }, [getSparePart]);

  return (
    <TableContainer component={Paper} className={commonStyles.scrollCard}>
      <Table size="small" className={commonStyles.scrollCardContent}>
        <TableHead>
          <TableRow className={styles.manteinTimeLineRow}>
            <TableCell align="center" colSpan={5} style={{ color: "white" }}>
              {i18n.t("maintenance.drivePro")}
            </TableCell>
          </TableRow>
          <TableRow style={{ height: 45 }}>
            <TableCell align="center">
              {i18n.t("maintenance.yearsFromStartup")}
            </TableCell>
            <TableCell align="center">
              {i18n.t("maintenance.performModule", { module: 1 })}
            </TableCell>
            <TableCell align="center">
              {i18n.t("maintenance.performModule", { module: 2 })}
              {maintenancePart && (
                <>
                  <br />
                  {i18n.t("maintenance.sparePart")}{" "}
                  <b>
                    {isPremium ? (
                      maintenancePart
                    ) : (
                      <Tooltip
                        title={i18n.t("common.isPremium")}
                        placement={"bottom-start"}
                        disableHoverListener={isPremium}
                        arrow
                      >
                        <span>********</span>
                      </Tooltip>
                    )}
                  </b>
                </>
              )}
            </TableCell>
            <TableCell align="center">
              {i18n.t("maintenance.performModule", { module: 3 })}
            </TableCell>
            <TableCell align="center">
              {i18n.t("maintenance.dueDate")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ fontSize: 10 }}>
          {!shouldRenderMaintenance ? (
            <TableRow key="empty">
              <TableCell align="center" colSpan={5} height={100}>
                {i18n.t("maintenance.formNotFilledOut")}
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, index) => (
              <TableRow key={index + 1}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  {row.module1 && (
                    <CheckCircle
                      className={styles.checkCircleColorManteinance}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.module2 && (
                    <CheckCircle
                      className={styles.checkCircleColorManteinance}
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  {row.module3 && (
                    <CheckCircle
                      className={styles.checkCircleColorManteinance}
                    />
                  )}
                </TableCell>
                <TableCell align="center">{row.dueDate}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MaintenanceTimeline;
