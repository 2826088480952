// @ts-nocheck
// eslint-disable-next-line no-unused-vars
import { PreManOptionsModel, ReportOptionsModel } from "../helpers";
import React from "react";
import { Page, Text, View, Image } from "@react-pdf/renderer";
import pictures from "../helpers/picturesPDF.json";
import { GeneralStyles, TableStyles, PreManStyles } from "../ReportPDFStyles";
import getText from "../helpers/traductions";
import { orderList, sliceText } from "../helpers";
import WaterMark from "./WaterMarkPDF";
import Footer from "./FooterPDF";

/**
 * Pestaña de mantenimiento preventivo (Preventive Maintenance) del PDF.
 * @param {object} props
 * @param {number} props.tab Cambia el contenido dependiendo de si es (0) impresión normal, (-4) impresión parcial o una (7) vista previa.
 * @param {any} props.data Datos del report.
 * @param {PreManOptionsModel} props.options Opciones de la pestaña.
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {any} props.header Header del report.
 * @param {any} props.waterMark Marca de agua por si algun equipo tiene riesgo indefinido.
 */
function PreManTable({ tab, data, options, reportOptions, header, waterMark }) {
  const lang = reportOptions.language || "en";
  const dinamicWidth = 60 / options.width;

  const maxWidth = (dinamicWidth * 3).toFixed(2) + "% !important";
  const medWidth = (dinamicWidth * 2).toFixed(2) + "% !important";
  const minWidth = (dinamicWidth * 1).toFixed(2) + "% !important";

  const renderContent = () => {
    if (!data) {
      return <></>;
    }

    let drives = orderList(options.orderBy, options.orderType, data);
    let result = [];

    for (let i = 0; i < drives.length; i++) {
      if (i >= 50 && tab !== 0 && tab !== -4) {
        result.push(
          <View
            wrap={false}
            style={TableStyles.tableRow}
            key={`${drives[i].id}_${i}`}
          >
            <Text style={TableStyles.tableHeaderCell}>
              {getText(lang, "sumrec.preview")}
            </Text>
          </View>
        );
        break;
      } else {
        result.push(
          drives[i].mantenimientoActivo && (
            <View
              key={drives[i].id}
              wrap={false}
              style={{ ...TableStyles.tableRow, fontSize: options.font }}
            >
              {options.column[10] && (
                <View
                  style={{
                    ...TableStyles.tableCell,
                    ...TableStyles.isSliced,
                    width: medWidth,
                  }}
                >
                  {drives[i].idCliente ? (
                    sliceText(drives[i].idCliente)
                  ) : (
                    <Text>{getText(lang, "common.na")}</Text>
                  )}
                </View>
              )}
              <Text style={{ ...TableStyles.tableCell, width: medWidth }}>
                {drives[i].model?.name || getText(lang, "common.na")}
              </Text>
              <Text style={{ ...TableStyles.tableCell, width: minWidth }}>
                {drives[i].brand?.name || getText(lang, "common.na")}
              </Text>
              <Text style={{ ...TableStyles.tableCell, width: medWidth }}>
                {drives[i].sn}
              </Text>
              <Text style={{ ...TableStyles.tableCell, width: medWidth }}>
                {drives[i].pn}
              </Text>
              {options.column[0] && (
                <View
                  style={{
                    ...TableStyles.tableCell,
                    ...TableStyles.isSliced,
                    width: maxWidth,
                  }}
                >
                  {drives[i].tc ? (
                    sliceText(drives[i].tc)
                  ) : (
                    <Text>{getText(lang, "common.na")}</Text>
                  )}
                </View>
              )}
              {options.column[1] && (
                <Text style={{ ...TableStyles.tableCell, width: minWidth }}>
                  {drives[i].frame || getText(lang, "common.na")}
                </Text>
              )}
              {options.column[4] && (
                <Text style={{ ...TableStyles.tableCell, width: medWidth }}>
                  {drives[i].zona?.descripcion || getText(lang, "common.na")}
                </Text>
              )}
              <View style={PreManStyles.PMCell}>
                {options.years.map((year, index) => (
                  <View
                    key={`${drives[i].id}_${year}_${index}`}
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      borderRight: index === options.years.length - 1 ? 0 : 0.5,
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor:
                          drives[i].mantenimientoActivo.fechasModulo1.filter(
                            (date) => new Date(date).getFullYear() === year
                          ).length >= 1
                            ? "#92d050"
                            : index % 2 === 0
                            ? "#d9d9d9"
                            : "#f2f2f2",
                      }}
                    >
                      <View>
                        {handleMaintenance(
                          year,
                          0,
                          drives[i].scheduledPreventiveMaintenances
                        )}
                      </View>
                    </View>
                    <View
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor:
                          drives[i].mantenimientoActivo.fechasModulo2.filter(
                            (date) => new Date(date).getFullYear() === year
                          ).length >= 1
                            ? "#8ea9db"
                            : index % 2 === 0
                            ? "#d9d9d9"
                            : "#f2f2f2",
                      }}
                    >
                      <View>
                        {handleMaintenance(
                          year,
                          1,
                          drives[i].scheduledPreventiveMaintenances
                        )}
                      </View>
                    </View>
                    <View
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor:
                          drives[i].mantenimientoActivo.fechasModulo3.filter(
                            (date) => new Date(date).getFullYear() === year
                          ).length >= 1
                            ? "#ffc000"
                            : index % 2 === 0
                            ? "#d9d9d9"
                            : "#f2f2f2",
                      }}
                    >
                      <View>
                        {handleMaintenance(
                          year,
                          2,
                          drives[i].scheduledPreventiveMaintenances
                        )}
                      </View>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          )
        );
      }
    }

    if (drives.length === 0) {
      result.push(
        <View
          key={"empty"}
          wrap={false}
          style={{ ...TableStyles.tableRow, fontSize: options.font }}
        >
          <Text
            style={{
              ...TableStyles.tableCell,
              width: "100%",
              textAlign: "center",
            }}
          >
            {getText(lang, "common.noData")}
          </Text>
        </View>
      );
    }

    return result;
  };

  const handleMaintenance = (year, type, man) => {
    if (!man || man.length === 0) {
      return "";
    }
    let posibleResults = {
      0: { has: false, list: [] },
      1: { has: false, list: [] },
      2: { has: false, list: [] },
      3: { has: false, list: [] },
    };

    for (let i = 0; i < man.length; i++) {
      if (
        man[i] &&
        !man[i].isProvisional &&
        type === man[i].type &&
        new Date(man[i].scheduledDate).getFullYear() === year
      ) {
        posibleResults[man[i].state].has = true;
        posibleResults[man[i].state].list.push(man[i]);
      }
    }

    switch (true) {
      case posibleResults[1].has:
        return <Image src={pictures.completedIcon} />;
      case posibleResults[0].has:
        if (posibleResults[0].list.length > 1) {
          posibleResults[0].list.sort((a, b) =>
            new Date(a.scheduledDate) > new Date(b.scheduledDate) ? -1 : 1
          );
        }
        if (new Date(posibleResults[0].list[0].scheduledDate) < new Date()) {
          return <Image src={pictures.overdueIcon} />;
        } else {
          return <Image src={pictures.scheduledIcon} />;
        }
      case posibleResults[2].has:
        return <Image src={pictures.cancelledIcon} />;
      default:
        return <View></View>;
    }
  };

  return (
    <Page
      style={{ ...GeneralStyles.body }}
      size={reportOptions.pageSize || "A4"}
      orientation={"landscape"}
    >
      {header && header}
      <View fixed style={PreManStyles.legendContainer}>
        <View style={PreManStyles.legendIconsContainer}>
          <View
            style={{
              ...PreManStyles.legendIcon,
              ...PreManStyles.backgroundM1,
            }}
          ></View>
          <Text>{getText(lang, "manPre.mod1")}</Text>
          <View
            style={{
              ...PreManStyles.legendIcon,
              ...PreManStyles.backgroundM2,
            }}
          ></View>
          <Text>{getText(lang, "manPre.mod2")}</Text>
          <View
            style={{
              ...PreManStyles.legendIcon,
              ...PreManStyles.backgroundM3,
            }}
          ></View>
          <Text>{getText(lang, "manPre.mod3")}</Text>
        </View>
        <View style={PreManStyles.legendIconsContainer}>
          <Image style={PreManStyles.legendIcon} src={pictures.completedIcon} />
          <Text>{getText(lang, "manPre.complete")}</Text>
          <Image style={PreManStyles.legendIcon} src={pictures.overdueIcon} />
          <Text>{getText(lang, "manPre.overdue")}</Text>
          <Image style={PreManStyles.legendIcon} src={pictures.scheduledIcon} />
          <Text>{getText(lang, "manPre.scheduled")}</Text>
          <Image style={PreManStyles.legendIcon} src={pictures.cancelledIcon} />
          <Text>{getText(lang, "manPre.cancelled")}</Text>
        </View>
      </View>
      <View fixed style={TableStyles.borderTop}></View>
      <View style={{ ...TableStyles.table, fontSize: options.font }}>
        <View fixed style={TableStyles.tableHeaderRow}>
          {options.column[10] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
              {getText(lang, "drive.idCliente")}
            </Text>
          )}
          <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
            {getText(lang, "drive.model")}
          </Text>
          <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
            {getText(lang, "drive.brand")}
          </Text>
          <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
            {getText(lang, "drive.sn")}
          </Text>
          <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
            {getText(lang, "drive.pn")}
          </Text>
          {options.column[0] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: maxWidth }}>
              {getText(lang, "drive.tc")}
            </Text>
          )}
          {options.column[1] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: minWidth }}>
              {getText(lang, "drive.frame")}
            </Text>
          )}
          {options.column[4] && (
            <Text style={{ ...TableStyles.tableHeaderCell, width: medWidth }}>
              {getText(lang, "drive.zone")}
            </Text>
          )}
          <View style={{ ...TableStyles.tableHeaderCell, width: "40%" }}>
            <Text style={{ borderBottom: 0.5 }}>
              {getText(lang, "titles.preMan")}
            </Text>
            <View style={PreManStyles.yearRow}>
              {options.years.map((year, index) => (
                <Text
                  key={`${year}_${index}`}
                  style={{
                    width: "100%",
                    borderRight: index === options.years.length - 1 ? 0 : 0.5,
                    backgroundColor: index % 2 === 0 ? "#d9d9d9" : "#f2f2f2",
                  }}
                >
                  {year}
                </Text>
              ))}
            </View>
          </View>
        </View>
        {renderContent()}
      </View>
      <Footer lang={lang} />
      <WaterMark text={waterMark} orientation={"landscape"} />
    </Page>
  );
}

export default PreManTable;
