// eslint-disable-next-line no-unused-vars
import { CoverOptionsModel, ShowTabsOptionsModel } from "../pdf/helpers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import i18n from "i18n-js";
import React, { useEffect, useState } from "react";
import {
  DetailTabs_Style,
  TabReportPlantaStyle,
} from "../../../../assets/css/Styles";
import moment from "moment";
import { splitArray } from "../../../../helpers/Common";

/**
 Menu lateral para modificar las opciones del report en la pestaña de portada (Cover).
 * @Component
 * @param {Object} props
 * @param {CoverOptionsModel} props.options Opciones de la portada.
 * @param {function} props.setOptions Funcion para cambiar las opciones de la portada.
 * @param {ShowTabsOptionsModel} props.showTabsOptions Opciones de la portada.
 * @param {function} props.setShowTabsOptions Funcion para cambiar las opciones de la portada.
 * @param {boolean} props.isPremium Si el usuario es premium.
 */
function CoverOptions({
  options,
  setOptions,
  showTabsOptions,
  setShowTabsOptions,
  isPremium,
}) {
  const [plantName, setPlantName] = useState(options.plantName || "");
  const [plantAddress, setPlantAddress] = useState(options.plantAddress || "");
  const [clientName, setClientName] = useState(options.clientName || "");
  const [clientAddress, setClientAddress] = useState(
    options.clientAddress || ""
  );
  const [partnerName, setPartnerName] = useState(options.partnerName || "");
  const [partnerAddress, setPartnerAddress] = useState(
    options.partnerAddress || ""
  );
  const [analysedBy, setAnalysedBy] = useState(options.analysedBy || "");
  const [assessmentBy, setAssessmentBy] = useState(options.assessmentBy || "");

  useEffect(() => {
    setClientName(options.clientName);
    setClientAddress(options.clientAddress);
    setPartnerName(options.partnerName);
    setPartnerAddress(options.partnerAddress);
  }, [
    options.clientAddress,
    options.clientName,
    options.partnerAddress,
    options.partnerName,
  ]);

  /* Fondo personalizado para el report 
  const customBackgroundInputRef = useRef(null);
  const handleCustomBackground = async () => {
    const file = customBackgroundInputRef.current.files[0];

    if (file) {
      const extension = file.name.split(".").pop();
      if (!["jpg", "jpeg", "png"].includes(extension)) {
        SnackbarError(setGlobal, i18n.t("report.snackbar.errorFormat"));
        return;
      }

      const base64 = `data:image/${extension};base64,${await getBase64(file)}`;

      setOptions((old) => ({
        ...old,
        customBackground: { name: file.name || "", base64 },
      }));
    }
  }; */

  const styles = DetailTabs_Style();
  const ownStyles = TabReportPlantaStyle();
  const [expanded, setExpanded] = useState(0);

  useEffect(() => {
    const setOptionsDelay = setTimeout(() => {
      setOptions((old) => ({
        ...old,
        plantName,
        plantAddress,
        clientName,
        clientAddress,
        analysedBy,
        assessmentBy,
      }));
    }, 600);
    return () => clearTimeout(setOptionsDelay);
  }, [
    plantName,
    plantAddress,
    clientName,
    clientAddress,
    analysedBy,
    setOptions,
    assessmentBy,
  ]);

  return (
    <>
      <Accordion
        expanded={expanded === 1}
        onChange={() => setExpanded(expanded === 1 ? -1 : 1)}
      >
        <AccordionSummary
          className={expanded === 1 ? ownStyles.AcordionSelectedTitle : null}
          expandIcon={
            <ExpandMore
              className={
                expanded === 1 ? ownStyles.AcordionSelectedTitleIcon : null
              }
            />
          }
        >
          <div>{i18n.t("report.settings.reportTabs.title")}</div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={ownStyles.AcordionContainer}>
          <div className={ownStyles.columnContainer}>
            {splitArray(Object.keys(showTabsOptions), 4).map((array, i) => (
              <div key={`tab_column_${i}`} className={ownStyles.column}>
                {array.map((row) => (
                  <Tooltip
                    key={row}
                    title={i18n.t("common.isPremium")}
                    placement={"bottom"}
                    disableHoverListener={
                      isPremium || !showTabsOptions[row].premium
                    }
                    arrow
                  >
                    <div className={ownStyles.checkboxContainer}>
                      <Checkbox
                        edge="start"
                        checked={showTabsOptions[row].value}
                        disableRipple
                        disabled={!isPremium && showTabsOptions[row].premium}
                        className={
                          !isPremium && showTabsOptions[row].premium
                            ? styles.checkBoxFormDisabled
                            : styles.checkBoxForm
                        }
                        color="default"
                        onChange={() =>
                          setShowTabsOptions((old) => ({
                            ...old,
                            [row]: { ...old[row], value: !old[row].value },
                          }))
                        }
                      />
                      <div
                        className={
                          !isPremium && showTabsOptions[row].premium
                            ? ownStyles.checkboxLabelDisabled
                            : ownStyles.checkboxLabel
                        }
                      >
                        {i18n.t(`report.tabs.${row}`)}
                      </div>
                    </div>
                  </Tooltip>
                ))}
              </div>
            ))}
          </div>
          {/* <input
            type="file"
            id="reportLogo"
            name="customBackground"
            accept=".jpg,.jpeg,.png"
            ref={customBackgroundInputRef}
            onChange={handleCustomBackground}
            hidden
          />
          <Tooltip
            title={i18n.t("common.isPremium")}
            placement={"bottom"}
            disableHoverListener={isPremium}
            arrow
          >
            <TextField
              className={styles.materialTextField}
              fullWidth
              value={options.customBackground?.name || ""}
              label={i18n.t("report.settings.reportTabs.coverBackground")}
              onClick={() =>
                isPremium &&
                !options.customBackground &&
                customBackgroundInputRef.current.click()
              }
              InputProps={{
                readOnly: true,
                endAdornment: options.customBackground ? (
                  <Clear
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      setOptions((old) => ({ ...old, customBackground: null }));
                      customBackgroundInputRef.current.value = "";
                    }}
                  />
                ) : (
                  <Publish
                    style={{ cursor: isPremium ? "pointer" : "inherit" }}
                  />
                ),
              }}
              InputLabelProps={{
                shrink: options.customBackground ? true : false,
              }}
              disabled={!isPremium}
            /> 
          </Tooltip>*/}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 2}
        onChange={() => setExpanded(expanded === 2 ? -1 : 2)}
      >
        <AccordionSummary
          className={expanded === 2 ? ownStyles.AcordionSelectedTitle : null}
          expandIcon={
            <ExpandMore
              className={
                expanded === 2 ? ownStyles.AcordionSelectedTitleIcon : null
              }
            />
          }
        >
          <div>{i18n.t("report.settings.dates.title")}</div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={ownStyles.AcordionContainer}>
          <TextField
            name="repotDate"
            className={styles.materialTextField}
            fullWidth
            type="date"
            value={
              options.reportDate
                ? moment(options.reportDate).format("YYYY-MM-DD")
                : moment(new Date()).format("YYYY-MM-DD")
            }
            label={i18n.t("report.settings.dates.reportDate")}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              const { value } = e.target;
              setOptions((old) => ({
                ...old,
                reportDate: new Date(value),
              }));
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 3}
        onChange={() => setExpanded(expanded === 3 ? -1 : 3)}
      >
        <AccordionSummary
          className={expanded === 3 ? ownStyles.AcordionSelectedTitle : null}
          expandIcon={
            <ExpandMore
              className={
                expanded === 3 ? ownStyles.AcordionSelectedTitleIcon : null
              }
            />
          }
        >
          <div>{i18n.t("report.settings.analFor.title")}</div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={ownStyles.AcordionContainer}>
          <TextField
            name="clientName"
            InputProps={{
              readOnly: true,
            }}
            className={styles.materialTextField}
            fullWidth
            value={clientName}
            label={i18n.t("report.settings.analFor.clientName")}
            onChange={(e) => setClientName(e.target.value)}
          />
          <TextField
            name="clientAddress"
            InputProps={{
              readOnly: true,
            }}
            className={styles.materialTextField}
            fullWidth
            value={clientAddress}
            label={i18n.t("report.settings.analFor.clientAddress")}
            onChange={(e) => setClientAddress(e.target.value)}
          />
          <TextField
            name="plantName"
            InputProps={{
              readOnly: true,
            }}
            className={styles.materialTextField}
            fullWidth
            value={plantName}
            label={i18n.t("report.settings.analFor.plantName")}
            onChange={(e) => setPlantName(e.target.value)}
          />
          <TextField
            name="plantAddress"
            InputProps={{
              readOnly: true,
            }}
            className={styles.materialTextField}
            fullWidth
            value={plantAddress}
            label={i18n.t("report.settings.analFor.plantAddress")}
            onChange={(e) => setPlantAddress(e.target.value)}
          />
          {/* <Tooltip
            title={i18n.t("common.isPremium")}
            placement={"bottom"}
            disableHoverListener={isPremium}
            arrow
          >
            <div className={ownStyles.checkboxContainer}>
              <Checkbox
                edge="start"
                checked={options.displayClientLogo}
                disableRipple
                disabled={!isPremium}
                className={
                  !isPremium ? styles.checkBoxFormDisabled : styles.checkBoxForm
                }
                color="default"
                onChange={() =>
                  setOptions((old) => ({
                    ...old,
                    displayClientLogo: !old.displayClientLogo,
                  }))
                }
              />
              <div
                className={
                  !isPremium
                    ? ownStyles.checkboxLabelDisabled
                    : ownStyles.checkboxLabel
                }
              >
                {i18n.t(`report.settings.analFor.displayClientLogo`)}
              </div>
            </div>
          </Tooltip> */}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === 4}
        onChange={() => setExpanded(expanded === 4 ? -1 : 4)}
      >
        <AccordionSummary
          className={expanded === 4 ? ownStyles.AcordionSelectedTitle : null}
          expandIcon={
            <ExpandMore
              className={
                expanded === 4 ? ownStyles.AcordionSelectedTitleIcon : null
              }
            />
          }
        >
          <div>{i18n.t("report.settings.analBy.title")}</div>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={ownStyles.AcordionContainer}>
          <TextField
            name="partnerName"
            InputProps={{
              readOnly: true,
            }}
            className={styles.materialTextField}
            fullWidth
            value={partnerName}
            label={i18n.t("report.settings.analBy.partnerName")}
            onChange={(e) => setPartnerName(e.target.value)}
          />
          <TextField
            name="partnerAddress"
            InputProps={{
              readOnly: true,
            }}
            className={styles.materialTextField}
            fullWidth
            value={partnerAddress}
            label={i18n.t("report.settings.analBy.partnerAddress")}
            onChange={(e) => setPartnerAddress(e.target.value)}
          />
          <TextField
            name="assessmentBy"
            className={styles.materialTextField}
            fullWidth
            value={assessmentBy}
            label={i18n.t("report.settings.analBy.assessmentBy")}
            onChange={(e) => setAssessmentBy(e.target.value)}
          />
          <TextField
            name="analBy"
            className={styles.materialTextField}
            fullWidth
            value={analysedBy}
            label={i18n.t("report.settings.analBy.analBy")}
            onChange={(e) => setAnalysedBy(e.target.value)}
          />
          <Tooltip
            title={i18n.t("common.isPremium")}
            placement={"bottom"}
            disableHoverListener={isPremium}
            arrow
          >
            <div className={ownStyles.checkboxContainer}>
              <Checkbox
                edge="start"
                checked={options.displayPartnerLogo}
                disableRipple
                disabled={!isPremium}
                className={
                  !isPremium ? styles.checkBoxFormDisabled : styles.checkBoxForm
                }
                color="default"
                onChange={() =>
                  setOptions((old) => ({
                    ...old,
                    displayPartnerLogo: !old.displayPartnerLogo,
                  }))
                }
              />
              <div
                className={
                  !isPremium
                    ? ownStyles.checkboxLabelDisabled
                    : ownStyles.checkboxLabel
                }
              >
                {i18n.t(`report.settings.analBy.displayPartnerLogo`)}
              </div>
            </div>
          </Tooltip>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default CoverOptions;
