import es from "./esPDF.json";
import en from "./enPDF.json";
import de from "./dePDF.json";
import fr from "./frPDF.json";
import it from "./itPDF.json";
import pl from "./plPDF.json";

/**
 * Metodo para obtener el texto de las traducciones para el report.
 * @param {string} lang Lenguaje de la traduccion
 * @param {string} key Ruta hasta la traducción separada por puntos
 */
const getText = (lang, key) => {
  try {
    let keys = key.split(".");
    let result;
    switch (lang) {
      case "es":
        result = es;
        break;
      case "en":
        result = en;
        break;
      case "de":
        result = de;
        break;
      case "fr":
        result = fr;
        break;
      case "it":
        result = it;
        break;
      case "pl":
        result = pl;
        break;
      default:
        result = en;
        break;
    }
    for (let i = 0; i < keys.length; i++) {
      result = result[keys[i]];
    }
    if (result && typeof result === "string") {
      return result;
    }
    return `Error: (nt) for '${key}'`;
  } catch (e) {
    console.error(e);
    return `Error: (nt) for '${key}'`;
  }
};

export default getText;
