import ClienteBase from "./clienteBase";

export default class Cliente extends ClienteBase {
  ///**@type {Array} */   accesos = null;
  /** @type {string} */ reportLogoURL = null;
  /**@type {Array} */ plantas = [];

  /**@param {Object} IntiObj  */
  constructor(IntiObj) {
    super(IntiObj);
    //  if(IntiObj.accesos) { this.accesos = IntiObj.accesos; }
    if (IntiObj.plantas) {
      this.plantas = IntiObj.plantas;
    }
  }
}
