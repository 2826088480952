import { Avatar, Button, Popover } from "@material-ui/core";
import { Close, ExitToApp, OpenInNew } from "@material-ui/icons";
import React from "react";
import i18n from "../../../locales/i18n";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import "react-perfect-scrollbar/dist/css/styles.css";

export default function UserPopover(props) {
  const { open, close, anchorUser, userInfo, logOutFunction } = props;

  const styles = DetailTabs_Style();
  const avatarText = userInfo
    ? userInfo.nombre.charAt(0) + userInfo.apellidos.charAt(0)
    : null;
  const permision = userInfo ? userInfo.roles[0] : null;
  const completeName = userInfo
    ? userInfo.nombre + " " + userInfo.apellidos
    : null;
  const email = userInfo ? userInfo.username : null;

  return (
    <>
      <Popover
        data-testid="user-popover"
        open={open}
        anchorEl={anchorUser}
        onClose={close}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        className={styles.userPopover}
      >
        <div className={styles.userPopoverContainer}>
          <div onClick={close} className={styles.userPopoverCloseContainer}>
            <Close
              className={styles.userPopoverCloseIcon}
              data-testid="user-popover-close"
            />
          </div>
          <div className={styles.userPopoverContainerTop}>
            <div className={styles.userPopoverContainerAvatarContainer}>
              <Avatar className={styles.userPopoverAvatar}>
                {avatarText || i18n.t("common.n/a")}
              </Avatar>
            </div>
            <div
              className={styles.userPopoverTextContainer}
              data-testid="user-rol"
            >
              {permision || i18n.t("common.n/a")}
            </div>
            <div className={styles.userPopoverTextContainer}>
              {completeName || i18n.t("common.n/a")}
            </div>
            <div className={styles.userPopoverTextContainer}>
              {email || i18n.t("common.n/a")}
            </div>
          </div>
          <div className={styles.userPopoverDivisor} />
          <div className={styles.userPopoverBottomContainer}>
            <div className={styles.userPopoverMenuContainer}>
              <Button
                data-testid="user-profile-button"
                className={styles.userPopoverButton}
                onClick={() => {
                  window.open(`https://my.danfoss.com/your-profile`);
                }}
              >
                <OpenInNew className={styles.userPopoverIcon} />
                <div className={styles.userPopoverTextButton}>
                  {i18n.t("sidebar.editProfile")}
                </div>
              </Button>
            </div>
            <div className={styles.userPopoverMenuContainer}>
              <Button
                data-testid="user-logOut-button"
                className={styles.userPopoverButton}
                onClick={logOutFunction}
              >
                <ExitToApp className={styles.userPopoverIcon} />
                <div className={styles.userPopoverTextButton}>
                  {i18n.t("sidebar.cerrarSesion")}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
