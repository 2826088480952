import React, { useEffect, useState, useCallback } from "react";
import i18n from "../../../locales/i18n";
import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Slide,
  Tooltip,
} from "@material-ui/core";
import {
  Room,
  ExpandMore,
  ExpandLess,
  HomeWork,
  Business,
  ArrowBackIos,
  ArrowForwardIos,
} from "@material-ui/icons";
import { ZonasAPI } from "../../../services/API";
import RelativeBackdrop from "../../RelativeBackdrop";
import { DetailTabs_Style, Common_Style } from "../../../assets/css/Styles";

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
//Treeview
import SearchField from "../../molecule/SearchField/SearchField";
import FrequencyConvertersSearchEngine from "../FrequencyConverterSearchEngine/FrequencyConverterSearchEngine";
import DriveIcon from "../../atoms/driveIcon";
import withHover from "../../withHover";

export default function TabPlantZone(props) {
  const { zonaId, plantaId, readOnly, isPremium, history, goBack, setErrors } =
    props;
  const [loading, setLoading] = useState(false);
  const [loadingZones, setLoadingZones] = useState(false);
  const [plantInfo, setPlantInfo] = useState(null);
  const [treeView, setTreeView] = useState([]);
  const [isFirstInput, setIsFirstInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIdZone, setSelectedIdZone] = useState(0);
  const [drawerZonesOpen, setDrawerZonesOpen] = useState(true);
  const [transitionFinished, setTransitionFinished] = useState(false);
  const [changeZone, setChangeZone] = useState(false);

  //cardStyles
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();
  const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

  //Get InfoZone
  const getPlantInfo = useCallback(async (plantId) => {
    setLoadingZones(true);
    setLoading(true);
    ZonasAPI.getPlantInfo(plantId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPlantInfo(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
        setLoadingZones(false);
      });
  }, []);

  //Get Zonas
  const getZonas = useCallback(
    async (zonaId, zoneLevel, treeView, savedZoneId) => {
      setLoadingZones(true);
      ZonasAPI.getTreeView(plantaId, zonaId, zoneLevel, savedZoneId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          //Para las subzonas
          if (zonaId) {
            //Funcion recursiva para añadir subzonas
            const findAndAssignSubZones = (zonas, zonaId, subZonas) => {
              return zonas.map((zona) => {
                if (zona.id === zonaId) {
                  zona.subZonas = subZonas;
                  return zona;
                } else if (zona.subZonas) {
                  zona.subZonas = findAndAssignSubZones(
                    zona.subZonas,
                    zonaId,
                    subZonas
                  );
                }
                return zona;
              });
            };
            const updatedZonas = findAndAssignSubZones(
              treeView,
              zonaId,
              //subzonas de backend
              data
            );
            setTreeView(updatedZonas);
          } else {
            setTreeView(data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
          setLoadingZones(false);
        });
    },
    [plantaId]
  );

  //Get Zonas - treeview by query
  const getTreeViewByQuery = useCallback(async (plantId, searchQuery) => {
    setLoadingZones(true);
    setLoading(true);
    ZonasAPI.getTreeViewByQuery(plantId, searchQuery)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setTreeView(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
        setLoadingZones(false);
      });
  }, []);

  //Abrir y cerrar treeview
  const toggleDrawer = () => {
    setDrawerZonesOpen(!drawerZonesOpen);
    localStorage.setItem("zoneDrawerOpen", !drawerZonesOpen + "");
  };

  //Busqueda con delay
  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (isFirstInput) {
        setIsFirstInput(false);
        setTreeView([]);
        //Si no hay busqueda, se utiliza el getZonas
        if (searchQuery === "") {
          getZonas();
        } else {
          getTreeViewByQuery(plantaId, searchQuery);
        }
      }
    }, 600);
    return () => clearTimeout(searchDelay);
  }, [getTreeViewByQuery, getZonas, isFirstInput, plantaId, searchQuery]);

  const getSubzonesTree = (zonaId, zoneLevel, treeView) => {
    //getSubZones(zonaId, zoneLevel);
    getZonas(zonaId, zoneLevel, treeView);
  };

  //Funcion recursiva para colapsar y expandir subzonas
  const expandTreeView = (zonas, zonaId) => {
    return zonas.map((zona) => {
      if (zona.id === zonaId) {
        zona.expanded = !zona.expanded;
        //Para colapsar las subzonas
        if (!zona.expanded && zona.subZonas) {
          closeZoneTree(zona.subZonas);
        }
        return zona;
      } else if (zona.subZonas) {
        zona.subZonas = expandTreeView(zona.subZonas, zonaId);
      }
      return zona;
    });
  };

  //Funcion recursiva para cerrar subzonas
  const closeZoneTree = (zonas) => {
    return zonas.map((zona) => {
      zona.expanded = false;
      if (zona.subZonas) {
        zona.subZonas = closeZoneTree(zona.subZonas);
      }
      return zona;
    });
  };

  //primer use Effect
  useEffect(() => {
    var storageZoneInfo = localStorage.getItem("storageZoneInfo");
    var drawerZonesOpen = localStorage.getItem("zoneDrawerOpen");

    setDrawerZonesOpen(drawerZonesOpen === "false" ? false : true);
    var savedZoneId = 0;

    if (storageZoneInfo) {
      var zoneInfo = JSON.parse(storageZoneInfo);
      if (zoneInfo.plantaId.toString() === plantaId.toString()) {
        savedZoneId = zoneInfo.zoneId;
        setSelectedIdZone(savedZoneId);
      }
    }
    getZonas(0, 0, null, savedZoneId);
    setLoading(true);
    getPlantInfo(plantaId);
  }, [getPlantInfo, getZonas, plantaId]);

  const RecursiveTreeView = (zonas) => (
    <>
      <List className={styles.treeViewList}>
        {zonas &&
          zonas.map((zona) => (
            <div key={zona.id}>
              <ListItem
                key={zona.id}
                className={styles.treeViewListItem}
                selected={selectedIdZone === zona.id}
                button
                onClick={() => {
                  if (zona.numSubZonas > 0 && !zona.subZonas) {
                    getSubzonesTree(zona.id, zona.zoneLevel, treeView);
                  }
                  if (zona.numSubZonas > 0) {
                    //Funcion recursiva para expandir subzonas
                    var updatedTreeView = [...treeView];
                    updatedTreeView = expandTreeView(treeView, zona.id);
                    setTreeView(updatedTreeView);
                  }
                  let storageZoneInfo = {
                    plantaId: plantaId,
                    zoneId: zona.id,
                  };
                  localStorage.setItem(
                    "storageZoneInfo",
                    JSON.stringify(storageZoneInfo)
                  );
                  setSelectedIdZone(zona.id);
                  setChangeZone(true);
                }}
              >
                <ListItemIcon className={styles.treeViewListItemIcon}>
                  {zona.isWarehouse ? (
                    <HomeWork className={styles.treeViewIcon} />
                  ) : (
                    <Room className={styles.treeViewIcon} />
                  )}
                </ListItemIcon>

                <ListItemText
                  className={
                    zona.nameFound
                      ? styles.treeViewListItemTextActive
                      : styles.treeViewListItemText
                  }
                  primary={zona.descripcion}
                  secondary={
                    <>
                      {zona.numEquipos +
                        " " +
                        i18n.t("planta.treeView.drives") +
                        " - " +
                        zona.numSubZonas +
                        " " +
                        i18n.t("planta.treeView.subzones")}
                    </>
                  }
                />

                {zona.numSubZonas > 0 &&
                  (zona.expanded ? <ExpandLess /> : <ExpandMore />)}
              </ListItem>

              {zona.subZonas && (
                <Collapse
                  in={zona.expanded}
                  timeout={360}
                  appear={loadingZones}
                  style={{
                    borderLeft: "1px dashed black",
                    paddingLeft: "2px",
                    marginLeft: "10px",
                    minWidth: "250px",
                  }}
                >
                  {zona.subZonas && <>{RecursiveTreeView(zona.subZonas)} </>}
                </Collapse>
              )}
            </div>
          ))}
      </List>
    </>
  );

  return (
    <RelativeBackdrop hidden={!loading}>
      <div
        className={commonStyles.mainContainerRow}
        style={{ minWidth: "60vw" }}
      >
        {!drawerZonesOpen && transitionFinished && (
          //Boton para cerrar el drawer
          <Tooltip
            arrow
            placement="left"
            title={
              !selectedIdZone
                ? i18n.t("planta.treeView.openTree")
                : i18n.t("planta.treeView.selectedZone")
            }
          >
            <IconButton
              onClick={toggleDrawer}
              style={{
                position: "absolute",
                zIndex: 1000,
              }}
            >
              <ArrowForwardIos
                style={selectedIdZone ? { color: "#E2000F" } : null}
              />
            </IconButton>
          </Tooltip>
        )}

        <Slide
          direction="right"
          in={drawerZonesOpen}
          timeout={100}
          mountOnEnter
          unmountOnExit
          onEntered={() => setTransitionFinished(false)}
          onExited={() => setTransitionFinished(true)}
        >
          <div className={styles.treeViewLeftSideContainer}>
            <div className={styles.treeViewSearch}>
              <SearchField
                inputBaseProps={{
                  placeholder: i18n.t("common.searchZone"),
                  value: searchQuery,
                  onChange: (event) => {
                    setIsFirstInput(true);
                    setSearchQuery(event.target.value);
                  },
                }}
              />
            </div>
            <CardWithHover
              className={styles.treeViewCardContainer}
              onClick={() => {
                if (selectedIdZone !== 0) {
                  setSelectedIdZone(0);
                  setChangeZone(true);
                  localStorage.removeItem("storageZoneInfo");
                }
              }}
            >
              <CardHeader
                className={styles.treeViewCardHeader}
                avatar={
                  <Avatar className={styles.tabHeaderAvatar}>
                    <Business />
                  </Avatar>
                }
                title={
                  <div className={styles.treeViewCardText}>
                    {plantInfo?.plantName}
                  </div>
                }
                subheader={
                  <div className={styles.treeViewCardSubText}>
                    <DriveIcon
                      color={"#484848"}
                      style={{
                        height: "16px",
                        marginRight: "-3px",
                      }}
                    />
                    <div className={styles.treeViewCardText}>
                      {plantInfo?.numDrives}{" "}
                    </div>
                    <Room className={styles.treeViewCardIcon} />
                    <div className={styles.treeViewCardText}>
                      {plantInfo?.numZones}
                    </div>
                  </div>
                }
                action={
                  <Tooltip
                    arrow
                    placement="left"
                    title={
                      !selectedIdZone
                        ? i18n.t("planta.treeView.closeTree")
                        : i18n.t("planta.treeView.selectedZone")
                    }
                  >
                    <IconButton
                      aria-label="settings"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDrawer();
                      }}
                    >
                      <ArrowBackIos
                        style={selectedIdZone ? { color: "#E2000F" } : null}
                      />
                    </IconButton>
                  </Tooltip>
                }
              />
            </CardWithHover>

            <div className={styles.treeViewContainer}>
              {treeView && <>{RecursiveTreeView(treeView)} </>}
            </div>
          </div>
        </Slide>

        <div className={styles.treeViewRightSideContainer}>
          <FrequencyConvertersSearchEngine
            readOnly={readOnly}
            isPremium={isPremium}
            PlantaId={plantaId}
            ZonaId={selectedIdZone ? selectedIdZone : null}
            history={history}
            goBack={goBack}
            setErrors={setErrors}
            changeZone={changeZone}
            setChangeZone={setChangeZone}
            fromTreeView={true}
          />
        </div>
      </div>
    </RelativeBackdrop>
  );
}
