import {
  Avatar,
  Button,
  Card,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import {
  AddCircleOutline,
  ExpandLess,
  ExpandMore,
  Group,
  GroupAdd,
  Info,
  People,
} from "@material-ui/icons";
import React, { useEffect, useState, useContext, useCallback } from "react";
import RelativeBackdrop from "../../RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { ClientesAPI, CountriesAPI, PartnersAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import AuthService from "../../../services/AuthService";
import AppContext from "../../../contexts/AppContext";
import {
  CardList_Style,
  Common_Style,
  DetailTabs_Style,
} from "../../../assets/css/Styles";
import InfiniteScroll from "react-infinite-scroll-component";
import PartnerFormInList from "./PartnerFormInList";
import ClientFormInList from "./ClientFormInList";
import PartnerClientIcon from "../../atoms/partnerClientIcon";

export default function PartnersClientsList(props) {
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const { keyuser, history, readOnly } = props;
  const isDSC = AuthService.hasRoleDSC();
  const isPartner = AuthService.hasRolePartner();
  const currentUserHasRoleEndCustomer = AuthService.hasRoleEndCustomer();
  const [isGestor, setIsGestor] = useState(false);
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFirstInput, setIsFirstInput] = useState(false);
  const styles = CardList_Style();
  const detailStyles = DetailTabs_Style();
  const commonStyles = Common_Style();
  //Para los 2 formularios
  const [showOnlyClients, setShowOnlyClients] = useState(
    currentUserHasRoleEndCustomer ? true : false
  );
  const [countries, setCountries] = useState([]);
  const [clientFormMode, setClientFormMode] = useState(false);
  const [notFound, setNotFound] = useState(false);
  //Para formulario de Partners
  const [hasMorePartners, setHasMorePartners] = useState(true);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [isNewPartner, setIsNewPartner] = useState(false);
  const [arrayPartnerException, setArrayPartnerException] = useState([]);
  // Para formulario Clientes
  const [hasMoreClients, setHasMoreClients] = useState(true);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [clientFormReadOnly, setClientFormReadOnly] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [arrayClientException, setArrayClientException] = useState([]);
  const [clients, setClients] = useState([]);

  //getPartners
  const getPartners = useCallback(
    (
      actualLength,
      searchQuery,
      isNewQuery,
      arrayPartnerException,
      partnerOnFirstPlace
    ) => {
      setLoading(true);
      PartnersAPI.getInfPartnersRefined(
        actualLength,
        searchQuery,
        arrayPartnerException
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          //Nueva Query
          if (isNewQuery) {
            if (partnerOnFirstPlace) {
              //Cuando se agrega un nuevo partner, se pone en primer lugar
              var newPartners = [...data.partners];
              newPartners.unshift(partnerOnFirstPlace);
              setPartners(newPartners);
              setSelectedPartnerId(partnerOnFirstPlace.id);
            } else {
              setPartners(data.partners);
              if (data.partners.length > 0) {
                setSelectedPartnerId(data.partners[0].id);
                setIsNewPartner(false);
                setNotFound(false);
              }
              //En el caso de que no haya partners en la lista y se puedan crear nuevos
              else if (
                data.partners.length === 0 &&
                searchQuery.trim().length === 0
              ) {
                setNotFound(false);
                setIsNewPartner(true);
                setSelectedPartnerId(null);
                //no se han encontrado resultados y no se puede crear nuevos
              } else {
                setNotFound(true);
                setSelectedPartnerId(null);
                setIsNewPartner(false);
              }
            }
            //No es nueva query
          } else {
            setPartners((old) => old.concat(data.partners));
          }
          setHasMorePartners(data.hasMorePartners);
          AuthService.updateUserAccessPermisions(data.userAccessPermissions);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    },
    []
  );

  const checkClientAccess = useCallback(
    (clientId) => {
      const isGestor = AuthService.isGestorFromPartnerForClient(clientId);
      setClientFormReadOnly(readOnly || !(keyuser || isDSC || isGestor));
      setIsGestor(isGestor);
    },
    [isDSC, keyuser, readOnly]
  );

  //Get SOLO Clientes (lista de solo clientes)
  const getClients = useCallback(
    (
      actualLength,
      searchQuery,
      isNewQuery,
      arrayClientException,
      clientOnFirstPlace
    ) => {
      setLoading(true);
      ClientesAPI.getInfClientsRefined(
        actualLength,
        searchQuery,
        arrayClientException
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          //Nueva Query
          if (isNewQuery) {
            if (clientOnFirstPlace) {
              //Cuando se agrega un nuevo partner, se pone en primer lugar
              var newClients = [...data.clientes];
              newClients.unshift(clientOnFirstPlace);
              setClients(newClients);
              setSelectedClientId(clientOnFirstPlace.id);
              setIsNewPartner(false);
            } else {
              setClients(data.clientes);
              if (data.clientes.length > 0) {
                setSelectedClientId(data.clientes[0].id);
                checkClientAccess(data.clientes[0].id);
                setNotFound(false);
              } else if (
                data.clientes.length === 0 &&
                searchQuery.trim().length === 0
              ) {
                setSelectedClientId(null);
                setNotFound(false);
                //no se han encontrado resultados y no se puede crear nuevos
              } else {
                setNotFound(true);
                setSelectedClientId(null);
              }
              setIsNewPartner(false);
            }
            //No es nueva query
          } else {
            setClients((old) => old.concat(data.clientes));
          }
          setHasMoreClients(data.hasMoreClients);
          AuthService.updateUserAccessPermisions(data.userAccessPermissions);
        })
        .finally(() => {
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    },
    [checkClientAccess]
  );

  //Get countries
  const getCountries = useCallback(() => {
    CountriesAPI.getWithRegion()
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        data.sort((a, b) =>
          i18n.t(`country.${a.name}`).localeCompare(i18n.t(`country.${b.name}`))
        );
        setCountries(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  //getPartners
  const getClientsFromPartner = useCallback(
    (partnerId, clientOnFirstPlace) => {
      setLoadingClient(true);
      setLoading(true);
      PartnersAPI.getClientsFromPartner(partnerId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          AuthService.updateUserAccessPermisions(data.userAccessPermissions);
          var copyArrayPartner = [...partners];
          const partnerIndex = copyArrayPartner.findIndex(
            (partner) => partner.id === partnerId
          );
          copyArrayPartner[partnerIndex].clientList = data.clients;
          //En caso de que se haya agregado un nuevo cliente
          if (clientOnFirstPlace) {
            //se busca por id
            const clientIndex = copyArrayPartner[
              partnerIndex
            ].clientList.findIndex(
              (client) => client.id === clientOnFirstPlace.id
            );
            //colocar en primer lugar
            if (clientIndex !== -1) {
              const clientFind = copyArrayPartner[
                partnerIndex
              ].clientList.splice(clientIndex, 1)[0];
              copyArrayPartner[partnerIndex].clientList.unshift(clientFind);
              setIsGestor(true);
              setClientFormReadOnly(false);
              setSelectedClientId(clientOnFirstPlace.id);
            }
          }
          setPartners(copyArrayPartner);
        })
        .finally(() => {
          setLoadingClient(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          window.location.reload();
        });
    },
    [partners]
  );

  const getAvatarText = (title) => {
    const abbreviatonLetters = title.split(" ");
    const abbreviaton =
      abbreviatonLetters.length > 1
        ? (abbreviatonLetters[0][0] + abbreviatonLetters[1][0]).toUpperCase()
        : title.substr(0, 2).toUpperCase();
    return abbreviaton;
  };

  const handleNewPartner = () => {
    setSelectedPartnerId(null);
    setIsNewPartner(true);
    setClientFormMode(false);
    setNotFound(false);
  };

  const handleNewClientFromClientList = () => {
    setSelectedClientId(null);
    setClientFormMode(true);
    setNotFound(false);
  };

  //Funcion recursiva para colapsar y expandir partners
  const expandPartner = (partner) => {
    if (!partner.expanded) {
      getClientsFromPartner(partner.id);
    }
    setSelectedPartnerId(partner.id);
    var copyArrayPartner = [...partners];
    const partnerId = partner.id;
    const partnerIndex = copyArrayPartner.findIndex(
      (partner) => partner.id === partnerId
    );
    copyArrayPartner[partnerIndex].expanded =
      !copyArrayPartner[partnerIndex].expanded;
    setPartners(copyArrayPartner);
    setSelectedClientId(null);
    setIsNewPartner(false);
    setClientFormMode(false);
  };

  const handleClient = (partnerId, clientId) => {
    checkClientAccess(clientId);
    setSelectedPartnerId(partnerId);
    setSelectedClientId(clientId);
    setClientFormMode(true);
    setIsNewPartner(false);
    setNotFound(false);
  };

  //Función boton de nuevo cliente
  const handleNewClient = (partnerId) => {
    document.getElementById("partnerclient-container").scrollTop = 0;
    setSelectedPartnerId(partnerId);
    setSelectedClientId(null);
    setClientFormMode(true);
    setIsNewPartner(false);
    setNotFound(false);
  };

  const partnerDeleted = () => {
    //Resetear busqueda
    document.getElementById("partnerclient-container").scrollTop = 0;
    getPartners(0, searchQuery, true);
    setArrayPartnerException([]);
    setArrayClientException([]);
  };

  const partnerAdded = (partner) => {
    //Scroll al principio en la lista
    document.getElementById("partnerclient-container").scrollTop = 0;
    //Limpiar array de excepciones
    var newArrayException = [parseInt(partner.id, 10)];
    setArrayPartnerException([parseInt(partner.id, 10)]);
    //Resetear busqueda
    setSearchQuery("");
    setIsFirstInput(false);
    getPartners(0, "", true, newArrayException, partner);
    setIsNewPartner(false);
  };

  const partnerUpdated = (partnerUpdated) => {
    //Añadir a array de excepciones en caso de que no esté
    var copyArrayPartnerException = [...arrayPartnerException];
    const partnerId = parseInt(partnerUpdated.id, 10);
    if (!copyArrayPartnerException.includes(partnerId)) {
      copyArrayPartnerException.push(partnerId);
    }
    setArrayPartnerException(copyArrayPartnerException);
    const updatedPartners = partners.map((partner) =>
      partner.id.toString() === partnerUpdated.id.toString()
        ? { ...partner, ...partnerUpdated }
        : partner
    );
    setPartners(updatedPartners);
  };

  const clientDeleted = (PartnerId) => {
    document.getElementById("partnerclient-container").scrollTop = 0;
    if (showOnlyClients) {
      getClients(0, searchQuery, true);
    } else {
      getClientsFromPartner(PartnerId);
      setClientFormMode(false);
      setSelectedPartnerId(PartnerId);
      setSelectedClientId(null);
    }
    setArrayClientException([]);
  };

  const clientAdded = (partnerId, client) => {
    document.getElementById("partnerclient-container").scrollTop = 0;
    if (showOnlyClients) {
      //Limpiar array de excepciones
      var newArrayException = [parseInt(client.id, 10)];
      setArrayClientException([parseInt(client.id, 10)]);
      //Resetear busqueda
      setSearchQuery("");
      setIsFirstInput(false);
      getClients(0, "", true, newArrayException, client);
    } else {
      //Volrver a cargar clientes en partners
      getClientsFromPartner(partnerId, client);
    }
  };

  const clientUpdated = (partnerId, clientUpdated) => {
    if (showOnlyClients) {
      //Modificar cliente en lista y añadir al array de excepciones
      var copyArrayClientException = [...arrayClientException];
      const clientId = parseInt(clientUpdated.id, 10);
      if (!copyArrayClientException.includes(clientId)) {
        copyArrayClientException.push(clientId);
      }
      setArrayClientException(copyArrayClientException);
      const updatedClients = clients.map((client) =>
        client.id.toString() === clientUpdated.id.toString()
          ? { ...client, ...clientUpdated }
          : client
      );
      setClients(updatedClients);
    } else {
      //copia de partners
      var copyArrayPartner = [...partners];
      const partnerIndex = copyArrayPartner.findIndex(
        (partner) => partner.id === partnerId
      );

      //se busca por id
      const clientIndex = copyArrayPartner[partnerIndex].clientList.findIndex(
        (client) => client.id === clientUpdated.id
      );
      //Modificar clienmte
      if (clientIndex !== -1) {
        copyArrayPartner[partnerIndex].clientList[clientIndex] = clientUpdated;
      }
      setPartners(copyArrayPartner);
    }
  };

  //Busqueda con delay
  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (isFirstInput) {
        setArrayClientException([]);
        setArrayPartnerException([]);
        setIsNewPartner(false);
        setSelectedClientId(null);
        setSelectedPartnerId(null);
        if (showOnlyClients) {
          setClients([]);
          getClients(0, searchQuery, true);
        } else {
          setPartners([]);
          getPartners(0, searchQuery, true);
        }
      }
    }, 600);
    return () => clearTimeout(searchDelay);
  }, [searchQuery, getPartners, isFirstInput, showOnlyClients, getClients]);

  //primera funcion al entrar getPartners
  useEffect(() => {
    //Para el infinite scroll se reinicie
    setLoading(true);
    document.getElementById("main-content").scrollTop = 0;
    document.getElementById("partnerclient-container").scrollTop = 0;
    setClients([]);
    setPartners([]);
    setArrayClientException([]);
    setArrayPartnerException([]);
    setSearchQuery("");
    setIsFirstInput(false);
    //Cargar partners o clientes
    if (showOnlyClients || currentUserHasRoleEndCustomer) {
      setSelectedClientId(null);
      setSelectedPartnerId(null);
      getClients(0, "", true);
      setClientFormMode(true);
    } else {
      setSelectedClientId(null);
      setSelectedPartnerId(null);
      getPartners(0, "", true);
      setClientFormMode(false);
    }
  }, [
    currentUserHasRoleEndCustomer,
    getClients,
    getCountries,
    getPartners,
    showOnlyClients,
  ]);

  //Titulo de la pagina
  useEffect(() => {
    getCountries();
    if (showOnlyClients) {
      setGlobal((prev) => ({
        ...prev,
        pageTitle: i18n.t("partnersClients.titleClients"),
      }));
    } else {
      setGlobal((prev) => ({
        ...prev,
        pageTitle: i18n.t("partnersClients.titlePartnerClient"),
      }));
    }
  }, [getCountries, setGlobal, showOnlyClients]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <div className={commonStyles.mainContainer}>
        <div className={commonStyles.searchFieldContainer}>
          <div className={commonStyles.searchField}>
            <SearchField
              inputBaseProps={{
                placeholder: i18n.t("common.search"),
                value: searchQuery,
                onChange: (event) => {
                  setIsFirstInput(true);
                  setSearchQuery(event.target.value);
                },
              }}
            />

            {!readOnly && (keyuser || isDSC) && !showOnlyClients && (
              //Nuevo Partner
              <Button
                data-testid="newPartner-Button"
                variant="contained"
                className={commonStyles.primaryButtonRaw}
                onClick={() => {
                  handleNewPartner();
                }}
              >
                <AddCircleOutline className={styles.addCircleOutline} />
                {i18n.t("partnersClients.addPartner")}
              </Button>
            )}
            {!readOnly && showOnlyClients && (
              //Nuevo Cliente
              <Button
                data-testid="newClient-Button"
                variant="contained"
                className={commonStyles.primaryButtonRaw}
                onClick={() => {
                  handleNewClientFromClientList();
                }}
              >
                <AddCircleOutline className={styles.addCircleOutline} />
                {i18n.t("partnersClients.addClient")}
              </Button>
            )}
            {!showOnlyClients && (
              <Button
                data-testid="showClientsOnly-Button"
                variant="outlined"
                className={commonStyles.secondaryButtonRaw}
                onClick={() => setShowOnlyClients(true)}
              >
                <People className={styles.addCircleOutline} />
                {i18n.t("partnersClients.onlyCLientsButton")}
              </Button>
            )}
            {(keyuser || isDSC || isPartner) && showOnlyClients && (
              <Button
                data-testid="showPartnerClients-Button"
                variant="outlined"
                className={commonStyles.secondaryButtonRaw}
                onClick={() => setShowOnlyClients(false)}
              >
                <PartnerClientIcon
                  color={"#E2000F"}
                  style={{ marginRight: "6px" }}
                />
                {i18n.t("partnersClients.titlePartnerClient")}
              </Button>
            )}
          </div>
        </div>
        <Card className={detailStyles.PartnerClientListMainCard}>
          <div
            id="partnerclient-container"
            className={detailStyles.infinityPartnerClientListContainer}
          >
            {!showOnlyClients ? (
              <InfiniteScroll
                scrollableTarget="partnerclient-container"
                dataLength={partners.length}
                style={{ overflow: "hidden" }}
                next={() =>
                  getPartners(
                    partners.length,
                    searchQuery,
                    false,
                    arrayPartnerException
                  )
                }
                hasMore={hasMorePartners}
                loader
              >
                {partners.length === 0 && !loading && (
                  // En caso de que no haya partners en la lista
                  //data-testid="totalDrives-test"
                  <Tooltip
                    title={
                      searchQuery.trim().length === 0
                        ? i18n.t("partnersClients.empyPartnerTooltip")
                        : i18n.t("partnersClients.elementsNotFound")
                    }
                    placement="bottom"
                    arrow
                  >
                    <ListItem
                      className={detailStyles.partnerClientListItem}
                      data-testid="partners-empty"
                    >
                      <ListItemIcon>
                        <Info style={{ color: "#46B9F3" }} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        secondaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        primary={i18n.t("partnersClients.noPartnerPrimary")}
                        secondary={
                          searchQuery.trim().length === 0
                            ? keyuser || isDSC
                              ? i18n.t(
                                  "partnersClients.noPartnerSecondaryAccess"
                                )
                              : i18n.t(
                                  "partnersClients.noPartnerSecondaryNoAccess"
                                )
                            : i18n.t("partnersClients.elementsNotFound")
                        }
                      />
                    </ListItem>
                  </Tooltip>
                )}

                {partners.map((partner, indx) => (
                  /*      Lista Partners      */
                  <div key={indx}>
                    <ListItem
                      data-testid={"partner-" + partner.razonSocial}
                      button
                      className={detailStyles.partnerClientListItem}
                      onClick={() => {
                        expandPartner(partner);
                      }}
                      selected={selectedPartnerId === partner.id}
                    >
                      <ListItemIcon>
                        <Avatar
                          className={detailStyles.partnerClientListAvatar}
                        >
                          {getAvatarText(partner.razonSocial)}
                        </Avatar>
                      </ListItemIcon>

                      <ListItemText
                        primaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        secondaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        primary={partner.razonSocial}
                        secondary={partner.direccion}
                      />

                      {partner.expanded ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    {partner?.clientList?.length <= 0 && !keyuser && !isDSC && (
                      <Collapse
                        /*      Sin clientes     */
                        key={"noClient" + indx}
                        in={partner.expanded}
                        appear={loadingClient}
                        timeout={360}
                        className={detailStyles.partnerClientListInactive}
                      >
                        <ListItem>
                          <ListItemText
                            primary={i18n.t(
                              "partnersClients.noCustomerInParner"
                            )}
                          />
                        </ListItem>
                      </Collapse>
                    )}

                    {!readOnly && (
                      <Collapse
                        /*      NUEVO CLIENTE EN LISTA PARTNER    */
                        key={"newPartner" + indx}
                        in={partner.expanded}
                        appear={loadingClient}
                        timeout={360}
                        className={detailStyles.partnerClientListCollapse}
                      >
                        <ListItem
                          data-testid={"newClient-" + partner.razonSocial}
                          button
                          onClick={() => {
                            handleNewClient(partner.id);
                          }}
                        >
                          <ListItemIcon>
                            <Avatar
                              className={
                                detailStyles.partnerClientListAvatarNewClient
                              }
                            >
                              <GroupAdd />
                            </Avatar>
                          </ListItemIcon>

                          <ListItemText
                            primary={i18n.t(
                              "partnersClients.addClientOnPartner"
                            )}
                          />
                        </ListItem>
                      </Collapse>
                    )}

                    {partner &&
                      /* Lista clientes en Partner */
                      partner.clientList?.map((client) => (
                        <Collapse
                          in={partner.expanded}
                          key={"collapse" + client.id}
                          appear={loadingClient}
                          timeout={360}
                          className={detailStyles.partnerClientListCollapse}
                        >
                          <ListItem
                            data-testid={"client-" + client.razonSocial}
                            key={"client" + client.id}
                            button
                            selected={selectedClientId === client.id}
                            onClick={() => {
                              handleClient(partner.id, client.id);
                            }}
                          >
                            <ListItemIcon>
                              <Avatar
                                className={detailStyles.partnerClientListAvatar}
                              >
                                <Group />
                              </Avatar>
                            </ListItemIcon>

                            <ListItemText
                              primaryTypographyProps={{
                                className:
                                  detailStyles.partnerClientListItemText,
                              }}
                              secondaryTypographyProps={{
                                className:
                                  detailStyles.partnerClientListItemText,
                              }}
                              primary={client.razonSocial}
                              secondary={client.direccion}
                            />
                          </ListItem>
                        </Collapse>
                      ))}
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <InfiniteScroll
                /*      Lista SOLO Clientes      */
                scrollableTarget="partnerclient-container"
                dataLength={clients.length}
                next={() =>
                  getClients(
                    clients.length,
                    searchQuery,
                    false,
                    arrayClientException
                  )
                }
                hasMore={hasMoreClients}
                loader
              >
                {clients.length === 0 && !loading && (
                  // En caso de que no haya partners en la lista
                  <Tooltip
                    title={
                      searchQuery.trim().length === 0
                        ? i18n.t("partnersClients.empyPartnerTooltip")
                        : i18n.t("partnersClients.elementsNotFound")
                    }
                    placement="bottom"
                    arrow
                  >
                    <ListItem
                      className={detailStyles.partnerClientListItem}
                      data-testid={"client-empty"}
                    >
                      <ListItemIcon>
                        <Info style={{ color: "#46B9F3" }} />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        secondaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        primary={i18n.t("partnersClients.noClientPrimary")}
                        secondary={
                          searchQuery.trim().length === 0
                            ? keyuser || isDSC || isPartner
                              ? i18n.t(
                                  "partnersClients.noClientSecondaryAccess"
                                )
                              : i18n.t(
                                  "partnersClients.noClientSecondaryNoAccess"
                                )
                            : i18n.t("partnersClients.elementsNotFound")
                        }
                      />
                    </ListItem>
                  </Tooltip>
                )}

                {clients.map((client, indx) => (
                  <div key={indx}>
                    <ListItem
                      data-testid={"client-" + client.razonSocial}
                      button
                      className={detailStyles.partnerClientListItem}
                      onClick={() => {
                        handleClient(null, client.id);
                      }}
                      selected={selectedClientId === client.id}
                    >
                      <ListItemIcon>
                        <Avatar
                          className={detailStyles.partnerClientListAvatar}
                        >
                          {getAvatarText(client.razonSocial)}
                        </Avatar>
                      </ListItemIcon>

                      <ListItemText
                        primaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        secondaryTypographyProps={{
                          className: detailStyles.partnerClientListItemText,
                        }}
                        primary={client.razonSocial}
                        secondary={client.direccion}
                      />
                    </ListItem>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>

          <div className={detailStyles.partnerClientFormContainer}>
            {!loading && (
              <>
                {clientFormMode || showOnlyClients ? (
                  <ClientFormInList
                    clientId={selectedClientId}
                    partnerId={selectedPartnerId}
                    history={history}
                    keyuser={keyuser}
                    readOnly={readOnly || clientFormReadOnly}
                    isGestor={isGestor}
                    countries={countries}
                    clientDeleted={clientDeleted}
                    clientAdded={clientAdded}
                    clientUpdated={clientUpdated}
                    notFound={notFound}
                  />
                ) : (
                  <PartnerFormInList
                    partnerId={selectedPartnerId}
                    isNewPartner={isNewPartner}
                    keyuser={keyuser}
                    readOnly={readOnly}
                    partnerDeleted={partnerDeleted}
                    partnerAdded={partnerAdded}
                    partnerUpdated={partnerUpdated}
                    countries={countries}
                    notFound={notFound}
                  />
                )}
              </>
            )}
          </div>
        </Card>
      </div>
    </RelativeBackdrop>
  );
}
