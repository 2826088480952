import { Avatar, Card, CardContent, Typography } from "@material-ui/core";
import { Room, HomeWorkOutlined } from "@material-ui/icons";
import React from "react";
import withHover from "../../withHover";
import i18n from "../../../locales/i18n";
import DriveIcon from "../../atoms/driveIcon";
const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

export default function ZonaRow(props) {
  const { zona } = props;

  return (
    <CardWithHover {...props} data-cy="CardInfo">
      <CardContent
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Avatar
            aria-label="avatar"
            style={{ backgroundColor: "#552877", marginRight: 10 }}
          >
            {zona.isWarehouse ? <HomeWorkOutlined /> : <Room />}
          </Avatar>
          <div>
            <Typography variant="body2" noWrap>
              {zona.descripcion}
            </Typography>
            <Typography variant="body2" noWrap color="textSecondary">
              {zona.isWarehouse
                ? i18n.t("zona.warehouse")
                : i18n.t("zona.itemTitle")}
            </Typography>
          </div>
        </div>
        <div>
          <Typography variant="body2" noWrap>
            <Room
              className="mr-1"
              color="action"
              style={{ fontSize: 21, verticalAlign: "bottom" }}
            />
            {zona.numSubZonas +
              " " +
              (zona.numSubZonas === 1
                ? i18n.t("zona.ContSingular")
                : i18n.t("zona.ContPlural"))}
          </Typography>
          <Typography variant="body2" className="mt-2" noWrap>
            <DriveIcon
              className="mr-1"
              color="#757575"
              style={{
                height: 20,
                width: 20,
                verticalAlign: "bottom",
                marginRight: 5,
              }}
            />
            {zona.numEquipos + " " + i18n.t("equipo.Abbreviated")}
          </Typography>
        </div>
      </CardContent>
    </CardWithHover>
  );
}
