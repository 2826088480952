import React, { useEffect } from "react";
import { Route, withRouter } from "react-router";
import PrivateRoute from "./components/PrivateRoute";

import Login from "./pages/Login";
import Unauthorized from "./pages/Unauthorized";
import Home from "./pages/Home";

import PartnersClientsList from "./components/page/partnerClientes/PartnersClientsList";

import PlantasList from "./components/page/Plantas/PlantasList";
import PlantasDetails from "./components/page/Plantas/PlantasDetails";
import ZonasDetails from "./components/page/Zonas/ZonasDetails";

import EquiposDetails from "./components/page/Equipos/EquiposDetails";

import UsersList from "./components/page/UserManager/UsersList";
import UserDetails from "./components/page/UserManager/UserDetails";
import UserAdd from "./components/page/UserManager/UserAdd";

import i18n from "../src/locales/i18n";
import "./assets/css/custom.css";
import "./assets/css/fonts.css";
import "./assets/css/swal2DialogStyle.css";
import ClearCache from "./helpers/ClearCache";
import Stats from "./components/page/Stats/stats";
import Main from "./components/layout/Main";
import { AccessLevel } from "./helpers/Common";
import AfterLoginPage from "./components/page/AfterLogin/AfterLoginPage";
import RequestAccessPage from "./components/page/RequestAccess/RequestAccessPage";
import Broadcast from "./components/page/Broadcast/Broadcast";
import SiteAssessmentVersionsDetail from "./components/page/SiteAssessmentVersionDetail/SiteAssessmentVersionDetail";

const nonLayoutPages = [
  "/login",
  "/unauthorized",
  "/unsub",
  "/callback",
  "/request",
];

const App = (props) => {
  i18n.locale = localStorage.getItem("locale");

  // After LogOut redirect the user to the PARTNER custom login page
  useEffect(() => {
    const semaf = localStorage.getItem("fromLogOut");
    const ptr = localStorage.getItem("ptr");

    if (semaf) {
      localStorage.removeItem("fromLogOut");
      if (ptr) {
        window.location.replace("/login?ptr=" + ptr);
      }
    }
  }, []);

  const isLayoutPage = !nonLayoutPages.some((p) =>
    props.location.pathname.startsWith(p)
  );
  if (isLayoutPage) {
    return (
      <ClearCache>
        <Main {...props}>
          <PrivateRoute
            exact
            path="/"
            acceso={{ nivel: AccessLevel.None }}
            component={(props) => <Home {...props} />}
          />

          {/* Plantas */}
          <PrivateRoute
            exact
            path="/plantas"
            acceso={{ nivel: AccessLevel.None }}
            component={(props) => <PlantasList {...props} />}
          />

          <PrivateRoute
            exact
            path="/clientes/:clienteId/nueva-planta"
            acceso={{ nivel: AccessLevel.Client, varId: "clienteId" }}
            component={(props) => <PlantasDetails {...props} />}
          />

          <PrivateRoute
            exact
            path="/plantas/:id"
            acceso={{ nivel: AccessLevel.Plant, varId: "id" }}
            component={(props) => <PlantasDetails {...props} />}
          />

          <PrivateRoute
            exact
            path="/plantas/:plantaId/zonas/:id?"
            acceso={{ nivel: AccessLevel.Zone, varId: "id" }}
            component={(props) => <ZonasDetails {...props} />}
          />

          <PrivateRoute
            exact
            path="/plantas/:plantaId/zonas/:zonaId/equipos/:id?"
            acceso={{ nivel: AccessLevel.Zone, varId: "zonaId" }}
            component={(props) => <EquiposDetails {...props} />}
          />

          <PrivateRoute
            exact
            path="/plantas/:plantaId/equipos/:id?"
            acceso={{ nivel: AccessLevel.Plant, varId: "plantaId" }}
            component={(props) => <EquiposDetails {...props} />}
          />

          <PrivateRoute
            exact
            path="/plantas/:plantaId/siteassessmentversion/:id"
            acceso={{ nivel: AccessLevel.Plant, varId: "plantaId" }}
            component={(props) => <SiteAssessmentVersionsDetail {...props} />}
          />

          {/* Partners y CLientes */}
          <PrivateRoute
            exact
            path="/partnersclients"
            acceso={{ nivel: AccessLevel.Partner }}
            component={(props) => <PartnersClientsList {...props} />}
          />

          {/* Usuarios */}
          <PrivateRoute
            exact
            path="/users"
            acceso={{ nivel: AccessLevel.None }}
            component={(props) => <UsersList {...props} />}
          />

          <PrivateRoute
            exact
            path="/user/:id"
            acceso={{ nivel: AccessLevel.None }}
            component={(props) => <UserDetails {...props} />}
          />

          <PrivateRoute
            exact
            path="/newuser"
            acceso={{ nivel: AccessLevel.None }}
            component={(props) => <UserAdd {...props} />}
          />

          {/* Lifecycle Notice */}
          <PrivateRoute
            exact
            path="/broadcast"
            acceso={{ nivel: AccessLevel.Admin }}
            component={(props) => <Broadcast {...props} />}
          />

          {/* Estadisticas */}
          <PrivateRoute
            exact
            path="/kpi"
            acceso={{ nivel: AccessLevel.Admin }}
            component={(props) => <Stats {...props} />}
          />
        </Main>
      </ClearCache>
    );
  } else {
    return (
      <>
        <ClearCache>
          <Route path="/login" exact component={Login} />
          <Route path="/callback" exact component={AfterLoginPage} />
          <Route path="/request" exact component={RequestAccessPage} />
          <Route path="/unauthorized" component={Unauthorized} />
        </ClearCache>
      </>
    );
  }
};

export default withRouter(App);
