import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import React, { useState } from "react";
import i18n from "../../../locales/i18n";
import { termsUrl } from "../../../helpers/Common";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import NewsContent from "./NewsSettingsContent";
import "react-perfect-scrollbar/dist/css/styles.css";

export default function DialogDriveActions(props) {
  const { open, close } = props;
  // @ts-ignore

  const [anchorMenuLanguage, setAnchorMenuLanguage] = useState(null);
  const [displayNews, setDisplayNews] = useState(false);
  const styles = DetailTabs_Style();
  const locale = i18n.locale || i18n.defaultLocale;

  const handleMenuLanguageClose = () => {
    setAnchorMenuLanguage(null);
  };

  const handleMenuLanguageOpen = (event) => {
    setAnchorMenuLanguage(event.currentTarget);
  };

  const handleLangClose = (locale) => {
    setAnchorMenuLanguage(null);
    let currentLocale = i18n.locale || i18n.defaultLocale;
    if (locale !== currentLocale) {
      switch (locale) {
        case "es":
        case "en":
        case "de":
        case "fr":
        case "it":
        case "pl":
          localStorage.setItem("locale", locale);
          i18n.locale = locale;
          window.location.reload();
          break;
        default:
          break;
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        className={styles.dialogSettings}
        data-testid="settings-dialog"
      >
        <DialogTitle className={styles.dialogSettingsTitle}>
          {displayNews ? (
            <div className={styles.dialogNewsHeader}>
              <Button
                id="settings-close-news"
                data-testid="settings-close-news"
                onClick={() => {
                  setDisplayNews(false);
                }}
                className={styles.dialogNewsHeaderButton}
              >
                <ArrowBackIos />
                <div>{i18n.t("sidebar.news")}</div>
              </Button>
            </div>
          ) : (
            i18n.t("sidebar.settings.title")
          )}
        </DialogTitle>
        <DialogContent className={styles.dialogSettingsContent}>
          {!displayNews ? (
            <>
              <MenuList className={styles.dialogSettingsMenu}>
                <MenuItem
                  data-testid="settings-language"
                  className={styles.dialogSettingsMenuItem}
                  onClick={handleMenuLanguageOpen}
                >
                  <ListItemText
                    primary={i18n.t("sidebar.settings.language")}
                    secondary={i18n.t("settings.languageName")}
                  />
                  <ArrowForwardIos className={styles.dialogSettingsMenuIcon} />
                </MenuItem>

                <MenuItem
                  data-testid="settings-LegalInfo"
                  className={styles.dialogSettingsMenuItem}
                  onClick={() => {
                    window.open(
                      `${termsUrl}/TermsAndConditions?culture=${locale}&appId=assetcare`
                    );
                  }}
                >
                  <ListItemText
                    primary={i18n.t("sidebar.settings.legalInfo")}
                    secondary={i18n.t("sidebar.terms")}
                  />
                  <ArrowForwardIos className={styles.dialogSettingsMenuIcon} />
                </MenuItem>

                <MenuItem
                  data-testid="settings-cookiesPol"
                  className={styles.dialogSettingsMenuItem}
                  onClick={() => {
                    window.open(`${termsUrl}/privacypolicy?culture=${locale}`);
                  }}
                >
                  <ListItemText
                    primary={i18n.t("sidebar.settings.cookiesPol")}
                    secondary={i18n.t("sidebar.privacity")}
                  />
                  <ArrowForwardIos className={styles.dialogSettingsMenuIcon} />
                </MenuItem>

                <MenuItem
                  data-testid="settings-news"
                  className={styles.dialogSettingsMenuItem}
                  onClick={() => {
                    setDisplayNews(true);
                  }}
                >
                  <ListItemText
                    primary={i18n.t("sidebar.settings.version")}
                    secondary={
                      i18n.t("sidebar.settings.versionSubheader") +
                      " " +
                      process.env.REACT_APP_VERSION
                    }
                  />
                  <ArrowForwardIos className={styles.dialogSettingsMenuIcon} />
                </MenuItem>

                <MenuItem
                  data-testid="settings-copyRight"
                  className={styles.dialogSettingsMenuItem}
                  onClick={() => {}}
                >
                  <ListItemText
                    primary={i18n.t("sidebar.settings.copyright")}
                    secondary={i18n.t("sidebar.settings.copyrightSubheader")}
                  />
                </MenuItem>
              </MenuList>
              <Menu
                anchorEl={anchorMenuLanguage}
                keepMounted
                open={Boolean(anchorMenuLanguage)}
                onClose={handleMenuLanguageClose}
                className={styles.dialogSettingsMenuLanguage}
              >
                <MenuItem
                  data-testid="settings-language-en"
                  onClick={() => handleLangClose("en")}
                  className={styles.dialogSettingsMenuLanguageItem}
                >
                  {i18n.t("settings.languageName", { locale: "en" })}
                </MenuItem>
                <MenuItem
                  data-testid="settings-language-es"
                  onClick={() => handleLangClose("es")}
                  className={styles.dialogSettingsMenuLanguageItem}
                >
                  {i18n.t("settings.languageName", { locale: "es" })}
                </MenuItem>
                <MenuItem
                  data-testid="settings-language-de"
                  onClick={() => handleLangClose("de")}
                  className={styles.dialogSettingsMenuLanguageItem}
                >
                  {i18n.t("settings.languageName", { locale: "de" })}
                </MenuItem>
                <MenuItem
                  data-testid="settings-language-pl"
                  onClick={() => handleLangClose("pl")}
                  className={styles.dialogSettingsMenuLanguageItem}
                >
                  {i18n.t("settings.languageName", { locale: "pl" })}
                </MenuItem>
                <MenuItem
                  data-testid="settings-language-it"
                  onClick={() => handleLangClose("it")}
                  className={styles.dialogSettingsMenuLanguageItem}
                >
                  {i18n.t("settings.languageName", { locale: "it" })}
                </MenuItem>
                <MenuItem
                  data-testid="settings-language-fr"
                  onClick={() => handleLangClose("fr")}
                  className={styles.dialogSettingsMenuLanguageItem}
                >
                  {i18n.t("settings.languageName", { locale: "fr" })}
                </MenuItem>
              </Menu>
            </>
          ) : (
            <NewsContent />
          )}
        </DialogContent>
      </Dialog>
      {/*@ts-ignore*/}
    </>
  );
}
