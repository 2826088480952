export function filter(array, searchQuery) {
  if (!searchQuery) return array;

  return array.filter((o) =>
    Object.keys(o).some(
      (k) =>
        typeof o[k] === "string" &&
        o[k].toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
}
export function filterEquipos(equipos, searchQuery) {
  if (!searchQuery) return equipos || [];

  return equipos.filter((o) =>
    Object.keys(o).some(
      (k) =>
        (typeof o[k] === "string" &&
          o[k].toLowerCase().includes(searchQuery.toLowerCase())) ||
        (o.zona &&
          o.zona.descripcion.toLowerCase().includes(searchQuery.toLowerCase()))
    )
  );
}

export function filterZonas(zonas, searchQuery) {
  if (!searchQuery) return zonas;
  return zonas.filter((zona) => {
    var planta = zona.planta || {
      denominacion: "Sin planta",
    };
    return (
      Object.keys(zona).some(
        (k) =>
          typeof zona[k] === "string" &&
          zona[k].toLowerCase().includes(searchQuery.toLowerCase())
      ) || planta.denominacion.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
}
