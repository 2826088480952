import React from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Link,
  Typography,
} from "@material-ui/core";
import { Delete, Save, AddShoppingCart, Cancel } from "@material-ui/icons";
import i18n from "../../../locales/i18n";
import FormularioVariador from "../../molecule/formVariador/formVariador";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import Equipo from "../../../models/equipo";
import DriveIcon from "../../atoms/driveIcon";

function CardVariador({
  id,
  keyuser,
  zonas,
  brands,
  readOnly,
  equipo,
  setEquipo,
  getEquipo,
  saveEquipo,
  errors,
  handleInputChange,
  provisionalDialog,
  deleteDialog,
  locale,
  updateEquipo,
  getPNFromStore,
  ofertaDialog,
  restoreDialog,
  goBack,
  setframeChanged,
}) {
  const styles = DetailTabs_Style();
  const restartEquipo = () => {
    if (id) {
      getEquipo();
      setframeChanged(false);
    } else {
      setEquipo(new Equipo());
      if (zonas.length === 1) {
        setEquipo({ zonaId: zonas[0].id });
      }
    }
  };

  return (
    <Card className={styles.variatorCard}>
      <div className={styles.pillContainer}>
        {equipo.zona && equipo.zona.isWarehouse && (
          <div className={styles.flexPill}>
            <span className={styles.pillText}>{i18n.t("zona.warehouse")}</span>
          </div>
        )}

        {equipo.riesgo === 3 &&
          !equipo.zona.isWarehouse &&
          equipo.equipmentStatus !== 1 &&
          equipo.equipmentStatus !== 2 &&
          !equipo.provisional && (
            <div
              className={styles.flexPill}
              style={{ backgroundColor: "#FFAA00" }}
            >
              <span className={styles.pillText}>
                {i18n.t("equipo.undefinedRisk")}
              </span>
            </div>
          )}
        {equipo?.equipoMantenimientos &&
          equipo?.equipoMantenimientos.length !== 0 && (
            <div className={styles.flexPill}>
              <span className={styles.pillText}>
                {i18n.t("common.manteinance")}
              </span>
            </div>
          )}
        {equipo.provisional && (
          <div className={styles.flexWarningPill}>
            <span className={styles.pillProvisionalText}>
              {i18n.t("equipo.provisional")}
            </span>
          </div>
        )}
        {equipo.equipmentStatus === 1 && (
          <div className={styles.flexPill}>
            <span className={styles.pillText}>
              {i18n.t("common.driveStatus.notInOperation")}
            </span>
          </div>
        )}
        {equipo.equipmentStatus === 2 && (
          <div className={styles.flexPill}>
            <span className={styles.pillText}>
              {i18n.t("common.driveStatus.dispossed")}
            </span>
          </div>
        )}
      </div>
      <div className={styles.flexRowForm}>
        <CardHeader
          title={equipo.nombre || i18n.t("common.noName")}
          subheader={
            id
              ? equipo.descripcion || i18n.t("common.noDescription")
              : i18n.t("equipo.nuevo")
          }
          avatar={
            <Avatar className={styles.tabHeaderAvatar} aria-label="avatar">
              <DriveIcon />
            </Avatar>
          }
        />
      </div>
      <div className={styles.flexRowForm}>
        <div className={styles.flexCellForm}>
          {id && (
            <>
              {equipo.id && equipo.sn && equipo.tc && equipo.pn ? (
                <>
                  <Button
                    onClick={() => ofertaDialog.current.open()}
                    className={styles.secondaryFormButton}
                    startIcon={<AddShoppingCart />}
                    variant="outlined"
                  >
                    {i18n.t("equipo.pedirOferta")}
                  </Button>
                  <Link
                    className={styles.variatorDocumentationLink}
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    href={`https://store.danfoss.com/${locale}/p/${equipo.pn}`}
                    onClick={(e) => {
                      if (
                        (equipo.textoOCR &&
                          equipo.textoOCR.includes("KRONES")) ||
                        /^S00[0-9]$/i.test(equipo.tc.substr(23, 4))
                      ) {
                        if (equipo.tc.startsWith("FC-302")) {
                          getPNFromStore();
                        } else {
                          alert(i18n.t("equipo.noDocAvailable"));
                          e.preventDefault();
                        }
                      }
                    }}
                  >
                    <span className={styles.linkPrimaryColor}>
                      {i18n.t("equipo.documentacion")}
                    </span>
                  </Link>
                </>
              ) : (
                <Typography>{i18n.t("equipo.utilidadesWarning")}</Typography>
              )}
            </>
          )}
        </div>
      </div>
      <FormularioVariador
        keyuser={keyuser}
        zonas={zonas}
        brands={brands}
        equipo={equipo}
        setEquipo={setEquipo}
        readOnly={readOnly}
        errors={errors}
        handleInputChange={handleInputChange}
      />
      <div className={styles.flexRowForm}>
        <div className={styles.flexCellForm}>
          {!readOnly && (
            <>
              <Button
                variant="contained"
                className={styles.primaryFormButton}
                aria-label="save"
                onClick={() =>
                  equipo.provisional
                    ? provisionalDialog.current.open()
                    : saveEquipo()
                }
              >
                <Save />
                {i18n.t("common.save")}
              </Button>
              <Button
                variant="outlined"
                aria-label="delete"
                className={styles.secondaryFormButton}
                onClick={() => restartEquipo()}
              >
                <Cancel className={styles.saveIcon} />
                {i18n.t("common.cancel")}
              </Button>
              {id && (
                <>
                  <Button
                    variant="outlined"
                    aria-label="delete"
                    className={styles.secondaryFormButton}
                    onClick={() => deleteDialog.current.open()}
                  >
                    <Delete />
                    {i18n.t("common.delete")}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Card>
  );
}

export default CardVariador;
