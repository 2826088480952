// @ts-nocheck
// eslint-disable-next-line no-unused-vars
import { ReportOptionsModel } from "../helpers";
import React from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { GeneralStyles, InformationStyles } from "../ReportPDFStyles";
import getText from "../helpers/traductions";
import { green, red, yellow } from "../helpers";
import WaterMark from "./WaterMarkPDF";
import Footer from "./FooterPDF";

/**
 * Pagina de información
 * @param {object} props
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {any} props.header Header del report.
 * @param {any} props.waterMark Marca de agua por si algun equipo tiene riesgo indefinido.
 */
function Information({ reportOptions, header, waterMark }) {
  const lang = reportOptions.language || "es";

  return (
    <Page
      style={GeneralStyles.body}
      size={reportOptions.pageSize || "A4"}
      orientation={reportOptions.pageOrientation || "portrait"}
    >
      {header && header}
      <div>
        <View style={InformationStyles.container}>
          <Text style={InformationStyles.title}>
            {getText(lang, "drive.lifecycle")}
          </Text>
          <View style={InformationStyles.row}>
            <View
              style={{ ...InformationStyles.header, backgroundColor: green }}
            >
              <Text>{getText(lang, "analysis.active")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.lifecycle.active")}
            </Text>
          </View>
          <View style={InformationStyles.row}>
            <View
              style={{ ...InformationStyles.header, backgroundColor: yellow }}
            >
              <Text>{getText(lang, "analysis.limited")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.lifecycle.limited")}
            </Text>
          </View>
          <View style={InformationStyles.row}>
            <View style={{ ...InformationStyles.header, backgroundColor: red }}>
              <Text>{getText(lang, "analysis.inactive")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.lifecycle.inactive")}
            </Text>
          </View>
        </View>
        <View style={InformationStyles.container}>
          <Text style={InformationStyles.title}>
            {getText(lang, "drive.criticality")}
          </Text>
          <View style={InformationStyles.row}>
            <View
              style={{ ...InformationStyles.header, backgroundColor: green }}
            >
              <Text>{getText(lang, "analysis.low")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.criticality.low")}
            </Text>
          </View>
          <View style={InformationStyles.row}>
            <View
              style={{ ...InformationStyles.header, backgroundColor: yellow }}
            >
              <Text>{getText(lang, "analysis.medium")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.criticality.medium")}
            </Text>
          </View>
          <View style={InformationStyles.row}>
            <View style={{ ...InformationStyles.header, backgroundColor: red }}>
              <Text>{getText(lang, "analysis.high")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.criticality.high")}
            </Text>
          </View>
        </View>
        <View style={InformationStyles.container}>
          <Text style={InformationStyles.title}>
            {getText(lang, "drive.condition")}
          </Text>
          <View style={InformationStyles.row}>
            <View
              style={{ ...InformationStyles.header, backgroundColor: green }}
            >
              <Text>{getText(lang, "analysis.good")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.condition.good")}
            </Text>
          </View>
          <View style={InformationStyles.row}>
            <View
              style={{ ...InformationStyles.header, backgroundColor: yellow }}
            >
              <Text>{getText(lang, "analysis.medium")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.condition.medium")}
            </Text>
          </View>
          <View style={InformationStyles.row}>
            <View style={{ ...InformationStyles.header, backgroundColor: red }}>
              <Text>{getText(lang, "analysis.poor")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.condition.poor")}
            </Text>
          </View>
        </View>
        <View style={InformationStyles.container}>
          <Text style={InformationStyles.title}>
            {getText(lang, "drive.risk")}
          </Text>
          <View style={InformationStyles.row}>
            <View
              style={{ ...InformationStyles.header, backgroundColor: green }}
            >
              <Text>{getText(lang, "analysis.low")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.risk.low")}
            </Text>
          </View>
          <View style={InformationStyles.row}>
            <View
              style={{ ...InformationStyles.header, backgroundColor: yellow }}
            >
              <Text>{getText(lang, "analysis.medium")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.risk.medium")}
            </Text>
          </View>
          <View style={InformationStyles.row}>
            <View style={{ ...InformationStyles.header, backgroundColor: red }}>
              <Text>{getText(lang, "analysis.high")}</Text>
            </View>
            <Text style={InformationStyles.cell}>
              {getText(lang, "information.risk.high")}
            </Text>
          </View>
        </View>
      </div>
      <Footer lang={lang} />
      <WaterMark text={waterMark} orientation={reportOptions.pageOrientation} />
    </Page>
  );
}

export default Information;
