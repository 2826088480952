import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { EquiposAPI } from "../services/API";
import RelativeBackdrop from "./RelativeBackdrop";
import i18n from "../locales/i18n";
import { DetailTabs_Style, Common_Style } from "../assets/css/Styles";

export default function AssessmentTable(props) {
  const { plantaId } = props;
  const [families, setFamilies] = useState(null);
  const [loading, setLoading] = useState(false);

  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  const getFamilies = useCallback(async () => {
    setLoading(true);
    await EquiposAPI.getAssessmentByPlanta(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setFamilies(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, [plantaId]);

  useEffect(() => {
    getFamilies();
  }, [getFamilies]);

  return (
    <RelativeBackdrop hidden={!loading} absolute>
      <TableContainer component={Paper} className={commonStyles.scrollCard}>
        <Table size="small" className={commonStyles.scrollCardContent}>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={15}
                align="center"
                className={styles.assessmentTableCell_Header}
              >
                <div className={styles.assessmentTableCell_Typography}>
                  {i18n.t("assessment.title")}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                rowSpan={3}
                align="center"
                className={styles.assessmentTableCell_NoColor}
              >
                <span>{i18n.t("maintenance.fields.brand")}</span>
              </TableCell>
              <TableCell
                rowSpan={3}
                align="center"
                className={styles.assessmentTableCell_BorderLeft}
              >
                <span>{i18n.t("assessment.productFamily")}</span>
              </TableCell>
              <TableCell
                rowSpan={3}
                align="center"
                className={styles.assessmentTableCell_BorderLeft}
              >
                <span>{i18n.t("assessment.numAssets")}</span>
              </TableCell>
              <TableCell
                colSpan={12}
                align="center"
                className={styles.assessmentTableCell_BorderLeft}
              >
                <span>{i18n.t("assessment.riskFactors")}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={3}
                align="center"
                className={styles.assessmentTableCell_BorderLeft}
              >
                <span>{i18n.t("assessment.LCS")}</span>
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                className={styles.assessmentTableCell_BorderLeft}
              >
                <span>{i18n.t("assessment.AC")}</span>
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                className={styles.assessmentTableCell_BorderLeft}
              >
                <span>{i18n.t("assessment.ACo")}</span>
              </TableCell>
              <TableCell
                colSpan={3}
                align="center"
                className={styles.assessmentTableCell_BorderLeft}
              >
                <span>{i18n.t("assessment.AR")}</span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_SuccessColor}
              >
                {i18n.t("common.activo.singularM")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_WarningColor}
              >
                {i18n.t("common.limitado")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_ErrorColor}
              >
                {i18n.t("common.inactivo")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_SuccessColor}
              >
                {i18n.t("common.bajo")}
              </TableCell>

              <TableCell
                align="center"
                className={styles.assessmentTableCell_WarningColor}
              >
                {i18n.t("common.medio")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_ErrorColor}
              >
                {i18n.t("common.alto")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_SuccessColor}
              >
                {i18n.t("common.bueno")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_WarningColor}
              >
                {i18n.t("common.medio")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_ErrorColor}
              >
                {i18n.t("common.pobre")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_SuccessColor}
              >
                {i18n.t("common.bajo")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_WarningColor}
              >
                {i18n.t("common.medio")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.assessmentTableCell_ErrorColor}
              >
                {i18n.t("common.alto")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {families !== null && (
              <>
                {families.assessmentList.map((row) => (
                  <TableRow key={`${row.brand?.name}-${row.family}`}>
                    <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                      {row.brand?.name || i18n.t("common.other.singularF")}
                    </TableCell>
                    <TableCell align="center">
                      {row.family !== "other"
                        ? row.family
                        : i18n.t("common.other.singularF")}
                    </TableCell>
                    <TableCell align="center">{row.driveCount}</TableCell>
                    <TableCell
                      align="center"
                      className={
                        styles.assessmentTableCell_BorderLeft_NoPadding
                      }
                    >
                      {row.lifeCycle.active}
                    </TableCell>
                    <TableCell align="center">
                      {row.lifeCycle.limited}
                    </TableCell>
                    <TableCell align="center">
                      {row.lifeCycle.inactive}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        styles.assessmentTableCell_BorderLeft_NoPadding
                      }
                    >
                      {row.criticality.low}
                    </TableCell>
                    <TableCell align="center">
                      {row.criticality.medium}
                    </TableCell>
                    <TableCell align="center">{row.criticality.high}</TableCell>
                    <TableCell
                      align="center"
                      className={
                        styles.assessmentTableCell_BorderLeft_NoPadding
                      }
                    >
                      {row.condition.good}
                    </TableCell>
                    <TableCell align="center">{row.condition.medium}</TableCell>
                    <TableCell align="center">{row.condition.poor}</TableCell>
                    <TableCell
                      align="center"
                      className={
                        styles.assessmentTableCell_BorderLeft_NoPadding
                      }
                    >
                      {row.risk.low}
                    </TableCell>
                    <TableCell align="center">{row.risk.medium}</TableCell>
                    <TableCell align="center">{row.risk.high}</TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </RelativeBackdrop>
  );
}
