import { Avatar, Card, CardContent, CardHeader } from "@material-ui/core";
import {
  InfoOutlined,
  Room,
  HomeWork,
  EventAvailableTwoTone,
} from "@material-ui/icons";
import React from "react";
import withHover from "../../withHover";
import i18n from "../../../locales/i18n";
import { isVacon } from "../../../helpers/Common";
import { CardList_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import DriveIcon from "../../atoms/driveIcon";
const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

export default function EquipoCard(props) {
  const styles = DetailTabs_Style();
  const cardStyles = CardList_Style();

  const { equipo, onClick } = props;

  const classNameIndex = [
    {
      lifeState: cardStyles.driveCardContainerSuscessStatus,
      critRisk: cardStyles.driveCardContainerErrorStatus,
      riskResult: cardStyles.driveCardContainerErrorStatus,
    },
    {
      lifeState: cardStyles.driveCardContainerWarningStatus,
      critRisk: cardStyles.driveCardContainerWarningStatus,
      riskResult: cardStyles.driveCardContainerWarningStatus,
    },
    {
      lifeState: cardStyles.driveCardContainerErrorStatus,
      critRisk: cardStyles.driveCardContainerSuscessStatus,
      riskResult: cardStyles.driveCardContainerSuscessStatus,
    },
  ];

  const getMaxRisk = () => {
    //indefinido

    if (equipo.provisional) {
      return {
        value: 0,
        //pillStyle: styles.flexSuccessPill,
        cardStyle: cardStyles.driveCardSuccesNoBorder,
        //text: i18n.t("planta.listViewHeader.assetRisk"),
      };
    }

    if (equipo.riesgo === 3) {
      return {
        value: 2,
        pillStyle: styles.flexWarningPill,
        cardStyle: cardStyles.driveCardSuccesNoBorder,
        text: i18n.t("equipo.undefinedRisk"),
      };
    }
    //alto
    if (equipo.riesgo === 0) {
      return {
        value: 2,
        pillStyle: styles.flexErrorPill,
        cardStyle: cardStyles.driveCardSuccesNoBorder,
        text: i18n.t("planta.listViewHeader.assetRisk"),
      };
    }

    //medio
    if (equipo.riesgo === 1) {
      return {
        value: 1,
        pillStyle: styles.flexWarningPill,
        cardStyle: cardStyles.driveCardSuccesNoBorder,
        text: i18n.t("planta.listViewHeader.assetRisk"),
      };
    }

    //Bueno
    return {
      value: 0,
      //pillStyle: styles.flexSuccessPill,
      cardStyle: cardStyles.driveCardSuccesNoBorder,
      //text: i18n.t("planta.listViewHeader.assetRisk"),
    };
  };

  return (
    <CardWithHover
      onClick={onClick}
      data-cy="CardInfo"
      className={`${cardStyles.driveCard} ${getMaxRisk().cardStyle}`}
    >
      <div className={styles.pillContainer}>
        <div
          style={{
            display: "flex",
            alignContent: "flex-end",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          {equipo?.equipoMantenimientos &&
          equipo?.equipoMantenimientos.length !== 0 ? (
            <EventAvailableTwoTone style={{ color: "black" }} />
          ) : (
            <EventAvailableTwoTone style={{ color: "transparent" }} />
          )}
        </div>

        <div className={getMaxRisk().pillStyle}>
          <span className={styles.pillText}>{getMaxRisk().text}</span>
        </div>
      </div>

      <CardHeader
        className={
          equipo.provisional ? styles.provisionalColor : styles.headerColor
        }
        classes={{ content: "overflow-hidden" }}
        title={
          <div
            className={cardStyles.driveCardContentText}
            style={{ maxWidth: "110px" }}
          >
            {equipo.nombre || i18n.t("common.noName")}
          </div>
        }
        subheader={
          <div>
            {equipo.equipmentStatus === 1 ? (
              <div>
                {i18n.t("common.driveStatus.notInOperation")}{" "}
                <div className={cardStyles.driveCardContainerErrorStatus} />
              </div>
            ) : equipo.equipmentStatus === 2 ? (
              <div>
                {i18n.t("common.driveStatus.dispossed")}{" "}
                <div className={cardStyles.driveCardContainerErrorStatus} />
              </div>
            ) : equipo.provisional ? (
              <div>
                {i18n.t("common.provisional")}{" "}
                <div className={cardStyles.driveCardContainerWarningStatus} />{" "}
              </div>
            ) : (
              <div>
                {i18n.t("common.driveStatus.inOperation")}{" "}
                <div className={cardStyles.driveCardContainerSuscessStatus} />
              </div>
            )}
          </div>
        }
        avatar={
          <Avatar aria-label="avatar" className={styles.tabHeaderAvatar}>
            <DriveIcon />
          </Avatar>
        }
      />
      <CardContent
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className={cardStyles.driveCardIconsContainer}>
          <div className={cardStyles.driveCardContentText}>
            <InfoOutlined className={cardStyles.diveCardIcon} />{" "}
            {equipo.descripcion || i18n.t("common.noDescription")}
          </div>
          <div className={cardStyles.driveCardContentText}>
            {equipo.zona.isWarehouse ? (
              <HomeWork className={cardStyles.diveCardIcon} />
            ) : (
              <Room className={cardStyles.diveCardIcon} />
            )}{" "}
            {equipo.zona.descripcion || "Sin zona asignada"}
          </div>
        </div>

        <div className={cardStyles.driveCardFParagraphContainer}>
          <div className={cardStyles.driveCardContentText}>
            <div>
              {i18n.t("planta.listViewHeader.lifeCycle")}{" "}
              <div
                className={
                  classNameIndex[equipo.cicloVida]?.lifeState ||
                  cardStyles.driveCardContainerNoStatus
                }
              />
            </div>
          </div>
          <div className={cardStyles.driveCardContentText}>
            <div>
              {i18n.t("planta.listViewHeader.assetCondition")}{" "}
              <div
                className={
                  classNameIndex[equipo.estado]?.lifeState ||
                  cardStyles.driveCardContainerNoStatus
                }
              />
            </div>
          </div>
          <div className={cardStyles.driveCardContentText}>
            <div>
              {i18n.t("planta.listViewHeader.assetCriticality")}{" "}
              <div
                className={
                  classNameIndex[equipo.criticidad]?.critRisk ||
                  cardStyles.driveCardContainerNoStatus
                }
              />
            </div>
          </div>
          <div className={cardStyles.driveCardContentText}>
            <div>
              {i18n.t("planta.listViewHeader.assetRisk")}{" "}
              <div
                className={
                  classNameIndex[equipo.riesgo]?.riskResult ||
                  cardStyles.driveCardContainerNoStatus
                }
              />
            </div>
          </div>
        </div>

        <div className={cardStyles.driveCardLParagraphContainer}>
          <div className={cardStyles.driveCardContentText}>
            <span className={cardStyles.driveCardContentSpan}>Tag:</span>{" "}
            {equipo.idCliente || i18n.t("common.noId")}
          </div>
          <div className={cardStyles.driveCardContentText}>
            <span className={cardStyles.driveCardContentSpan}>SN:</span>{" "}
            {equipo.sn}
          </div>
          <div className={cardStyles.driveCardContentText}>
            <span className={cardStyles.driveCardContentSpan}>
              {isVacon(equipo.brand) ? "TYPE:" : "TC:"}
            </span>{" "}
            {equipo.tc}
          </div>
        </div>
      </CardContent>
    </CardWithHover>
  );
}
