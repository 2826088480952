import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(pn = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/${pn}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getVacon(type = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/vacon/${type}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getPNFromStore(tc) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/store/${tc}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getMissing() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/missing`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getMissingVacon() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/vacon`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });

}

export function syncType(id){
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/sync/syncTypes/${id}`, {
    method: "POST",
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function add(familia) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(familia),
  });
}

export function uploadExcel(formData) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/excel`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  });
}

export function downloadMissingExcel() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/missing/export`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function syncVLT() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/syncVLT`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function syncVacon(){
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/syncVacon`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function syncStore() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/sync/store`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function update(familia) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/${familia.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(familia),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/pntcs/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}
