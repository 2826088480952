import React, { useState } from "react";
import { CardList_Style, DetailTabs_Style } from "../../../assets/css/Styles";
import {
  Card,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import i18n from "../../../locales/i18n";
import { ArrowDownward, ArrowUpward, SwapVert } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

export default function DriveListHeader(props) {
  const styles = DetailTabs_Style();
  const cardStyles = CardList_Style();
  const [openSelector, setOpenSelector] = useState("");
  const [someCheck, setSomeCheck] = useState(false);
  const {
    driveFilters,
    setDriveFilters,
    defaultFilters,
    handleCheckAllDrives,
    handleFilterChange,
    checkAllDrive,
    headerRef,
    sortValue,
    setSortValue,
  } = props;

  const handleSelectorClose = () => {
    if (someCheck) {
      setSomeCheck(false);
      handleFilterChange(driveFilters);
    }
    setOpenSelector("");
  };

  const handleSorting = (sortColumn, sortDirection) => {
    setSortValue(sortColumn + sortDirection);
    setDriveFilters({
      ...driveFilters,
      sorting: {
        sortColumn: sortColumn,
        sortDirection: sortDirection,
      },
    });
    setSomeCheck(true);
  };

  const handleChange = (type, id, name) => {
    // Deep copy of filters to prevent strange things when working with it
    var activeFilters = JSON.parse(JSON.stringify(driveFilters));

    // Marca
    if (type === "brand") {
      //buscar atributo en el array
      const index = activeFilters.brands.findIndex((b) => b.id === id);
      if (index === -1) {
        // Atributo no presente, añadirlo
        activeFilters.brands.push(
          defaultFilters.brands.find((b) => b.id === id)
        );
      } else {
        // Atributo encontrado, eliminarlo
        activeFilters.brands.splice(index, 1);
      }
    }

    //Modelos
    if (type === "model") {
      //buscar atributo en el array
      const index = activeFilters.models.findIndex((b) => b.id === id);
      if (index === -1) {
        // Atributo no presente, añadirlo
        activeFilters.models.push(
          defaultFilters.models.find((b) => b.id === id)
        );
      } else {
        // Atributo encontrado, eliminarlo
        activeFilters.models.splice(index, 1);
      }
    }

    //Zonas
    if (type === "zone") {
      //buscar atributo en el array
      const index = activeFilters.zones.findIndex((z) => z.id === id);
      if (index === -1) {
        // Atributo no presente, añadirlo
        activeFilters.zones.push(defaultFilters.zones.find((z) => z.id === id));
      } else {
        // Atributo encontrado, eliminarlo
        activeFilters.zones.splice(index, 1);
      }
    }

    if (type === "tag") {
      const index = activeFilters.tag.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.tag.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.tag.splice(index, 1);
      }
    }

    // Check recomendation
    if (type === "recomendation") {
      const index = activeFilters.recomendation.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.recomendation.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.recomendation.splice(index, 1);
      }
    }

    // Check retrofit
    if (type === "retrofit") {
      const index = activeFilters.retrofit.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.retrofit.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.retrofit.splice(index, 1);
      }
    }

    // Check serialNumber
    if (type === "serialNumber") {
      const index = activeFilters.serialNumber.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.serialNumber.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.serialNumber.splice(index, 1);
      }
    }

    // Check typeCode
    if (type === "typeCode") {
      const index = activeFilters.typeCode.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.typeCode.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.typeCode.splice(index, 1);
      }
    }

    // Check partNumber
    if (type === "partNumber") {
      const index = activeFilters.partNumber.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.partNumber.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.partNumber.splice(index, 1);
      }
    }

    // Check year
    if (type === "year") {
      const index = activeFilters.yearOfManufacture.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.yearOfManufacture.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.yearOfManufacture.splice(index, 1);
      }
    }

    // Check sizeKw
    if (type === "sizeKw") {
      const index = activeFilters.sizeKw.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.sizeKw.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.sizeKw.splice(index, 1);
      }
    }

    // Check sizeA
    if (type === "sizeA") {
      const index = activeFilters.sizeA.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.sizeA.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.sizeA.splice(index, 1);
      }
    }

    // Check Ip Rating
    if (type === "ip") {
      const index = activeFilters.ipRating.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.ipRating.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.ipRating.splice(index, 1);
      }
    }

    // Check frame
    if (type === "frame") {
      const index = activeFilters.frameSize.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.frameSize.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.frameSize.splice(index, 1);
      }
    }

    // Check lifeCycleStatus
    if (type === "lcs") {
      const index = activeFilters.lifeCycleStatus.indexOf(parseInt(id, 10));
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.lifeCycleStatus.push(parseInt(id, 10));
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.lifeCycleStatus.splice(index, 1);
      }
    }

    // Check condition
    if (type === "condition") {
      const index = activeFilters.condition.indexOf(parseInt(id, 10));
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.condition.push(parseInt(id, 10));
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.condition.splice(index, 1);
      }
    }

    // Check criticality
    if (type === "criticality") {
      const index = activeFilters.criticality.indexOf(parseInt(id, 10));
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.criticality.push(parseInt(id, 10));
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.criticality.splice(index, 1);
      }
    }

    // Check risk
    if (type === "risk") {
      const index = activeFilters.risk.indexOf(parseInt(id, 10));
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.risk.push(parseInt(id, 10));
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.risk.splice(index, 1);
      }
    }

    // Check status
    if (type === "status") {
      const index = activeFilters.status.indexOf(id);
      if (index === -1) {
        // El elemento no está presente, agregarlo
        activeFilters.status.push(id);
      } else {
        // El elemento está presente, eliminarlo
        activeFilters.status.splice(index, 1);
      }
    }

    setSomeCheck(true);
    setDriveFilters(activeFilters);
  };

  return (
    <Card className={cardStyles.driveListHeader} ref={headerRef}>
      <div className={cardStyles.driveListCheckBoxContainer}>
        <Checkbox
          className={styles.checkBoxForm}
          color="default"
          checked={checkAllDrive}
          onClick={handleCheckAllDrives}
          disableRipple
        />
      </div>

      <div className={cardStyles.driveListSNContainer}>
        <div className={cardStyles.driveListTextHeaderContainer}>
          {/* Filtro Brand */}
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.brands?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "Brand"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="brands"
              displayEmpty
              readOnly={defaultFilters?.brands?.length === 0}
              open={openSelector === "brands"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("brands")}
            >
              {/* Para mostrar el titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.brand")}
              </MenuItem>
              {defaultFilters?.brands?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="BrandAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Brand", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="BrandDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Brand", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>
                  {/* Check All */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  ></MenuItem>
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.brands?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          brands: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          brands: defaultFilters?.brands,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.brands?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}

              {/* Lista de opciones */}
              {defaultFilters?.brands?.map((brand, indx) => (
                <MenuItem
                  value={brand.id}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("brand", brand.id);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={
                      !driveFilters?.brands?.some((b) => b.id === brand.id)
                    }
                  />
                  <ListItemText primary={brand.name} />
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* Filtro Model */}
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.models?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "Model"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="model"
              displayEmpty
              readOnly={defaultFilters?.models?.length === 0}
              open={openSelector === "models"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("models")}
            >
              {/* Para mostrar el titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.model")}
              </MenuItem>
              {defaultFilters?.models?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="ModelAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Model", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="ModelDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Model", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>
                  {/* Check ALL */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.models?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          models: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          models: defaultFilters?.models,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.models?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.models?.map((model, indx) => (
                <MenuItem
                  value={model.id}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("model", model.id);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={
                      !driveFilters?.models?.some((b) => b.id === model.id)
                    }
                  />
                  <ListItemText primary={model.name} />
                </MenuItem>
              ))}
            </Select>
          </div>
          {/* Filtro Year */}
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-2}
              disableUnderline
              className={
                driveFilters?.yearOfManufacture?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "AñoFabricacion"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="year"
              displayEmpty
              readOnly={defaultFilters?.yearOfManufacture?.length === 0}
              open={openSelector === "yearOfManufacture"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("yearOfManufacture")}
            >
              {/* Para mostrar el titulo */}
              <MenuItem value={-2} hidden>
                {i18n.t("planta.listViewHeader.year")}
              </MenuItem>

              {defaultFilters?.yearOfManufacture?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="AñoFabricacionAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("AñoFabricacion", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="AñoFabricacionDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("AñoFabricacion", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>
                  {/* Check all */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.yearOfManufacture?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          yearOfManufacture: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          yearOfManufacture: defaultFilters?.yearOfManufacture,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.yearOfManufacture?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.yearOfManufacture?.map((year, indx) => (
                <MenuItem
                  value={year}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("year", year);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={
                      !driveFilters?.yearOfManufacture?.some((y) => y === year)
                    }
                  />
                  <ListItemText
                    primary={year !== -1 ? year : i18n.t("common.n/a")}
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        {/* Serial Number */}
        <div className={cardStyles.driveListTextHeaderContainer}>
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.serialNumber?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "SN"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="serialNumber"
              displayEmpty
              readOnly={defaultFilters?.serialNumber?.length === 0}
              open={openSelector === "serialNumber"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("serialNumber")}
            >
              {/* Para mostrar el titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.serialNumber")}
              </MenuItem>

              {defaultFilters?.serialNumber?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="SNAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("SN", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="SNDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("SN", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>

                  {/* Check all */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.serialNumber?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          serialNumber: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          serialNumber: defaultFilters?.serialNumber,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.serialNumber?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.serialNumber?.map((sn, indx) => (
                <MenuItem
                  value={sn}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("serialNumber", sn);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={!driveFilters?.serialNumber?.some((s) => s === sn)}
                  />
                  <ListItemText primary={sn ? sn : i18n.t("common.n/a")} />
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* Filtro PartNumber */}
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.partNumber?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "PN"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="partNumber"
              displayEmpty
              readOnly={defaultFilters?.partNumber?.length === 0}
              open={openSelector === "partNumber"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("partNumber")}
            >
              {/* Para mostrar el titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.partNumber")}
              </MenuItem>

              {defaultFilters?.partNumber?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="PNAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("PN", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="PNDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("PN", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>

                  {/* Check all */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.partNumber?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          partNumber: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          partNumber: defaultFilters?.partNumber,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.partNumber?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.partNumber?.map((pn, indx) => (
                <MenuItem
                  value={pn}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("partNumber", pn);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={!driveFilters?.partNumber?.some((p) => p === pn)}
                  />
                  <ListItemText primary={pn ? pn : i18n.t("common.n/a")} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        {/*Filtro TypeCode */}
        <div className={cardStyles.driveListTextHeaderContainer}>
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.typeCode?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "TC"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              displayEmpty
              readOnly={defaultFilters?.typeCode?.length === 0}
              open={openSelector === "typeCode"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("typeCode")}
            >
              {/* Titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.typeCode")}
              </MenuItem>

              {defaultFilters?.typeCode?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="TCAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("TC", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="TCDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("TC", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>

                  {/* Check all */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.typeCode?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          typeCode: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          typeCode: defaultFilters?.typeCode,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.typeCode?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.typeCode?.map((tc, indx) => (
                <MenuItem
                  value={tc}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("typeCode", tc);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={!driveFilters?.typeCode?.some((t) => t === tc)}
                  />
                  <ListItemText primary={tc ? tc : i18n.t("common.n/a")} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={cardStyles.driveListSizeContainer}>
        {/*Filtro Size KW */}
        <div className={cardStyles.driveListTextHeaderContainer}>
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.sizeKw?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "PowerSize"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="sizeKw"
              displayEmpty
              readOnly={defaultFilters?.sizeKw?.length === 0}
              open={openSelector === "sizeKw"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("sizeKw")}
            >
              {/* Titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.size")}
              </MenuItem>

              {defaultFilters?.sizeKw?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="PowerSizeAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("PowerSize", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="PowerSizeDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("PowerSize", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>

                  {/* Check all */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.sizeKw?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          sizeKw: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          sizeKw: defaultFilters?.sizeKw,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.sizeKw?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.sizeKw?.map((sizeKw, indx) => (
                <MenuItem
                  value={sizeKw}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("sizeKw", sizeKw);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={!driveFilters?.sizeKw?.some((s) => s === sizeKw)}
                  />
                  <ListItemText
                    primary={sizeKw ? sizeKw : i18n.t("common.n/a")}
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        {/* Framesize */}
        <div className={cardStyles.driveListTextHeaderContainer}>
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.frameSize?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "Frame"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="frameSize"
              displayEmpty
              readOnly={defaultFilters?.frameSize?.length === 0}
              open={openSelector === "frameSize"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("frameSize")}
            >
              {/* Titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.frame")}
              </MenuItem>

              {defaultFilters?.frameSize?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="FrameAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Frame", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="FrameDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Frame", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>

                  {/* Check all */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.frameSize?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          frameSize: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          frameSize: defaultFilters?.frameSize,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.frameSize?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.frameSize?.map((frameSize, indx) => (
                <MenuItem
                  value={frameSize}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("frame", frameSize);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={
                      !driveFilters?.frameSize?.some((f) => f === frameSize)
                    }
                  />
                  <ListItemText
                    primary={frameSize ? frameSize : i18n.t("common.n/a")}
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={cardStyles.driveListNameContainer}>
        {/* Filtro Tag */}
        <div className={cardStyles.driveListTextHeaderContainer}>
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.tag?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "IdCliente"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="tag"
              displayEmpty
              readOnly={defaultFilters?.tag?.length === 0}
              open={openSelector === "tag"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("tag")}
            >
              {/* Titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.tag")}
              </MenuItem>

              {defaultFilters?.tag?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="IdClienteAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("IdCliente", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="IdClienteDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("IdCliente", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>

                  {/* Check all */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.tag?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          tag: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          tag: defaultFilters?.tag,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.tag?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.tag?.map((tag, indx) => (
                <MenuItem
                  value={tag}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("tag", tag);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={!driveFilters?.tag?.some((t) => t === tag)}
                  />
                  <ListItemText primary={tag ? tag : i18n.t("common.n/a")} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className={cardStyles.driveListNameContainer}>
        {/* Filtro Zona */}
        <div className={cardStyles.driveListTextHeaderContainer}>
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.zones?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "Zone"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="zones"
              displayEmpty
              readOnly={defaultFilters?.zones?.length === 0}
              open={openSelector === "zone"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("zone")}
            >
              {/* Titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.location")}
              </MenuItem>

              {defaultFilters?.zones?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="ZoneAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Zone", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="ZoneDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Zone", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>

                  {/* Check all */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.zones?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          zones: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          zones: defaultFilters?.zones,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.zones?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.zones?.map((zone, indx) => (
                <MenuItem
                  value={zone.id}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("zone", zone.id);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={
                      !driveFilters?.zones?.some((b) => b.id === zone.id)
                    }
                  />
                  <ListItemText
                    primary={
                      zone.id === 0
                        ? i18n.t("common.showWarehouse")
                        : zone.descripcion
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>

      {/* Estados */}
      {/* Lifecycle */}
      <div className={cardStyles.driveListStatusContainer}>
        <div className={cardStyles.driveListTextHeaderContainer}>
          <div className={cardStyles.driveListClickableHeaderContainer}>
            <Select
              required
              value={-1}
              disableUnderline
              className={
                driveFilters?.lifeCycleStatus?.length > 0 ||
                driveFilters?.sorting?.sortColumn === "CicloVida"
                  ? cardStyles.driveListSelectorHeaderActive
                  : cardStyles.driveListSelectorHeader
              }
              MenuProps={{
                getContentAnchorEl: () => null,
                PaperProps: {
                  className: cardStyles.driveListSelectorMenuContainer,
                },
              }}
              name="lifeCycleStatus"
              displayEmpty
              readOnly={defaultFilters?.lifeCycleStatus?.length === 0}
              open={openSelector === "lifeCycleStatus"}
              onClose={handleSelectorClose}
              onOpen={() => setOpenSelector("lifeCycleStatus")}
            >
              {/* Titulo */}
              <MenuItem value={-1} hidden>
                {i18n.t("planta.listViewHeader.lifeCycle")}
              </MenuItem>

              {defaultFilters?.lifeCycleStatus?.length > 0 && (
                <div>
                  {/* Sorting */}
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={cardStyles.driveListMenuItemHeader}
                  >
                    <ToggleButtonGroup size="small" value={sortValue} exclusive>
                      <ToggleButton
                        //selected
                        value="CicloVidaAsc"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("CicloVida", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.ascOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowUpward />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value={"IdAsc"}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("Id", "Asc");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.defaultOrder")}
                          placement="top"
                          arrow
                        >
                          <SwapVert />
                        </Tooltip>
                      </ToggleButton>

                      <ToggleButton
                        value="CicloVidaDes"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSorting("CicloVida", "Des");
                        }}
                      >
                        <Tooltip
                          title={i18n.t("common.desOrder")}
                          placement="top"
                          arrow
                        >
                          <ArrowDownward />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </MenuItem>

                  {/* Check all */}
                  <MenuItem
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      if (driveFilters?.lifeCycleStatus?.length !== 0) {
                        setDriveFilters({
                          ...driveFilters,
                          lifeCycleStatus: [],
                        });
                      } else {
                        setDriveFilters({
                          ...driveFilters,
                          lifeCycleStatus: defaultFilters?.lifeCycleStatus,
                        });
                      }
                      setSomeCheck(true);
                    }}
                  >
                    <ListItemText
                      className={styles.checkAllText}
                      primary={
                        driveFilters?.lifeCycleStatus?.length === 0
                          ? i18n.t("dashboard.alertDialog.uncheckAll")
                          : i18n.t("dashboard.alertDialog.checkAll")
                      }
                    />
                  </MenuItem>
                </div>
              )}
              {/* Lista de opciones */}
              {defaultFilters?.lifeCycleStatus?.map((life, indx) => (
                <MenuItem
                  value={life}
                  key={indx}
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    handleChange("lcs", life);
                  }}
                >
                  <Checkbox
                    className={styles.checkBoxForm}
                    color="default"
                    disableRipple
                    checked={
                      !driveFilters?.lifeCycleStatus?.some((l) => l === life)
                    }
                  />
                  <ListItemText
                    primary={
                      life === 0
                        ? i18n.t("common.activo.singularM")
                        : life === 1
                        ? i18n.t("common.limitado")
                        : life === 2
                        ? i18n.t("common.inactivo")
                        : i18n.t("common.indefinido")
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {/*Asset condition */}
      <div className={cardStyles.driveListStatusContainer}>
        <div className={cardStyles.driveListTextHeaderContainer}>
          <Select
            required
            value={-1}
            disableUnderline
            className={
              driveFilters?.condition?.length > 0 ||
              driveFilters?.sorting?.sortColumn === "Estado"
                ? cardStyles.driveListSelectorHeaderActive
                : cardStyles.driveListSelectorHeader
            }
            MenuProps={{
              getContentAnchorEl: () => null,
              PaperProps: {
                className: cardStyles.driveListSelectorMenuContainer,
              },
            }}
            name="condition"
            displayEmpty
            readOnly={defaultFilters?.condition?.length === 0}
            open={openSelector === "condition"}
            onClose={handleSelectorClose}
            onOpen={() => setOpenSelector("condition")}
          >
            {/* Titulo */}
            <MenuItem value={-1} hidden>
              {i18n.t("planta.listViewHeader.assetCondition")}
            </MenuItem>

            {defaultFilters?.condition?.length > 0 && (
              <div>
                {/* Sorting */}
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={cardStyles.driveListMenuItemHeader}
                >
                  <ToggleButtonGroup size="small" value={sortValue} exclusive>
                    <ToggleButton
                      //selected
                      value="EstadoAsc"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Estado", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.ascOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowUpward />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value={"IdAsc"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Id", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.defaultOrder")}
                        placement="top"
                        arrow
                      >
                        <SwapVert />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value="EstadoDes"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Estado", "Des");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.desOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowDownward />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </MenuItem>

                {/* Check all */}
                <MenuItem
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    if (driveFilters?.condition?.length !== 0) {
                      setDriveFilters({
                        ...driveFilters,
                        condition: [],
                      });
                    } else {
                      setDriveFilters({
                        ...driveFilters,
                        condition: defaultFilters?.condition,
                      });
                    }
                    setSomeCheck(true);
                  }}
                >
                  <ListItemText
                    className={styles.checkAllText}
                    primary={
                      driveFilters?.condition?.length === 0
                        ? i18n.t("dashboard.alertDialog.uncheckAll")
                        : i18n.t("dashboard.alertDialog.checkAll")
                    }
                  />
                </MenuItem>
              </div>
            )}
            {/* Lista de opciones */}
            {defaultFilters?.condition?.map((condition, indx) => (
              <MenuItem
                value={condition}
                key={indx}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  handleChange("condition", condition);
                }}
              >
                <Checkbox
                  className={styles.checkBoxForm}
                  color="default"
                  disableRipple
                  checked={
                    !driveFilters?.condition?.some((c) => c === condition)
                  }
                />
                <ListItemText
                  primary={
                    condition === 0
                      ? i18n.t("common.bueno")
                      : condition === 1
                      ? i18n.t("common.medio")
                      : condition === 2
                      ? i18n.t("common.pobre")
                      : i18n.t("common.indefinido")
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      {/* Asset criticality */}
      <div className={cardStyles.driveListStatusContainer}>
        <div className={cardStyles.driveListTextHeaderContainer}>
          <Select
            required
            value={-1}
            disableUnderline
            className={
              driveFilters?.criticality?.length > 0 ||
              driveFilters?.sorting?.sortColumn === "Criticidad"
                ? cardStyles.driveListSelectorHeaderActive
                : cardStyles.driveListSelectorHeader
            }
            MenuProps={{
              getContentAnchorEl: () => null,
              PaperProps: {
                className: cardStyles.driveListSelectorMenuContainer,
              },
            }}
            name="assetCriticality"
            displayEmpty
            readOnly={defaultFilters?.criticality?.length === 0}
            open={openSelector === "assetCriticality"}
            onClose={handleSelectorClose}
            onOpen={() => setOpenSelector("assetCriticality")}
          >
            {/* Titulo */}
            <MenuItem value={-1} hidden>
              {i18n.t("planta.listViewHeader.assetCriticality")}
            </MenuItem>

            {defaultFilters?.criticality?.length > 0 && (
              <div>
                {/* Sorting */}
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={cardStyles.driveListMenuItemHeader}
                >
                  <ToggleButtonGroup size="small" value={sortValue} exclusive>
                    <ToggleButton
                      //selected
                      value="CriticidadAsc"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Criticidad", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.ascOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowUpward />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value={"IdAsc"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Id", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.defaultOrder")}
                        placement="top"
                        arrow
                      >
                        <SwapVert />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value="CriticidadDes"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Criticidad", "Des");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.desOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowDownward />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </MenuItem>

                {/* Check all */}
                <MenuItem
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    if (driveFilters?.criticality?.length !== 0) {
                      setDriveFilters({
                        ...driveFilters,
                        criticality: [],
                      });
                    } else {
                      setDriveFilters({
                        ...driveFilters,
                        criticality: defaultFilters?.criticality,
                      });
                    }
                    setSomeCheck(true);
                  }}
                >
                  <ListItemText
                    className={styles.checkAllText}
                    primary={
                      driveFilters?.criticality?.length === 0
                        ? i18n.t("dashboard.alertDialog.uncheckAll")
                        : i18n.t("dashboard.alertDialog.checkAll")
                    }
                  />
                </MenuItem>
              </div>
            )}
            {/* Lista de opciones */}
            {defaultFilters?.criticality?.map((criticality, indx) => (
              <MenuItem
                value={criticality}
                key={indx}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  handleChange("criticality", criticality);
                }}
              >
                <Checkbox
                  className={styles.checkBoxForm}
                  color="default"
                  disableRipple
                  checked={
                    !driveFilters?.criticality?.some((c) => c === criticality)
                  }
                />
                <ListItemText
                  primary={
                    criticality === 0
                      ? i18n.t("common.alto")
                      : criticality === 1
                      ? i18n.t("common.medio")
                      : criticality === 2
                      ? i18n.t("common.bajo")
                      : i18n.t("common.indefinido")
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      {/* Asset risk */}
      <div className={cardStyles.driveListStatusContainer}>
        <div className={cardStyles.driveListTextHeaderContainer}>
          <Select
            required
            value={-1}
            disableUnderline
            className={
              driveFilters?.risk?.length > 0 ||
              driveFilters?.sorting?.sortColumn === "Riesgo"
                ? cardStyles.driveListSelectorHeaderActive
                : cardStyles.driveListSelectorHeader
            }
            MenuProps={{
              getContentAnchorEl: () => null,
              PaperProps: {
                className: cardStyles.driveListSelectorMenuContainer,
              },
            }}
            name="assetRisk"
            displayEmpty
            readOnly={defaultFilters?.risk?.length === 0}
            open={openSelector === "assetRisk"}
            onClose={handleSelectorClose}
            onOpen={() => setOpenSelector("assetRisk")}
          >
            {/* Titulo */}
            <MenuItem value={-1} hidden>
              {i18n.t("planta.listViewHeader.assetRisk")}
            </MenuItem>

            {defaultFilters?.risk?.length > 0 && (
              <div>
                {/* Sorting */}
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={cardStyles.driveListMenuItemHeader}
                >
                  <ToggleButtonGroup size="small" value={sortValue} exclusive>
                    <ToggleButton
                      //selected
                      value="RiesgoAsc"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Riesgo", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.ascOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowUpward />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value={"IdAsc"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Id", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.defaultOrder")}
                        placement="top"
                        arrow
                      >
                        <SwapVert />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value="RiesgoDes"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Riesgo", "Des");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.desOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowDownward />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </MenuItem>

                {/* Check all */}
                <MenuItem
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    if (driveFilters?.risk?.length !== 0) {
                      setDriveFilters({
                        ...driveFilters,
                        risk: [],
                      });
                    } else {
                      setDriveFilters({
                        ...driveFilters,
                        risk: defaultFilters?.risk,
                      });
                    }
                    setSomeCheck(true);
                  }}
                >
                  <ListItemText
                    className={styles.checkAllText}
                    primary={
                      driveFilters?.risk?.length === 0
                        ? i18n.t("dashboard.alertDialog.uncheckAll")
                        : i18n.t("dashboard.alertDialog.checkAll")
                    }
                  />
                </MenuItem>
              </div>
            )}
            {/* Lista de opciones */}
            {defaultFilters?.risk?.map((risk, indx) => (
              <MenuItem
                value={risk}
                key={indx}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  handleChange("risk", risk);
                }}
              >
                <Checkbox
                  className={styles.checkBoxForm}
                  color="default"
                  disableRipple
                  checked={!driveFilters?.risk?.some((r) => r === risk)}
                />
                <ListItemText
                  primary={
                    risk === 0
                      ? i18n.t("common.alto")
                      : risk === 1
                      ? i18n.t("common.medio")
                      : risk === 2
                      ? i18n.t("common.bajo")
                      : i18n.t("common.indefinido")
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      {/* Status - Active / inactive */}
      <div className={cardStyles.driveListStatusContainer}>
        <div className={cardStyles.driveListTextHeaderContainer}>
          <Select
            required
            value={-1}
            disableUnderline
            className={
              driveFilters?.status?.length > 0 ||
              driveFilters?.sorting?.sortColumn === "EquipmentStatus"
                ? cardStyles.driveListSelectorHeaderActive
                : cardStyles.driveListSelectorHeader
            }
            MenuProps={{
              getContentAnchorEl: () => null,
              PaperProps: {
                className: cardStyles.driveListSelectorMenuContainer,
              },
            }}
            name="assetStatus"
            displayEmpty
            readOnly={defaultFilters?.status?.length === 0}
            open={openSelector === "status"}
            onClose={handleSelectorClose}
            onOpen={() => setOpenSelector("status")}
          >
            {/* Titulo */}
            <MenuItem value={-1} hidden>
              {i18n.t("planta.listViewHeader.assetStatus")}
            </MenuItem>

            {defaultFilters?.status?.length > 0 && (
              <div>
                {/* Sorting */}
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={cardStyles.driveListMenuItemHeader}
                >
                  <ToggleButtonGroup size="small" value={sortValue} exclusive>
                    <ToggleButton
                      //selected
                      value="EquipmentStatusAsc"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("EquipmentStatus", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.ascOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowUpward />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value={"IdAsc"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Id", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.defaultOrder")}
                        placement="top"
                        arrow
                      >
                        <SwapVert />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value="EquipmentStatusDes"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("EquipmentStatus", "Des");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.desOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowDownward />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </MenuItem>

                {/* Check all */}
                <MenuItem
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    if (driveFilters?.status?.length !== 0) {
                      setDriveFilters({
                        ...driveFilters,
                        status: [],
                      });
                    } else {
                      setDriveFilters({
                        ...driveFilters,
                        status: defaultFilters?.status,
                      });
                    }
                    setSomeCheck(true);
                  }}
                >
                  <ListItemText
                    className={styles.checkAllText}
                    primary={
                      driveFilters?.status?.length === 0
                        ? i18n.t("dashboard.alertDialog.uncheckAll")
                        : i18n.t("dashboard.alertDialog.checkAll")
                    }
                  />
                </MenuItem>
              </div>
            )}
            {/* Lista de opciones */}
            {defaultFilters?.status?.map((status, indx) => (
              <MenuItem
                value={status}
                key={indx}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  handleChange("status", status);
                }}
              >
                <Checkbox
                  className={styles.checkBoxForm}
                  color="default"
                  disableRipple
                  checked={!driveFilters?.status?.some((s) => s === status)}
                />
                <ListItemText
                  primary={
                    status === 0
                      ? i18n.t("common.driveStatus.inOperation")
                      : status === 1
                      ? i18n.t("common.driveStatus.notInOperation")
                      : i18n.t("common.driveStatus.dispossed")
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      {/* Recomendation */}
      <div className={cardStyles.driveListRecomendationHeaderContainer}>
        <div className={cardStyles.driveListTextHeaderContainer}>
          <Select
            required
            value={-1}
            disableUnderline
            className={
              driveFilters?.recomendation?.length > 0 ||
              driveFilters?.sorting?.sortColumn === "Recommendation"
                ? cardStyles.driveListSelectorHeaderActive
                : cardStyles.driveListSelectorHeader
            }
            MenuProps={{
              getContentAnchorEl: () => null,
              PaperProps: {
                className: cardStyles.driveListSelectorMenuContainer,
              },
            }}
            name="recomendation"
            displayEmpty
            readOnly={defaultFilters?.recomendation?.length === 0}
            open={openSelector === "recomendation"}
            onClose={handleSelectorClose}
            onOpen={() => setOpenSelector("recomendation")}
          >
            {/* Titulo */}
            <MenuItem value={-1} hidden>
              {i18n.t("planta.listViewHeader.recommendation")}
            </MenuItem>

            {defaultFilters?.recomendation?.length > 0 && (
              <div>
                {/* Sorting */}
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={cardStyles.driveListMenuItemHeader}
                >
                  <ToggleButtonGroup size="small" value={sortValue} exclusive>
                    <ToggleButton
                      //selected
                      value="RecommendationAsc"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Recommendation", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.ascOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowUpward />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value={"IdAsc"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Id", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.defaultOrder")}
                        placement="top"
                        arrow
                      >
                        <SwapVert />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value="RecommendationDes"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Recommendation", "Des");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.desOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowDownward />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </MenuItem>

                {/* Check all */}
                <MenuItem
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    if (driveFilters?.recomendation?.length !== 0) {
                      setDriveFilters({
                        ...driveFilters,
                        recomendation: [],
                      });
                    } else {
                      setDriveFilters({
                        ...driveFilters,
                        recomendation: defaultFilters?.recomendation,
                      });
                    }
                    setSomeCheck(true);
                  }}
                >
                  <ListItemText
                    className={styles.checkAllText}
                    primary={
                      driveFilters?.recomendation?.length === 0
                        ? i18n.t("dashboard.alertDialog.uncheckAll")
                        : i18n.t("dashboard.alertDialog.checkAll")
                    }
                  />
                </MenuItem>
              </div>
            )}
            {/* Lista de opciones */}
            {defaultFilters?.recomendation?.map((recomendation, indx) => (
              <MenuItem
                value={recomendation}
                key={indx}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  handleChange("recomendation", recomendation);
                }}
              >
                <Checkbox
                  className={styles.checkBoxForm}
                  color="default"
                  disableRipple
                  checked={
                    !driveFilters?.recomendation?.some(
                      (r) => r === recomendation
                    )
                  }
                />
                <ListItemText
                  primary={
                    recomendation
                      ? recomendation
                      : i18n.t("common.noRecomendation")
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>

      {/* Retrofit */}
      <div className={cardStyles.driveListRetrofitContainer}>
        <div className={cardStyles.driveListTextHeaderContainer}>
          <Select
            required
            value={-2}
            disableUnderline
            className={
              driveFilters?.retrofit?.length > 0 ||
              driveFilters?.sorting?.sortColumn === "Retrofit"
                ? cardStyles.driveListSelectorHeaderActive
                : cardStyles.driveListSelectorHeader
            }
            MenuProps={{
              getContentAnchorEl: () => null,
              PaperProps: {
                className: cardStyles.driveListSelectorMenuContainer,
              },
            }}
            name="retrofit"
            displayEmpty
            readOnly={defaultFilters?.retrofit?.length === 0}
            open={openSelector === "retrofit"}
            onClose={handleSelectorClose}
            onOpen={() => setOpenSelector("retrofit")}
          >
            {/* Titulo */}
            <MenuItem value={-2} hidden>
              {i18n.t("planta.listViewHeader.retrofitProposal")}
            </MenuItem>

            {defaultFilters?.retrofit?.length > 0 && (
              <div>
                {/* Sorting */}
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={cardStyles.driveListMenuItemHeader}
                >
                  <ToggleButtonGroup size="small" value={sortValue} exclusive>
                    <ToggleButton
                      //selected
                      value="RetrofitAsc"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Retrofit", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.ascOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowUpward />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value={"IdAsc"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Id", "Asc");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.defaultOrder")}
                        placement="top"
                        arrow
                      >
                        <SwapVert />
                      </Tooltip>
                    </ToggleButton>

                    <ToggleButton
                      value="RetrofitDes"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSorting("Retrofit", "Des");
                      }}
                    >
                      <Tooltip
                        title={i18n.t("common.desOrder")}
                        placement="top"
                        arrow
                      >
                        <ArrowDownward />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </MenuItem>

                {/* Check all */}
                <MenuItem
                  onClickCapture={(e) => {
                    e.stopPropagation();
                    if (driveFilters?.retrofit?.length !== 0) {
                      setDriveFilters({
                        ...driveFilters,
                        retrofit: [],
                      });
                    } else {
                      setDriveFilters({
                        ...driveFilters,
                        retrofit: defaultFilters?.retrofit,
                      });
                    }
                    setSomeCheck(true);
                  }}
                >
                  <ListItemText
                    className={styles.checkAllText}
                    primary={
                      driveFilters?.retrofit?.length === 0
                        ? i18n.t("dashboard.alertDialog.uncheckAll")
                        : i18n.t("dashboard.alertDialog.checkAll")
                    }
                  />
                </MenuItem>
              </div>
            )}
            {/* Lista de opciones */}
            {defaultFilters?.retrofit?.map((retrofit, indx) => (
              <MenuItem
                value={retrofit}
                key={indx}
                onClickCapture={(e) => {
                  e.stopPropagation();
                  handleChange("retrofit", retrofit);
                }}
              >
                <Checkbox
                  className={styles.checkBoxForm}
                  color="default"
                  disableRipple
                  checked={!driveFilters?.retrofit?.some((r) => r === retrofit)}
                />
                <ListItemText
                  primary={retrofit ? retrofit : i18n.t("common.n/a")}
                />
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </Card>
  );
}
