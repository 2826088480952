/* eslint-disable no-unused-vars */
import React from "react";
import { Document, Font } from "@react-pdf/renderer";
import SumRecTable from "./reportParts/SumRecTablePDF";
import Cover from "./reportParts/CoverPDF";
import Analysis from "./reportParts/AnalysisPDF";
import Result from "./reportParts/ResultPDF";
import Header from "./reportParts/HeaderPDF";
import PreManTable from "./reportParts/PreManTablePDF";
import getText from "./helpers/traductions";
import Information from "./reportParts/InformationPDF";
import {
  ReportOptionsModel,
  CoverOptionsModel,
  AnalysisOptionsModel,
  RecommendationOptionsModel,
  SummaryOptionsModel,
  PreManOptionsModel,
  EmStockOptionsModel,
  ShowTabsOptionsModel,
} from "./helpers";
import EmStockTable from "./reportParts/EmStockTablePDF";

/**
 * Componente principal del PDF.
 * @Component
 * @param {Object} props
 * @param {number} props.tab Tab seleccionada, 0 se utiliza para imprimir, >= 1 se utiliza para renderizar cada una de las distintas partes del PDF y <= -1 se utiliza para imprimir el PDF cuando se ha superado el número maximo de equipos.
 * @param {ShowTabsOptionsModel} props.showTabsOptions Opciones para seleccionar las pestañas a mostrar y imprimir.
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {CoverOptionsModel} props.coverOptions Opciones de la portada.
 * @param {AnalysisOptionsModel} props.analysisOptions Opciones de la pestaña de análisis.
 * @param {RecommendationOptionsModel} props.recommendationOptions Opciones de la pestaña de recomendaciones.
 * @param {SummaryOptionsModel} props.summaryOptions Opciones de la pestaña de resumen.
 * @param {PreManOptionsModel} props.preManOptions Opciones de la pestaña de mantenimiento preventivo.
 * @param {EmStockOptionsModel} props.emStockOptions Opciones de la pestaña de stock de emergencia.
 * @param {*} props.pictures Array con las imagenes referencias (useRef) de las imagenes para la pestaña de resultados (Result).
 * @param {string} props.partnerLogo Logo del partner.
 * @param {string} props.clientReportLogo Logo del cliente.
 * @param {Object} props.assessment Informacion importante de la tabla estadisticas, flags, etc...
 * @param {Array} props.allDrives Array con todos los drives.
 * @param {Array} props.preManDrives Array con los equipos que tienen mantenimiento preventivo.
 * @param {Array} props.emStock Array con los datos del stocks de emergencia.
 */
function Report({
  tab,
  showTabsOptions,
  reportOptions,
  coverOptions,
  analysisOptions,
  recommendationOptions,
  summaryOptions,
  preManOptions,
  emStockOptions,
  pictures,
  partnerLogo,
  clientReportLogo,
  assessment,
  allDrives,
  preManDrives,
  emStock,
}) {
  const lang = reportOptions.language || "en";

  Font.register({
    family: "MyriadPro",
    fonts: [
      { src: "/fonts/MyriadPro-Regular.woff", fontWeight: "normal" },
      { src: "/fonts/MyriadPro-Semibold.woff", fontWeight: "semibold" },
      { src: "/fonts/MyriadPro-Bold.woff", fontWeight: "bold" },
      { src: "/fonts/MyriadPro-It.woff", fontWeight: "thin" },
      { src: "/fonts/MyriadPro-Light.woff", fontWeight: "light" },
    ],
  });

  const getHeader = (title) => (
    <Header
      reportOptions={reportOptions}
      coverOptions={coverOptions}
      driveNum={assessment.totalDrives}
      title={title}
      partnerLogo={partnerLogo}
    />
  );

  const waterMark = assessment?.draft ? getText(lang, "common.draft") : "";

  return (
    <Document>
      {tab === 1 || tab === 0 || tab === -1 ? (
        <Cover
          reportOptions={reportOptions}
          options={coverOptions}
          driveNum={assessment.totalDrives}
          partnerLogo={partnerLogo}
          waterMark={waterMark}
        />
      ) : null}
      {(tab === 2 || tab === 0 || tab === -1) &&
      showTabsOptions.analysis.value ? (
        <Analysis
          options={analysisOptions}
          reportOptions={reportOptions}
          data={assessment}
          header={getHeader(getText(lang, "titles.analysis"))}
          waterMark={waterMark}
        />
      ) : null}
      {(tab === 3 || tab === 0 || tab === -1) &&
      showTabsOptions.results.value ? (
        <Result
          reportOptions={reportOptions}
          pictures={pictures}
          header={getHeader(getText(lang, "titles.result"))}
          waterMark={waterMark}
        />
      ) : null}
      {(tab === 4 || tab === 0 || tab === -1) &&
      showTabsOptions.information.value ? (
        <Information
          reportOptions={reportOptions}
          header={getHeader(getText(lang, "titles.information"))}
          waterMark={waterMark}
        />
      ) : null}
      {(tab === 5 || tab === 0 || tab === -2) &&
      showTabsOptions.summary.value ? (
        <SumRecTable
          tab={tab}
          options={summaryOptions}
          reportOptions={reportOptions}
          data={allDrives}
          header={getHeader(getText(lang, "titles.summary"))}
          waterMark={waterMark}
        />
      ) : null}
      {(tab === 6 || tab === 0 || tab === -3) &&
      showTabsOptions.recommendation.value ? (
        <SumRecTable
          tab={tab}
          options={recommendationOptions}
          reportOptions={reportOptions}
          data={allDrives}
          header={getHeader(getText(lang, "titles.recommendation"))}
          waterMark={waterMark}
        />
      ) : null}
      {(tab === 7 || tab === 0 || tab === -4) &&
      showTabsOptions.preMan.value ? (
        <PreManTable
          tab={tab}
          options={preManOptions}
          reportOptions={reportOptions}
          data={preManDrives}
          header={getHeader(getText(lang, "titles.preMan"))}
          waterMark={waterMark}
        />
      ) : null}
      {(tab === 8 || tab === 0 || tab === -1) &&
      showTabsOptions.emeSto.value ? (
        <EmStockTable
          tab={tab}
          options={emStockOptions}
          reportOptions={reportOptions}
          data={emStock}
          header={getHeader(getText(lang, "titles.emStock"))}
          waterMark={waterMark}
        />
      ) : null}
    </Document>
  );
}

export default Report;
