import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  FormControl,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { getEquipoFromVisionString } from "../../../helpers/OCR";
import FormularioRiesgoVariador from "../../molecule/formRiesgoVariador/formRiesgoVariador";
import i18n from "../../../locales/i18n";
import moment from "moment";
import {
  Sync,
  FlipCameraIosOutlined,
  ExpandMore,
  Close,
} from "@material-ui/icons";
import { isVacon } from "../../../helpers/Common";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import VaconMaintenance from "../../../helpers/vacon-maintenance-calculation.json";
import VLTMaintenance from "../../../helpers/vlt-maintenance-calculation.json";
import ICMaintenance from "../../../helpers/ic-maintenance-calculation.json";
import { getEquiposDanfossDb } from "../../../services/EquiposAPI";
import AlertDialog from "../../../components/AlertDialog";
import { Autocomplete } from "@material-ui/lab";
import Equipo from "../../../models/equipo";

/**
 * @Conponent
 * @param {Object} props
 * @param {Boolean} props.keyuser
 * @param {Object} props.zonas
 * @param {object} props.brands
 * @param {Object} props.equipo
 * @param {Function} props.setEquipo
 * @param {Boolean} props.readOnly
 * @param {Object} props.errors
 * @param {*} props.handleInputChange
 */

export default function FormularioVariador({
  keyuser,
  zonas,
  brands,
  equipo,
  setEquipo,
  readOnly,
  errors,
  handleInputChange,
}) {
  /**
   * Evita que el formularia haga OnSubmit
   * @param {*} event
   */
  const onSubmit = (event) => {
    event.preventDefault();
  };

  const styles = DetailTabs_Style();
  const ocrNotFoundDialog = useRef(null);
  const autocompleteConfirmDialog = useRef(null);
  const [autocompleteTmpEquipo, setAutocompleteTmpEquipo] = useState(
    new Equipo()
  );
  const [sizes, setSizes] = useState([]);

  useEffect(() => {
    if (equipo.brand?.isDanfoss) {
      isVacon(equipo.brand)
        ? setSizes(VaconMaintenance.allFrames)
        : equipo.brand?.type === 4
        ? setSizes(ICMaintenance.allFrames)
        : setSizes(VLTMaintenance.allFrames);
    } else {
      setSizes([]);
    }
  }, [equipo.brand]);
  /**
   * Manda el equipo al OCR con el boton Reintentar
   */
  const autocompleteEquipo = async (ocr) => {
    var newEq = new Equipo();
    if (ocr === true) {
      newEq = await getEquipoFromVisionString(equipo.textoOCR, equipo);
    } else {
      var brand = equipo.brand?.name;
      if (brand === "IC") {
        if (equipo?.model?.name) {
          equipo?.model?.name?.includes("IC2")
            ? (brand = "IC2")
            : (brand = "IC7");
        } else {
          brand = "IC2";
        }
      }
      await getEquiposDanfossDb(equipo.pn, equipo.sn, brand)
        .then((response) => (response.ok ? response.json() : undefined))
        .then((data) => (newEq = data));
    }

    if (newEq && newEq.sn) {
      setAutocompleteTmpEquipo(newEq);
      autocompleteConfirmDialog.current.open();
    } else if (ocr === false) {
      ocrNotFoundDialog.current.open();
    }
  };

  return (
    <div className={styles.mainCardFormContainer}>
      <div className={styles.formCardContainer}>
        <form autoComplete="off" onSubmit={onSubmit}>
          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={equipo.idCliente || ""}
                label={i18n.t("equipo.idCliente")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="idCliente"
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={equipo.nombre || ""}
                label={i18n.t("equipo.nombre")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="nombre"
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={readOnly}
                  getOptionSelected={(option, value) =>
                    option.value === value.value
                  }
                  options={[
                    {
                      label: i18n.t("common.driveStatus.inOperation"),
                      value: 0,
                    },
                    {
                      label: i18n.t("common.driveStatus.notInOperation"),
                      value: 1,
                    },
                    { label: i18n.t("common.driveStatus.dispossed"), value: 2 },
                  ]}
                  getOptionLabel={(option) => option.label}
                  value={
                    [
                      {
                        label: i18n.t("common.driveStatus.inOperation"),
                        value: 0,
                      },
                      {
                        label: i18n.t("common.driveStatus.notInOperation"),
                        value: 1,
                      },
                      {
                        label: i18n.t("common.driveStatus.dispossed"),
                        value: 2,
                      },
                    ].find(
                      (status) => status.value === equipo.equipmentStatus
                    ) || null
                  }
                  autoComplete={!readOnly}
                  closeIcon={null}
                  openOnFocus={!readOnly}
                  freeSolo={readOnly}
                  open={!readOnly ? undefined : false}
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: {
                        name: "equipmentStatus",
                        value:
                          newValue !== null && newValue !== undefined
                            ? // @ts-ignore
                              newValue?.value
                            : //para poner un valor por defecto
                              0,
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        //para que no se pueda borrar el valor
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: readOnly ? true : undefined,
                      }}
                      name="equipmentStatus"
                      label={i18n.t("equipo.status")}
                      variant="standard"
                      className={styles.materialTextField}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>
          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <FormControl fullWidth>
                <Autocomplete
                  getOptionSelected={(option, value) => option.id === value.id}
                  options={brands || []}
                  getOptionLabel={(option) => option.name || ""}
                  value={equipo.brand || null}
                  autoComplete={!readOnly}
                  closeIcon={readOnly ? null : <Close />}
                  openOnFocus={!readOnly}
                  freeSolo={readOnly}
                  open={!readOnly ? undefined : false}
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: { name: "brandId", value: newValue?.id || "" },
                    });
                    setEquipo((old) => ({ ...old, brand: newValue }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: readOnly,
                      }}
                      InputLabelProps={{
                        shrink: readOnly ? true : undefined,
                      }}
                      name={"brandId"}
                      label={i18n.t("maintenance.fields.brand")}
                      variant="standard"
                      className={styles.materialTextField}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className={styles.flexCellForm}>
              <FormControl fullWidth>
                <Autocomplete
                  getOptionSelected={(option, value) => option.id === value.id}
                  disabled={
                    !(equipo?.brand?.models && equipo.brand.models.length > 0)
                  }
                  options={equipo.brand?.models || []}
                  getOptionLabel={(option) => option.name || ""}
                  value={equipo.model || null}
                  autoComplete={!readOnly}
                  closeIcon={readOnly ? null : <Close />}
                  openOnFocus={!readOnly}
                  freeSolo={readOnly}
                  open={!readOnly ? undefined : false}
                  onChange={(event, newValue) => {
                    handleInputChange({
                      target: { name: "modelId", value: newValue?.id || "" },
                    });
                    setEquipo((old) => ({ ...old, model: newValue }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: readOnly,
                      }}
                      InputLabelProps={{
                        shrink: readOnly ? true : undefined,
                      }}
                      name={"modelId"}
                      label={i18n.t("maintenance.fields.model")}
                      variant="standard"
                      className={styles.materialTextField}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className={styles.flexCellForm}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled={sizes.length === 0}
                  options={sizes}
                  value={equipo.frame || ""}
                  onChange={(e, newValue) => {
                    let fakeEvent = {
                      target: {
                        value: newValue,
                        type: "text",
                        name: "frame",
                      },
                    };
                    handleInputChange(fakeEvent);
                  }}
                  getOptionSelected={(option, value) =>
                    sizes.find((row) => row === value)
                      ? option === value
                      : option ===
                        sizes.filter((s) => s.toLowerCase().includes(value))[0]
                  }
                  autoComplete={!readOnly}
                  closeIcon={readOnly ? null : <Close />}
                  openOnFocus={!readOnly}
                  freeSolo={readOnly}
                  open={!readOnly ? undefined : false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: readOnly,
                      }}
                      InputLabelProps={{
                        shrink: readOnly ? true : undefined,
                      }}
                      name={"frame"}
                      label={i18n.t("maintenance.fields.frameSize")}
                      variant="standard"
                      className={styles.materialTextField}
                    />
                  )}
                />
              </FormControl>
            </div>
          </div>

          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                required
                fullWidth
                value={equipo.sn || ""}
                label={i18n.t("equipo.sn")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="sn"
                error={
                  errors["SN"] !== undefined ||
                  errors["SN_Unique"] !== undefined
                }
                helperText={
                  errors["SN"]
                    ? i18n.t("textField.required")
                    : errors["SN_Unique"]
                    ? i18n.t("textField.unique", { name: "SN" })
                    : null
                }
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={equipo.tc || ""}
                label={
                  isVacon(equipo.brand)
                    ? i18n.t("equipo.vType")
                    : i18n.t("equipo.tc")
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="tc"
                id="tc"
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                required
                fullWidth
                value={equipo.pn || ""}
                label={i18n.t("equipo.pn")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="pn"
                id="pn"
                error={errors["PN"] !== undefined}
                helperText={errors["PN"] ? i18n.t("textField.required") : null}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={equipo.powerSize || ""}
                label={i18n.t("equipo.powerSize")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="powerSize"
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={equipo.voltage || ""}
                label={i18n.t("equipo.voltage")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="voltage"
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={equipo.chassis || ""}
                label={i18n.t("equipo.chassis")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="chassis"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={equipo.in || ""}
                label={i18n.t("equipo.in")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="in"
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={equipo.out || ""}
                label={i18n.t("equipo.out")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="out"
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                type="number"
                value={equipo.tempAmbiental || ""}
                label={i18n.t("equipo.tempAmbMax")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="tempAmbiental"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                type="number"
                value={equipo.añoFabricacion || ""}
                label={i18n.t("equipo.añoFabricacion")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="añoFabricacion"
                error={errors["AñoFabricacion"] !== undefined}
                helperText={
                  errors["AñoFabricacion"] ? i18n.t("textField.required") : null
                }
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                type="number"
                value={equipo.semanaFabricacion || ""}
                label={i18n.t("equipo.semanaFabricacion")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="semanaFabricacion"
                error={errors["SemanaFabricacion"] !== undefined}
                helperText={
                  errors["SemanaFabricacion"]
                    ? i18n.t("textField.required")
                    : null
                }
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.flexCellForm}>
              <Autocomplete
                fullWidth
                getOptionSelected={(option, value) => option.id === value.id}
                options={zonas}
                getOptionLabel={(option) => option.descripcion || ""}
                value={
                  zonas.find(
                    (option) =>
                      option?.id?.toString() === equipo?.zonaId?.toString()
                  ) || null
                }
                autoComplete={!readOnly}
                closeIcon={null}
                openOnFocus={!readOnly}
                freeSolo={readOnly}
                open={!readOnly ? undefined : false}
                onChange={(event, newValue) => {
                  handleInputChange({
                    target: {
                      name: "zonaId",
                      value: newValue?.id || "",
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: readOnly,
                    }}
                    InputLabelProps={{
                      shrink: readOnly ? true : undefined,
                      required: true,
                    }}
                    error={errors["ZonaId"] !== undefined}
                    name="zonaId"
                    label={i18n.t("equipo.zona")}
                    variant="standard"
                    helperText={
                      errors["ZonaId"] ? i18n.t("textField.required") : null
                    }
                    className={styles.materialTextField}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={
                  equipo.appUser
                    ? `${equipo.appUser.nombre} ${equipo.appUser.apellidos}`
                    : ""
                }
                label={i18n.t("equipo.appuser")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={
                  equipo.fechaCreacion
                    ? moment(equipo.fechaCreacion)
                        .local()
                        .format("DD/MM/yyyy HH:mm")
                    : ""
                }
                label={i18n.t("equipo.fecha")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </div>
          </div>
          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                value={equipo.descripcion || ""}
                label={i18n.t("equipo.descripcion")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="descripcion"
                error={errors["Descripcion"] !== undefined}
                helperText={
                  errors["Descripcion"] ? i18n.t("textField.required") : null
                }
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              <TextField
                className={styles.materialTextField}
                fullWidth
                multiline
                value={equipo.observaciones || ""}
                label={i18n.t("equipo.observacion")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                name="observaciones"
                onChange={handleInputChange}
              />
            </div>
          </div>
          {keyuser && equipo.textoOCR && (
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <Accordion className={styles.accordionForm}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <div>OCR</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.ocrContainerForm}>
                      {equipo.textoOCR || ""}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          )}
          <div className={styles.flexRowForm}>
            <div className={styles.flexCellForm}>
              {keyuser && equipo.textoOCR && !readOnly && (
                <Button
                  variant="outlined"
                  startIcon={<FlipCameraIosOutlined />}
                  onClick={() => autocompleteEquipo(true)}
                  className={styles.secondaryFormButton}
                >
                  {i18n.t("equipo.reOcr")}
                </Button>
              )}
              {!readOnly && (
                <Button
                  onClick={() => autocompleteEquipo(false)}
                  variant="outlined"
                  className={styles.secondaryFormButton}
                  startIcon={<Sync />}
                >
                  {i18n.t("equipo.requestInfo")}
                </Button>
              )}
            </div>
          </div>

          <FormularioRiesgoVariador
            equipo={equipo}
            handleInputChange={handleInputChange}
            readOnly={readOnly}
          />
        </form>
      </div>
      {/* @ts-ignore */}
      <AlertDialog
        ref={ocrNotFoundDialog}
        title={i18n.t("equipo.ocrAlert.noDataTitle")}
        text={i18n.t("equipo.ocrAlert.noDataDescription")}
        confirmText={i18n.t("common.cerrar")}
        confirmAction={() => ocrNotFoundDialog.current.close()}
      />
      {/* @ts-ignore */}
      <AlertDialog
        ref={autocompleteConfirmDialog}
        title={i18n.t("equipo.ocrAlert.title")}
        redTitle={true}
        text={
          <ul style={{ minWidth: 400 }}>
            <li>
              {i18n.t("equipo.pn")}: {autocompleteTmpEquipo.pn}
            </li>
            <li>
              {i18n.t("equipo.sn")}: {autocompleteTmpEquipo.sn}
            </li>
            <li>
              {isVacon(autocompleteTmpEquipo.brand)
                ? i18n.t("equipo.vType")
                : i18n.t("equipo.tc")}
              : {autocompleteTmpEquipo.tc || autocompleteTmpEquipo.pn}
            </li>
            <li>
              {i18n.t("maintenance.fields.brand")}:{" "}
              {autocompleteTmpEquipo.brand?.name ||
                i18n.t("equipo.ocrAlert.noData")}
            </li>
            <li>
              {i18n.t("maintenance.fields.model")}:{" "}
              {autocompleteTmpEquipo.model?.name ||
                i18n.t("equipo.ocrAlert.noData")}
            </li>
            <li>
              {i18n.t("equipo.semanaFabricacion")}:{" "}
              {autocompleteTmpEquipo.semanaFabricacion ||
                i18n.t("equipo.ocrAlert.noData")}
            </li>
            <li>
              {i18n.t("equipo.añoFabricacion")}:{" "}
              {autocompleteTmpEquipo.añoFabricacion ||
                i18n.t("equipo.ocrAlert.noData")}
            </li>
            {autocompleteTmpEquipo.in && (
              <li>
                {i18n.t("equipo.in")}:{" "}
                {autocompleteTmpEquipo.in || i18n.t("equipo.ocrAlert.noData")}
              </li>
            )}
            {autocompleteTmpEquipo.out && (
              <li>
                {i18n.t("equipo.out")}:{" "}
                {autocompleteTmpEquipo.out || i18n.t("equipo.ocrAlert.noData")}
              </li>
            )}
            {autocompleteTmpEquipo.tempAmbiental && (
              <li>
                {i18n.t("equipo.tempAmbMax")}:{" "}
                {autocompleteTmpEquipo.tempAmbiental ||
                  i18n.t("equipo.ocrAlert.noData")}
              </li>
            )}
            {autocompleteTmpEquipo.frame && (
              <li>
                {i18n.t("equipo.frame")}:{" "}
                {autocompleteTmpEquipo.frame ||
                  i18n.t("equipo.ocrAlert.noData")}
              </li>
            )}
            <li>
              {i18n.t("equipo.chassis")}:{" "}
              {autocompleteTmpEquipo.chassis ||
                i18n.t("equipo.ocrAlert.noData")}
            </li>
            <li>
              {i18n.t("equipo.powerSize")}:{" "}
              {autocompleteTmpEquipo.powerSize ||
                i18n.t("equipo.ocrAlert.noData")}
            </li>
            <li>
              {i18n.t("equipo.voltage")}:{" "}
              {autocompleteTmpEquipo.voltage ||
                i18n.t("equipo.ocrAlert.noData")}
            </li>
          </ul>
        }
        cancelText={i18n.t("equipo.ocrAlert.cancel")}
        confirmText={i18n.t("equipo.ocrAlert.ok")}
        confirmAction={() => {
          setEquipo((equipo) => ({
            ...equipo,
            tc: autocompleteTmpEquipo.tc
              ? autocompleteTmpEquipo.tc
              : autocompleteTmpEquipo.pn
              ? autocompleteTmpEquipo.pn
              : equipo.tc,
            pn: autocompleteTmpEquipo.pn ? autocompleteTmpEquipo.pn : equipo.pn,
            sn: autocompleteTmpEquipo.sn ? autocompleteTmpEquipo.sn : equipo.sn,
            in: autocompleteTmpEquipo.in ? autocompleteTmpEquipo.in : equipo.in,
            out: autocompleteTmpEquipo.out
              ? autocompleteTmpEquipo.out
              : equipo.out,
            tempAmbiental: autocompleteTmpEquipo.tempAmbiental
              ? autocompleteTmpEquipo.tempAmbiental
              : equipo.tempAmbiental,
            chassis: autocompleteTmpEquipo.chassis
              ? autocompleteTmpEquipo.chassis
              : equipo.chassis,
            semanaFabricacion: autocompleteTmpEquipo.semanaFabricacion
              ? autocompleteTmpEquipo.semanaFabricacion
              : equipo.semanaFabricacion,
            añoFabricacion: autocompleteTmpEquipo.añoFabricacion
              ? autocompleteTmpEquipo.añoFabricacion
              : equipo.añoFabricacion,
            brandId: autocompleteTmpEquipo.brandId
              ? autocompleteTmpEquipo.brandId
              : equipo.brandId,
            brand: autocompleteTmpEquipo.brand
              ? autocompleteTmpEquipo.brand
              : equipo.brand,
            model: autocompleteTmpEquipo.model
              ? autocompleteTmpEquipo.model
              : equipo.model,
            modelId: autocompleteTmpEquipo.modelId
              ? autocompleteTmpEquipo.modelId
              : equipo.modelId,
            powerSize: autocompleteTmpEquipo.powerSize
              ? autocompleteTmpEquipo.powerSize
              : equipo.powerSize,
            voltage: autocompleteTmpEquipo.voltage
              ? autocompleteTmpEquipo.voltage
              : equipo.voltage,
            frame: autocompleteTmpEquipo.frame
              ? autocompleteTmpEquipo.frame
              : equipo.frame,
            cicloVida:
              autocompleteTmpEquipo.cicloVida ||
              autocompleteTmpEquipo.cicloVida === 0
                ? autocompleteTmpEquipo.cicloVida
                : equipo.cicloVida,
            recommendation: autocompleteTmpEquipo.recommendation
              ? autocompleteTmpEquipo.recommendation
              : equipo.recommendation,
            cicloVidaDanfossDbLock: autocompleteTmpEquipo.cicloVidaDanfossDbLock
              ? autocompleteTmpEquipo.cicloVidaDanfossDbLock
              : equipo.cicloVidaDanfossDbLock,
          }));
          autocompleteConfirmDialog.current.close();
        }}
      />
    </div>
  );
}
