import React, { useState, useEffect, useContext, useCallback } from "react";

import i18n from "../../../locales/i18n";
import { PlantasAPI, UsersAPI, PartnersAPI } from "../../../services/API";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import {
  TextField,
  CardHeader,
  Avatar,
  MenuItem,
  FormControl,
  FormHelperText,
  Button,
  Card,
} from "@material-ui/core";
import { Save, AssignmentInd } from "@material-ui/icons";
import AuthService from "../../../services/AuthService";
import AppContext from "../../../contexts/AppContext";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import { SnackbarSuccess, SnackbarError, Roles } from "../../../helpers/Common";
import { Autocomplete } from "@material-ui/lab";

export default function UserAdd(props) {
  // @ts-ignore
  const { setGlobal, global } = useContext(AppContext);
  const styles = DetailTabs_Style();

  const myRols = AuthService.getUserInfo().roles;
  const ImOnlyPartner =
    myRols.includes(Roles.Partner) &&
    !myRols.includes(Roles.DSC) &&
    !myRols.includes(Roles.DSC);

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [user, setUser] = useState({
    nombre: "",
    apellidos: "",
    region: "",
    country: {},
    email: "",
    role: "",
    roleAccess: "",
    partnerId: 0,
    plantaId: 0,
    requestId: "",
    dipId: "",
  });

  const [rolesOptions, setRolesOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [plantasOptions, setPlantasOptions] = useState([]);

  const getRolesOptions = useCallback(() => {
    UsersAPI.getRoles()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then(setRolesOptions)
      .catch((e) => console.error(e.message));
  }, []);

  const getPartnerOptions = useCallback(() => {
    PartnersAPI.getPartnersForSelector()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then(setPartnerOptions)
      .catch((e) => console.error(e.message));
  }, []);

  const getPlantasOptions = useCallback(() => {
    PlantasAPI.getPlantasForSelector()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then(setPlantasOptions)
      .catch((e) => console.error(e.message));
  }, []);

  useEffect(() => {
    getRolesOptions();
    getPartnerOptions();
    getPlantasOptions();

    setGlobal((prev) => ({
      ...prev,
      pageTitle: i18n.t("gestorUsuarios.titulo"),
    }));
    document.getElementById("main-content").scrollTop = 0;
  }, [getPartnerOptions, getPlantasOptions, getRolesOptions, setGlobal]);

  useEffect(() => {
    const request = global.userRequest;

    if (request === undefined) {
      props.history.goBack();
    }

    if (request) {
      setUser((prev) => ({
        ...prev,
        nombre: request.name,
        apellidos: request.surname,
        email: request.mail,
        level: request.level,
        country: request.country,
        region: request.region,
        dipId: request.dipId,
        requestId: request.id,
        partnerId: request.partnerId,
      }));

      handleInputChangeLevel({
        target: {
          value:
            request.level === "DSC"
              ? "dsc"
              : request.level === "end customer"
              ? "endcustomer"
              : request.level === "partner"
              ? "partner"
              : "",
        },
      });

      setGlobal((prev) => ({
        ...prev,
        userRequest: null,
      }));
    }
  }, [global.userRequest, props.history, setGlobal]);

  const handleInputChangeLevel = (event) => {
    setUser((prev) => ({ ...prev, role: event.target.value }));
    setLoading(true);

    if (event.target.value === "endcustomer") {
      setUser((prev) => ({ ...prev, roleAccess: "gestor-planta" }));
    }

    if (event.target.value === "partner") {
      setUser((prev) => ({ ...prev, roleAccess: "gestor-partner" }));
    }
    setLoading(false);
  };

  async function handlePostUser() {
    if (!AuthService.hasRoleAdmin() && !AuthService.hasRoleDSC()) {
      setUser((prev) => ({ ...prev, partnerId: AuthService.getPartnerId() }));
    }

    let newFormErrors = {};
    if (user.role === "") {
      newFormErrors.level = i18n.t("textField.required");
    }

    if (!(user.role === "keyuser" || user.role === "dsc")) {
      if (
        (user.role === "partner" || user.role === "endcustomer") &&
        !user.partnerId
      ) {
        newFormErrors.partner = i18n.t("textField.required");
      } else if (user.role === "endcustomer" && !user.plantaId) {
        newFormErrors.planta = i18n.t("textField.required");
      }
    }

    if (Object.entries(newFormErrors).length === 0) {
      setFormErrors({});
      setLoading(true);

      UsersAPI.add(user)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          if (data.status === "error") {
            SnackbarError(setGlobal, data.message);
          } else {
            SnackbarSuccess(setGlobal, data.message);
            props.history.push(`./users`);
            setGlobal((prev) => ({ ...prev, update: true }));
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setFormErrors(newFormErrors);
    }
  }

  return (
    <Card>
      <RelativeBackdrop hidden={!loading}>
        <div className={styles.mainFormContainer}>
          <form autoComplete="off" className={styles.userFormContainerWidth}>
            <div className={styles.flexRowForm}>
              <CardHeader
                title={i18n.t("gestorUsuarios.nuevoUsuario.tituloCard")}
                avatar={
                  <Avatar className={styles.tabHeaderAvatar}>
                    <AssignmentInd />
                  </Avatar>
                }
              ></CardHeader>
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <TextField
                  className={styles.materialTextField}
                  required
                  inputProps={{
                    readOnly: true,
                  }}
                  fullWidth
                  value={user.nombre}
                  label={i18n.t("user.nombre")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="nombre"
                />
              </div>
              <div className={styles.flexCellForm}>
                <TextField
                  className={styles.materialTextField}
                  required
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                  value={user.apellidos}
                  label={i18n.t("user.apellidos")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="apellidos"
                />
              </div>
            </div>
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <TextField
                  className={styles.materialTextField}
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                  value={user.region || undefined}
                  label={i18n.t("user.region")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="region"
                />
              </div>
              <div className={styles.flexCellForm}>
                <TextField
                  className={styles.materialTextField}
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                  value={i18n.t(`country.${user.country.name || "null"}`)}
                  label={i18n.t("user.pais")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="country"
                />
              </div>
            </div>
            <div className={styles.flexCellForm}>
              <div className={styles.flexRowForm}>
                <TextField
                  className={styles.materialTextField}
                  required
                  fullWidth
                  value={user.email}
                  autoComplete="new-email"
                  label={i18n.t("user.email")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    readOnly: true,
                  }}
                  name="email"
                  error={formErrors["email"] !== undefined}
                  helperText={
                    formErrors["email"] !== undefined
                      ? formErrors["email"]
                      : null
                  }
                />
              </div>
            </div>
            <div className={styles.flexCellForm}>
              <div className={styles.flexRowForm}>
                <FormControl
                  fullWidth
                  required
                  error={formErrors["level"] !== undefined}
                >
                  <TextField
                    select
                    label={i18n.t("user.rol")}
                    className={styles.materialTextField}
                    value={user.role}
                    name="role"
                    onChange={handleInputChangeLevel}
                  >
                    {rolesOptions.map((role) => (
                      <MenuItem value={role.name} key={role.name}>
                        {role.normalizedName}
                      </MenuItem>
                    ))}
                  </TextField>
                  {formErrors["level"] !== undefined && (
                    <FormHelperText>{formErrors["level"]}</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>
            {user.role !== "keyuser" && user.role !== "dsc" && (
              <>
                {/* SELECTOR DE PARTNER */}
                <div className={styles.flexCellForm}>
                  <div className={styles.flexRowForm}>
                    <Autocomplete
                      fullWidth
                      groupBy={(row) => i18n.t(`country.${row?.country?.name}`)}
                      options={partnerOptions}
                      getOptionLabel={(row) => row.razonSocial}
                      value={
                        partnerOptions.find(
                          (row) => row.id === user?.partnerId
                        ) || null
                      }
                      className={styles.materialTextField}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={i18n.t("user.partner")}
                          required
                          name="partner"
                          error={formErrors["partner"] !== undefined}
                          helperText={formErrors["partner"]}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: ImOnlyPartner,
                          }}
                        />
                      )}
                      onChange={(e, newVal) =>
                        setUser((old) => ({
                          ...old,
                          partnerId: newVal?.id || null,
                          plantaId:
                            plantasOptions?.find((p) => p.id === old.plantaId)
                              ?.partnerId === newVal?.id
                              ? old?.plantaId
                              : null,
                        }))
                      }
                      disabled={ImOnlyPartner}
                    />
                  </div>
                </div>
                {user.role === "endcustomer" && (
                  <div className={styles.flexCellForm}>
                    <div className={styles.flexRowForm}>
                      {/* SELECTOR DE PLANTA */}
                      <Autocomplete
                        fullWidth
                        groupBy={(row) => row?.partner?.razonSocial}
                        options={
                          user.partnerId
                            ? plantasOptions.filter(
                                (p) => p.partnerId === user.partnerId
                              )
                            : plantasOptions
                        }
                        getOptionLabel={(row) =>
                          `${row?.denominacion} (${row?.cliente?.razonSocial})`
                        }
                        value={
                          plantasOptions.find(
                            (row) => row.id === user?.plantaId
                          ) || null
                        }
                        className={styles.materialTextField}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={i18n.t("user.planta")}
                            required
                            name="planta"
                            error={formErrors["planta"] !== undefined}
                            helperText={formErrors["planta"]}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                        onChange={(e, newVal) =>
                          setUser((prev) => ({
                            ...prev,
                            plantaId: newVal?.id || null,
                            partnerId: newVal?.partnerId || null,
                          }))
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            <div className={styles.flexRowForm}>
              <div className={styles.flexCellForm}>
                <Button
                  variant="contained"
                  className={styles.primaryFormButton}
                  onClick={() => handlePostUser()}
                >
                  <Save className={styles.saveIcon} />
                  {i18n.t("common.save")}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </RelativeBackdrop>
    </Card>
  );
}
