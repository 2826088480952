import { BrandAPI, EquiposAPI } from "../services/API";
import { getEquiposDanfossDb } from "../services/EquiposAPI";

export async function getEquipoFromVisionString(visionString, equipo) {
  visionString = visionString || "";
  visionString = visionString.toUpperCase();

  let OCRParams = {
    typeIndex: 0,
    tcIndex: 0,
    pnIndex: 0,
    snIndex: 0,
    inIndex: 0,
    outIndex: 0,
    tempIndex: 0,
    tempLength: 0,
    typeNextReturnIndex: 0,
    tcNextReturnIndex: 0,
    snNextReturnIndex: 0,
    inNextReturnIndex: 0,
    outNextReturnIndex: 0,
  };

  let ocrEquipo = {
    ...equipo,
    sn: "",
    tc: "",
    pn: "",
    in: "",
    out: "",
    tempAmbiental: "",
    chassis: "",
  };

  visionString = replaceVision(visionString);

  const driveType = getDriveType(visionString);

  OCRParams = inittializeParam(OCRParams, visionString);
  OCRParams = getIndex(OCRParams, visionString);
  OCRParams = getTempIndex(OCRParams, visionString);
  OCRParams = getInIndex(OCRParams, visionString);

  switch (driveType) {
    case "FCM":
      console.log("FCM");
      ocrEquipo = getPnFCM(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getSnFCM(ocrEquipo, visionString);
      ocrEquipo = getTc(OCRParams, ocrEquipo, visionString);
      ocrEquipo.marca = "VLT";
      break;

    case "VLT2000":
      console.log("VLT2000");
      ocrEquipo = getPnVLT2000(ocrEquipo, visionString);
      ocrEquipo = getSnVLT2000(ocrEquipo, visionString);
      ocrEquipo = getTcVLT2000(ocrEquipo);
      ocrEquipo.marca = "VLT";
      break;

    default:
      console.log("default");
      ocrEquipo = getPn(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getTc(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getSnAnyo(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getMarca(ocrEquipo, visionString);
      break;
  }

  let dbEquipo;
  if (ocrEquipo.sn !== "") {
    if (ocrEquipo.marca === "Vacon") {
      dbEquipo = await getFromDanfossVacon(ocrEquipo);
    } else if (ocrEquipo.marca === "IC2" || ocrEquipo.marca === "IC7") {
      dbEquipo = await getFromDanfossIC(ocrEquipo);
    } else {
      dbEquipo = await getFromDanfoss(ocrEquipo);
    }
  }

  if (dbEquipo && dbEquipo.sn) {
    ocrEquipo = setDataOnDrive(dbEquipo, ocrEquipo);
  } else {
    ocrEquipo = await getBrandAPI(ocrEquipo);
  }

  if (ocrEquipo.marca === "Vacon" && !ocrEquipo.pn) {
    ocrEquipo.pn = ocrEquipo.tc;
  }
  if (!ocrEquipo.chassis) {
    ocrEquipo = getChasis(ocrEquipo, visionString);
  }
  ocrEquipo = getIn(OCRParams, ocrEquipo, visionString);
  ocrEquipo = getOut(OCRParams, ocrEquipo, visionString);
  ocrEquipo = getTemp(OCRParams, ocrEquipo, visionString);

  console.log("\nCHECK:");
  console.log(visionString);
  console.log("Marca " + ocrEquipo.marca);
  console.log("TC " + OCRParams.tcIndex + " -> " + ocrEquipo.tc);
  console.log("PN " + OCRParams.pnIndex + " -> " + ocrEquipo.pn);
  console.log("SN " + OCRParams.snIndex + " -> " + ocrEquipo.sn);
  console.log("IN " + OCRParams.inIndex + " -> " + ocrEquipo.in);
  console.log("OUT " + OCRParams.outIndex + " -> " + ocrEquipo.out);
  console.log(
    "Temp: " + OCRParams.tempIndex + " -> " + ocrEquipo.tempAmbiental
  );
  console.log("Chassis: -> " + ocrEquipo.chassis);

  // Type checking
  if (typeof ocrEquipo.sn !== "string") {
    ocrEquipo.sn = null;
  }
  if (typeof ocrEquipo.tc !== "string") {
    ocrEquipo.tc = null;
  }
  if (typeof ocrEquipo.pn !== "string") {
    ocrEquipo.pn = null;
  }
  if (typeof ocrEquipo.in !== "string") {
    ocrEquipo.in = null;
  }
  if (typeof ocrEquipo.out !== "string") {
    ocrEquipo.out = null;
  }
  if (typeof ocrEquipo.tempAmbiental !== "number") {
    ocrEquipo.tempAmbiental = null;
  }
  if (typeof ocrEquipo.chassis !== "string") {
    ocrEquipo.chassis = null;
  }
  if (typeof ocrEquipo.semanaFabricacion !== "number") {
    ocrEquipo.semanaFabricacion = null;
  }
  if (typeof ocrEquipo.añoFabricacion !== "number") {
    ocrEquipo.añoFabricacion = null;
  }
  if (typeof ocrEquipo.marca !== "string") {
    ocrEquipo.marca = null;
  }

  if (ocrEquipo.pn === "") {
    ocrEquipo.pn = "PROVISIONAL";
  }

  if (ocrEquipo.tc === "") {
    ocrEquipo.tc = "PROVISIONAL";
  }

  return ocrEquipo;
}

function inittializeParam(OCRParams, visionString) {
  OCRParams.typeIndex = visionString.search("TYPE:");
  OCRParams.tcIndex = visionString.search("T/C:");
  OCRParams.pnIndex = visionString.search("P/N:");
  OCRParams.snIndex = visionString.search("S/N:");
  OCRParams.inIndex = visionString.search("IN:");
  OCRParams.outIndex = visionString.search("OUT:");
  OCRParams.tempIndex = visionString.search("TAMB MAX.");
  OCRParams.tempLength = "TAMB MAX.".length;
  OCRParams.typeNextReturnIndex = 0;
  OCRParams.tcNextReturnIndex = 0;
  OCRParams.snNextReturnIndex = 0;
  OCRParams.inNextReturnIndex = 0;
  OCRParams.outNextReturnIndex = 0;

  return OCRParams;
}

function replaceVision(visionString) {
  visionString = visionString
    .replace("UCT", "VLT")
    .replace("VCT", "VLT")
    .replace("ULT", "VLT")
    .replace("UIT", "VLT")
    .replace("OLT", "VLT")
    .replace("OIT", "VLT")
    .replace("0LT", "VLT")
    .replace("0IT", "VLT")
    .replace("VIT", "VLT")
    .replace("F0-", "FC-")
    .replace("FO-", "FC-")
    .replace("FC0", "FCD")
    .replace("FCO", "FCD")
    .replace("FCN", "FCM")
    .replace("HCM", "FCM")
    .replace("MCO", "MCD")
    .replace("MC0", "MCD")
    .replace("MOO", "MCD")
    .replace("M00", "MCD")
    .replace("M0O", "MCD")
    .replace("MO0", "MCD")
    .replace("VAC0N", "VACON")
    .replace("VA0CN", "VACON")
    .replace("VA00N", "VACON")
    .replace("VACCN", "VACON")
    .replace("VAGON", "VACON")
    .replace("NX1", "NXI");
  return visionString;
}

function getDriveType(visionString) {
  visionString = visionString.replace(/ /g, "");
  if (visionString.match(/(FCM)([0-9])/g)) {
    return "FCM";
  }
  if (visionString.match(/VLT.*TYPE.*20/gm)) {
    return "VLT2000";
  }
  if (visionString.match(/VLT.*20.*PACK/gm)) {
    return "VLT2000";
  }
}

function getIndex(OCRParams, visionString) {
  OCRParams.typeIndex =
    OCRParams.typeIndex > -1
      ? OCRParams.typeIndex
      : visionString.search("TYPE"); // Type - TC
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("T/C:"); // TC
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("T/C");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("TIC:");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("TIC");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("M/C:");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("M/C");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("MC:");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("MC");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("MIC:");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("MIC");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CNO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CHO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CAO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CMO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CN.");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CN");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("NO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CO");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("P/N:"); // PN
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("P/N");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("PIN:");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("PIN");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("C/N:");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("C/N");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("CIN:");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("CIN");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("CN:");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("S/N:"); // SN
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("S/NO");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("S/N");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("8/N:");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("8/N");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("SIN:");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("SIN");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("SN:");
  OCRParams.inIndex =
    OCRParams.inIndex > -1 ? OCRParams.inIndex : visionString.search("IN"); // IN
  OCRParams.outIndex =
    OCRParams.outIndex > -1 ? OCRParams.outIndex : visionString.search("OUT"); // OUT
  OCRParams.outIndex =
    OCRParams.outIndex > -1 ? OCRParams.outIndex : visionString.search("QUT");

  OCRParams.typeNextReturnIndex = visionString.indexOf(
    "\n",
    OCRParams.typeIndex
  );
  OCRParams.tcNextReturnIndex = visionString.indexOf("\n", OCRParams.tcIndex);
  OCRParams.snNextReturnIndex = visionString.indexOf("\n", OCRParams.snIndex);
  OCRParams.inNextReturnIndex = visionString.indexOf("\n", OCRParams.inIndex);
  OCRParams.outNextReturnIndex = visionString.indexOf("\n", OCRParams.outIndex);

  return OCRParams;
}

function getTempIndex(OCRParams, visionString) {
  if (OCRParams.tempIndex === -1) {
    OCRParams.tempIndex = visionString.search("TAMB MAX");
    OCRParams.tempLength = "TAMB MAX".length;
  }

  if (OCRParams.tempIndex === -1) {
    OCRParams.tempIndex = visionString.search("TAMB");
    OCRParams.tempLength = "TAMB".length;
  }

  if (OCRParams.tempIndex === -1) {
    OCRParams.tempIndex = visionString.search("MAX.");
    OCRParams.tempLength = "MAX.".length;
  }

  return OCRParams;
}

function getInIndex(OCRParams, visionString) {
  const bINConditions = () =>
    OCRParams.inIndex > -1 &&
    (visionString.substr(OCRParams.inIndex - 1, 3) === "UIN" ||
      visionString.substr(OCRParams.inIndex - 1, 3) === "VIN" ||
      OCRParams.inIndex <= OCRParams.tcIndex ||
      OCRParams.inIndex <= OCRParams.snIndex ||
      OCRParams.inIndex <= OCRParams.pnIndex);
  if (!bINConditions) {
    const indexes = [...visionString.matchAll(new RegExp("IN", "gi"))].map(
      (a) => a.index
    );
    if (indexes.length >= 2 && indexes[0] === OCRParams.inIndex) {
      OCRParams.inIndex = indexes[1];
    } else {
      OCRParams.inIndex = -1;
    }
  }
  if (bINConditions) {
    if (
      OCRParams.inIndex > OCRParams.pnIndex &&
      OCRParams.inIndex <= OCRParams.pnIndex + 5
    ) {
      OCRParams.inIndex = visionString.indexOf(
        "IN:",
        OCRParams.inNextReturnIndex
      );
      OCRParams.inIndex =
        OCRParams.inIndex > -1
          ? OCRParams.inIndex
          : visionString.indexOf("IN", OCRParams.pnIndex + 14);
      OCRParams.inNextReturnIndex = visionString.indexOf(
        "\n",
        OCRParams.inIndex
      );
    }

    if (
      OCRParams.inIndex >= OCRParams.snIndex &&
      OCRParams.inIndex <= OCRParams.snIndex + 5
    ) {
      OCRParams.inIndex = visionString.indexOf(
        "IN:",
        OCRParams.inNextReturnIndex
      );
      OCRParams.inIndex =
        OCRParams.inIndex > -1
          ? OCRParams.inIndex
          : visionString.indexOf("IN", OCRParams.snIndex + 16);
      OCRParams.inNextReturnIndex = visionString.indexOf(
        "\n",
        OCRParams.inIndex
      );
    }
  }

  if (
    OCRParams.inIndex > -1 &&
    OCRParams.snNextReturnIndex >= OCRParams.inNextReturnIndex
  ) {
    OCRParams.snNextReturnIndex = visionString.indexOf(
      " ",
      OCRParams.snIndex + 6
    );
  }

  return OCRParams;
}

function getTc(OCRParams, ocrEquipo, visionString) {
  var tcMatch = visionString.match(
    // eslint-disable-next-line no-useless-escape
    /(VLT|VACON|FCM|FC|FC-|MCD|NXP|NXL|FCD|FCP|MCD|MCD5-|MCD6-)([0-9]){3,}([a-zA-Z0-9-\+]+\s)*[a-zA-Z0-9-\+]+/g
  );

  if (tcMatch !== null) {
    var tcNorm = tcMatch[0].includes("\n")
      ? tcMatch[0].split("\n")[0]
      : tcMatch[0];
    ocrEquipo.tc = tcNorm.replace(/O/gi, "0").replace(/ /g, "");
  } else if (OCRParams.tcIndex > -1) {
    ocrEquipo.tc = visionString
      .substring(OCRParams.tcIndex + 4, OCRParams.tcNextReturnIndex)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .replace("VL1", "VLT")
      .trim();
  } else if (OCRParams.typeIndex > -1) {
    ocrEquipo.tc = visionString
      .substring(OCRParams.typeIndex + 4, OCRParams.typeNextReturnIndex)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .replace("VL1", "VLT")
      .trim();
  }

  return ocrEquipo;
}

function getPn(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.pnIndex > -1) {
    ocrEquipo.pn = visionString
      .substr(OCRParams.pnIndex + 4, 10)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .replace("S/N", "")
      .replace("SIN", "")
      .replace("S/", "")
      .replace("SI", "")
      .substr(0, 8)
      .trim();
  } else {
    var partNumber = visionString.match(
      new RegExp("[0-9]{3}[A-Z,a-z]{1}[0-9]{4}")
    );
    if (partNumber == null) {
      partNumber = visionString.match(/^[0-9]{8}(?![0-9])/m);
    }
    if (partNumber != null) {
      ocrEquipo.pn = partNumber[0];
    }
  }

  if (ocrEquipo.pn.length >= 4) {
    if (ocrEquipo.pn[3] === "2" || ocrEquipo.pn[3] === "7") {
      ocrEquipo.pn =
        ocrEquipo.pn.substr(0, 3) + "Z" + ocrEquipo.pn.substr(3 + 1);
    } else if (ocrEquipo.pn[3] === "8") {
      ocrEquipo.pn =
        ocrEquipo.pn.substr(0, 3) + "B" + ocrEquipo.pn.substr(3 + 1);
    } else if (ocrEquipo.pn[3] === "0") {
      ocrEquipo.pn =
        ocrEquipo.pn.substr(0, 3) + "U" + ocrEquipo.pn.substr(3 + 1);
    } else if (ocrEquipo.pn[3] === "6") {
      ocrEquipo.pn =
        ocrEquipo.pn.substr(0, 3) + "G" + ocrEquipo.pn.substr(3 + 1);
    } else if (ocrEquipo.pn[3] === "1") {
      ocrEquipo.pn =
        ocrEquipo.pn.substr(0, 3) + "H" + ocrEquipo.pn.substr(3 + 1);
    }
  }

  return ocrEquipo;
}

function getMarca(ocrEquipo, visionString) {
  if (
    ocrEquipo.tc &&
    (ocrEquipo.tc.includes("FC") || ocrEquipo.tc.includes("VLT"))
  ) {
    ocrEquipo.marca = "VLT";
  }

  if (
    visionString.includes("VACON") ||
    (ocrEquipo.tc && ocrEquipo.tc.includes("NXP"))
  ) {
    ocrEquipo.marca = "Vacon";
  }

  if (ocrEquipo.tc && ocrEquipo.tc.includes("IC2")) {
    ocrEquipo.marca = "IC2";
  }

  if (ocrEquipo.tc && ocrEquipo.tc.includes("IC7")) {
    ocrEquipo.marca = "IC7";
    if (ocrEquipo.sn) {
      ocrEquipo.sn = ocrEquipo.sn.split("-")[0];
    }
  }

  return ocrEquipo;
}

function getSnAnyo(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.snIndex > -1) {
    if (
      visionString
        .substring(OCRParams.snIndex + 4, OCRParams.snNextReturnIndex)
        .includes("\n")
    ) {
      OCRParams.snNextReturnIndex = visionString.indexOf(
        "\n",
        OCRParams.snIndex
      );
    }
    let sn = visionString
      .substring(OCRParams.snIndex + 4, OCRParams.snNextReturnIndex)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .trim();

    if (
      ocrEquipo.tc &&
      (ocrEquipo.tc.startsWith("FC-051") ||
        ocrEquipo.tc.startsWith("FC-102") ||
        ocrEquipo.tc.startsWith("FC-202") ||
        ocrEquipo.tc.startsWith("FC-280") ||
        ocrEquipo.tc.startsWith("FC-301") ||
        ocrEquipo.tc.startsWith("FC-302") ||
        ocrEquipo.tc.startsWith("FCD302") ||
        ocrEquipo.tc.startsWith("VLT")) &&
      (sn[sn.length - 4] === "0" ||
        sn[sn.length - 4] === "6" ||
        sn[sn.length - 4] === "9" ||
        sn[sn.length - 4] === "4")
    ) {
      sn = sn.substr(0, sn.length - 4) + "G" + sn.substr(sn.length - 4 + 1);
    }

    if (visionString.includes("VACON") && sn.startsWith("5")) {
      sn = "S" + sn.substr(1, sn.length);
    }

    ocrEquipo.sn = sn;
  } else {
    var snreg = visionString.match(/^[A-Z,a-z][0-9]{11}/m);
    let sn = "";
    if (snreg === null) {
      snreg = visionString.match(/^[0-9]{11}/m);
      if (snreg !== null) {
        sn = snreg[0];
      }
    } else {
      sn = snreg[0];
    }
    if (visionString.includes("VACON") && sn.startsWith("5")) {
      sn = "S" + sn.substr(1, sn.length);
    }
    ocrEquipo.sn = sn;
  }

  if (ocrEquipo.sn.length === 0) {
    var vsn = "";
    let reg = visionString.match(/^[A-Z,a-z][0-9]{11}/m);
    if (reg === null) {
      reg = visionString.match(/^[A-Z,a-z][0-9]{9}/m);
      if (reg === null) {
        reg = visionString.match(/^[0-9]{11}/m);
        if (reg !== null) {
          vsn = reg[0];
        }
      } else {
        vsn = reg[0];
      }
    } else {
      vsn = reg[0];
    }
    if (visionString.includes("VACON") && vsn.startsWith("5")) {
      vsn = "S" + vsn.substr(1, vsn.length);
    }
    ocrEquipo.sn = vsn;
  }

  return ocrEquipo;
}

function getIn(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.inIndex > -1) {
    ocrEquipo.in = visionString
      .substring(OCRParams.inIndex + 3, OCRParams.inNextReturnIndex)
      .replace(/\n/g, "")
      .replace("UT", "")
      .replace("UT:", "")
      .replace("PUT", "")
      .replace("PUT:", "")
      .replace("RATINGS", "")
      .replace("RATINGS:", "")
      .replace(":", "")
      .replace(": ", "")
      .trim();
  }

  return ocrEquipo;
}

function getOut(OCRParams, ocrEquipo, visionString) {
  console.log("out");
  if (OCRParams.outIndex > -1) {
    ocrEquipo.out = visionString
      .substring(OCRParams.outIndex + 4, OCRParams.outNextReturnIndex)
      .replace(/\n/g, "")
      .replace("UT", "")
      .replace("UT:", "")
      .replace("PUT", "")
      .replace("PUT:", "")
      .replace("RATINGS", "")
      .replace("RATINGS:", "")
      .replace(":", "")
      .replace(": ", "")
      .trim();
  }

  return ocrEquipo;
}

function getTemp(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.tempIndex > -1) {
    ocrEquipo.tempAmbiental = visionString
      .substr(OCRParams.tempIndex + OCRParams.tempLength, 4)
      .replace(/O/gi, "0")
      .replace(/\D/g, "")
      .trim();
  }

  return ocrEquipo;
}

function getChasis(ocrEquipo, visionString) {
  var chassisMatch = visionString
    .replace(/O/gi, "0")
    .match(new RegExp("IP ?[0-9]{2}"));
  ocrEquipo.chassis = chassisMatch ? chassisMatch[0].replace(" ", "") : "";

  return ocrEquipo;
}

const getBrandAPI = async (ocrEquipo) => {
  if (ocrEquipo.marca) {
    await (ocrEquipo.marca === "VLT"
      ? BrandAPI.getVlt()
      : ocrEquipo.marca === "IC2" || ocrEquipo.marca === "IC7"
      ? BrandAPI.getIC()
      : BrandAPI.getVacon()
    )
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          const error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        ocrEquipo.brand = data;
        ocrEquipo.brandId = data.id;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return ocrEquipo;
};

function getPnFCM(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.snIndex > -1) {
    ocrEquipo.pn = visionString
      .substr(OCRParams.snIndex + 4, 10)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .replace("S/N", "")
      .replace("SIN", "")
      .replace("S/", "")
      .replace("SI", "")
      .substr(0, 8)
      .trim();

    if (ocrEquipo.pn.length >= 4) {
      if (ocrEquipo.pn[3] === "2" || ocrEquipo.pn[3] === "7") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "Z" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "8") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "B" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "0") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "U" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "6") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "G" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "1") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "H" + ocrEquipo.pn.substr(3 + 1);
      }
    }
  }

  return ocrEquipo;
}

function getSnFCM(ocrEquipo, visionString) {
  let visionBack = visionString
    .replace(/,/g, "")
    .replace(/\./g, "")
    //Esto no es un Guion normal, es un ascii diferente
    .replace(/–/g, "-")
    .replace(/O/gi, "0")
    .trim();

  visionString = visionBack.replace(/ /g, "");

  let sn = visionString.match(new RegExp(/[0-9]{6}-[0-9]{3,}/gm));
  if (sn == null) {
    sn = visionBack.match(new RegExp(/[0-9]{6} [0-9]{3,}/gm));
  }

  ocrEquipo.sn = sn ? sn[0] : null;

  return ocrEquipo;
}

function getSnVLT2000(ocrEquipo, visionString) {
  let sn = visionString.match(/[0-9]{6}[A-Z,a-z]{1}[0-9]{3}/gm);

  if (sn == null) {
    sn = visionString.match(/[0-9]{6}.[0-9]{3}/gm);
    if (sn) {
      if (sn[0][6] === "6" || sn[0][6] === "9" || sn[0][6] === "0") {
        sn[0] = sn[0].substr(0, 6) + "G" + sn[0].substr(6 + 1);
      }
    }
  }

  ocrEquipo.sn = sn ? sn[0] : "";
  return ocrEquipo;
}

function getPnVLT2000(ocrEquipo, visionString) {
  let pn = visionString.match(/[0-9]{3}[A-Z,a-z]{1}[0-9]{4}/gm);
  ocrEquipo.pn = pn ? pn[0] : "";
  return ocrEquipo;
}

function getTcVLT2000(ocrEquipo) {
  ocrEquipo.tc = ocrEquipo.pn;
  return ocrEquipo;
}

function getFromDanfoss(ocrEquipo) {
  return getEquiposDanfossDb(ocrEquipo.pn, ocrEquipo.sn, undefined)
    .then((response) => (response.ok ? response.json() : undefined))
    .catch(() => undefined);
}

function getFromDanfossVacon(ocrEquipo) {
  return getEquiposDanfossDb(undefined, ocrEquipo.sn, undefined)
    .then((response) => (response.ok ? response.json() : undefined))
    .catch(() => undefined);
}

function getFromDanfossIC(ocrEquipo) {
  return EquiposAPI.getEquiposDanfossDb(
    ocrEquipo.pn,
    ocrEquipo.sn,
    ocrEquipo.marca
  )
    .then((response) => (response.ok ? response.json() : undefined))
    .catch(() => undefined);
}

export const setDataOnDrive = (dbEquipo, ocrEquipo) => {
  const ignoreKeys = [
    "id",
    "fechaCreacion",
    "zona",
    "zonaId",
    "estado",
    "criticidad",
    "inactivo",
    "provisional",
    "riesgo",
  ];
  Object.keys(dbEquipo).map((key) =>
    !ignoreKeys.includes(key) && dbEquipo[key] != undefined
      ? (ocrEquipo[key] = dbEquipo[key])
      : undefined
  );
  return ocrEquipo;
};
