import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getInfiniteUsers(usersRendered = 0, searchQuery = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;
  return fetch(`${baseUrl}/api/userManager/infiniteUsers/${usersRendered}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(searchQuery),
  });
}

export function recoverAccount(email) {
  return fetch(`${baseUrl}/api/userManager/recovery?email=${email}`, {
    method: "POST",
  });
}

export function validateAccountRecovery(token, email) {
  return fetch(
    `${baseUrl}/api/userManager/recovery-validation?token=${token}&email=${email}`,
    {
      method: "POST",
    }
  );
}

export function resetPassword(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}/resetPassword`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function add(requestUser) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestUser),
  });
}

export function update(id, requestUser) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestUser),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function removePartnerAcces(id, partnerId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/partnerAcces/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(partnerId),
  });
}

export function getPlantAccess(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/plantaccess/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function addPlantAccess(id, plantId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/plantaccess/${id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(plantId),
  });
}

export function removePlantAccess(id, plantId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/plantaccess/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(plantId),
  });
}

export function deshabilitarUsuario(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}/disable`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function habilitarUsuario(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}/enable`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getIfUserExist(email) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/getByEmail`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  });
}

export function getUserPartner() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/getUserPartner`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function emailReminder() {
  return fetch(`${baseUrl}/api/userManager/remindertest`, {});
}

export function turnOffReminder(id) {
  return fetch(`${baseUrl}/api/userManager/reminderoff`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(id),
  });
}

export function getRoles(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/roles`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function changeRole(changeRoleRequest) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/changerole`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(changeRoleRequest),
  });
}

export function getExtraData(token, userId) {
  return fetch(
    `https://api.danfoss.com/dip/profile/users/${userId}?include_legal_info=false&user_id_type=EMAIL`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
}
