import React, { useRef, useContext } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import RelativeBackdrop from "../../RelativeBackdrop";
import i18n from "../../../locales/i18n";
import {
  EquiposAPI,
  EquipoStockEmergenciaAPI,
  PNTCsAPI,
} from "../../../services/API";
import CardVariador from "../cardVariador/cardVariador";
import CardImagenesVariador from "../../molecule/cardImgVariador/cardImgVariador";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  isVacon,
  SnackbarSuccess,
  SnackbarError,
  getBase64,
} from "../../../helpers/Common";
import AlertDialog from "../../../components/AlertDialog";
import AppContext from "../../../contexts/AppContext";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import EquipoRetrofitTable from "../../molecule/equipoRetrofitTable/EquipoRetrofitTable";
import EquipoStockEmergenciaTable from "../../molecule/equipoStockEmergenciaTable/EquipoStockEmergenciaTable";
import EquipoRepuestosEnAlmacen from "../../molecule/equipoRepuestosEnAlmacen/EquipoRepuestosEnAlmacen";

function TabVariador({
  id,
  locale,
  readOnly,
  isPremium,
  keyuser,
  zonas,
  brands,
  plantaId,
  equipo,
  setEquipo,
  getEquipo,
  setBrandStored,
  errors,
  setErrors,
  loading,
  setLoading,
  goBack,
  handleInputChange,
  setDirty,
  history,
  setframeChanged,
}) {
  const vacon = isVacon(equipo.brand);

  const [reloadRetrofit, setReloadRetrofit] = useState(false);
  const [stock_identico, setStock_identico] = useState([]);
  const [stock_retrofit, setStock_retrofit] = useState([]);
  const [stock_emergencia, setStock_emergencia] = useState([]);
  const [stockE, setStockE] = useState([]);
  const [loadingSE, setLoadingSE] = useState(false);
  const [images, setImages] = useState([]);
  const [sendingOferta, setSendingOferta] = useState(false);
  const styles = DetailTabs_Style();
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const restoreDialog = useRef(null);
  const provisionalDialog = useRef(null);
  const ofertaDialog = useRef(null);
  const deleteDialog = useRef(null);

  const updateEquipo = async (inactivo, provisional) => {
    setLoading(true);
    setDirty(false);
    return EquiposAPI.update({
      ...equipo,
      inactivo: inactivo === undefined ? equipo.inactivo : inactivo,
      provisional: provisional === undefined ? equipo.provisional : provisional,
    })
      .then(async (response) => {
        if (response.ok) {
          setReloadRetrofit(true);
          setErrors([]);
          setBrandStored(equipo.brand ? true : false);
          setEquipo({
            ...equipo,
            inactivo: inactivo === undefined ? equipo.inactivo : inactivo,
            provisional:
              provisional === undefined ? equipo.provisional : provisional,
          });
          provisionalDialog.current.close();
          SnackbarSuccess(setGlobal, i18n.t("equipo.snackbar.update.success"));
          setframeChanged(false);
          return response.json();
        } else {
          if (response.status === 500) {
            goBack();
            SnackbarError(setGlobal, i18n.t("equipo.snackbar.update.error"));
          } else {
            var json = await response.json();
            SnackbarError(setGlobal, i18n.t("equipo.snackbar.update.error"));
            setErrors(json.errors);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setReloadRetrofit(false);
        setLoading(false);
      });
  };
  const addEquipo = async () => {
    setLoading(true);
    setDirty(false);

    return EquiposAPI.add(equipo)
      .then(async (response) => {
        if (response.ok) {
          setReloadRetrofit(true);
          SnackbarSuccess(setGlobal, i18n.t("equipo.snackbar.new.success"));
          setframeChanged(false);
          return response.json();
        } else {
          var json = await response.json();
          SnackbarError(setGlobal, i18n.t("equipo.snackbar.new.error"));
          setErrors(json.errors);
        }
      })
      .then((equipo) => {
        if (equipo.zonaId) {
          history.replace(
            `/plantas/${plantaId}/zonas/${equipo.zonaId}/equipos/${equipo.id}`
          );
        } else {
          history.replace(`/plantas/${plantaId}/equipos/${equipo.id}`);
        }
      })
      .catch((error) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
        setReloadRetrofit(false);
      });
  };
  const getImages = useCallback(async () => {
    EquiposAPI.getEquipoImages(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setImages(data);
      })
      .catch((error) => {});
  }, [id]);
  const sendOferta = async () => {
    setSendingOferta(true);
    try {
      let response = await EquiposAPI.sendOferta(equipo.id);
      if (response.ok) {
        ofertaDialog.current.close();
        SnackbarSuccess(setGlobal, i18n.t("equipo.ofertaEnviada"));
      } else {
        const error = await response.text();
        throw new Error(error);
      }
    } catch (error) {
      ofertaDialog.current.close();
      SnackbarError(setGlobal, i18n.t("equipo.ofertaError"));
    }

    setSendingOferta(false);
  };
  const deleteEquipo = async () => {
    setLoading(true);
    EquiposAPI.remove(id)
      .then((response) => {
        if (response.ok) {
          SnackbarSuccess(setGlobal, i18n.t("equipo.snackbar.deleted.success"));
          setframeChanged(false);
          return response.json();
        } else {
          if (response.status === 404) {
            goBack();
          }
          SnackbarError(setGlobal, i18n.t("equipo.snackbar.deleted.error"));
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        goBack();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const addImage = async (file) => {
    setLoading(true);
    let base64 = "";
    await getBase64(file)
      .then((result) => {
        base64 = result;
      })
      .catch((err) => {
        console.log(err);
      });
    EquiposAPI.addImages(id, [base64])
      .then((response) => {
        if (response.ok) {
          getImages();
          SnackbarSuccess(
            setGlobal,
            i18n.t("equipo.imagen.snackbar.new.success")
          );
        } else {
          SnackbarError(setGlobal, i18n.t("equipo.imagen.snackbar.new.error"));
          if (response.status === 404 || response.status === 401) {
            goBack();
            throw new Error("Something went wrong on Image");
          }
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };
  const removeImage = async (imageIndex) => {
    setLoading(true);
    await EquiposAPI.removeImage(id, imageIndex)
      .then((response) => {
        if (response.ok) {
          setImages(images.filter((value, index) => index !== imageIndex));
          SnackbarSuccess(
            setGlobal,
            i18n.t("equipo.imagen.snackbar.deleted.success")
          );
        } else {
          if (response.status === 500) {
            goBack();
            SnackbarError(setGlobal, i18n.t("equipo.snackbar.update.error"));
          }
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };
  const saveEquipo = () => {
    if (id) {
      return updateEquipo();
    } else {
      return addEquipo();
    }
  };

  const getPNFromStore = () => {
    setLoading(true);

    var standardTc = equipo.tc.substr(0, 16) + "2XGXXXXSXXXXAXBXCXXXXDX";
    PNTCsAPI.getPNFromStore(standardTc)
      .then((response) => response.json())
      .then((data) => {
        var json = JSON.parse(data);
        var pn = json.products[0].code;
        window.open(`https://store.danfoss.com/${locale}/p/${pn}`, "_blank");
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        alert(i18n.t("equipo.noDocAvailable"));
        setLoading(false);
      });
  };

  const getReplacementEquipment = useCallback(async () => {
    setLoading(true);
    await EquiposAPI.getReplacementEquipment(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        if (data) {
          setStock_identico(data.equiposIdenticos);
          setStock_retrofit(data.equiposRetrofit);
          setStock_emergencia(data.equiposEmergencia);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [id, setLoading]);

  const getStockEmerg = useCallback(async () => {
    setLoadingSE(true);
    await EquipoStockEmergenciaAPI.getByEquipo(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setStockE(data);
      })
      .catch((error) => {
        setLoadingSE(false);
      });
    setLoadingSE(false);
  }, [id]);

  useEffect(() => {
    if (id) {
      getStockEmerg();
      getImages();
      getReplacementEquipment();
    }
  }, [id, getStockEmerg, getImages, getReplacementEquipment]);

  return (
    <>
      <RelativeBackdrop hidden={!loading} inherit={!loading}>
        <CardVariador
          id={id}
          keyuser={keyuser}
          zonas={zonas}
          brands={brands}
          readOnly={readOnly}
          equipo={equipo}
          setEquipo={setEquipo}
          getEquipo={getEquipo}
          saveEquipo={saveEquipo}
          errors={errors}
          handleInputChange={handleInputChange}
          provisionalDialog={provisionalDialog}
          deleteDialog={deleteDialog}
          locale={locale}
          updateEquipo={updateEquipo}
          getPNFromStore={getPNFromStore}
          ofertaDialog={ofertaDialog}
          restoreDialog={restoreDialog}
          goBack={goBack}
          setframeChanged={setframeChanged}
        />
      </RelativeBackdrop>
      {equipo.zona && !equipo.zona.isWarehouse && (
        <>
          <EquipoRetrofitTable
            reloadRetrofit={reloadRetrofit}
            equipo={equipo}
            keyuser={keyuser}
            readOnly={readOnly}
            isPremium={isPremium}
            setEquipo={setEquipo}
            sustituciones={stock_retrofit}
          />
          <EquipoStockEmergenciaTable
            equipoId={id}
            plantaId={plantaId}
            readOnly={readOnly}
            isPremium={isPremium}
            vacon={vacon}
            loading={loadingSE}
            setLoading={setLoadingSE}
            getStock={getStockEmerg}
            stock={stockE}
            setStock={setStockE}
            sustituciones={stock_emergencia}
          />

          {stock_identico.length > 0 && (
            <EquipoRepuestosEnAlmacen
              equipo={equipo}
              sustituciones={stock_identico}
            />
          )}
        </>
      )}

      {id && (
        <RelativeBackdrop hidden={!loading}>
          <CardImagenesVariador
            isPremium={isPremium}
            images={images}
            removeImage={removeImage}
            addImage={addImage}
            loading={loading}
            readOnly={readOnly}
          />
        </RelativeBackdrop>
      )}
      {/* ////Alerts//// */}
      {/*@ts-ignore*/}
      <AlertDialog
        ref={restoreDialog}
        title={i18n.t("equipo.recuperarTitle")}
        text={
          <TextField
            className={styles.materialTextField}
            select
            required
            fullWidth
            value={equipo.zonaId || ""}
            label={i18n.t("equipo.recuperarText")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="zonaId"
            error={errors["ZonaId"] !== undefined}
            helperText={errors["ZonaId"] ? i18n.t("textField.required") : null}
            onChange={handleInputChange}
            style={{ minWidth: 200 }}
          >
            {zonas.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.descripcion}
              </MenuItem>
            ))}
          </TextField>
        }
        cancelText={i18n.t("equipo.alertDialog.cancelText")}
        confirmText={i18n.t("common.guardar")}
        confirmAction={() => updateEquipo(false).then(() => goBack())}
      />
      {/*@ts-ignore*/}
      <AlertDialog
        ref={provisionalDialog}
        loading={loading}
        title={i18n.t("equipo.provisionalTitle")}
        text={i18n.t("equipo.provisionalText")}
        cancelText={i18n.t("common.cancelar")}
        confirmText={i18n.t("common.confirmar")}
        confirmAction={() => updateEquipo(undefined, false)}
      />
      {/*@ts-ignore*/}
      <AlertDialog
        ref={ofertaDialog}
        loading={sendingOferta}
        title={i18n.t("equipo.ofertaTitulo")}
        text={i18n.t("equipo.ofertaTexto")}
        cancelText={i18n.t("equipo.alertDialog.cancelText")}
        confirmText={i18n.t("equipo.ofertaSolicitar")}
        confirmAction={() => sendOferta()}
      />
      {/*@ts-ignore*/}
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("equipo.alertDialog.title")}
        text={i18n.t("equipo.alertDialog.text")}
        cancelText={i18n.t("equipo.alertDialog.cancelText")}
        confirmText={i18n.t("equipo.alertDialog.confirmText")}
        confirmAction={() => deleteEquipo()}
      />
    </>
  );
}

export default TabVariador;
