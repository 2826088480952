import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { Divider, Typography } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import RelativeBackdrop from "./RelativeBackdrop";

export default class AlertDialog extends Component {
  state = {
    open: false,
    item: null,
  };

  getItem = () => {
    return this.state.item;
  };

  open = (item) => {
    this.setState({ open: true, item: item });
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      isWarning,
      title,
      text,
      confirmText,
      confirmAction,
      cancelText,
      style,
      maxWidth,
      fullWidth,
      contentStyle,
      loading,
      extraButton,
      bottomLeft,
      confirmDisabled,
      cancelAction,
    } = this.props;

    return (
      <Dialog
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        style={style}
        open={this.state.open}
        onClose={cancelAction || this.close}
      >
        <DialogTitle
          style={
            this.props.redTitle
              ? { backgroundColor: "#E2000F", color: "white" }
              : this.props.activeTitle
              ? { backgroundColor: "#B6000F", color: "white" }
              : { backgroundColor: "white" }
          }
        >
          {isWarning && (
            <Warning
              style={{ verticalAlign: "text-bottom" }}
              className="mr-2"
            />
          )}
          <Typography
            style={{
              fontFamily: "MyriadPro",
              fontWeight: "bold",
              fontSize: 18,
            }}
          >
            {title}
          </Typography>
        </DialogTitle>
        <Divider />
        <RelativeBackdrop hidden={!loading}>
          <DialogContent className="mt-2" style={contentStyle}>
            {typeof text === "string" ? (
              <DialogContentText
                style={{ fontFamily: "sans-serif", fontSize: 16 }}
              >
                {text}
              </DialogContentText>
            ) : (
              text
            )}
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginLeft: 20,
              marginRight: 20,
              marginBottom: 20,
            }}
          >
            <div>{bottomLeft}</div>
            <div style={{ marginTop: "auto" }}>
              {extraButton}
              {cancelText && (
                <Button
                  onClick={cancelAction || this.close}
                  // color="primary"
                  variant="outlined"
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    color: "#E2000f",
                    borderRadius: 2,
                    borderColor: "#E2000f",
                    height: 40,
                    fontFamily: "MyriadPro",
                    textTransform: "none",
                    paddingLeft: 15,
                    paddingRight: 15,
                    fontWeight: "bold",
                  }}
                >
                  {cancelText}
                </Button>
              )}
              <Button
                disabled={confirmDisabled}
                onClick={confirmAction}
                // color="primary"
                autoFocus
                variant="contained"
                style={{
                  marginLeft: 10,
                  color: "white",
                  backgroundColor: confirmDisabled ? null : "#E2000f",
                  borderRadius: 2,
                  height: 40,
                  fontFamily: "MyriadPro",
                  textTransform: "none",
                  paddingLeft: 20,
                  paddingRight: 20,
                  fontWeight: "bold",
                }}
              >
                {confirmText}
              </Button>
            </div>
          </DialogActions>
        </RelativeBackdrop>
      </Dialog>
    );
  }
}

AlertDialog.propTypes = {
  isWarning: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmDisabled: PropTypes.bool,
  confirmAction: PropTypes.func,
  cancelText: PropTypes.string,
  loading: PropTypes.bool,
  extraButton: PropTypes.node,
};
