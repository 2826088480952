import React from "react";
import { CircularProgress } from "@material-ui/core";

export default function RelativeBackdrop(props) {
  const displayMode = props.hidden ? "none" : "block";
  const position = props.absolute ? "absolute" : "fixed";
  const flexGrow = props.inherit ? "inherit" : undefined;
  const minWidth = props.minWidth || "auto";

  return (
    <div
      style={{
        position: "relative",
        minHeight: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "inherit",
        flexGrow: flexGrow,
        minWidth: minWidth,
      }}
    >
      <CircularProgress
        hidden={props.hidden}
        style={{
          color: "#E2000F",
          position: position,
          zIndex: 11,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
          padding: 0,
        }}
      />
      {props.children}
      <div
        style={{
          display: displayMode,
          position: "absolute",
          top: 0,
          height: "100%",
          zIndex: 10,
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      ></div>
    </div>
  );
}
