import React from "react";
import AuthService from "../services/AuthService";
import { Roles, AccessType, AccessLevel } from "../helpers/Common";
import { Route, Redirect } from "react-router";

/**
 * Component que genera las rutas privadas y limita su acceso.
 * @Component
 */

const PrivateRoute = ({ component: Component, acceso: Acceso, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      /** @type {boolean} */ let AccessRequired = Acceso !== undefined;
      /** @type {boolean} */ let authorized = false;
      /** @type {boolean} */ let isAuthenticated =
        AuthService.isAuthenticated();
      /** @type {boolean} */ let readOnly = true;
      /** @type {boolean} */ let keyuser = false;

      let isDSC = false;
      let isPartner = false;
      let isEndcustomer = false;

      let userInfo = AuthService.getUserInfo();

      if (AuthService.hasRoleDSC()) {
        isDSC = true;
      } else if (AuthService.hasRolePartner()) {
        isPartner = true;
      } else if (AuthService.hasRoleEndCustomer()) {
        isEndcustomer = true;
      }

      if (isAuthenticated && !AccessRequired) {
        authorized = true;
      } else if (isAuthenticated && AccessRequired) {
        //Para que el keyuser se salte los permisos
        if (userInfo.roles.some((r) => r === Roles.Admin)) {
          authorized = true;
          keyuser = true;
          readOnly = false;
        } else {
          // Control de seguridad inicial
          if (
            userInfo.partnerAccesos.length === 0 &&
            userInfo.plantaAccesos.length === 0 &&
            userInfo.zonaAccesos.length === 0 &&
            //Fix para que los usuarios de DSC puedan acceder sin accesos
            !userInfo.roles.includes(Roles.DSC)
          ) {
            authorized = false;
            AuthService.logOut();
            window.location.replace("/unauthorized");
          } else {
            // Compruebo los acceso en función del tipo de acceso que requiera esta ruta.
            // EL nivel de acceso de los componentes se definen en APP
            switch (Acceso.nivel + "") {
              //No hay ningun acceso de SOLO nivel de DSC 09-2024
              case AccessLevel.DSC:
                if (isDSC) {
                  authorized = true;
                  readOnly = false;
                }
                break;
              //Acceso a la lista de clientes / partners
              case AccessLevel.Partner:
                if (Acceso.varId) {
                  let elPartner = userInfo.partnerAccesos.find(
                    (c) =>
                      String(c.partnerId) === props.match.params[Acceso.varId]
                  );
                  if (elPartner !== undefined) {
                    authorized = true;
                    if (elPartner.accessType === AccessType.Manager) {
                      readOnly = false;
                    }
                  }
                } else {
                  if (isDSC) {
                    authorized = true;
                    readOnly = userInfo.isReadOnly || userInfo.isGlobal;
                  } else if (isPartner) {
                    authorized = true;
                    readOnly = false;
                  } else if (isEndcustomer) {
                    authorized = true;
                    readOnly = true;
                  }
                }
                break;
              //Se usa para crear una nueva planta
              case AccessLevel.Client:
                if (isDSC || isPartner) {
                  userInfo.partnerAccesos.forEach((element) => {
                    element.partner.partnerClientes.forEach((access) => {
                      if (
                        access.clienteId.toString() ===
                        props.match.params[Acceso.varId].toString()
                      ) {
                        authorized = true;
                        readOnly = false;
                      }
                    });
                  });
                } else if (isEndcustomer) {
                  authorized = false;
                  readOnly = true;
                } else {
                  //Se usa para crear una nueva planta y para la antigua lista de clientes
                  userInfo.plantaAccesos.forEach((element) => {
                    if (
                      String(element.planta.clienteId) ===
                      props.match.params[Acceso.varId]
                    ) {
                      authorized = true;
                      if (element.accessType === AccessType.Manager) {
                        readOnly = false;
                      }
                    }
                  });
                }
                break;
              //Acceso a detalle planta y Equipo
              case AccessLevel.Plant:
                let laPlanta = userInfo.plantaAccesos.find(
                  (p) => String(p.plantaId) === props.match.params[Acceso.varId]
                );
                if (laPlanta !== undefined) {
                  authorized = true;
                  if (laPlanta.accessType === AccessType.Manager) {
                    readOnly = false;
                  }
                }
                break;
              //Acceso a detalle zona y Detalle equipo de una zona
              case AccessLevel.Zone:
                let laZona = userInfo.zonaAccesos.find(
                  (z) =>
                    String(z.plantaZonaId) === props.match.params[Acceso.varId]
                );
                if (laZona !== undefined) {
                  authorized = true;
                  if (laZona.accessType === AccessType.Manager) {
                    readOnly = false;
                  }
                }
                break;
              //Acceso a Dashboard y Listado de plantas - Lista de usuarios y creacion se controla desde el propio componente
              case AccessLevel.None:
                authorized = true;
                break;
              default:
                break;
            }
          }
        }
      }

      if (authorized) {
        return <Component {...props} readOnly={readOnly} keyuser={keyuser} />;
      } else {
        return (
          <Redirect
            to={{
              pathname:
                isAuthenticated && AccessRequired ? "/unauthorized" : "/login",
              search: props.location.search,
              state: { referrer: props.location.pathname },
            }}
          />
        );
      }
    }}
  />
);

export default PrivateRoute;
