import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function getByPlanta(plantaId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaStockEmergencia/planta/${plantaId}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getExport(plantaId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaStockEmergencia/planta/${plantaId}/export`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function add(stock) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaStockEmergencia`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(stock),
  });
}

export function update(stock) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaStockEmergencia/${stock.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(stock),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantaStockEmergencia/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}
