// @ts-ignore
// eslint-disable-next-line no-unused-vars
import { ReportOptionsModel, statCondColors } from "../pdf/helpers";
import React from "react";
import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import getText from "../pdf/helpers/traductions";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale
);

/**
 * Componente para generar las graficas de la pestaña de resultados. (Las graficas solo se muestran en el PDF, tienen display: "none")
 * @Component
 * @param {Object} props
 * @param {ReportOptionsModel} props.reportOptions Opciones del report.
 * @param {Object} props.pictureRefs Objeto con las referencias a cada una de las graficas.
 * @param {Object} props.data Informacion de los equipos de la planta (Assessment).
 */
function ResultGraphs({ reportOptions, pictureRefs, data }) {
  const lang = reportOptions.language || "en";

  const assessment = data.assessmentList;
  const { lifeCycle, criticality, condition, risk, yearCount } = data;

  const pieOptions = {
    animation: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 40,
          boxHeight: 40,
          padding: 50,
          color: "black",
          font: {
            size: 65,
          },
        },
        align: "center",
      },
    },
    responsive: true,
  };

  const barOptions = {
    animation: false,
    plugins: {
      legend: {
        labels: {
          boxHeight: 0,
        },
      },
      datalabels: {
        anchor: "end",
        align: "top",
        display: true,
        color: "black",
        font: {
          size: 20,
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          color: "black",
          font: {
            size: 16,
          },
        },
      },
      x: {
        stacked: true,
        ticks: {
          color: "black",
          font: {
            size: 18,
          },
        },
      },
    },
    responsive: true,
  };

  return (
    <div style={{ width: "1500px" }}>
      <Pie
        ref={pictureRefs.lifeCycle}
        style={{ display: "none" }}
        height={80}
        width={80}
        data={{
          labels: [
            `${getText(lang, "analysis.active")} - ${lifeCycle.active}`,
            `${getText(lang, "analysis.limited")} - ${lifeCycle.limited}`,
            `${getText(lang, "analysis.inactive")} - ${lifeCycle.inactive}`,
            `${getText(lang, "common.undefined")} - ${lifeCycle.undefined}`,
          ],
          datasets: [
            {
              data: [
                lifeCycle.active,
                lifeCycle.limited,
                lifeCycle.inactive,
                lifeCycle.undefined,
              ],
              backgroundColor: statCondColors,
              borderColor: "black",
              borderWidth: 1,
            },
          ],
        }}
        // @ts-ignore
        options={pieOptions}
      />
      <Pie
        ref={pictureRefs.criticality}
        style={{ display: "none" }}
        height={80}
        width={80}
        data={{
          labels: [
            `${getText(lang, "analysis.low")} - ${criticality.low}`,
            `${getText(lang, "analysis.medium")} - ${criticality.medium}`,
            `${getText(lang, "analysis.high")} - ${criticality.high}`,
            `${getText(lang, "common.undefined")} - ${criticality.undefined}`,
          ],
          datasets: [
            {
              data: [
                criticality.low,
                criticality.medium,
                criticality.high,
                criticality.undefined,
              ],
              backgroundColor: statCondColors,
              borderColor: "black",
              borderWidth: 1,
            },
          ],
        }}
        // @ts-ignore
        options={pieOptions}
      />
      <Pie
        ref={pictureRefs.condition}
        style={{ display: "none" }}
        height={80}
        width={80}
        data={{
          labels: [
            `${getText(lang, "analysis.good")} - ${condition.good}`,
            `${getText(lang, "analysis.medium")} - ${condition.medium}`,
            `${getText(lang, "analysis.poor")} - ${condition.poor}`,
            `${getText(lang, "common.undefined")} - ${condition.undefined}`,
          ],
          datasets: [
            {
              data: [
                condition.good,
                condition.medium,
                condition.poor,
                condition.undefined,
              ],
              backgroundColor: statCondColors,
              borderColor: "black",
              borderWidth: 1,
            },
          ],
        }}
        // @ts-ignore
        options={pieOptions}
      />

      <Pie
        ref={pictureRefs.risk}
        style={{ display: "none" }}
        height={80}
        width={80}
        data={{
          labels: [
            `${getText(lang, "analysis.low")} - ${risk.low}`,
            `${getText(lang, "analysis.medium")} - ${risk.medium}`,
            `${getText(lang, "analysis.high")} - ${risk.high}`,
            `${getText(lang, "common.undefined")} - ${risk.undefined}`,
          ],
          datasets: [
            {
              data: [risk.low, risk.medium, risk.high, risk.undefined],
              backgroundColor: statCondColors,
              borderColor: "black",
              borderWidth: 1,
            },
          ],
        }}
        // @ts-ignore
        options={pieOptions}
      />
      <Bar
        ref={pictureRefs.year}
        style={{ display: "none" }}
        height={100}
        data={{
          labels: Object.keys(yearCount).map((year) =>
            year === "undefined" ? getText(lang, "common.na") : year
          ),
          datasets: [
            {
              data: Object.values(yearCount).map((count) => count),
              label: "",
              backgroundColor: "#B6000F",
              minBarLength: 5,
              maxBarThickness: 60,
            },
          ],
        }}
        // @ts-ignore
        options={barOptions}
        plugins={[ChartDataLabels]}
      />
      <Bar
        ref={pictureRefs.family}
        style={{ display: "none" }}
        height={100}
        data={{
          labels: assessment.map((fam) =>
            fam.family !== "other"
              ? fam.family
              : fam.brand && fam.brand.name !== "Other"
              ? `${getText(lang, "common.other.fs")} ${fam.brand.name}`
              : getText(lang, "common.other.fs")
          ),
          datasets: [
            {
              data: assessment.map((fam) => fam.driveCount),
              label: "",
              backgroundColor: "#B6000F",
              minBarLength: 5,
              maxBarThickness: 60,
            },
          ],
        }}
        // @ts-ignore
        options={barOptions}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
}

export default ResultGraphs;
