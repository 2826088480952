import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoScheduledPreventiveMaintenance/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getFromEquipo(idEquipo = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipoScheduledPreventiveMaintenance/Equipo/${idEquipo}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

export function getScheduledInYear(year = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipoScheduledPreventiveMaintenance/scheduledInYear/${year}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

export function getScheduledByPlant(PlantaId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipoScheduledPreventiveMaintenance/plant/${PlantaId}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

export function add(schedule) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoScheduledPreventiveMaintenance`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(schedule),
  });
}

export function update(schedule) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipoScheduledPreventiveMaintenance/${schedule.id}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(schedule),
    }
  );
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoScheduledPreventiveMaintenance/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function requestEquipmnetMaintenance(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipoScheduledPreventiveMaintenance/request/equipment/${id}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    }
  );
}

export function requestPlantMaintenance(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipoScheduledPreventiveMaintenance/request/plant/${id}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    }
  );
}