import React, { useEffect, useState, useCallback } from "react";
import i18n from "../../../locales/i18n";
import MaterialTable from "material-table";
import { SiteAssessmentVersionsAPI } from "../../../services/API";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

export default function SiteAssessmentVersionsTable(props) {
  const { plantaId, history } = props;

  const [loading, setLoading] = useState(false);
  const [siteassessmentversions, setsiteassessmentversions] = useState([]);
  const materialTableTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#000000",
      },
    },
  });
  const getSiteAssessment = useCallback(async () => {
    setLoading(true);
    await SiteAssessmentVersionsAPI.getSiteAssessmentVersions(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw Error(response.statusText + " getSiteAssessment");
        }
      })
      .then((data) => {
        setsiteassessmentversions(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [plantaId]);

  useEffect(() => {
    getSiteAssessment();
  }, [getSiteAssessment]);

  return (
    // @ts-ignore
    <ThemeProvider theme={materialTableTheme}>
      {/*@ts-ignore*/}
      <MaterialTable
        style={{ minWidth: "100%" }}
        title={null}
        // @ts-ignore
        localization={i18n.t("dataTableProps")}
        isLoading={loading}
        data={siteassessmentversions}
        options={{
          sorting: true,
          showTitle: false,
          searchFieldAlignment: "left",
          pageSize: 10,
          actionsColumnIndex: -1,
        }}
        onRowClick={(event, rowData) => {
          history.push(
            `/plantas/${plantaId}/siteassessmentversion/${rowData.id}`
          );
        }}
        columns={[
          {
            field: "created",
            title: i18n.t("siteAssessment.created"),
            // render: (rowData) => {
            //   return moment(rowData.created).format("DD/MM/YYYY");
            // },
            type: "datetime",
            defaultSort: "desc",
          },
          {
            field: "appUser",
            title: i18n.t("siteAssessment.user"),
            render: (rowData) =>
              rowData.appUser
                ? `${rowData.appUser.nombre} ${rowData.appUser.apellidos} (${rowData.appUser.email})`
                : i18n.t("common.n/a"),
          },
          {
            field: "numberOfEquipment",
            title: i18n.t("siteAssessment.equipmentNumber"),
          },
        ]}
        actions={[
          {
            icon: "refresh",
            tooltip: i18n.t("alarma.actualizar"),
            isFreeAction: true,
            onClick: (event) => {
              getSiteAssessment();
            },
          },
          // {
          //   icon: "arrow_forward_ios",
          //   tooltip: i18n.t("siteAssessment.view"),
          //   onClick: (event, rowData) => {
          //     history.push(
          //       `/plantas/${plantaId}/siteassessmentversion/${rowData.id}`
          //     );
          //   },
          // },
        ]}
      />
    </ThemeProvider>
  );
}
