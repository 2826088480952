import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Auth_Style } from "../../assets/css/Styles";

export default function AuthLoading({ hidden }) {
  const styles = Auth_Style();
  return (
    <div className={styles.authLoadingContainer}>
      <CircularProgress className={styles.authLoading} hidden={hidden} />
    </div>
  );
}
