import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  FormHelperText,
  Switch,
  Button,
  Checkbox,
  Icon,
  Menu,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import RelativeBackdrop from "../../RelativeBackdrop";
import i18n from "../../../locales/i18n";
import {
  CalendarToday,
  Save,
  MailOutline,
  Delete,
  MoreVert,
  ArrowDropDown,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import AlertDialog from "../../../components/AlertDialog";
import MaintenanceTimeline from "../../molecule/maintenanceTimeline/maintenanceTimeline";
import ActualScheduledMaintenance from "../../molecule/actualScheduledMaintenace/actualScheduledMaintenace";
import VaconMaintenance from "../../../helpers/vacon-maintenance-calculation.json";
import VLTMaintenance from "../../../helpers/vlt-maintenance-calculation.json";
import {
  EquipoMantenimientosAPI,
  EquipoScheduledPreventiveMaintenancesAPI,
  EquiposAPI,
} from "../../../services/API";
import { DetailTabs_Style } from "../../../assets/css/Styles";
import {
  SnackbarError,
  SnackbarSuccess,
  isVacon,
  isValidFrameSize,
} from "../../../helpers/Common";
import AppContext from "../../../contexts/AppContext";
import moment from "moment";
import MouseOverPopover from "../../MouseOverPopover";
import MaintenanceCopyToDialog from "../../molecule/maintenanceCopyToDialog/maintenanceCopyToDialog";

function TabMantenimiento({
  id,
  equipo,
  setEquipo,
  readOnly,
  isPremium,
  brandStored,
  colWith,
  goBack,
  mantFlag,
  setMantFlag,
  loading,
  setLoading,
  errors,
  setErrors,
  frameChanged,
}) {
  const [mantenimiento, setMantenimiento] = useState({
    id: null,
    frame: null,
    temperatura: null,
    usoDiario: null,
    startup: null,
    ambiente: null,
    carga: null,
    activo: null,
    personalizado: false,
    customIntervaloModulo1: 0,
    customIntervaloModulo2: 0,
    customIntervaloModulo3: 0,
    fechaModulo1: null,
    fechaModulo2: null,
    fechaModulo3: null,
    heavy: false,
  });

  const validFrameSize = isValidFrameSize(equipo.brand, equipo.frame);
  const clipboardPM = localStorage.getItem("PreventiveMaintenanceCopy");
  const copyDialogRef = useRef(null);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);

  // @ts-ignore
  const { setGlobal } = useContext(AppContext);

  const [personalizado, setPersonalizado] = useState(
    mantenimiento.personalizado
  );
  const deleteDialog = useRef(null);
  const [maintenance, setMaintenance] = useState(VaconMaintenance);
  const [maintenanceTabValue, setMaintenanceTabValue] = useState(0);
  const [scheduledPreventiveMaintinance, setScheduledPreventiveMaintinance] =
    useState([]);
  const [actionsAnchor, setActionsAnchor] = useState(null);

  const styles = DetailTabs_Style();

  const regex = /^[0-9]+$/;

  const handleMaintenanceTypeChange = (event) => {
    let value = event;

    if (value.target) {
      value = event.target.checked;
    }

    setPersonalizado(value);
    setMantenimiento((old) => ({
      ...old,
      personalizado: value,
    }));
  };

  if (!validFrameSize && !mantenimiento.personalizado) {
    handleMaintenanceTypeChange(true);
  }

  const handleMaintenanceInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setMantenimiento((prevMantenimiento) => ({
      ...prevMantenimiento,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const changeNotProperlyInstalled = async (value) => {
    await EquiposAPI.updateProp(equipo.id, value, "NotProperlyInstalled").then(
      (response) => {
        if (response.ok) {
          setEquipo((old) => ({
            ...old,
            notProperlyInstalled: value,
          }));
          SnackbarSuccess(
            setGlobal,
            i18n.t(
              `maintenance.snackbar.notProperlyInstalled.success${
                value ? "Not" : ""
              }`
            )
          );
          setMantenimiento(mantenimiento);
          setPersonalizado(personalizado);
        } else {
          SnackbarError(
            setGlobal,
            i18n.t("maintenance.snackbar.notProperlyInstalled.error")
          );
        }
      }
    );
  };

  const shouldRenderMaintenance = () => {
    const { brand } = equipo;
    const {
      startup,
      temperatura,
      ambiente,
      carga,
      usoDiario,
      fechaModulo1,
      fechaModulo2,
      fechaModulo3,
    } = mantenimiento;

    return (
      (brand &&
        startup &&
        frame &&
        temperatura &&
        ambiente &&
        carga &&
        usoDiario) ||
      (fechaModulo1 && fechaModulo2 && fechaModulo3)
    );
  };

  if (equipo.frame) {
    var frame = maintenance.frames.find((f) => f.sizes.includes(equipo.frame));

    if (frame) {
      var temperaturas = frame.fanMapping.map(
        (mapping) =>
          (mapping.isLessOperator ? "<= " : ">= ") + mapping.temperature
      );
    }
  }
  const getModule2Years = () => {
    const { temperatura, ambiente, usoDiario } = mantenimiento;

    const frame = maintenance.frames.find((f) =>
      f.sizes.includes(equipo.frame)
    );

    const usage = maintenance.dailyUsage.find(
      (usage) => usage.hours === usoDiario
    );

    if (frame && temperatura && ambiente && usoDiario) {
      var fanMapping = frame.fanMapping.find((m) => {
        var temperatureText =
          (m.isLessOperator ? "<= " : ">= ") + m.temperature;
        return temperatureText === temperatura;
      });

      if (fanMapping) {
        const ambient = fanMapping.ambients.find((a) => a.ambient === ambiente);

        return ambient.value + (usage ? usage.value : 0);
      } else {
        return 0;
      }
    }
  };

  const getModule3Years = () => {
    const { temperatura, carga } = mantenimiento;

    const frame = maintenance.frames.find((f) =>
      f.sizes.includes(equipo.frame)
    );

    if (frame && temperatura && carga) {
      var capacitorMapping = frame.capacitorMapping.find((m) => {
        var temperatureText =
          (m.isLessOperator ? "<= " : ">= ") + m.temperature;
        // @ts-ignore
        if ((equipo.voltage === "T5" || equipo.voltage === "T6") && m.input) {
          // @ts-ignore
          return temperatureText === temperatura && equipo.voltage === m.input;
        } else {
          return temperatureText === temperatura;
        }
      });

      if (capacitorMapping) {
        const loadValue = capacitorMapping.loadProfiles.find(
          (a) => a.load === carga
        ).value;

        return loadValue;
      } else {
        return 0;
      }
    }
  };

  const getScheduledPreventiveMaintinance = useCallback(async () => {
    setLoading(true);

    EquipoScheduledPreventiveMaintenancesAPI.getFromEquipo(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setScheduledPreventiveMaintinance(data);
        if (data.length === 0) {
          setMaintenanceTabValue(0);
        } else {
          setMaintenanceTabValue(1);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id, setLoading]);

  const saveMaintenance = (isContract) => {
    const {
      id,
      startup,
      frame,
      temperatura,
      ambiente,
      carga,
      usoDiario,
      activo,
      personalizado,
      customIntervaloModulo1,
      customIntervaloModulo2,
      customIntervaloModulo3,
      fechaModulo1,
      fechaModulo2,
      fechaModulo3,
    } = mantenimiento;

    var years2 = getModule2Years() || null;
    var years3 = getModule3Years() || null;

    var equipoMantenimiento = {
      equipoId: equipo.id,
      intervaloModulo1: 1,
      intervaloModulo2: years2,
      intervaloModulo3: years3,
      fecha: new Date(),
      startup: startup ? new Date(startup) : null,
      frame: frame,
      temperatura: temperatura,
      ambiente: ambiente,
      carga: carga,
      usoDiario: usoDiario,
      activo: isContract ? !activo : activo,
      customIntervaloModulo1: customIntervaloModulo1,
      customIntervaloModulo2: customIntervaloModulo2,
      customIntervaloModulo3: customIntervaloModulo3,
      fechaModulo1: fechaModulo1,
      fechaModulo2: fechaModulo2,
      fechaModulo3: fechaModulo3,
      personalizado: personalizado,
    };

    if (id) {
      equipoMantenimiento.id = id;
    }

    setLoading(true);

    EquipoMantenimientosAPI.addOrUpdate(equipo.id, equipoMantenimiento)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var json = await response.json();
          console.log("errores");
          console.log(json.errors);
          setErrors(json.errors);
        }
      })
      .then(async (mantenimiento) => {
        if (mantenimiento) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("maintenance.snackbar.new.success")
          );
          setEquipo((old) => ({
            ...old,
            mantenimientoActivo: mantenimiento,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const requestMaintenance = () => {
    setLoading(true);
    EquipoScheduledPreventiveMaintenancesAPI.requestEquipmnetMaintenance(
      equipo.id
    )
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          SnackbarSuccess(setGlobal, i18n.t("maintenance.request.success"));
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        SnackbarError(setGlobal, i18n.t("maintenance.request.error"));
        setLoading(false);
      });
  };

  const deleteMaintenance = () => {
    deleteDialog.current.close();
    setLoading(true);
    EquipoMantenimientosAPI.remove(mantenimiento.id)
      .then((response) => {
        if (response.ok) {
          setEquipo((old) => ({ ...old, mantenimientoActivo: null }));
          setMantenimiento(null);
          setLoading(false);
          SnackbarSuccess(
            setGlobal,
            i18n.t("maintenance.snackbar.deleted.success")
          );
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
        SnackbarError(setGlobal, i18n.t("maintenance.snackbar.deleted.error"));
        setLoading(false);
      });
  };

  useEffect(() => {
    if (equipo.mantenimientoActivo) {
      setMantenimiento(equipo.mantenimientoActivo);
      setPersonalizado(equipo.mantenimientoActivo.personalizado);
    } else {
      setMantenimiento((prevMantenimiento) => ({
        ...prevMantenimiento,
        startup: moment(equipo.añoFabricacion + "W" + equipo.semanaFabricacion)
          .add(6, "months")
          .toISOString(),
        temperatura:
          equipo.frame &&
          maintenance.frames.find((f) => f.sizes.includes(equipo.frame)) !==
            undefined
            ? (maintenance.frames.find((f) => f.sizes.includes(equipo.frame))
                .fanMapping[1].isLessOperator
                ? "<= "
                : ">= ") +
              maintenance.frames.find((f) => f.sizes.includes(equipo.frame))
                .fanMapping[1].temperature
            : null,
        ambiente: "Dust",
        carga: "< 90%",
        usoDiario: maintenance.dailyUsage[1].hours,
        activo: false,
        heavy: true,
      }));
    }
  }, [equipo, maintenance]);
  useEffect(() => {
    isVacon(equipo.brand)
      ? setMaintenance(VaconMaintenance)
      : setMaintenance(VLTMaintenance);
  }, [equipo.brand]);
  useEffect(() => {
    getScheduledPreventiveMaintinance();
  }, [getScheduledPreventiveMaintinance]);
  useEffect(() => {
    if (mantFlag) {
      setMantenimiento((prevMantenimiento) => {
        prevMantenimiento.temperatura = null;
        prevMantenimiento.usoDiario = null;
        setMantFlag(false);
        return prevMantenimiento;
      });
    }
  }, [mantFlag, setMantFlag]);

  const copyPreventiveMaintenance = (massive) => {
    let response = {};

    if (mantenimiento.personalizado) {
      response = {
        personalizado: mantenimiento.personalizado,
        fechaModulo1: mantenimiento.fechaModulo1,
        fechaModulo2: mantenimiento.fechaModulo2,
        fechaModulo3: mantenimiento.fechaModulo3,
        customIntervaloModulo1: mantenimiento.customIntervaloModulo1,
        customIntervaloModulo2: mantenimiento.customIntervaloModulo2,
        customIntervaloModulo3: mantenimiento.customIntervaloModulo3,
      };
    } else {
      response = {
        personalizado: mantenimiento.personalizado,
        frame: equipo.frame,
        temperatura: mantenimiento.temperatura,
        ambiente: mantenimiento.ambiente,
        usoDiario: mantenimiento.usoDiario,
        carga: mantenimiento.carga,
      };
    }
    let semaf = Object.keys(response).filter(
      (key) => key !== "personalizado" && !response[key]
    );
    if (semaf.length) {
      for (let i = 0; i < semaf.length; i++) {
        setErrors((old) => ({ ...old, [semaf[i]]: true }));
      }
      SnackbarError(setGlobal, i18n.t("maintenance.snackbar.copy.error"));
    } else {
      if (massive) {
        copyDialogRef.current.open();
        setOpenCopyDialog(true);
      } else {
        localStorage.setItem(
          "PreventiveMaintenanceCopy",
          JSON.stringify(response)
        );
        SnackbarSuccess(setGlobal, i18n.t("maintenance.snackbar.copy.success"));
      }
    }
  };

  const pastePreventiveMaintenance = () => {
    let objectCopy = JSON.parse(clipboardPM);
    let semaf = true;
    if (objectCopy) {
      if (objectCopy.personalizado) {
        setMantenimiento((old) => ({
          ...old,
          personalizado: objectCopy.personalizado,
          fechaModulo1: objectCopy.fechaModulo1,
          fechaModulo2: objectCopy.fechaModulo2,
          fechaModulo3: objectCopy.fechaModulo3,
          customIntervaloModulo1: objectCopy.customIntervaloModulo1,
          customIntervaloModulo2: objectCopy.customIntervaloModulo2,
          customIntervaloModulo3: objectCopy.customIntervaloModulo3,
        }));
        setPersonalizado(true);
      } else {
        semaf = equipo.frame === objectCopy.frame;
        if (semaf) {
          setMantenimiento((old) => ({
            ...old,
            personalizado: objectCopy.personalizado,
            temperatura: objectCopy.temperatura,
            ambiente: objectCopy.ambiente,
            usoDiario: objectCopy.usoDiario,
            carga: objectCopy.carga,
          }));
          setPersonalizado(false);
        }
      }
    }
    if (semaf && objectCopy) {
      SnackbarSuccess(setGlobal, i18n.t("maintenance.snackbar.paste.success"));
    } else {
      SnackbarError(setGlobal, i18n.t("maintenance.snackbar.paste.error"));
    }
  };

  return (
    <>
      <RelativeBackdrop hidden={!loading}>
        <Card>
          <CardHeader
            title={i18n.t("maintenance.title")}
            subheader={equipo.sn}
            avatar={
              <Avatar aria-label="avatar" className={styles.tabHeaderAvatar}>
                <CalendarToday />
              </Avatar>
            }
            action={
              mantenimiento.heavy ? (
                <MouseOverPopover
                  content={
                    <Typography variant="body2">
                      {i18n.t("maintenance.heavyTooltip")}
                    </Typography>
                  }
                  parent={
                    <div className={styles.flexPill}>
                      <span className={styles.pillText}>
                        {i18n.t("maintenance.heavy")}
                      </span>
                    </div>
                  }
                />
              ) : undefined
            }
          />
          <div className={styles.mainCardFormContainer}>
            <div className={styles.formCardContainer}>
              {!frameChanged ? (
                <>
                  {brandStored && equipo.brand?.isDanfoss ? (
                    <>
                      <div className={styles.pmButtonsContainer}>
                        <div className={styles.pmButtons}>
                          {i18n.t("maintenance.danfoss")}
                          <Switch
                            name="maintenaceSwitch"
                            checked={mantenimiento.personalizado}
                            onChange={handleMaintenanceTypeChange}
                            color="default"
                            disabled={readOnly || !isPremium || !validFrameSize}
                          />
                          {i18n.t("maintenance.personalized")}
                        </div>
                        <div className={styles.pmButtons}>
                          <Checkbox
                            disabled={readOnly || !isPremium}
                            checked={equipo.notProperlyInstalled}
                            onChange={() =>
                              changeNotProperlyInstalled(
                                !equipo.notProperlyInstalled
                              )
                            }
                            className={styles.checkBoxForm}
                            name="notProperlyInstalled"
                          />
                          {i18n.t("maintenance.notProperlyInstalled")}
                        </div>
                      </div>
                      <div className={styles.flexCellForm}>
                        {i18n.t("maintenance.danfoss")}
                        <div className={styles.separatorLineMantienance} />
                      </div>
                      {validFrameSize ? (
                        <>
                          <div className={styles.flexRowForm}>
                            <div className={styles.flexCellForm}>
                              <TextField
                                className={styles.materialTextField}
                                required
                                fullWidth
                                type="date"
                                value={
                                  mantenimiento.startup
                                    ? mantenimiento.startup.substring(0, 10)
                                    : ""
                                }
                                label={i18n.t("maintenance.fields.startup")}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  readOnly: readOnly || !isPremium,
                                }}
                                name="startup"
                                error={errors["startup"] !== undefined}
                                helperText={
                                  errors["startup"]
                                    ? i18n.t("textField.required")
                                    : null
                                }
                                disabled={personalizado}
                                onChange={handleMaintenanceInputChange}
                              />
                            </div>
                            <div className={styles.flexCellForm}>
                              <TextField
                                className={styles.materialTextField}
                                value={equipo.frame || ""}
                                required
                                fullWidth
                                disabled
                                name="frameMant"
                                label={i18n.t("maintenance.fields.frameSize")}
                                error={errors["frame"] !== undefined}
                                helperText={
                                  errors["frame"]
                                    ? i18n.t("textField.required")
                                    : null
                                }
                              />
                            </div>

                            <div className={styles.flexCellForm}>
                              <FormControl
                                className={styles.materialTextField}
                                required
                                fullWidth
                                disabled={personalizado}
                                error={errors["temperatura"] ? true : false}
                              >
                                <InputLabel>
                                  {i18n.t("maintenance.fields.temp")}
                                </InputLabel>
                                <Select
                                  className={
                                    readOnly && styles.selectReadOnlyTextfield
                                  }
                                  required
                                  fullWidth
                                  value={mantenimiento.temperatura || ""}
                                  name="temperatura"
                                  onChange={handleMaintenanceInputChange}
                                  inputProps={{
                                    readOnly: readOnly || !isPremium,
                                  }}
                                  IconComponent={
                                    readOnly ? () => null : ArrowDropDown
                                  }
                                >
                                  {temperaturas?.map((row) => (
                                    <MenuItem value={row} key={row}>
                                      {row}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errors["temperatura"]
                                    ? i18n.t("textField.required")
                                    : null}
                                </FormHelperText>
                              </FormControl>
                            </div>
                          </div>
                          <div className={styles.flexRowForm}>
                            <div className={styles.flexCellForm}>
                              <FormControl
                                className={styles.materialTextField}
                                required
                                fullWidth
                                disabled={personalizado}
                                error={errors["ambiente"] ? true : false}
                              >
                                <InputLabel>
                                  {i18n.t("maintenance.fields.ambient")}
                                </InputLabel>
                                <Select
                                  className={
                                    readOnly && styles.selectReadOnlyTextfield
                                  }
                                  required
                                  fullWidth
                                  value={mantenimiento.ambiente || ""}
                                  name="ambiente"
                                  displayEmpty
                                  onChange={handleMaintenanceInputChange}
                                  inputProps={{
                                    readOnly: readOnly || !isPremium,
                                  }}
                                  IconComponent={
                                    readOnly ? () => null : ArrowDropDown
                                  }
                                >
                                  <MenuItem value="Standard">
                                    {i18n.t("maintenance.ambients.standard")}
                                  </MenuItem>
                                  <MenuItem value="Dust">
                                    {i18n.t("maintenance.ambients.dust")}
                                  </MenuItem>
                                  <MenuItem value="Extreme">
                                    {i18n.t("maintenance.ambients.extreme")}
                                  </MenuItem>
                                </Select>
                                <FormHelperText>
                                  {errors["ambiente"]
                                    ? i18n.t("textField.required")
                                    : null}
                                </FormHelperText>
                              </FormControl>
                            </div>
                            <div className={styles.flexCellForm}>
                              <FormControl
                                className={styles.materialTextField}
                                required
                                fullWidth
                                disabled={personalizado}
                                error={errors["carga"] ? true : false}
                              >
                                <InputLabel>
                                  {i18n.t("maintenance.fields.load")}
                                </InputLabel>
                                <Select
                                  className={
                                    readOnly && styles.selectReadOnlyTextfield
                                  }
                                  required
                                  fullWidth
                                  value={mantenimiento.carga || ""}
                                  name="carga"
                                  displayEmpty
                                  onChange={handleMaintenanceInputChange}
                                  inputProps={{
                                    readOnly: readOnly || !isPremium,
                                  }}
                                  IconComponent={
                                    readOnly ? () => null : ArrowDropDown
                                  }
                                >
                                  <MenuItem value={"< 100%"}>
                                    {"< 100%"}
                                  </MenuItem>
                                  <MenuItem value={"< 90%"}>{"< 90%"}</MenuItem>
                                  <MenuItem value={"< 75%"}>{"< 75%"}</MenuItem>
                                </Select>
                                <FormHelperText>
                                  {errors["carga"]
                                    ? i18n.t("textField.required")
                                    : null}
                                </FormHelperText>
                              </FormControl>
                            </div>
                            <div className={styles.flexCellForm}>
                              <FormControl
                                className={styles.materialTextField}
                                required
                                fullWidth
                                disabled={personalizado}
                                error={errors["usoDiario"] ? true : false}
                              >
                                <InputLabel>
                                  {i18n.t("maintenance.fields.usage")}
                                </InputLabel>
                                <Select
                                  className={
                                    readOnly && styles.selectReadOnlyTextfield
                                  }
                                  required
                                  fullWidth
                                  value={mantenimiento.usoDiario || ""}
                                  name="usoDiario"
                                  onChange={handleMaintenanceInputChange}
                                  inputProps={{
                                    readOnly: readOnly || !isPremium,
                                  }}
                                  IconComponent={
                                    readOnly ? () => null : ArrowDropDown
                                  }
                                >
                                  {maintenance.dailyUsage.map((usage) => (
                                    <MenuItem
                                      value={usage.hours}
                                      key={usage.hours}
                                    >
                                      {usage.hours}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {errors["usoDiario"]
                                    ? i18n.t("textField.required")
                                    : null}
                                </FormHelperText>
                              </FormControl>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className={styles.flexCellForm}>
                          <Alert severity="info" style={{ width: "100%" }}>
                            {i18n.t("maintenance.selectValidFrame")}
                          </Alert>
                        </div>
                      )}
                      <div className={styles.flexCellForm}>
                        {i18n.t("maintenance.personalized")}
                        <div className={styles.separatorLineMantienance} />
                      </div>
                      <div className={styles.flexRowForm}>
                        <div className={styles.flexCellForm}>
                          <TextField
                            className={styles.materialTextField}
                            required
                            fullWidth
                            type="date"
                            disabled={!personalizado}
                            value={
                              mantenimiento.fechaModulo1
                                ? mantenimiento.fechaModulo1.substring(0, 10)
                                : ""
                            }
                            label={i18n.t("maintenance.fields.inicioM1")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly || !isPremium,
                            }}
                            name="fechaModulo1"
                            error={errors["fechaModulo1"] !== undefined}
                            helperText={
                              errors["fechaModulo1"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            onChange={handleMaintenanceInputChange}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          <TextField
                            className={styles.materialTextField}
                            required
                            fullWidth
                            type="date"
                            disabled={!personalizado}
                            value={
                              mantenimiento.fechaModulo2
                                ? mantenimiento.fechaModulo2.substring(0, 10)
                                : ""
                            }
                            label={i18n.t("maintenance.fields.inicioM2")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly || !isPremium,
                            }}
                            name="fechaModulo2"
                            error={errors["fechaModulo2"] !== undefined}
                            helperText={
                              errors["fechaModulo2"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            onChange={handleMaintenanceInputChange}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          <TextField
                            className={styles.materialTextField}
                            required
                            fullWidth
                            type="date"
                            disabled={!personalizado}
                            value={
                              mantenimiento.fechaModulo3
                                ? mantenimiento.fechaModulo3.substring(0, 10)
                                : ""
                            }
                            label={i18n.t("maintenance.fields.inicioM3")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly || !isPremium,
                            }}
                            name="fechaModulo3"
                            error={errors["fechaModulo3"] !== undefined}
                            helperText={
                              errors["fechaModulo3"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            onChange={handleMaintenanceInputChange}
                          />
                        </div>
                      </div>
                      <div className={styles.flexRowForm}>
                        <div className={styles.flexCellForm}>
                          <TextField
                            required
                            className={styles.materialTextField}
                            fullWidth
                            disabled={!personalizado}
                            value={mantenimiento.customIntervaloModulo1 || ""}
                            label={i18n.t("maintenance.fields.intervaloM1")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly || !isPremium,
                            }}
                            name="customIntervaloModulo1"
                            error={
                              errors["customIntervaloModulo1"] !== undefined
                            }
                            helperText={
                              errors["customIntervaloModulo1"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            //filtro solo numeros positivos
                            onChange={(event) => {
                              if (
                                event.target.value === "" ||
                                regex.test(event.target.value)
                              ) {
                                handleMaintenanceInputChange(event);
                              }
                            }}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          <TextField
                            required
                            className={styles.materialTextField}
                            fullWidth
                            type="number"
                            disabled={!personalizado}
                            value={mantenimiento.customIntervaloModulo2 || ""}
                            label={i18n.t("maintenance.fields.intervaloM2")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly || !isPremium,
                            }}
                            name="customIntervaloModulo2"
                            error={
                              errors["customIntervaloModulo2"] !== undefined
                            }
                            helperText={
                              errors["customIntervaloModulo2"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            //filtro solo numeros positivos
                            onChange={(event) => {
                              if (
                                event.target.value === "" ||
                                regex.test(event.target.value)
                              ) {
                                handleMaintenanceInputChange(event);
                              }
                            }}
                          />
                        </div>
                        <div className={styles.flexCellForm}>
                          <TextField
                            required
                            className={styles.materialTextField}
                            fullWidth
                            type="number"
                            disabled={!personalizado}
                            value={mantenimiento.customIntervaloModulo3 || ""}
                            label={i18n.t("maintenance.fields.intervaloM3")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly || !isPremium,
                            }}
                            name="customIntervaloModulo3"
                            error={
                              errors["customIntervaloModulo3"] !== undefined
                            }
                            helperText={
                              errors["customIntervaloModulo3"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            //filtro solo numeros positivos
                            onChange={(event) => {
                              if (
                                event.target.value === "" ||
                                regex.test(event.target.value)
                              ) {
                                handleMaintenanceInputChange(event);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={styles.flexCellForm}>
                      <Alert severity="info" style={{ width: "100%" }}>
                        {equipo.brand
                          ? i18n.t("equipo.noMarcaGuardada")
                          : i18n.t("equipo.noMarca")}
                      </Alert>
                    </div>
                  )}
                </>
              ) : (
                <div className={styles.flexCellForm}>
                  <Alert severity="info" style={{ width: "100%" }}>
                    {i18n.t("equipo.frameModificado")}
                  </Alert>
                </div>
              )}
            </div>
          </div>
          <CardActions className={styles.pmButtonsContainer} disableSpacing>
            {(brandStored || personalizado) &&
            !readOnly &&
            equipo.brand?.isDanfoss &&
            !frameChanged ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Tooltip
                  title={i18n.t("common.isPremium")}
                  placement={"top-start"}
                  disableHoverListener={isPremium}
                  arrow
                >
                  <div>
                    <Button
                      variant="contained"
                      className={styles.primaryFormButton}
                      onClick={() => saveMaintenance(false)}
                      style={{ marginLeft: "8px" }}
                      disabled={!isPremium}
                    >
                      <Save className={styles.saveIcon} />
                      {i18n.t("common.save")}
                    </Button>
                  </div>
                </Tooltip>
                <Button
                  variant="outlined"
                  disabled={
                    mantenimiento.id === null ||
                    !equipo.mantenimientoActivo ||
                    !isPremium
                  }
                  className={styles.secondaryFormButton}
                  onClick={() => deleteDialog.current.open()}
                >
                  {i18n.t("common.delete")}
                  <Delete />
                </Button>
              </div>
            ) : (
              <div></div>
            )}
            <div>
              <Button
                variant="outlined"
                className={styles.secondaryFormButton}
                onClick={(e) => setActionsAnchor(e?.currentTarget)}
              >
                <MoreVert className={styles.addCircleOutline} />
                {i18n.t("planta.actions.actionButton")}
              </Button>
              <Menu
                anchorEl={actionsAnchor}
                keepMounted
                open={Boolean(actionsAnchor)}
                onClose={() => setActionsAnchor(null)}
              >
                <MenuItem
                  onClick={() => {
                    requestMaintenance();
                    setActionsAnchor(null);
                  }}
                >
                  <ListItemIcon>
                    <MailOutline //className={styles.addCircleOutlineNewDrive}
                    />
                  </ListItemIcon>
                  <ListItemText /* className={styles.menuActiveText} */>
                    {i18n.t("maintenance.request.button")}
                  </ListItemText>
                </MenuItem>
                <Tooltip
                  title={i18n.t("common.isPremium")}
                  placement={"left"}
                  disableHoverListener={isPremium}
                  arrow
                >
                  <div>
                    <MenuItem
                      disabled={readOnly || !mantenimiento || !isPremium}
                      onClick={() => {
                        copyPreventiveMaintenance(true);
                        setActionsAnchor(null);
                      }}
                    >
                      <ListItemIcon>
                        <Icon /* className={styles.addCircleOutlineNewDrive} */>
                          copy_all
                        </Icon>
                      </ListItemIcon>
                      <ListItemText /* className={styles.menuActiveText} */>
                        {i18n.t("maintenance.copyTo.button")}
                      </ListItemText>
                    </MenuItem>
                  </div>
                </Tooltip>
                <MenuItem
                  disabled={readOnly || !mantenimiento}
                  onClick={() => {
                    copyPreventiveMaintenance();
                    setActionsAnchor(null);
                  }}
                >
                  <ListItemIcon>
                    <Icon
                    //className={styles.addCircleOutlineNewDrive}
                    >
                      content_copy
                    </Icon>
                  </ListItemIcon>
                  <ListItemText /* className={styles.menuActiveText} */>
                    {i18n.t("maintenance.copy")}
                  </ListItemText>
                </MenuItem>
                <Tooltip
                  title={i18n.t("common.isPremium")}
                  placement={"left"}
                  disableHoverListener={isPremium}
                  arrow
                >
                  <div>
                    <MenuItem
                      disabled={readOnly || !clipboardPM || !isPremium}
                      onClick={() => {
                        pastePreventiveMaintenance();
                        setActionsAnchor(null);
                      }}
                    >
                      <ListItemIcon>
                        <Icon /* className={styles.addCircleOutlineNewDrive} */>
                          content_paste
                        </Icon>
                      </ListItemIcon>
                      <ListItemText /* className={styles.menuActiveText} */>
                        {i18n.t("maintenance.paste")}
                      </ListItemText>
                    </MenuItem>
                  </div>
                </Tooltip>
              </Menu>
            </div>
          </CardActions>
        </Card>
        <Paper square>
          <Tabs
            value={maintenanceTabValue}
            onChange={(event, value) => setMaintenanceTabValue(value)}
            centered
            variant="standard"
            indicatorColor="secondary"
            textColor="inherit"
            classes={{ indicator: styles.tabsHeader }}
          >
            <Tab
              value={0}
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("maintenance.subtabs.forecast")}
                </span>
              }
              id="maintenance-tab-0"
            />
            <Tab
              value={1}
              label={
                <span className={styles.detailTabLabel}>
                  {i18n.t("maintenance.subtabs.scheduled")}
                </span>
              }
              id="maintenance-tab-1"
            />
          </Tabs>
        </Paper>
        {maintenanceTabValue === 0 && (
          <MaintenanceTimeline
            equipoId={id}
            mantenimiento={mantenimiento}
            getModule2Years={getModule2Years}
            getModule3Years={getModule3Years}
            shouldRenderMaintenance={shouldRenderMaintenance()}
            readOnly={readOnly}
            isPremium={isPremium}
          />
        )}
        {maintenanceTabValue === 1 && (
          <ActualScheduledMaintenance
            equipo={equipo}
            getScheduledPreventiveMaintinance={
              getScheduledPreventiveMaintinance
            }
            scheduledPreventiveMaintinance={scheduledPreventiveMaintinance}
            setErrors={setErrors}
            setLoading={setLoading}
            readOnly={readOnly}
            isPremium={isPremium}
            goBack={goBack}
          />
        )}
        {/* @ts-ignore */}
        <AlertDialog
          ref={deleteDialog}
          title={i18n.t("common.dialogDeleteTitle")}
          text={i18n.t("common.dialogDeleteText")}
          cancelText={i18n.t("common.dialogDeleteCancelButtonText")}
          confirmText={i18n.t("common.dialogDeleteConfirmButtonText")}
          confirmAction={() => deleteMaintenance()}
        />
        <MaintenanceCopyToDialog
          dialogRef={copyDialogRef}
          open={openCopyDialog}
          setOpen={setOpenCopyDialog}
          drive={equipo}
          maintenance={mantenimiento}
        />
      </RelativeBackdrop>
    </>
  );
}

export default TabMantenimiento;
