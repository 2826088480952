import React, { useState, useContext, useRef } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
} from "@material-ui/core";
import {
  Add,
  Clear,
  Schedule,
  Event,
  Done,
  Edit,
  AssignmentOutlined,
} from "@material-ui/icons";
import { SnackbarSuccess, SnackbarError } from "../../../helpers/Common";
import AppContext from "../../../contexts/AppContext";
import i18n from "../../../locales/i18n";
import moment from "moment";
import AlertDialog from "../../../components/AlertDialog";
import { EquipoScheduledPreventiveMaintenancesAPI } from "../../../services/API";
import { Common_Style, DetailTabs_Style } from "../../../assets/css/Styles";

function ActualScheduledMaintenance({
  scheduledPreventiveMaintinance,
  setLoading,
  getScheduledPreventiveMaintinance,
  equipo,
  setErrors,
  readOnly,
  isPremium,
  goBack,
}) {
  const [scheduleDialog_open, setScheduleDialog_open] = useState(false);
  const [scheduleDialog_id, setScheduleDialog_id] = useState(null);
  const [scheduleDialog_showErrors, setScheduleDialog_showErrors] =
    useState(false);
  const [scheduleDialog_Date, setScheduleDialog_Date] = useState(null);
  const [scheduleDialog_Provisional, setScheduleDialog_Provisional] =
    useState(false);
  const [scheduleDialog_type, setScheduleDialog_type] = useState("");
  const [scheduleDialog_state, setScheduleDialog_state] = useState("");
  const deleteDialog = useRef(null);
  const [deleteRow, setDeleteRow] = useState(null);
  // @ts-ignore
  const { setGlobal } = useContext(AppContext);
  const styles = DetailTabs_Style();
  const commonStyles = Common_Style();

  const deleteScheduledPreventiveMaintenance = (id) => {
    deleteDialog.current.close();
    setLoading(true);
    EquipoScheduledPreventiveMaintenancesAPI.remove(id)
      .then(async (response) => {
        if (response.ok) {
          setLoading(false);
          getScheduledPreventiveMaintinance();
          SnackbarSuccess(
            setGlobal,
            i18n.t("maintenance.altualScheduled.snackbar.deleted.success")
          );
          setLoading(false);
        } else {
          SnackbarError(
            setGlobal,
            i18n.t("maintenance.altualScheduled.snackbar.deleted.error")
          );
          if (response.status === 404 || response.status === 401) {
            goBack();
            throw new Error("Something went wrong on Scheduled Maintenance");
          }
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const addNewScheduledPreventiveMaintenance = () => {
    setLoading(true);
    const newSchedule = {
      equipoId: equipo.id,
      scheduledDate: scheduleDialog_Date,
      isProvisionalDate: scheduleDialog_Provisional,
      type: scheduleDialog_type,
      state: scheduleDialog_state,
    };

    EquipoScheduledPreventiveMaintenancesAPI.add(newSchedule)
      .then(async (response) => {
        if (response.ok) {
          SnackbarSuccess(
            setGlobal,
            i18n.t("maintenance.altualScheduled.snackbar.new.success")
          );
          return response.json();
        } else {
          SnackbarError(
            setGlobal,
            i18n.t("maintenance.altualScheduled.snackbar.new.error")
          );
          if (response.status === 404 || response.status === 401) {
            goBack();
            throw new Error("Something went wrong on Scheduled Maintenance");
          }
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .then((prevMaintenance) => {
        setLoading(false);
        getScheduledPreventiveMaintinance();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const editScheduledPreventiveMaintenance = () => {
    setLoading(true);
    const schedule = {
      id: scheduleDialog_id,
      equipoId: equipo.id,
      scheduledDate: scheduleDialog_Date,
      isProvisionalDate: scheduleDialog_Provisional,
      type: scheduleDialog_type,
      state: scheduleDialog_state,
    };

    EquipoScheduledPreventiveMaintenancesAPI.update(schedule)
      .then(async (response) => {
        if (response.ok) {
          setLoading(false);
          getScheduledPreventiveMaintinance();
          SnackbarSuccess(
            setGlobal,
            i18n.t("maintenance.altualScheduled.snackbar.update.success")
          );
          setLoading(false);
        } else {
          SnackbarError(
            setGlobal,
            i18n.t("maintenance.altualScheduled.snackbar.update.error")
          );
          if (response.status === 404 || response.status === 401) {
            goBack();
            throw new Error("Something went wrong on Scheduled Maintenance");
          }
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const scheduleDialogSave = () => {
    if (
      !scheduleDialog_Date ||
      (!scheduleDialog_type && scheduleDialog_type !== "0") ||
      (!scheduleDialog_state && scheduleDialog_state !== "0")
    ) {
      setScheduleDialog_showErrors(true);
    } else {
      if (scheduleDialog_id) {
        editScheduledPreventiveMaintenance();
      } else {
        addNewScheduledPreventiveMaintenance();
      }
      scheduleDialogClose();
    }
  };

  const scheduleDialogClose = () => {
    setScheduleDialog_open(false);
    setScheduleDialog_id(null);
    setScheduleDialog_showErrors(false);
    setScheduleDialog_Date(null);
    setScheduleDialog_Provisional(false);
    setScheduleDialog_type("");
    setScheduleDialog_state("");
  };

  const handleDelete = (id) => {
    setDeleteRow(id);
    deleteDialog.current.open();
    //scheduleDialogDelete(id);
  };

  return (
    <>
      <TableContainer component={Paper} className={commonStyles.scrollCard}>
        <Table size="small" className={commonStyles.scrollCardContent}>
          <TableHead>
            <TableRow className={styles.manteinTimeLineRow}>
              <TableCell align="center">
                <Tooltip
                  title={i18n.t("common.isPremium")}
                  placement={"top-end"}
                  disableHoverListener={isPremium}
                >
                  <div>
                    <Button
                      variant="outlined"
                      className={styles.secondaryButton}
                      startIcon={<Add />}
                      onClick={() => setScheduleDialog_open(true)}
                      disabled={!isPremium}
                      hidden={readOnly}
                    >
                      {i18n.t("maintenance.altualScheduled.addSchedule")}
                    </Button>
                  </div>
                </Tooltip>
              </TableCell>
              <TableCell colSpan={3}></TableCell>
            </TableRow>
            <TableRow style={{ height: 45 }}>
              <TableCell align="center">
                <Typography variant="subtitle1">
                  {i18n.t("maintenance.altualScheduled.date")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle1">
                  {i18n.t("maintenance.altualScheduled.type")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle1">
                  {i18n.t("maintenance.altualScheduled.state")}
                </Typography>
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ fontSize: 10 }}>
            {scheduledPreventiveMaintinance.length < 1 && (
              <TableRow key="empty">
                <TableCell align="center" colSpan={4} height={100}>
                  {i18n.t("maintenance.altualScheduled.emptyDataSourceMessage")}
                </TableCell>
              </TableRow>
            )}
            {scheduledPreventiveMaintinance.length > 0 &&
              scheduledPreventiveMaintinance.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    {moment(row.scheduledDate).local().format("DD/MM/yyyy")}
                  </TableCell>
                  <TableCell align="center">
                    <div className={styles.scheduledTypeCell}>
                      {row.type === 0
                        ? i18n.t("maintenance.altualScheduled.module1")
                        : row.type === 1
                        ? i18n.t("maintenance.altualScheduled.module2")
                        : row.type === 2
                        ? i18n.t("maintenance.altualScheduled.module3")
                        : i18n.t("maintenance.altualScheduled.moduleBase")}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {row.state === 3 ? (
                      <div>
                        <AssignmentOutlined className={styles.iconColor} />
                        <Typography
                          variant="body2"
                          className={styles.typographyColor}
                        >
                          {i18n.t("maintenance.altualScheduled.proposed")}
                        </Typography>
                      </div>
                    ) : row.state === 2 ? (
                      <div>
                        <Clear className={styles.errorStatusColor} />
                        <Typography
                          variant="body2"
                          className={styles.errorStatusColor}
                        >
                          {i18n.t("maintenance.altualScheduled.cancelled")}
                        </Typography>
                      </div>
                    ) : row.state === 1 ? (
                      <div>
                        <Done className={styles.sucessStatusColor} />
                        <Typography
                          variant="body2"
                          className={styles.sucessStatusColor}
                        >
                          {i18n.t("maintenance.altualScheduled.done")}
                        </Typography>
                      </div>
                    ) : row.isProvisionalDate ? (
                      <div>
                        <Schedule />
                        <Typography
                          variant="body2"
                          className={styles.typographyColor}
                        >
                          {i18n.t("maintenance.altualScheduled.pending")}
                        </Typography>
                      </div>
                    ) : (
                      <div>
                        <Event />
                        <Typography
                          variant="body2"
                          className={styles.typographyColor}
                        >
                          {i18n.t("maintenance.altualScheduled.scheduled")}
                        </Typography>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={i18n.t("maintenance.altualScheduled.edit")}>
                      <Button
                        className={styles.iconColor}
                        startIcon={<Edit />}
                        onClick={() => {
                          setScheduleDialog_id(row.id);
                          setScheduleDialog_Date(row.scheduledDate);
                          setScheduleDialog_Provisional(row.isProvisionalDate);
                          setScheduleDialog_type(row.type.toString());
                          setScheduleDialog_state(row.state.toString());
                          setScheduleDialog_open(true);
                        }}
                        hidden={readOnly}
                      ></Button>
                    </Tooltip>
                    <Tooltip
                      title={i18n.t("maintenance.altualScheduled.delete")}
                    >
                      <Button
                        name="clearRowButton"
                        className={styles.iconColor}
                        startIcon={<Clear />}
                        onClick={() => {
                          handleDelete(row.id);
                        }}
                        hidden={readOnly}
                      ></Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={scheduleDialog_open} onClose={() => scheduleDialogClose()}>
        <DialogTitle id="form-dialog-title">
          {i18n.t("maintenance.altualScheduled.dialogTitle")}
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            className={styles.materialTextField}
            name="scheduleDate"
            margin="dense"
            id="scheduleDate"
            label={i18n.t("maintenance.altualScheduled.date")}
            type="date"
            defaultValue={
              scheduleDialog_Date &&
              moment(scheduleDialog_Date).local().format("yyyy-MM-DD")
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
            error={scheduleDialog_showErrors && !scheduleDialog_Date}
            helperText={
              scheduleDialog_showErrors &&
              !scheduleDialog_Date &&
              i18n.t("textField.required")
            }
            onChange={(event) => setScheduleDialog_Date(event.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={scheduleDialog_Provisional}
                className={styles.checkBoxForm}
                color="default"
                onChange={(event) => {
                  setScheduleDialog_Provisional(event.target.checked);
                  setScheduleDialog_state("0");
                }}
                name="provisionalDate"
              />
            }
            label={i18n.t("maintenance.altualScheduled.provisionalDate")}
          />
          <TextField
            className={styles.materialTextField}
            id="type"
            name="type"
            select
            label={i18n.t("maintenance.altualScheduled.type")}
            value={scheduleDialog_type}
            onChange={(event) => setScheduleDialog_type(event.target.value)}
            fullWidth
            required
            error={scheduleDialog_showErrors && !scheduleDialog_type}
            helperText={
              scheduleDialog_showErrors &&
              !scheduleDialog_type &&
              i18n.t("textField.required")
            }
          >
            <MenuItem key={3} value="3">
              {i18n.t("maintenance.altualScheduled.moduleBase")}
            </MenuItem>
            <MenuItem key={0} value="0">
              {i18n.t("maintenance.altualScheduled.module1")}
            </MenuItem>
            <MenuItem key={1} value="1">
              {i18n.t("maintenance.altualScheduled.module2")}
            </MenuItem>
            <MenuItem key={2} value="2">
              {i18n.t("maintenance.altualScheduled.module3")}
            </MenuItem>
          </TextField>
          <TextField
            className={styles.materialTextField}
            id="state"
            name="state"
            select
            label={i18n.t("maintenance.altualScheduled.state")}
            value={scheduleDialog_state}
            disabled={scheduleDialog_Provisional}
            onChange={(event) => setScheduleDialog_state(event.target.value)}
            fullWidth
            required
            error={scheduleDialog_showErrors && !scheduleDialog_state}
            helperText={
              scheduleDialog_showErrors &&
              !scheduleDialog_state &&
              i18n.t("textField.required")
            }
            style={{ marginTop: 20 }}
          >
            <MenuItem key={0} value="0">
              {i18n.t("maintenance.altualScheduled.pending")}
            </MenuItem>
            <MenuItem key={1} value="1">
              {i18n.t("maintenance.altualScheduled.done")}
            </MenuItem>
            <MenuItem key={2} value="2">
              {i18n.t("maintenance.altualScheduled.cancelled")}
            </MenuItem>
            <MenuItem key={3} value="3">
              {i18n.t("maintenance.altualScheduled.proposed")}
            </MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={styles.closeDialogButton}
            onClick={() => scheduleDialogClose()}
          >
            {i18n.t("maintenance.altualScheduled.cancel")}
          </Button>
          <Button
            onClick={() => scheduleDialogSave()}
            className={styles.acceptDialogButton}
          >
            {scheduleDialog_id
              ? i18n.t("maintenance.altualScheduled.save")
              : i18n.t("maintenance.altualScheduled.add")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* @ts-ignore */}
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("maintenance.altualScheduled.DialogDeleteTitle")}
        text={i18n.t("maintenance.altualScheduled.DialogDeleteText")}
        cancelText={i18n.t(
          "maintenance.altualScheduled.DialogDeleteCancelButtonText"
        )}
        confirmText={i18n.t(
          "maintenance.altualScheduled.DialogDeleteConfirmButtonText"
        )}
        confirmAction={() => deleteScheduledPreventiveMaintenance(deleteRow)}
      />
    </>
  );
}
export default ActualScheduledMaintenance;
