import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import App from "./App";
import { AppContextProvider } from "./contexts/AppContext";
// @ts-ignore
import MyriadPro from "../src/assets/css/fonts.css";
import { Auth0Provider } from "@auth0/auth0-react";

//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
//MyriadPro
const myriad = {
  fontFamily: "Myriad Pro Regular",
  fontStyle: "normal",
  fontWeight: "normal",
  src: `
    local('MyriadPro'),
    local('Myriad Pro Regular'),
    url(${MyriadPro}) format('woff')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const theme = createMuiTheme({
  typography: {
    fontFamily: "MyriadPro , sans-serif",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [myriad],
      },
    },
  },
});

ReactDOM.render(
  <Auth0Provider
    domain="accounts.danfoss.com"
    clientId="Obt2COPV2xsVrGxyty4VuU1OvJAEtaYV"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: "https://MyDanfossAccountBackend.azurewebsites.net/",
      scope:
        "openid profile email read.users https://danfoss.com/identity_id/ https://danfoss.com/is_workforce/",
    }}
  >
    <BrowserRouter basename={baseUrl}>
      <ThemeProvider theme={theme}>
        <AppContextProvider initValue={{}}>
          <App />
        </AppContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Auth0Provider>,
  rootElement
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
