import axios from "axios";
import { baseUrl } from "../helpers/Url";
import { Roles, AccessType } from "../helpers/Common";

const TOKEN_URL = `${baseUrl}/connect/token`;
const REGISTER_URL = `${baseUrl}/api/userManager/registro`;
const VERIFY_URL = `${baseUrl}/api/userManager/verificar`;

class AuthService {
  login(credentials) {
    /*iNGESIS: We add the parameter version temporarily to solve 
    the accesses with uppercase instead of lowerCamelCase.*/
    return axios.post(
      TOKEN_URL,
      `token=${credentials.token}&grant_type=token&version=1`
    );
  }

  register(nombre, apellidos, empresa, cargo, telefono, email, partner) {
    return axios.post(REGISTER_URL, null, {
      params: {
        nombre,
        apellidos,
        empresa,
        cargo,
        telefono,
        email,
        partner,
      },
    });
  }

  verify(token) {
    return axios.post(VERIFY_URL, null, {
      params: { token },
    });
  }

  logOut() {
    localStorage.setItem("fromLogOut", "true");
    localStorage.removeItem("tokenInfo");
    localStorage.removeItem("dashboardFilters");
    localStorage.removeItem("reportFilters");
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem("tokenInfo"));
  }

  setUserInfo(userInfo) {
    localStorage.setItem("tokenInfo", JSON.stringify(userInfo));
  }

  /**
   * @returns {boolean}
   */
  isAuthenticated() {
    const userInfo = this.getUserInfo();
    if (userInfo === null) return false;

    if (userInfo.expireDate === undefined) return true;
    else return new Date(userInfo.expireDate) >= new Date();
  }

  /**
   * @returns {boolean}
   */
  isInRole(role) {
    const userInfo = this.getUserInfo();
    if (userInfo === null) return false;

    return userInfo.roles.includes(role);
  }

  /**
   * @returns {boolean}
   */
  hasRoleAdmin() {
    return this.isInRole(Roles.Admin);
  }

  /**
   * @returns {boolean}
   */
  hasRoleDSC() {
    return this.isInRole(Roles.DSC);
  }

  /**
   * @returns {boolean}
   */
  hasRolePartner() {
    return this.isInRole(Roles.Partner);
  }

  /**
   * @returns {boolean}
   */
  hasRoleEndCustomer() {
    return this.isInRole(Roles.EndCustomer);
  }

  /**
   * @returns {boolean}
   */
  isGestorFromPartner(partnerId) {
    if (!partnerId) {
      return false;
    }
    const userInfo = this.getUserInfo();
    if (userInfo === null) return false;

    if (this.isInRole(Roles.Partner) || this.isInRole(Roles.DSC)) {
      return userInfo.partnerAccesos.some(
        (a) =>
          a.accessType === AccessType.Manager &&
          a.partnerId.toString() === partnerId.toString()
      );
    }

    return false;
  }

  /**
   * @returns {boolean}
   */
  isGestorFromPartnerForClient(clienteId) {
    if (!clienteId) {
      return false;
    }
    const userInfo = this.getUserInfo();
    if (userInfo === null) {
      return false;
    }
    if (userInfo.roles.includes(Roles.Admin)) {
      return true;
    }
    const accesosPartnerGestor = userInfo.partnerAccesos.filter(
      (a) => a.accessType === AccessType.Manager
    );
    let result = false;
    if (accesosPartnerGestor.length) {
      accesosPartnerGestor.forEach((access) => {
        access.partner.partnerClientes.forEach((element) => {
          if (element.clienteId.toString() === clienteId.toString()) {
            result = true;
          }
        });
      });
    }
    return result;
  }

  /**
   * @returns {boolean}
   */
  isGestorFromPlant(plantaId) {
    if (!plantaId) {
      return false;
    }
    const userInfo = this.getUserInfo();
    if (userInfo === null) return false;

    return userInfo.plantaAccesos.some(
      (a) =>
        a.accessType === AccessType.Manager &&
        a.plantaId.toString() === plantaId.toString()
    );
  }

  /**
   * @returns {boolean}
   */
  isGestorFromZone(zoneId) {
    if (!zoneId) {
      return false;
    }
    const userInfo = this.getUserInfo();
    if (userInfo === null) return false;

    return userInfo.zonaAccesos.some(
      (a) =>
        a.accessType === AccessType.Manager &&
        a.plantaZonaId.toString() === zoneId.toString()
    );
  }

  addTempAccessToPartner(partnerId) {
    const userInfo = this.getUserInfo();
    if (userInfo === null) return false;

    userInfo.partnerAccesos.push({
      accessType: AccessType.Manager,
      appUser: null,
      appUserId: userInfo.userid,
      partner: null,
      partnerId: partnerId,
    });

    this.setUserInfo(userInfo);
  }

  addTempAccessToClient(partnerId, clientId) {
    if (!this.hasRoleAdmin()) {
      const userInfo = this.getUserInfo();
      if (userInfo === null) return false;
      userInfo.partnerAccesos.map((pa) => {
        if (pa.partnerId === partnerId) {
          const pcs = [...pa.partner.partnerClientes];
          pcs.push({
            cliente: null,
            clienteId: clientId,
            partner: null,
            partnerId: 1,
          });
          pa.partner.partnerClientes = pcs;
        }
        return pa;
      });
      this.setUserInfo(userInfo);
    }
  }

  addTempAccessToPlant(plantaId) {
    const userInfo = this.getUserInfo();
    if (userInfo === null) return false;

    userInfo.plantaAccesos.push({
      accessType: AccessType.Manager,
      appUser: null,
      appUserId: userInfo.userid,
      planta: null,
      plantaId: plantaId,
    });

    this.setUserInfo(userInfo);
  }

  addTempAccessToZone(zonaId) {
    const userInfo = this.getUserInfo();
    if (userInfo === null) return false;

    userInfo.zonaAccesos.push({
      accessType: AccessType.Manager,
      appUser: null,
      appUserId: userInfo.userid,
      plantaZona: null,
      plantaZonaId: zonaId,
    });

    this.setUserInfo(userInfo);
  }

  updateUserAccessPermisions(userAccessPermissions) {
    const userInfo = this.getUserInfo();
    if (userInfo === null) {
      return false;
    }
    let updateTokenInfo = false;
    if (
      !userAccessPermissions.partnerAccesos &&
      !userAccessPermissions.clienteAccesos &&
      !userAccessPermissions.plantaAccesos &&
      !userAccessPermissions.zonaAccesos &&
      !userAccessPermissions.roles
    ) {
      this.logOut();
      window.location.replace("/unauthorized");
    }

    const isEqualsJson = (obj1, obj2) => {
      if (!obj1) {
        obj1 = [];
      }
      if (!obj2) {
        obj2 = [];
      }
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      /*return true when the two json has same length and 
      all the properties has same value key by key*/
      return (
        keys1.length === keys2.length &&
        Object.keys(obj1).every((key) => obj1[key] === obj2[key])
      );
    };
    if (userInfo.isReadOnly !== userAccessPermissions.isReadOnly) {
      userInfo.isReadOnly = userAccessPermissions.isReadOnly;
      updateTokenInfo = true;
    }
    if (userInfo.isPremium !== userAccessPermissions.isPremium) {
      userInfo.isPremium = userAccessPermissions.isPremium;
      updateTokenInfo = true;
    }
    if (userInfo.isRegionManager !== userAccessPermissions.isRegionManager) {
      userInfo.isRegionManager = userAccessPermissions.isRegionManager;
      updateTokenInfo = true;
    }
    if (userInfo.isGlobal !== userAccessPermissions.isGlobal) {
      userInfo.isGlobal = userAccessPermissions.isGlobal;
      updateTokenInfo = true;
    }
    if (
      !isEqualsJson(
        userInfo.partnerAccesos,
        userAccessPermissions.partnerAccesos
      )
    ) {
      userInfo.partnerAccesos = userAccessPermissions.partnerAccesos;
      updateTokenInfo = true;
    }
    if (
      !isEqualsJson(
        userInfo.clienteAccesos,
        userAccessPermissions.clienteAccesos
      )
    ) {
      userInfo.clienteAccesos = userAccessPermissions.clienteAccesos;
      updateTokenInfo = true;
    }
    if (
      !isEqualsJson(userInfo.plantaAccesos, userAccessPermissions.plantaAccesos)
    ) {
      userInfo.plantaAccesos = userAccessPermissions.plantaAccesos;
      updateTokenInfo = true;
    }
    if (
      !isEqualsJson(userInfo.zonaAccesos, userAccessPermissions.zonaAccesos)
    ) {
      userInfo.zonaAccesos = userAccessPermissions.zonaAccesos;
      updateTokenInfo = true;
    }
    if (!isEqualsJson(userInfo.roles, userAccessPermissions.roles)) {
      this.logOut();
      window.location.replace("/login");
    }

    if (updateTokenInfo) {
      this.setUserInfo(userInfo);
    }
  }

  /**
   * @returns {number}
   */
  getPartnerId() {
    const userInfo = this.getUserInfo();
    if (userInfo === null) {
      return 0;
    }

    const partners = userInfo.partnerAccesos;
    const partnersGestor = partners.filter(
      (p) => p.accessType === AccessType.Manager
    );
    let partnerId = 0;
    if (partnersGestor.length > 0) {
      partnerId = parseInt(partnersGestor[0].partnerId);
    }
    return isNaN(partnerId) ? 0 : partnerId;
  }

  /**
   * Comprueba si el usuario es premium y en caso de ser EndUser, si tiene acceso a plantas premium.
   * @returns {number} 0 - No es premium, 1 - Es premium, 2 - Tiene plantas premium, pero no todas ellas.
   */
  userPremium() {
    const userInfo = this.getUserInfo();

    if (userInfo === null) {
      return 0;
    }

    if (
      userInfo.roles.includes(Roles.Admin) ||
      userInfo.roles.includes(Roles.DSC)
    ) {
      return 1;
    }

    if (userInfo.roles.includes(Roles.Partner)) {
      return userInfo.plantaAccesos.some((pa) => pa.premium) ? 1 : 0;
    }

    if (userInfo.plantaAccesos?.filter((row) => row.premium)?.length > 0) {
      var isPartialyPremium =
        userInfo.plantaAccesos?.filter((row) => row.premium)?.length !==
        userInfo.plantaAccesos?.length;

      return isPartialyPremium ? 2 : 1;
    }

    return 0;
  }

  /**
   * Busca en plantaAccesos si el usuario tiene acceso PREMIUM a la planta.
   * @param {number} id Id de la planta
   * @return {boolean} IsPremium
   */
  plantPremium(id) {
    const userInfo = this.getUserInfo();
    if (userInfo === null) {
      return false;
    }

    if (
      userInfo.roles.includes(Roles.Admin) ||
      userInfo.roles.includes(Roles.DSC)
    ) {
      return true;
    }

    return (
      userInfo.plantaAccesos?.find(
        (plac) => plac?.plantaId?.toString() === id?.toString()
      )?.premium || false
    );
  }
}

export default new AuthService();
