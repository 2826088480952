import React, { useState } from "react";

const withHover = (Component, { init = 1, hovered = 3 }) => {
  return function(props) {
    const [elevation, setElevation] = useState(init)

    const onMouseOver = () => setElevation(hovered);

    const onMouseOut = () => setElevation(init);

    return (
      <Component
        className="cursor-pointer"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        elevation={elevation}
        {...props}
      />
    );
  }
};

export default withHover;
