import { createStyles, makeStyles } from "@material-ui/core";
import DanfossDIPLogo from "../images/logo-DIP.svg";
import drive360Logo from "../images/logo-360live.png";

const globalColors = {
  primaryColor: "#FFFFFF",
  secondaryColor: "#303030",
  tertiaryColor: "#FFFFFF",
  accentColor: "#E2000F",
  activeColor: "#B6000F",
  neutralColor: "#BFBEBE",
  neutralStrongColor: "#969696",
  neutralStrongerColor: "#484848",
  neutralWeakerColor: "#F4F4F4",
};

const globalStatusColors = {
  infoColor: "#46B9F3",
  successColor: "#14C510",
  warningColor: "#FFAA00",
  errorColor: "#E2000F",
};

const surfaceColors = {
  surfaceColor: "#F4F4F4",
  surfaceWeakColor: "#57575659",
  surfaceWeakerColor: "#FFFFFFD8",
  surfaceStrongColor: "#EBEBEB",
  surfaceStrongerColor: "#BFBEBE",
  surfaceStrongestColor: "#484848",
  surfaceInverseColor: "#303030",
  surfaceHeaderColor: "#D71920",
};

const surfaceStatusColors = {
  surfaceInfoColor: "#F6FBFE",
  surfaceSuccessColor: "#F3FCF3",
  surfaceWarningColor: "#FFFBF2",
  surfaceErrorColor: "#FEF2F3",
};

const textColors = {
  textColor: "#BFBEBE",
  textStrongColor: "#969696",
  textStrongerColor: "#686868",
  textStrongestColor: "#484848",
};

const outlineColors = {
  outlineColor: "#BFBEBE",
  outlineWeakColor: "#D8D8D9",
  outlineWeakerColor: "#F4F4F4",
  outlineStrongColor: "#969696",
  outlineStrongerColor: "#7C7C7C",
  outlineStrongestColor: "#484848",
};

const objectColors = {
  objectColor: "#BFBEBE",
  objectWeakColor: "#D8D8D9",
  objectWeakerColor: "#F4F4F4",
  objectWeakestColor: "#F4F4F4",
  objectStrongColor: "#969696",
  objectStrongerColor: "#7C7C7C",
  objectStrongestColor: "#686868",
  objectInverseColor: "#484848",
  //These colors are used to signify the state of an object (active / inactive)
  objectActiveColor: "#E2000F7F",
  objectActiveStrongColor: "#E2000F33",
  objectInactiveColor: "#630304",
};

const objectStatusColors = {
  objectInfoColor: "#46B9F3",
  objectSucessColor: "#14C510",
  objectWarningColor: "#FFAA00",
  objectErrorColor: "#E2000F",
};

const cardContainerBaseStyle = {
  display: "grid",
  gridRowGap: "16px",
  gridColumnGap: "16px",
  justifyContent: "center",
  width: "100%",
  margin: "0 auto",
  listStyle: "none",
};

const cardBaseStyle = {
  width: "370px",
  height: 230,
};

const rawPrimaryButton = {
  color: "white",
  backgroundColor: globalColors.accentColor,
  borderRadius: 2,
  paddingLeft: 20,
  paddingRight: 20,
  "&:hover": {
    backgroundColor: globalColors.activeColor,
  },
};

const primaryButton = {
  ...rawPrimaryButton,
  marginLeft: 10,
  height: 40,
};

const rawSecondaryButton = {
  backgroundColor: "#FFFFFF",
  color: globalColors.accentColor,
  borderColor: globalColors.accentColor,
  borderRadius: 2,
  paddingLeft: 15,
  paddingRight: 15,
  "&:hover": {
    borderColor: globalColors.activeColor,
    color: globalColors.activeColor,
  },
};

const secondaryButton = {
  ...rawSecondaryButton,
  marginLeft: 20,
  height: 40,
};

const separatorLineContainer = {
  marginLeft: "auto",
  marginRight: "auto",
  marginBottom: 5,
  backgroundColor: globalColors.secondaryColor,
  backgroundSize: "cover",
  height: 3,
  width: "90%",
  //backgroundImage: `url("${Gbar}")`,
};

//Pills
const normalPill = {
  width: 110,
  height: 20,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 90,
  zIndex: 3,
  marginTop: 5,
};

const absoluteBanner = {
  display: "flex",
  alignItems: "center",
  fontSize: 18,
  paddingLeft: 8,
  paddingRight: 8,
  gap: 8,
  borderRadius: "0 0 5px 5px",
  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
};

const Common_Style = makeStyles(() =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },

    mainContainerRow: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },

    mainContainerRowNoGap: {
      display: "flex",
      flexDirection: "row",
    },

    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      gap: "16px",
    },

    header: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },

    infoCardContainer: {
      display: "flex",
      justifyContent: "space-between",
      gap: "16px",
      width: "contain",
      overflow: "ignore",
      "@media (max-width: 730px)": {
        flexDirection: "column",
      },
    },

    infoCard: {
      width: "100%",
      minWidth: 200,
    },

    searchFieldContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
      width: "100%",
      maxWidth: 650,
      margin: "auto",
      flexWrap: "wrap",
    },

    searchField: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      gap: "16px",
      flexWrap: "wrap",
    },

    searchFieldIntreeView: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      gap: "16px",
      marginLeft: "48px",
      flexWrap: "wrap",
    },

    searchActionContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
      width: "100%",
      maxWidth: "665px",
      margin: "auto",
    },

    searchZonesContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "16px",
      width: "100%",
      margin: "auto",
    },

    searchActionField: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },

    cardsContainerBase: {
      ...cardContainerBaseStyle,
      gridTemplateColumns: "repeat(auto-fit, 370px)",
      gridAutoRows: "max-content",
    },

    cardBase: {
      ...cardBaseStyle,
    },
    cardBaseNotValidated: {
      ...cardBaseStyle,
      borderLeft: `4px solid ${objectStatusColors.objectWarningColor}`,
    },

    scrollCard: {
      paddingBottom: 8,
      overflow: "auto",
    },

    scrollCardContent: {
      minWidth: 750,
    },

    primaryButtonRaw: {
      ...rawPrimaryButton,
      textTransform: "none",
      fontWeight: "bold",
    },

    primaryButton: {
      ...primaryButton,
      textTransform: "none",
      fontWeight: "bold",
    },

    secondaryButtonRaw: {
      ...rawSecondaryButton,
      textTransform: "none",
      fontWeight: "bold",
    },

    partiallyPremiumBanner: {
      margin: "-16px",
      marginBottom: "0px",
      backgroundColor: surfaceColors.surfaceColor,
      borderBottom: "2px solid",
      borderBottomColor: surfaceColors.surfaceWeakColor,
      zIndex: 11,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontSize: 18,
      gap: 8,
      padding: "2px 8px",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    },

    partiallyPremiumBannerIcon: {
      fontSize: 24,
      color: globalStatusColors.infoColor,
    },

    bannerIcon: { fontSize: 18, marginRight: 6 },
  })
);

const Auth_Style = makeStyles(() =>
  createStyles({
    container: {
      backgroundImage: "radial-gradient(#e2000f, #b6000f);",
      height: "100vh",
      overflow: "auto",
      paddingTop: 23,
    },

    logoContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingRight: 30,
    },

    danfossLogo: {
      backgroundImage: `url(${DanfossDIPLogo})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      height: 112,
      width: 316,
    },

    d360LifeLogoContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 20,
      width: "100%",
    },

    d360LifeLogo: {
      backgroundImage: `url(${drive360Logo})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      height: 100,
      width: 100,
    },

    partnerLogo: {
      height: 69,
      width: 69,
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    },

    d360LifeTitle: {
      color: "#303030",
    },

    separator: {
      width: "100%",
      marginTop: 0,
      borderBottom: "1px solid #d8d8d9",
    },

    authCardContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "65%",
    },

    authCard: {
      width: 500,
      marginBottom: 80,
      marginTop: 80,
    },

    authForm: {
      display: "flex",
      flexDirection: "column",
      padding: 32,
      paddingTop: 16,
      gap: 16,
    },

    authTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontWeight: "bold",
      color: "#303030",
    },

    authLoadingContainer: {
      minHeight: 150,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    authLoading: {
      color: "#E2000F !important",
      zIndex: 11,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      padding: 0,
    },

    authMessage: {
      fontSize: 18,
    },

    authPartnerAvatarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 16,
    },

    authPartnerAvatar: {
      fontSize: "24px !important",
      height: "60px !important",
      width: "60px !important",
    },

    requestContainer: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 8,
      paddingRight: 8,
      gap: 16,
    },

    requestStatusLabel: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: -6,
    },

    unathorizedTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: "bold",
      color: "#303030",
    },

    welcomeMessage: {
      textIndent: 20,
      fontSize: 18,
      textAlign: "justify",
      margin: 20,
    },

    authButtonContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },

    authButton: {
      ...primaryButton,
      textTransform: "none",
      fontSize: 18,
      fontWeight: "bold",
      minWidth: 150,
    },

    authSecondaryButton: {
      ...secondaryButton,
      textTransform: "none",
      fontSize: 18,
      fontWeight: "bold",
      minWidth: 150,
    },

    materialTextField: {
      "& label.Mui-focused": {
        color: globalColors.secondaryColor + "!important",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: globalColors.secondaryColor + "!important",
      },
    },

    checkBoxForm: {
      color: globalColors.secondaryColor + "!important",
    },

    linkColor: {
      color: globalColors.secondaryColor,
      "&:hover": {
        color: globalColors.secondaryColor,
      },
      textDecoration: "underline",
    },

    secondaryButton: {
      ...secondaryButton,
      marginLeft: "auto",
      fontWeight: "bold",
      textTransform: "none",
    },

    closeDialogButton: {
      ...secondaryButton,
      borderColor: globalColors.secondaryColor + "!important",
      backgroundColor: globalColors.secondaryColor + "!important",
      marginLeft: "auto",
      fontWeight: "bold",
      textTransform: "none",
      border: "1px dashed #333",
    },

    footer: {
      position: "fixed",
      bottom: 0,
      width: "100%",
      backgroundColor: globalColors.secondaryColor,
      height: 30,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: 10,
    },

    footerLink: {
      textDecoration: "none",
      marginLeft: 10,
      marginRight: 10,
      fontSize: 14,
      color: "white",
      "&:hover": {
        color: "white",
      },
    },

    footerText: {
      textDecoration: "underline",
    },
  })
);

//Styles for Card Lists
const CardList_Style = makeStyles(() =>
  createStyles({
    // USER AND ZONE LIST CONTAINER AND CARD:
    userZoneCardContainer: {
      ...cardContainerBaseStyle,
      gridTemplateColumns: "repeat(auto-fit, 300px)",
    },

    userZoneCard: {
      width: "300px",
      height: 160,
    },

    // REQUEST ACCESS LIST CONTAINER AND CARD: (RegistrosList.js)
    requestAccessCardContainer: {
      ...cardContainerBaseStyle,
      gridTemplateColumns: "repeat(auto-fit, 420px)",
    },

    requestAccessCard: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "420px",
      height: 325,
      backgroundColor: "#FFFFFFD8 !important",
      padding: "2px",
      "& .MuiCardContent-root": {
        padding: "0px",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      "& .MuiCardActions-root": {
        padding: "0px",
        paddingLeft: "6px",
        paddingBottom: "8px",
      },
      textWrap: "nowrap",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },

    userCard: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "420px",
      height: "200px",
      padding: 2,
      paddingBottom: 3,
      backgroundColor: "#FFFFFFD8 !important",
      textWrap: "nowrap",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },

    userCardDissabled: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "420px",
      height: "200px",
      padding: 2,
      paddingBottom: 3,
      backgroundColor: "#BFBEBE !important",
      textWrap: "nowrap",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },

    userCardContent: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      alignContent: "flex-start",
    },

    userCardPropietyText: {
      display: "inline-block",
      marginRight: "8px",
    },

    userCardDataText: {
      display: "inline-block",
    },

    userCardBadge: {
      marginLeft: "12px",
      marginBottom: "8px",
      color: "#46B9F3 !important",
    },

    driveCardContainer: {
      ...cardContainerBaseStyle,
      gridTemplateColumns: "repeat(auto-fit, 250px)",
      overflow: "visible !important",
      paddingTop: "4px",
      paddingBottom: "4px",
    },

    driveListContainer: {
      display: "flex",
      width: "100%",
      height: "calc(100vh - 420px)",
      overflow: "auto",
      paddingRight: 8,

      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EBEBEB",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },

    driveListInfiniteContainer: {
      //Para que al cambiar entre viewmodes salga el scroll
      overflow: "visible !important",
      minHeight: "300px",
    },

    driveListCardContainer: {
      display: "flex",
      width: "100%",
      height: "calc(100vh - 300px)",
      overflow: "auto",

      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EBEBEB",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },

    driveListContainerInfinity: {
      display: "grid",
      width: "100%",
    },

    driveCard: {
      "& .MuiCardContent-root": {
        paddingBottom: "4px !important",
        paddingTop: "4px !important",
      },
      width: "250px !important",
    },

    driveCardSuccesNoBorder: {
      position: "relative",
      width: "270px",
    },

    driveCardSuccesBorder: {
      position: "relative",
      width: "270px",
      borderLeft: globalStatusColors.successColor + " 4px solid",
    },

    driveCardWarningBorder: {
      position: "relative",
      width: "270px",
      borderLeft: globalStatusColors.warningColor + " 4px solid",
    },

    driveCardErrorBorder: {
      position: "relative",
      width: "270px",
      borderLeft: globalStatusColors.errorColor + " 4px solid",
    },

    diveCardIcon: {
      fontSize: "20.5px !important",
    },

    driveCardIconsContainer: {
      width: "100%",
      marginBottom: "10px",
    },

    driveCardFParagraphContainer: {
      width: "100%",
      paddingLeft: "3px",
      marginBottom: "10px",
    },

    driveCardLParagraphContainer: {
      width: "100%",
      paddingLeft: "3px",
    },

    driveCardContentText: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontSize: "14px",
      flexDirection: "row",
    },

    driveCardContainerNoStatus: {
      display: "inline-block",
      width: "8px",
      height: "8px",
      marginLeft: "3px",
      backgroundColor: "#969696",
      borderRadius: "100%",
    },

    driveCardContainerSuscessStatus: {
      display: "inline-block",
      width: "8px",
      height: "8px",
      marginLeft: "3px",
      backgroundColor: globalStatusColors.successColor,
      borderRadius: "100%",
    },

    driveCardContainerWarningStatus: {
      display: "inline-block",
      width: "8px",
      height: "8px",
      marginLeft: "3px",
      backgroundColor: globalStatusColors.warningColor,
      borderRadius: "100%",
    },

    driveCardContainerErrorStatus: {
      display: "inline-block",
      width: "8px",
      height: "8px",
      marginLeft: "3px",
      backgroundColor: globalStatusColors.errorColor,
      borderRadius: "100%",
    },

    driveCardContentSpan: {
      fontWeight: "bold",
    },

    driveListHeader: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "space-between",
      alignItems: "space-between",
      height: "100px !important",
      backgroundColor: globalColors.neutralWeakerColor + "!important",
      overflow: "hidden",
      paddingRight: "16px",
    },

    driveListSelectorHeader: {
      fontSize: "13px !important",
      "& .MuiSelect-root": {
        fontWeight: "bold !important",
        textWrap: "wrap !important",
      },
      position: "relative",
    },

    driveListSelectorHeaderActive: {
      fontSize: "13px !important",
      color: globalColors.activeColor + "!important",
      "& .MuiSelect-root": {
        fontWeight: "bold",
        textWrap: "wrap !important",
        color: globalColors.activeColor + "!important",
      },
    },

    driveListSelectorMenuContainer: {
      maxHeight: "400px !important",

      display: "flex",
      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EBEBEB",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },

    driveList: {
      display: "flex",
      width: "100%",
      minWidth: "1400px",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "space-between",
      alignItems: "space-between",
      height: "100px",
      minHeight: "100px",
      borderBottom: `1px solid ${outlineColors.outlineColor}`,
      transition: "background-color 0.9s, box-shadow 0.4s",
      "&:hover": {
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      },
    },

    driveListTextHeaderContainer: {
      width: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: "13px !important",
      fontWeight: "bold",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      display: "flex",
    },

    driveListClickableHeaderContainer: {
      userSelect: "none",
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },

    driveListClickableHeaderContainerActive: {
      userSelect: "none",
      color: globalColors.activeColor,
      "&:hover": {
        textDecoration: "underline",
        cursor: "pointer",
      },
    },

    driveListMenuItemHeader: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },

    driveListTextContainer: {
      width: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },

    driveListCheckBoxContainer: {
      display: "flex",
      width: "3%",
      minWidth: "40px !important",
      height: "100%",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
    },

    driveListSNContainer: {
      display: "flex",
      width: "16%",
      minWidth: "220px",
      height: "100%",
      flexDirection: "column",
      alignContent: "flex-start",
      alignItems: "flex-start",
      justifyContent: "center",
      fontSize: "15px",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },

    driveListSizeContainer: {
      display: "flex",
      width: "6%",
      minWidth: "100px",
      paddingTop: "5px",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "15px",
      overflow: "hidden",
      wordBreak: "break-word",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },

    driveListNameContainer: {
      display: "flex",
      width: "8%",
      minWidth: "120px",
      height: "100%",
      flexDirection: "column",
      alignContent: "flex-start",
      alignItems: "flex-start",
      justifyContent: "center",
      fontSize: "15px",
      overflow: "hidden",
      wordBreak: "break-word",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },

    driveListRecomendationHeaderContainer: {
      display: "flex",
      minWidth: "130px",
      width: "10%",
      height: "100%",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    },

    driveListRecomendationContainer: {
      display: "flex",
      width: "10%",
      minWidth: "110px",
      height: "38px",
      flexDirection: "row",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      overflow: "hidden",
    },

    driveListTextRecomendation: {
      width: "100%",
      overflow: "hidden",
      wordBreak: "break-word",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      alignItems: "center",
      justifyContent: "center",
      alignContent: "center",
      textAlign: "center",
      fontSize: "14px",
      alignSelf: "center",
    },

    driveListRetrofitContainer: {
      display: "flex",
      paddingLeft: "2px",
      width: "5%",
      minWidth: "60px",
      height: "100%",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "15px",
      overflow: "hidden",
      wordBreak: "break-word",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },

    driveListStatusContainer: {
      display: "flex",
      padding: "2px",
      width: "8%",
      minWidth: "120px",
      height: "100%",
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "15px",
      overflow: "hidden",
      wordBreak: "break-word",
      whiteSpace: "nowrap",
      alignSelf: "center",
    },

    driveListFormControl: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: outlineColors.outlineColor + "!important",
          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
        },
      },
      "& .MuiSelect-select": {
        backgroundColor: "transparent !important",
      },
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
    },

    driveListSelector: {
      textAlign: "center",
      fontSize: "15px !important",
      height: "70%",
      alignSelf: "center",
      "& label.Mui-focused": {
        color: globalColors.secondaryColor + "!important",
      },
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },

    driveListSelectorSuscess: {
      display: "flex",
      alignItems: "center !important",
      justifyContent: "center !important",
      flexDirection: "row",
      textAlign: "center",
      fontSize: "15px !important",
      height: "70%",
      alignSelf: "center",
      backgroundColor: globalStatusColors.successColor + "!important",
      color: "white !important",
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },

    driveListSelectorWarning: {
      textAlign: "center",
      fontSize: "15px !important",
      height: "70%",
      alignSelf: "center",
      backgroundColor: globalStatusColors.warningColor + "!important",
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },

    driveListSelectorError: {
      textAlign: "center",
      fontSize: "15px !important",
      height: "70%",
      alignSelf: "center",
      backgroundColor: globalStatusColors.errorColor + "!important",
      color: "white !important",
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },

    driveListSelectorActive: {
      textAlign: "center",
      fontSize: "15px !important",
      height: "70%",
      alignSelf: "center",
      backgroundColor: objectColors.objectWeakestColor + "!important",
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },

    driveListSelectorInactive: {
      textAlign: "center",
      fontSize: "15px !important",
      height: "70%",
      alignSelf: "center",
      backgroundColor: objectColors.objectStrongColor + "!important",
      color: "white !important",
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingRight: "0px",
        paddingLeft: "0px",
      },
    },

    // DOCUMENT LIST CONTAINER AND CARD:
    documentCardContainer: {
      ...cardContainerBaseStyle,
      gridTemplateColumns: "repeat(auto-fit, 430px)",
      "@media (max-width: 600px)": {
        gridTemplateColumns: "repeat(auto-fit, 185px)",
      },
    },

    documentCard: {
      width: "430px",
      maxHeight: 85,
      gap: "16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "@media (max-width: 600px)": {
        flexDirection: "column",
        width: "185px",
        maxHeight: "none",
        margin: "auto",
      },
    },

    documentCardLeft: {
      overflow: "hidden",
    },

    documentCardRight: {
      display: "flex",
      flexDirection: "row",
      paddingRight: 20,
      paddingBottom: 18,
      "@media (max-width: 600px)": {
        margin: "auto",
      },
    },

    // TASK LIST CONTAINER AND CARD:
    taskCardContainer: {
      display: "flex",
      justifyContent: "space-between",
      gap: "16px",
      width: "contain",
      overflow: "ignore",
      "@media (max-width: 730px)": {
        flexDirection: "column",
      },
    },

    taskCard: {
      width: "100%",
      minWidth: 200,
    },

    // To be deleted (START)

    secondaryButton: {
      ...secondaryButton,
      fontWeight: "bold",
      textTransform: "none",
    },

    // To be deleted (END)

    //Containers
    mainFormContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      flexDirection: "column",
      flexWrap: "wrap",
    },

    //Icons
    tabHeaderAvatar: {
      backgroundColor: globalColors.secondaryColor + " !important",
    },

    dialogIcon: {
      color: globalColors.secondaryColor,
      verticalAlign: "sub",
    },

    addCircleOutline: {
      marginRight: 6,
    },

    //Override Material Styles
    materialTextField: {
      "& label.Mui-focused": {
        color: globalColors.secondaryColor + "!important",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: globalColors.secondaryColor + "!important",
      },
    },

    DriveToggleButtonSelected: {
      width: 160,
      // @ts-ignore
      textTransform: "none !important",
      fontSize: "16px !important",
      height: 40,
      marginTop: "auto",
      border: `2px solid ${outlineColors.outlineStrongerColor} !important`,
      backgroundColor: objectColors.objectStrongestColor + "!important",
      color: globalColors.primaryColor + "!important",
      "&:hover": {
        backgroundColor: objectColors.objectColor + "!important",
        color: globalColors.primaryColor + "!important",
      },
    },

    DriveToggleButton: {
      width: 160,
      // @ts-ignore
      textTransform: "none !important",
      fontSize: "16px !important",
      height: 40,
      marginTop: "auto",
      border: `2px solid ${outlineColors.outlineStrongerColor} !important`,
      backgroundColor: objectColors.objectWeakestColor + "!important",
      color: globalColors.secondaryColor + "!important",
      "&:hover": {
        backgroundColor: objectColors.objectColor + "!important",
        color: globalColors.primaryColor + "!important",
      },
    },
  })
);

//Styles for Details with Tabs
const DetailTabs_Style = makeStyles(() =>
  createStyles({
    userFormContainerWidth: {
      width: "65%",
    },

    //Form Buttons
    primaryFormButton: {
      ...primaryButton,
      fontWeight: "bold",
      textTransform: "none",
      marginLeft: 0,
      marginRight: 10,
      marginBottom: 10,
    },

    secondaryFormButton: {
      ...secondaryButton,
      fontWeight: "bold",
      textTransform: "none",
      marginLeft: 0,
      marginRight: 10,
      marginBottom: 10,
    },

    primarySingleButton: {
      ...primaryButton,
      marginLeft: 0,
      marginRight: 0,
      marginTop: 10,
      marginBottom: 10,
      fontWeight: "bold",
      textTransform: "none",
    },

    saveDialogEquipmentButton: {
      ...primaryButton,
      fontWeight: "bold",
      textTransform: "none",
      marginLeft: 5,
    },

    activationEquipmentButton: {
      ...primaryButton,
      alignSelf: "end",
      alignContent: "end",
      marginLeft: 0,
    },

    acceptDialogButton: {
      ...primaryButton,
      marginRight: 20,
      marginLeft: 10,
      fontWeight: "bold",
      textTransform: "none",
    },

    secondaryButton: {
      ...secondaryButton,
      fontWeight: "bold",
      textTransform: "none",
    },

    imageSecondaryButton: {
      ...secondaryButton,
      marginLeft: "auto",
    },

    secondaryMaintenanceButton: {
      ...secondaryButton,
      backgroundColor: globalColors.primaryColor,
      marginLeft: 0,
      fontWeight: "bold",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#F4F4F4",
      },
    },

    desactivationEquipmentButton: {
      ...secondaryButton,
      alignSelf: "end",
      alignContent: "end",
      marginLeft: 0,
    },

    closeDialogEquipmentButton: {
      ...secondaryButton,
      fontWeight: "bold",
      textTransform: "none",
      paddingLeft: 25,
      paddingRight: 25,
    },

    closeDialogButton: {
      ...secondaryButton,
      marginRight: 10,
      marginLeft: 20,
      textTransform: "none",
    },

    navigationButton: {
      ...secondaryButton,
      fontWeight: "bold",
      textTransform: "none",
    },

    equipmentReviewButton: {
      ...rawSecondaryButton,
      marginRight: 0,
      minWidth: 200,
      textTransform: "none",
      fontWeight: "bold",
    },

    iconButton: {
      alignSelf: "end",
      color: globalColors.accentColor,
      backgroundColor: globalColors.primaryColor,
      borderColor: globalColors.accentColor,
      margin: 0.1,
      scale: 0.8,
    },

    filterButton: {
      ...rawSecondaryButton,
      textTransform: "none",
      fontWeight: "bold",
      padding: "0px !important",
      paddingLeft: "6px !important",
    },

    //Containers
    mainPageContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      width: "100%",
    },

    tabsHeader: {
      height: "3px !important",
      borderTopLeftRadius: "3px !important",
      borderTopRightRadius: "3px !important",
      backgroundColor: globalColors.accentColor + "!important",
    },

    zoneGridContainer: {
      width: "100%",
      backgroundColor: globalColors.primaryColor,
      boxShadow:
        "0.0px 1.0px 3.0px 0px rgba(0, 0, 0, 0.2),0.0px 1.0px 1.0px 0px rgba(0, 0, 0, 0.14)",
    },

    PartnerClientListMainCard: {
      flexDirection: "row",
      display: "flex",
      width: "100%",
      height: "100%",
    },

    infinityPartnerClientListContainer: {
      overflow: "auto !important",
      width: "30vw !important",
      minWidth: "216px",
      height: "calc(100vh - 200px) !important",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EBEBEB",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },
    partnerClientFormContainer: {
      width: "75vw",
      maxHeight: "calc(100vh - 200px)",
      //display: "flex",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EBEBEB",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },

    partnerClientListAvatar: {
      color: "white !important",
      backgroundColor: "#484848 !important",
    },

    partnerClientListAvatarNewClient: {
      //color: "#E2000F",
      //backgroundColor: "#F4F4F4",
      color: "#F4F4F4 !important",
      backgroundColor: "#E2000F !important",
    },

    partnerClientListItem: {
      width: "100%",
      overflow: "hidden",
    },

    partnerClientListItemText: {
      textWrap: "nowrap",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },

    partnerClientListCollapse: {
      paddingLeft: "32px !important",
    },

    partnerClientListInactive: {
      paddingLeft: "32px !important",
      backgroundColor: "#969696 !important",
      color: "white !important",
    },

    separatorLineContainer100: {
      ...separatorLineContainer,
      height: "0.1em",
      width: "100%",
      marginBottom: 20,
      marginTop: 20,
      padding: "1",
    },

    separatorLineMantienance: {
      ...separatorLineContainer,
      height: "0.1em",
      width: "100%",
      padding: 1,
      backgroundColor: "#969696",
    },

    separatorLineContainer: {
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: 5,
      backgroundColor: globalColors.secondaryColor,
      backgroundSize: "cover",
      height: 3,
      width: "90%",
    },

    zoneList: {
      width: "48%",
      margin: 5,
      marginLeft: 0,
    },

    variatorCardUtil: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      height: "100%",
      maxWidth: "100%",
    },

    variatorCardUtilContent: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 15,
    },

    variatorCard: {
      position: "relative",
      width: "100%",
    },

    plantPaper: {
      display: "flex",
      justifyContent: "center",
    },

    accordionForm: {
      width: "100%",
    },

    ocrContainerForm: {
      whiteSpace: "pre-wrap",
      maxWidth: "100%",
      overflow: "auto",
    },

    formContainerWithLogo: {
      display: "flex",
      flexDirection: "row",
      "@media (max-width: 900px)": {
        flexDirection: "column",
      },
    },

    logoInputContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "16px 16px 16px 8px",
      paddingLeft: 16,
      borderLeft: "1px solid #ccc",
      "@media (max-width: 900px)": {
        borderLeft: "none",
        marginLeft: "auto",
      },
    },

    logoInputLabel: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 12,
      marginBottom: 2,
      color: "#757575",
    },

    logoInputImg: {
      height: 130,
      width: 130,
      borderRadius: 6,
    },

    logoInputButtons: {
      marginTop: 2,
      marginLeft: "auto",
    },

    //Icons and Links
    tabHeaderAvatar: {
      backgroundColor: globalColors.secondaryColor + " !important",
    },

    addCircleOutline: {
      marginRight: 6,
    },

    addCircleOutlineNewDrive: {
      marginRight: 6,
      color: globalColors.accentColor + "!important",
    },

    menuItemSpace: {
      "& .MuiListItemIcon-root": {
        minWidth: "unset !important",
      },
    },

    ghostFilterButtonContainer: {
      width: "65px",
      minHeight: "45px",
      display: "flex",
    },

    menuActiveText: {
      color: globalColors.activeColor + "!important",
    },

    menuMargin: {
      marginTop: "50px !important",
    },

    saveIcon: {
      marginRight: 6,
    },

    deleteIcon: {
      marginRight: 6,
    },

    dialogIcon: {
      color: globalColors.secondaryColor,
      verticalAlign: "sub",
    },

    iconColor: {
      color: objectColors.objectInverseColor,
    },

    outlineIcon: {
      position: "absolute",
      right: 0,
      top: -5,
    },

    imageCardContainer: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      height: "20vh",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },

    imageReactPanZoomContainer: {
      height: "80%",
      width: "80%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: 10,
      paddingRight: 60,
    },

    treeViewLeftSideContainer: {
      width: "30vw",
      minWidth: "216px",
      flexDirection: "column",
      display: "flex",
      overflow: "auto",
      gap: "16px",
    },

    treeViewRightSideContainer: {
      flexGrow: 1,
      width: "100%",
      minWidth: "50vw",
      overflow: "hidden",
    },

    treeViewCardContainer: {
      display: "flex",
      width: "100% !important",
      minWidth: "216px !important",
      height: "70px ",
      overflow: "hidden",
      gap: "0px !important",
      cursor: "pointer",
      backgroundColor: globalColors.neutralWeakerColor + "!important",
      marginTop: "2px",
    },

    treeViewCardHeader: {
      width: "100% !important",
      //padding: "8px !important",
    },

    treeViewCardIcon: {
      color: globalColors.neutralStrongerColor + "!important",
      fontSize: "18px !important",
    },

    treeViewCardText: {
      fontSize: "18px !important",
      maxWidth: "15vw !important",
      flexDirection: "row",
      display: "inline-block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontWeight: 580,
    },

    treeViewCardSubText: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      alignContent: "flex-start",
      marginLeft: "-5px !important",
    },

    treeViewContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "calc(100vh - 390px)",
      // minHeight: "calc(100vh - 390px)",
      // maxHeight: "calc(100vh - 390px)",
      maxWidth: "30vw",
      minWidth: "216px",
      //overflow: "auto",
      overflowY: "auto",
      overflowX: "visible",

      gap: "0px !important",
      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EBEBEB",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },

    treeViewSearch: {
      minWidth: 200,
      maxHeight: "50px !important",
    },

    treeViewList: {
      "& .MuiListItem-gutters": {
        padding: "0px !important",
      },
    },

    treeViewListItem: {
      display: "flex",
      textWrap: "nowrap",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "auto",

      maxWidth: "calc(100% - 10px)",
    },

    treeViewListItemIcon: {
      minWidth: "30px !important",
    },

    treeViewIcon: {
      color: "#484848 !important",
    },

    treeViewSubIcon: {
      color: "#757575 !important",
      scale: 0.7,
    },

    treeViewListItemText: {
      fontSize: "20px !important",
      fontWeight: "lighter",
      textWrap: "nowrap",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },

    treeViewListItemTextActive: {
      fontSize: "20px !important",
      fontWeight: "lighter",
      textWrap: "nowrap",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: globalColors.accentColor + "!important",
    },

    treeViewItemCount: {
      display: "none",
    },

    itemCount: {
      position: "absolute",
      left: 50,
    },

    //Material override styles
    detailTabLabel: {
      fontWeight: "bold",
    },

    variatorDocumentationLink: {
      color: globalColors.accentColor,
      height: 40,
      alignSelf: "center",
      marginLeft: 0,
      marginTop: 10,
      fontWeight: "bold",
      textTransform: "none",
    },

    checkBoxForm: {
      color: globalColors.secondaryColor + "!important",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },

    checkBoxFormDisabled: {
      color: surfaceColors.surfaceStrongerColor + "!important",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },

    checkAllText: {
      color: "#B6000F !important",
      textAlign: "center",
    },

    separatorLineForm: {
      backgroundColor: outlineColors.outlineColor,
      width: "100%",
      marginTop: -3,
      height: 1,
    },

    withInputLabelLifeCicle: {
      width: "134%",
    },

    infoStatusBgColor: {
      backgroundColor: globalStatusColors.infoColor,
    },

    infoStatusColor: {
      color: globalStatusColors.infoColor,
    },

    surfaceWeakestBgColor: {
      backgroundColor: surfaceColors.surfaceWeakerColor,
    },

    headerColor: {
      backgroundColor: "#EBEBEB",
    },

    selectReadOnlyTextfield: {
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
    },

    selectFormRisckDrive: {
      paddingLeft: 5,
    },

    selectReadOnlyFormRisckDrive: {
      paddingLeft: 5,
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
    },

    provisionalColor: {
      backgroundColor: globalStatusColors.warningColor + "!important",
    },

    objectWeakestColor: {
      color: objectColors.objectWeakestColor,
    },

    objectInverseBgColor: {
      backgroundColor: objectColors.objectInverseColor,
    },

    objectInverseColor: {
      color: objectColors.objectInverseColor,
    },

    sucessStatusBgColor: {
      backgroundColor: globalStatusColors.successColor + "!important",
      color: globalColors.primaryColor + "!important",
      "& .MuiSelect-icon": {
        color: globalColors.primaryColor + "!important",
      },
    },

    sucessStatusColor: {
      color: globalStatusColors.successColor,
    },

    warningStatusBgColor: {
      backgroundColor: globalStatusColors.warningColor + "!important",
    },

    warningStatusColor: {
      color: globalStatusColors.warningColor,
    },

    errorStatusBgColor: {
      backgroundColor: globalStatusColors.errorColor + "!important",
      color: globalColors.primaryColor + "!important",
      "& .MuiSelect-icon": {
        color: globalColors.primaryColor + "!important",
      },
    },

    errorStatusColor: {
      color: globalStatusColors.errorColor,
    },

    checkCircleColorManteinance: {
      color: objectColors.objectInverseColor,
    },

    linkColor: {
      color: globalColors.secondaryColor,
      "&:hover": {
        color: globalColors.secondaryColor,
      },
      textDecoration: "underline",
    },

    assessmentTableCell_Header: {
      backgroundColor: globalColors.accentColor,
      color: globalColors.primaryColor,
    },

    linkPrimaryColor: {
      color: globalColors.accentColor,
      "&:hover": {
        color: globalColors.accentColor,
      },
      textDecoration: "underline",
    },

    assessmentTableCell_Typography: {
      fontSize: 16,
      fontWeight: "bold",
    },

    assessmentTableCell_NoColor: {
      padding: "6px 0",
    },

    assessmentTableCell_SuccessColor: {
      borderLeft: "3px solid rgba(224, 224, 224, 1)",
      backgroundColor: globalStatusColors.successColor,
      padding: "6px 0",
    },

    assessmentTableCell_WarningColor: {
      backgroundColor: globalStatusColors.warningColor,
      padding: "6px 0",
    },

    assessmentTableCell_ErrorColor: {
      backgroundColor: globalStatusColors.errorColor,
      padding: "6px 0",
    },
    assessmentTableCell_BorderLeft: {
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
      padding: "6px 0",
    },

    assessmentTableCell_BorderLeft_NoPadding: {
      borderLeft: "3px solid rgba(224, 224, 224, 1)",
    },

    siteAssessmentStatusCell: {
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 2,
      width: "100%",
      textAlign: "center",
      fontSize: 15,
      color: "white",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    },

    siteAssessmentStatusGood: {
      backgroundColor: "#14C510",
    },

    siteAssessmentStatusBad: {
      backgroundColor: "#E2000F",
    },

    siteAssessmentStatusMedium: {
      backgroundColor: "#FFAA00",
    },

    siteAssessmentStatusNone: {
      backgroundColor: "#969696",
    },

    tabSurfaceColor: {
      backgroundColor: surfaceColors.surfaceColor,
    },

    typographyColor: {
      color: textColors.textStrongestColor,
    },

    manteinTimeLineRow: {
      height: 65,
      backgroundColor: surfaceColors.surfaceStrongestColor,
    },

    scheduledTypeCell: {
      color: textColors.textStrongestColor,
      wordBreak: "break-word",
      textAlign: "center",
      alignContent: "center",
    },

    newsPageTitle: {
      color: textColors.textStrongestColor,
      fontSize: 36,
      lineHeight: "48px",
      display: "flex",
      alignSelf: "center",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      textAlign: "center",
    },

    newsPage: {
      paddingLeft: "16px",
      paddingRight: "8px",
      backgroundColor: globalColors.neutralWeakerColor + "!important",
    },

    newsSectionTitle: {
      color: textColors.textStrongestColor,
      fontSize: 28,
      lineHeight: "48px",
    },

    newsListTitle: {
      color: textColors.textStrongestColor,
      fontSize: 24,
      lineHeight: "48px",
    },

    dialogEmergencyStock: {
      //marginTop: "50px",
    },

    dialogEmergencyStockTitle: {
      borderBottom: "1px solid lightgrey",
      backgroundColor: globalColors.activeColor,
      color: "white",
      height: "60px",
      alignContent: "flex-start",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },

    dialogESNDrivesContainer: {
      marginTop: "10px",
    },

    dialogESTextContainer: {
      marginTop: "20px",
      marginBottom: "10px",
    },

    dialogESAlertContainer: {
      width: "400px",
      maxHeight: "500px",
    },

    dialogESListContainer: {
      padding: "10px",
      maxHeight: "200px",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EBEBEB",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },

    dialogEmergencyStockActions: {
      width: "100%",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      alignContent: "flex-end",
      paddingRight: "15px",
    },

    dialogSettings: {
      "& .MuiDialogContent-root": {
        padding: "0px !important",
      },
      "& .MuiDialogActions-root": {
        padding: "0px !important",
      },
    },

    dialogSettingsTitle: {
      backgroundColor: globalColors.activeColor + "!important",
      color: "white",
      height: "40px",
      alignContent: "flex-start",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingLeft: "16px",
    },

    dialogSettingsContent: {
      width: "500px !important",
      height: "400px !important",
      backgroundColor: globalColors.neutralWeakerColor + "!important",
      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#EBEBEB",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
    },

    dialogSettingsMenu: {
      padding: "0px !important",
    },

    dialogSettingsMenuItem: {
      height: "60px",
    },

    dialogSettingsMenuIcon: {
      color: "#686868 !important",
    },

    dialogSettingsMenuLanguage: {
      marginTop: "50px",
      marginLeft: "20px",
      boxShadow:
        "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important",
      "& .MuiMenu-list": {
        width: "480px !important",
        padding: "0px !important",
      },
    },

    dialogSettingsMenuLanguageItem: {
      backgroundColor: "#EBEBEB !important",
      "&:hover": {
        backgroundColor: "#D8D8D9 !important",
      },
    },

    dialogSettingsActions: {
      width: "100%",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      alignContent: "flex-end",
      backgroundColor: "#F4F4F4 !important",
    },

    dialogSettingsButton: {
      ...secondaryButton,
      fontWeight: "bold",
      textTransform: "none",
      paddingLeft: 25,
      paddingRight: 25,
      marginRight: "20px",
      marginBottom: "10px",
    },

    userPopover: {
      "& .MuiPopover-paper": {
        marginLeft: "16px",
        boxShadow:
          "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important",
      },
    },

    userPopoverCloseContainer: {
      width: "25px",
      height: "25px",
      position: "absolute",
      right: "8px",
      top: "8px",
      cursor: "pointer",
    },

    userPopoverCloseIcon: {
      color: "#686868 !important",
    },

    userPopoverContainer: {
      width: "200px",
      height: "280px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      border: "1px solid #D8D8D9",
      backgroundColor: "#FFFFFF",
    },

    userPopoverContainerTop: {
      width: "100%",
      height: "175px",
      display: "flex",
      flexDirection: "column",
    },

    userPopoverContainerAvatarContainer: {
      width: "100%",
      height: "100px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },

    userPopoverAvatar: {
      width: "60px",
      height: "60px",
      textTransform: "uppercase",
      backgroundColor: "#B6000F !important",
      fontSize: "30px",
    },

    userPopoverTextContainer: {
      width: "200px",
      height: "25px",
      flexDirection: "row",
      paddingLeft: "16px",

      fontSize: "15px",
      alignContent: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      wordBreak: "break-word",
      color: "#686868",
    },

    userPopoverDivisor: {
      width: "180px",
      height: "1px",
      display: "flex",
      alignSelf: "center",
      backgroundColor: "#D8D8D9",
    },

    userPopoverBottomContainer: {
      width: "100%",
      height: "90px",
      display: "flex",
      flexDirection: "column",
    },

    userPopoverMenuContainer: {
      width: "100%",
      height: "45px",
      display: "flex",
      alignContent: "center",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    userPopoverButton: {
      textTransform: "none",
      width: "100%",
      height: "100%",
      justifyContent: "flex-start",
    },

    userPopoverIcon: {
      marginLeft: "40px",
      fontSize: "15px",
    },

    userPopoverTextButton: {
      marginLeft: "8px",
      fontSize: "15px",
      color: "#484848 !important",
    },

    dialogNewsHeader: {
      marginLeft: "-8px",
      alignContent: "center",
      justifyContent: "center",
      textAlign: "center",
      display: "inline-block",
    },

    dialogNewsHeaderButton: {
      color: "white",
      fontSize: "20px",
      textTransform: "none",
    },

    materialTextField: {
      "& label.Mui-focused": {
        color: globalColors.secondaryColor + "!important",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: globalColors.secondaryColor + "!important",
      },
    },

    materialTextFieldSelectorReadOnly: {
      "& label.Mui-focused": {
        color: globalColors.secondaryColor + "!important",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: globalColors.secondaryColor + "!important",
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "transparent",
      },
    },

    materialTextFieldRequired: {
      "& label.Mui-focused": {
        color: globalColors.secondaryColor + "!important",
      },
      "& .MuiInput-underline": {
        borderBottomColor: globalColors.secondaryColor + "!important",
      },
    },

    materialTextFieldOutlined: {
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused, &.Mui-active, &:hover": {
          "& fieldset": {
            borderColor: globalColors.secondaryColor + "!important",
          },
        },
      },
      "& label.Mui-focused": {
        color: globalColors.secondaryColor + "!important",
      },
    },

    //Pills
    flexPill: {
      ...normalPill,
      backgroundColor: globalColors.secondaryColor,
    },

    flexInfoPill: {
      ...normalPill,
      backgroundColor: globalStatusColors.infoColor,
    },

    flexSuccessPill: {
      ...normalPill,
      backgroundColor: globalStatusColors.successColor,
    },

    flexWarningPill: {
      ...normalPill,
      backgroundColor: globalStatusColors.warningColor,
    },

    flexErrorPill: {
      ...normalPill,
      backgroundColor: globalStatusColors.errorColor,
    },

    pillText: {
      fontWeight: "bold",
      fontSize: 10,
      color: "white",
    },

    pillBlackText: {
      fontWeight: "bold",
      fontSize: 10,
    },

    pillProvisionalText: {
      fontWeight: "bold",
      fontSize: 11.5,
      color: globalColors.secondaryColor,
    },

    pillContainer: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      right: 5,
      top: 10,
    },

    //Forms
    mainFormContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      flexDirection: "column",
      flexWrap: "wrap",
    },

    mainCardFormContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
    },

    mainCardFormHeader: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: "1px solid #ccc",
    },

    partnerClientHeader: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },

    partnerClientHeaderDivisor: {
      alignSelf: "center",
      marginBottom: "-30px",
      display: "flex",
      width: "100%",
      height: "1px",
      backgroundColor: "#969696",
    },

    labelInfoFromSalesforce: {
      ...absoluteBanner,
      marginLeft: 16,
      color: "white",
      width: "fit-content",
    },

    formCardContainer: {
      alignItems: "start",
      width: "100%",
      height: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      minWidth: "15em",
    },

    formCardStartAlingContainer: {
      alignItems: "start",
      width: "100%",
      height: "100%",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "start",
      minWidth: "15em",
      maxWidth: "50%",
    },

    di: {
      borderBottom: "0px !important",
    },

    flexRowForm: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      flexDirection: "row",
      flexWrap: "wrap",
      minWidth: "99%",
      maxWidth: "100%",
      flexGrow: 1,
    },

    flexCellForm: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-start",
      flexDirection: "row",
      minWidth: "15em",
      flexGrow: 1,
      margin: "1em",
      flex: 1,
    },

    pmButtonsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },

    pmButtons: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "1em",
    },

    tabTreeViewIcon: {
      fontSize: "30px !important",
    },
  })
);

const NoDataStyle = makeStyles(() =>
  createStyles({
    noDataContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      opacity: 0.8,
      padding: 24,
    },
    noDataIconContainer: {
      marginBottom: 25,
      backgroundColor: "#FEF2F3",
      color: "#E2000F",
      borderRadius: "100%",
      height: 200,
      width: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    noDataTitle: {
      fontSize: 32,
    },
    noDataText: {
      fontSize: 16,
    },
  })
);

const TabReportPlantaStyle = makeStyles(() =>
  createStyles({
    mainContainer: {
      height: "content-fit",
      display: "flex",
      flexDirection: "row-reverse",
      gap: "inherit",
      "@media (max-width: 850px)": {
        flexDirection: "column",
      },
    },
    leftContainer: {
      width: "30%",
      height: "content-fit",
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 850px)": {
        width: "100%",
      },
    },
    rightContainer: {
      width: "70%",
      "@media (max-width: 850px)": {
        width: "100%",
      },
    },
    tabContainer: {
      marginBottom: "16px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    tabsHeader: {
      height: "3px !important",
      borderTopLeftRadius: "3px !important",
      borderTopRightRadius: "3px !important",
      backgroundColor: globalColors.accentColor + "!important",
    },
    pdfViewer: { minHeight: "70vh", width: "100%" },
    reportOptionsTitle: {
      backgroundColor: "#E2000F",
      color: "white",
      fontFamily: "MyriadPro",
      fontWeight: "bold",
      fontSize: 18,
    },
    reportOptionsContent: {
      marginBottom: 10,
    },
    AcordionSelectedTitle: {
      backgroundColor: "#E2000F",
      color: "white",
      fontWeight: "bold",
    },
    AcordionSelectedTitleIcon: {
      color: "white",
    },
    AcordionContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      marginRight: "auto",
    },
    checkboxContainer: {
      display: "flex",
      flexDirection: "row",
    },
    checkboxLabel: {
      marginTop: "auto",
      marginBottom: "auto",
    },
    checkboxLabelDisabled: {
      marginTop: "auto",
      marginBottom: "auto",
      color: surfaceColors.surfaceStrongerColor + " !important",
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  })
);

const DashboardHeaderStyle = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: "16px",
      flexWrap: "wrap",
      alignSelf: "center",
      width: "100%",
      "@media (max-width: 850px)": {
        flexDirection: "column",
      },
    },
    cardContainerPartner: {
      minWidth: "calc((100%/4) - 15px)",
      maxWidth: "calc((100%/4) - 15px)",
      "@media (max-width: 850px)": {
        minWidth: "100%",
      },
      "@media (min-width: 851px) and (max-width: 1100px)": {
        minWidth: "calc((100%/3) - 15px)",
        maxWidth: "calc((100%/3) - 15px)",
      },
      // height: 150,
    },
    cardContainerDSC: {
      minWidth: "calc((100%/4) - 15px)",
      maxWidth: "calc((100%/4) - 15px)",
      "@media (max-width: 850px)": {
        minWidth: "100%",
      },
      "@media (min-width: 851px) and (max-width: 1100px)": {
        minWidth: "calc((100%/4) - 15px)",
        maxWidth: "calc((100%/4) - 15px)",
      },
      // height: 150,
    },
    cardHeaderContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    cardHeaderNum: { fontWeight: "bold", marginLeft: 8 },
    h4: { textAlign: "center" },
  })
);

export {
  globalColors,
  globalStatusColors,
  surfaceColors,
  surfaceStatusColors,
  textColors,
  outlineColors,
  objectColors,
  objectStatusColors,
  Auth_Style,
  Common_Style,
  CardList_Style,
  DetailTabs_Style,
  NoDataStyle,
  TabReportPlantaStyle,
  DashboardHeaderStyle,
};
